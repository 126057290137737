import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";

import homCards from "../../assets/img/home-cards.svg";

const VCardSection = () => {
  const navigate = useNavigate();

  return (
    <div className="hv-card">
      <div className="hv-card__main">
        <div className="hv-card__header">
          <p className="hv-card__sub-heading">Introducing</p>
          <h2 className="hv-card__heading">SekiApp Virtual Card</h2>
        </div>
        <div className="hv-card__body">
          <p>
            This features instant payment and card security management. You can
            pay for everything (Netflix, Amazon, Spotify, Apple Music, EBay and
            more), anywhere with the SekiApp Virtual Dollar Card.
          </p>
          <p>Start making payments right away.</p>
        </div>
        <div>
          <button
            className="hv-card__button"
            onClick={() => navigate("/virtual-cards")}
          >
            Get a Card <IonIcon icon={arrowForwardOutline} />
          </button>
        </div>
      </div>
      <img src={homCards} alt="" className="hv-card__img" />
    </div>
  );
};

export default VCardSection;
