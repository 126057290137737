import playstore from "../../assets/img/playstore-btn.png";
import appstore from "../../assets/img/apple-btn.png";
import playstoreDark from "../../assets/img/playstore-dark-btn.png";
import appstoreDark from "../../assets/img/appstore-dark-btn.png";

import heroLight from "../../assets/img/hero-light.png";
import heroDark from "../../assets/img/hero-dark.png";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="container hero-section__container">
        <div className="hero-section__content">
          <h1 className="hero-section__heading">
            Crypto Exchange Powered By Freedom
          </h1>
          <div className="hero-section__desc">
            Store, Sell, Buy and Swap Cryptocurrencies and Giftcards for free in
            Nigeria!
          </div>
          <div className="hero-section__actions">
            <a
              href="https://play.google.com/store/apps/details?id=com.wiseki.sekiapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={playstore}
                alt=""
                className="hero-section__action light-block"
              />
              <img
                src={playstoreDark}
                alt=""
                className="hero-section__action dark-block"
              />
            </a>
            <a
              href="https://apps.apple.com/ng/app/sekiapp/id1622624126"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={appstore}
                alt=""
                className="hero-section__action light-block"
              />
              <img
                src={appstoreDark}
                alt=""
                className="hero-section__action dark-block"
              />
            </a>
          </div>
          <img
            src={heroLight}
            className="hero-section__img hero-section__img--light"
            alt=""
          />
          <img
            src={heroDark}
            className="hero-section__img hero-section__img--dark"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
