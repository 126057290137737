import { useSelector } from "react-redux";
import { useState, useEffect, FormEvent } from "react";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";

import { tRootState } from "../../../store";

import useAlert from "../../../hooks/useAlert/useAlert";

const SelectSortDates = ({
  show,
  closeHandler,
  handler,
}: {
  show: boolean;
  closeHandler: () => void;
  handler: (startDate: string, endDate: string) => void;
}) => {
  const uiMode = useSelector((state: tRootState) => state.app.uiMode);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!start || !end) return setMessage("warning", "Fill in all fields");

    handler(start, end);
  };

  useEffect(() => {
    clearMessage();
  }, [start, end, clearMessage]);

  if (!show) return null;

  return (
    <>
      <div className="currency-modal currency-modal--sm">
        <div className="currency-modal__header">
          <span className="currency-modal__close" onClick={closeHandler}>
            <IonIcon icon={close} />
          </span>
          <h3 className="currency-modal__heading">Select Sort Dates</h3>
        </div>
        <form
          className="currency-modal__list auth__form-main mt-0"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              className="form-input"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              style={uiMode === "dark" ? { colorScheme: "dark" } : {}}
            />
          </div>
          <div className="form-group">
            <label>End Date</label>
            <input
              type="date"
              className="form-input"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              style={uiMode === "dark" ? { colorScheme: "dark" } : {}}
            />
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                start && end && "form-button--active"
              )}
            >
              Sort
            </button>
          </div>
        </form>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default SelectSortDates;
