import { useSelector } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  arrowBackOutline,
  business,
  chevronForwardOutline,
  lockOpen,
  person,
  receipt,
  wallet,
} from "ionicons/icons";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import VerifyIdentity from "./VerifyIdentity";

import { assertNotNull } from "../../utils/func";
import { getProfilePicture } from "../../utils/user";

const locationData: { [key: string]: string } = {
  "/account/verify-identity": "Verify Identity",
  "/account/profile": "Profile",
  "/account/banks": "Deposit Banks",
  "/account/security": "Security",
  "/account/refer": "Refer and Earn",
};

const Account = () => {
  const navigate = useNavigate();
  const { pathname: location } = useLocation();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  const { names, username, is_verified, gender } = userDetails;

  return (
    <DashboardLayout>
      <div className="account-pg">
        <div className="account-pg__header">
          {!["/account/", "/account"].includes(location) ? (
            <span onClick={() => navigate("/account")}>
              <IonIcon icon={arrowBackOutline} />
            </span>
          ) : null}
          {locationData[location] || "Settings"}
        </div>
        <section className="account-user">
          <div className="account-user__img-block">
            <img src={getProfilePicture(gender)} alt="" />
          </div>
          <div className="account-user__info">
            <p className="account-user__name">{names}</p>
            <p className="account-user__username">{username}</p>
          </div>
          <button className="account-user__verif">
            {is_verified ? "Verified" : "Not verified"}
          </button>
          {/* {is_verified ? (
            <button className="account-user__verif">Verified</button>
          ) : (
            <MatiButton email={email} />
          )} */}
        </section>
        <ul className="account-menu">
          <li className="account-menu__item">
            <NavLink
              to="/account/verify-identity"
              className={({ isActive }) =>
                cls(
                  "account-menu__link",
                  isActive && "account-menu__link--active"
                )
              }
            >
              <IonIcon icon={person} />
              Verify Identity
            </NavLink>
          </li>
          <li className="account-menu__item">
            <NavLink
              to="/account/profile"
              className={({ isActive }) =>
                cls(
                  "account-menu__link",
                  isActive && "account-menu__link--active"
                )
              }
            >
              <IonIcon icon={person} />
              Profile
            </NavLink>
          </li>
          <li className="account-menu__item">
            <NavLink
              to="/account/banks"
              className={({ isActive }) =>
                cls(
                  "account-menu__link",
                  isActive && "account-menu__link--active"
                )
              }
            >
              <IonIcon icon={business} />
              Deposit Banks
            </NavLink>
          </li>
          <li className="account-menu__item">
            <NavLink
              to="/account/security"
              className={({ isActive }) =>
                cls(
                  "account-menu__link",
                  isActive && "account-menu__link--active"
                )
              }
            >
              <IonIcon icon={lockOpen} />
              Security
            </NavLink>
          </li>
          <li className="account-menu__item">
            <NavLink
              to="/account/refer"
              className={({ isActive }) =>
                cls(
                  "account-menu__link",
                  isActive && "account-menu__link--active"
                )
              }
            >
              <IonIcon icon={wallet} />
              Refer and Earn
            </NavLink>
          </li>
          <li className="account-menu__item">
            <NavLink
              to="/account-statement"
              className={({ isActive }) =>
                cls(
                  "account-menu__link",
                  isActive && "account-menu__link--active"
                )
              }
            >
              <IonIcon icon={receipt} />
              Account Statement
            </NavLink>
          </li>
        </ul>
        {["/account", "/account/"].includes(location) ? (
          <ul className="account-mmenu">
            <li
              className="account-mmenu__item"
              onClick={() => navigate("/account/verify-identity")}
            >
              <IonIcon icon={person} className="account-mmenu__icon" />
              <div className="account-mmenu__main">
                <p className="account-mmenu__heading">Verify Identity</p>
                <p className="account-mmenu__text">
                  Complete your account details
                </p>
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                className="account-mmenu__icon"
              />
            </li>
            <li
              className="account-mmenu__item"
              onClick={() => navigate("/account/profile")}
            >
              <IonIcon icon={person} className="account-mmenu__icon" />
              <div className="account-mmenu__main">
                <p className="account-mmenu__heading">Profile</p>
                <p className="account-mmenu__text">
                  View your profile’s details
                </p>
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                className="account-mmenu__icon"
              />
            </li>
            <li
              className="account-mmenu__item"
              onClick={() => navigate("/account/banks")}
            >
              <IonIcon icon={business} className="account-mmenu__icon" />
              <div className="account-mmenu__main">
                <p className="account-mmenu__heading">Deposit Banks</p>
                <p className="account-mmenu__text">
                  Manage deposit bank accounts
                </p>
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                className="account-mmenu__icon"
              />
            </li>
            <li
              className="account-mmenu__item"
              onClick={() => navigate("/account/security")}
            >
              <IonIcon icon={lockOpen} className="account-mmenu__icon" />
              <div className="account-mmenu__main">
                <p className="account-mmenu__heading">Security</p>
                <p className="account-mmenu__text">
                  Manage Password and Transaction PIN
                </p>
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                className="account-mmenu__icon"
              />
            </li>
            <li
              className="account-mmenu__item"
              onClick={() => navigate("/account/refer")}
            >
              <IonIcon icon={wallet} className="account-mmenu__icon" />
              <div className="account-mmenu__main">
                <p className="account-mmenu__heading">Refer and earn</p>
                <p className="account-mmenu__text">
                  Earn commission for referring
                </p>
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                className="account-mmenu__icon"
              />
            </li>
            <li
              className="account-mmenu__item"
              onClick={() => navigate("/account-statement")}
            >
              <IonIcon icon={receipt} className="account-mmenu__icon" />
              <div className="account-mmenu__main">
                <p className="account-mmenu__heading">Account Statement</p>
                <p className="account-mmenu__text">
                  Generate your account statement
                </p>
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                className="account-mmenu__icon"
              />
            </li>
          </ul>
        ) : null}
        <section className="account-pg__main">
          <Outlet />
          {["/account", "/account/"].includes(location) ? (
            <div className="account-show-desktop">
              <VerifyIdentity />
            </div>
          ) : null}
        </section>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Account);
