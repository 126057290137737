import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  alertCircle,
  arrowBackOutline,
  eyeOffOutline,
  eyeOutline,
  searchOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";

import useData from "../../hooks/useData/useData";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import Pagination from "../../components/Pagination/Pagination";
import VirtualCard from "../../components/VirtualCard/VirtualCard";

import { getPagination, getSkip } from "../../utils/func";

import virtualCardsImg from "../../assets/img/vcards.png";

const DIVISION = 6;

const VirtualCards = () => {
  const navigate = useNavigate();

  const virtualCards = useSelector(
    (state: tRootState) => state.cache.virtualCards
  );

  const { fetchVirtualCards } = useData();

  const [search, setSearch] = useState("");

  const [vCards, setVCards] = useState(virtualCards || []);

  const [page, setPage] = useState(1);

  const [showBalances, setShowBalances] = useState(false);

  useEffect(() => {
    if (!virtualCards) return;

    setVCards(
      virtualCards.filter((vCard) => new RegExp(search, "i").test(vCard.alias))
    );
    setPage(1);
  }, [virtualCards, search]);

  useEffect(() => {
    fetchVirtualCards();
  }, [fetchVirtualCards]);

  if (!virtualCards) return <Preloader />;

  // For pagination
  const pagination = vCards ? getPagination(vCards.length, DIVISION) : 0;
  const skip = getSkip(page, DIVISION);

  return (
    <DashboardLayout>
      {virtualCards.length ? (
        <div className="virtual-card virtual-card--md">
          <div className="virtual-card__header">
            <span
              className="virtual-card__back"
              onClick={() => navigate("/dashboard")}
            >
              <IonIcon icon={arrowBackOutline} />
            </span>
            <h3 className="virtual-card__heading">Virtual card</h3>
          </div>
          <div className="virtual-card__cards-block">
            <div className="virtual-card__cards-header">
              <form className="search-form virtual-card__search-form">
                <div className="search-form__icon-block">
                  <IonIcon icon={searchOutline} />
                </div>
                <input
                  type="text"
                  placeholder="Search for card"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </form>
              <button
                className="virtual-card__cards-button"
                onClick={() => navigate("/create-virtual-card")}
              >
                <IonIcon icon={addOutline} /> Create card
              </button>
            </div>
            {vCards.length ? (
              <h3 className="virtual-card__cards-heading">
                {showBalances ? "Hide" : "Show"} balances
                <IonIcon
                  icon={showBalances ? eyeOffOutline : eyeOutline}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowBalances((sb) => !sb)}
                />
              </h3>
            ) : null}

            {vCards.length ? (
              <div className="virtual-card__cards">
                {vCards.slice(skip, skip + DIVISION).map((vcard) => (
                  <Fragment key={vcard.id}>
                    <VirtualCard
                      card={vcard}
                      clickHandler={() =>
                        navigate(`/virtual-card?card=${vcard.id}`)
                      }
                      showBalance={showBalances}
                    />
                  </Fragment>
                ))}
              </div>
            ) : (
              <div className="giftcard-transactions__empty">
                <p className="giftcard-transactions__empty-text">
                  No Virtual Cards
                </p>
                <p className="giftcard-transactions__empty-sub-text">
                  This place is empty because there is no virtual card in
                  current filter...
                </p>
              </div>
            )}
          </div>
          <div className="mt-lg">
            <Pagination pagination={pagination} page={page} setPage={setPage} />
          </div>
        </div>
      ) : (
        <div className="virtual-card">
          <div className="virtual-card__header">
            <span
              className="virtual-card__back"
              onClick={() => navigate("/dashboard")}
            >
              <IonIcon icon={arrowBackOutline} />
            </span>
          </div>
          <img className="virtual-card__img" src={virtualCardsImg} alt="" />
          <div className="virtual-card__alert">
            <IonIcon icon={alertCircle} /> You have no cards available. You are
            required to create a new card.
          </div>
          <div className="virtual-card__footer">
            <button
              className="form-button form-button--md form-button--active"
              style={{ color: "#fff", width: "30rem" }}
              onClick={() => navigate("/create-virtual-card")}
            >
              <IonIcon icon={addOutline} />
              Create Card
            </button>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default withAuth(VirtualCards);
