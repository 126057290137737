const WalletPageLoader = () => {
  return (
    <>
      {new Array(20).fill(null).map((_, i) => {
        return (
          <div className="currency" key={i}>
            <div className="currency__img-block">
              <div className="placeholder"></div>
            </div>
            <div className="currency__main">
              <p className="currency__name">
                <span className="color-transparent placeholder">USD Coin</span>
              </p>
              <div className="currency__price">
                <p className="currency__price-main">
                  <span className="color-transparent placeholder">
                    0.0006 USDC
                  </span>
                </p>
                <p>
                  <span className="color-transparent placeholder">
                    Approx. USD 2.67
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default WalletPageLoader;
