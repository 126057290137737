import { ReactNode } from "react";

import DashboardFooter from "../../components/DashboardFooter/DashboardFooter";
import DashbordHeader from "../../components/DashboardHeader/DashboardHeader";

import ModeToggle from "../../components/ModeToggle/ModeToggle";
import NewsSection from "../../components/NewsSection/NewsSection";

const DashboardLayout = ({
  children,
  showNews = false,
}: {
  children: ReactNode;
  showNews?: boolean;
}) => {
  return (
    <>
      <ModeToggle />
      <DashbordHeader />
      <main className="dashboard-main container">{children}</main>
      {showNews ? <NewsSection noPadding={true} /> : null}
      <DashboardFooter />
    </>
  );
};

export default DashboardLayout;
