import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import { tNairaTransferTransaction } from "../../../store/types/app.types";

import { getDate, roundDP, copyData } from "../../../utils/func";

const NairaTransferTransaction = ({
  transaction,
}: {
  transaction: tNairaTransferTransaction;
}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  return (
    <div className="transaction-details">
      <h3 className="transaction-details__heading">
        <span onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        Internal Naira Transfer
      </h3>
      <p className="transaction-details__heading-subtext">
        Internal Naira Transaction Details
      </p>
      <div className="transaction-details__infos">
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount</p>
          <div className="transaction-details__info-right">
            &#8358; {roundDP(transaction.amount, 2)}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Type</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.transaction_type.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">
            {transaction.transaction_type === "sent" ? "To" : "From"}
          </p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {
                transaction[
                  transaction.transaction_type === "sent" ? "to" : "from"
                ]
              }
            </p>
          </div>
        </div>
        {transaction.description ? (
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Description</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {transaction.description}
              </p>
            </div>
          </div>
        ) : null}
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Hash</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.reference}
            </p>
            <div
              className="copy"
              onClick={() => copyData(transaction.reference, setCopied)}
            >
              <IonIcon icon={copy} />
              {copied ? "Copied!" : "Copy"}
            </div>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Status</p>
          <div className="transaction-details__info-right">
            <span className="badge badge--success"> Confirmed </span>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Date</p>
          <div className="transaction-details__info-right">
            {getDate(transaction.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NairaTransferTransaction;
