import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tWallet, tVirtualCard } from "./types/app.types";

export type tFundVirtualCardTransaction = {
  hash: string;
  card_id: number;
  from_wallet: string;
  from_wallet_amount: number;
  amount_to_receive: number;
};

type VCardType = {
  fund: {
    wallet: tWallet;
    virtualCard: tVirtualCard;
    cryptoAmount: number | string;
    fiatAmount: number | string;
    receiveAmount: number | string;
    otpMessage: string;
    transaction: tFundVirtualCardTransaction;
  } | null;
};

const VCardInitialState: VCardType = {
  fund: null,
};

const vcardSlice = createSlice({
  name: "vcard",
  initialState: getState<VCardType>("vcard", VCardInitialState),
  reducers: {
    updateFundVirtualCard(
      state,
      { payload }: PayloadAction<VCardType["fund"]>
    ) {
      state.fund = payload;

      saveState("vcard", current(state));
    },
    clearFundVirtualCard(state) {
      state.fund = null;

      saveState("vcard", current(state));
    },
  },
});

export const { updateFundVirtualCard, clearFundVirtualCard } =
  vcardSlice.actions;

export default vcardSlice.reducer;
