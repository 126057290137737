import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tDepositBank } from "./types/app.types";

type tDepositTransaction = {
  bank_name: string;
  account_name: string;
  account_number: string;
  amount: number;
  hash: string;
};

type tWithdrawTransaction = {
  id: number;
  payment_hash: string;
  amount: number;
  bank_name: string;
  account_name: string;
  account_no: string;
};

type tTransferTransaction = {
  username: string;
  amount: string;
  reference: string;
};

type NairaType = {
  deposit: {
    bank: tDepositBank | null;
    amount: number | "";
    fee: number | null;
    feeText: string | null;
    transaction: tDepositTransaction | null;
  };
  withdraw: {
    transaction: tWithdrawTransaction | null;
    fee: number | null;
    emailMessage: string | null;
    OTPMessage: string | null;
    longOTPMessage: string | null;
  };
  transfer: {
    transaction: tTransferTransaction | null;
    emailMessage: string | null;
    OTPMessage: string | null;
    longOTPMessage: string | null;
  };
};

const CryptoInitialState: NairaType = {
  deposit: {
    bank: null,
    amount: "",
    fee: null,
    feeText: null,
    transaction: null,
  },
  withdraw: {
    transaction: null,
    fee: null,
    emailMessage: null,
    OTPMessage: null,
    longOTPMessage: null,
  },
  transfer: {
    transaction: null,
    emailMessage: null,
    OTPMessage: null,
    longOTPMessage: null,
  },
};

const nairaSlice = createSlice({
  name: "naira",
  initialState: getState<NairaType>("naira", CryptoInitialState),
  reducers: {
    addDepositInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        bank: tDepositBank;
        amount: number;
        fee: number;
        feeText: string;
      }>
    ) {
      state.deposit.bank = payload.bank;
      state.deposit.amount = payload.amount;
      state.deposit.fee = payload.fee;
      state.deposit.feeText = payload.feeText;

      saveState("naira", current(state));
    },
    addDepositTransaction(
      state,
      { payload }: PayloadAction<tDepositTransaction>
    ) {
      state.deposit.transaction = payload;

      saveState("naira", current(state));
    },
    clearDepositDetails(state) {
      state.deposit.bank = null;
      state.deposit.amount = "";
      state.deposit.transaction = null;

      saveState("naira", current(state));
    },
    addWithdrawInfo1(
      state,
      {
        payload,
      }: PayloadAction<{
        transaction: tWithdrawTransaction;
        fee: number;
        emailMessage: string;
      }>
    ) {
      state.withdraw.transaction = payload.transaction;
      state.withdraw.fee = payload.fee;
      state.withdraw.emailMessage = payload.emailMessage;

      saveState("naira", current(state));
    },
    addWithdrawInfo2(
      state,
      {
        payload,
      }: PayloadAction<{
        OTPMessage: string;
        longOTPMessage: string;
      }>
    ) {
      state.withdraw.OTPMessage = payload.OTPMessage;
      state.withdraw.longOTPMessage = payload.longOTPMessage;

      saveState("naira", current(state));
    },
    clearWithdrawInfo(state) {
      state.withdraw.fee = null;
      state.withdraw.transaction = null;
      state.withdraw.emailMessage = null;
      state.withdraw.OTPMessage = null;
      state.withdraw.longOTPMessage = null;

      saveState("naira", current(state));
    },
    addTransferInfo1(
      state,
      {
        payload,
      }: PayloadAction<{
        transaction: tTransferTransaction;
        emailMessage: string;
      }>
    ) {
      state.transfer.transaction = payload.transaction;
      state.transfer.emailMessage = payload.emailMessage;

      saveState("naira", current(state));
    },
    addTransferInfo2(
      state,
      {
        payload,
      }: PayloadAction<{
        OTPMessage: string;
        longOTPMessage: string;
      }>
    ) {
      state.transfer.OTPMessage = payload.OTPMessage;
      state.transfer.longOTPMessage = payload.longOTPMessage;

      saveState("naira", current(state));
    },
    clearTransferInfo(state) {
      state.transfer.transaction = null;
      state.transfer.emailMessage = null;
      state.transfer.OTPMessage = null;
      state.transfer.longOTPMessage = null;

      saveState("naira", current(state));
    },
  },
});

export const {
  addDepositInfo,
  addDepositTransaction,
  clearDepositDetails,
  addWithdrawInfo1,
  addWithdrawInfo2,
  clearWithdrawInfo,
  addTransferInfo1,
  addTransferInfo2,
  clearTransferInfo,
} = nairaSlice.actions;

export default nairaSlice.reducer;
