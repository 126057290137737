import { useRef, useEffect } from "react";
import cls from "classnames";

// import { isNumber } from "../../utils/func";

type tPinGroup = {
  className?: string;
  numInputs?: number;
  pin: string;
  handler: (value: string) => void;
  dontFocus?: boolean;
  type?: "text" | "password";
  inputType?: "alpha-num" | "num";
};

const PinGroup = ({
  className,
  numInputs,
  pin: inpPin,
  handler,
  dontFocus = true,
  type = "password",
  inputType = "num",
}: tPinGroup) => {
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const pin = new Array(numInputs).fill(null).map((_, i) => inpPin[i] || "");

  useEffect(() => {
    const emptyIndex = pin.findIndex((code) => !code);
    if ((emptyIndex || emptyIndex === 0) && inputsRef.current[emptyIndex]) {
      if (dontFocus && emptyIndex === 0) return;
      inputsRef.current[emptyIndex].focus();
    }
  }, [inpPin, pin, dontFocus]);

  useEffect(() => {
    inputsRef.current.forEach((input, i) => {
      input.addEventListener("keyup", (e) => {
        if (
          !(e.target as HTMLInputElement).value &&
          e.key === "Backspace" &&
          i > 0
        ) {
          inputsRef.current[i - 1].focus();
        }
      });
    });
  });

  return (
    <>
      {new Array(numInputs).fill(null).map((_, i) => {
        return (
          <input
            key={i}
            type={inputType === "alpha-num" ? "text" : "number"}
            className={cls(
              className || "register__pin-input",
              type === "password" && "password"
            )}
            maxLength={1}
            value={pin[i]}
            onChange={(e) => {
              // if (e.target.value && !isNumber(e.target.value)) return;

              handler(
                pin.map((v, j) => (i === j ? e.target.value : v)).join("")
              );
            }}
            ref={(el) => (inputsRef.current[i] = el!)}
            autoComplete="new-password"
          />
        );
      })}
    </>
  );
};

export default PinGroup;
