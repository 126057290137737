import { Link } from "react-router-dom";

const NewsLoader = () => {
  return (
    <>
      {new Array(5).fill(null).map((_, i) => (
        <div
          className="blog"
          style={{ transform: `translateX(${i * 100}%)` }}
          key={i}
        >
          <div className="blog__img-block">
            <div className="blog__img placeholder"></div>
          </div>
          <div className="blog__body">
            <div className="blog__title">
              <span className="color-transparent placeholder">
                Cryptocurrency Predictions
              </span>
            </div>
            <div className="blog__text">
              <p className="color-transparent placeholder mb-tiny">&nbsp;</p>
              <p className="color-transparent placeholder wm-90">&nbsp;</p>
            </div>
            <Link to="#" className="blog__link color-transparent placeholder">
              Read More
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewsLoader;
