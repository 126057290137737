const PayBillsLoader = () => {
  return (
    <>
      {new Array(8).fill(null).map((_, i) => (
        <tr key={i}>
          <td>
            <div className="transaction-label">
              <div className="transaction-label__icon-box placeholder"></div>
              <p className="bold color-transparent placeholder">Airtime</p>
            </div>
          </td>
          <td>
            <span className="color-transparent placeholder">&#8358;99.00</span>
          </td>
          <td>
            <span className="badge color-transparent placeholder">
              Completed
            </span>
          </td>
          <td>
            <span className="color-transparent placeholder">
              7 April 2023 03:17:04 PM
            </span>
          </td>
        </tr>
      ))}
    </>
  );
};

export default PayBillsLoader;
