import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { tRootState } from "../../store";

const giftCard =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const location = useLocation().pathname;

    const { giftCard, cardCountry, cardType } = useSelector(
      (state: tRootState) => state.giftcard
    );

    if (!giftCard) return <Navigate to="/gift-cards" />;

    if (
      !cardCountry &&
      !["/gift-cards/country", "/gift-cards/country/"].includes(location)
    )
      return <Navigate to="/gift-cards/country" />;

    if (
      !cardType &&
      ["/gift-cards/order-create", "/gift-cards/order-create/"].includes(
        location
      )
    )
      return <Navigate to="/gift-cards/receipt" />;

    return <Component {...props} />;
  };

export default giftCard;
