import { roundDP } from "../../utils/func";

const AmountPercentage = ({
  totalAmount,
  precision = 2,
  handler,
}: {
  totalAmount: number;
  precision?: number;
  handler: (value: number) => void;
}) => {
  const setValue = (percent: number) => {
    handler(+roundDP(percent * totalAmount, precision, true));
  };

  return (
    <div className="amount-blocks">
      <div onClick={() => setValue(0.25)}>25%</div>
      <div onClick={() => setValue(0.5)}>50%</div>
      <div onClick={() => setValue(0.75)}>75%</div>
      <div onClick={() => setValue(1)}>100%</div>
    </div>
  );
};

export default AmountPercentage;
