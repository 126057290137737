import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { alertCircle, checkmarkCircle } from "ionicons/icons";

import { tRootState } from "../../store";
import { assertNotNull } from "../../utils/func";

import { getProfilePicture } from "../../utils/user";

const UserWelcome = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  return (
    <section className="user-welcome mb-small">
      <img
        src={getProfilePicture(userDetails.gender)}
        className="user-welcome__img"
        alt=""
      />
      <p className="user-welcome__text">
        Welcome back, <span>{userDetails.username}</span>
        {userDetails.is_verified ? (
          <span className="text-success" title="Verified">
            <IonIcon icon={checkmarkCircle} />
          </span>
        ) : (
          <span
            className="text-warning"
            title="Unverified"
            onClick={() => navigate("/account/verify-identity")}
          >
            <IonIcon icon={alertCircle} />
          </span>
        )}
      </p>
      {userDetails.is_influencer ? (
        !["/influencer-dashboard", "/influencer-dashboard/"].includes(
          location
        ) ? (
          <div className="user-welcome__right">
            <button
              className="user-welcome__btn"
              onClick={() => navigate("/influencer-dashboard")}
            >
              Influencer Dashboard
            </button>
          </div>
        ) : (
          <div className="user-welcome__right">
            <button
              className="user-welcome__btn"
              onClick={() => navigate("/dashboard")}
            >
              User Dashboard
            </button>
          </div>
        )
      ) : null}
    </section>
  );
};

export default UserWelcome;
