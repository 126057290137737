import { useState, useRef, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { updatePinMessage } from "../../store/cacheSlice";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import { assertNotNull } from "../../utils/func";

import whatsapp from "../../assets/img/whatsapp.png";
import sms from "../../assets/img/sms.png";
import withAuth from "../../hoc/withAuth/withAuth";

const ChangePin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [smsType, setSMSType] = useState<"sms" | "whatsapp">("whatsapp");

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password) {
      return setMessage("warning", "Password is required");
    }

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/reset-pin-post",
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ password, option: smsType }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        dispatch(updatePinMessage(res.data.message));
        navigate("/reset-pin");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled");
        submitBtnRef.current.innerHTML = `Next`;
      });
  };

  useEffect(() => {
    clearMessage();
  }, [password, smsType, clearMessage]);

  if (!userDetails.is_pin_set) return <Navigate to="/account/security" />;

  return (
    <DashboardLayout>
      <div className="form-card">
        <div className="form-card__back mb-medium" onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
          Back
        </div>
        <div className="text-center mb-medium">
          <h3 className="heading-tertiary">Let’s change your PIN</h3>
          <p>Enter your password to verify it is you</p>
        </div>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sh) => !sh)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
          </div>
          <div className="otp-medium">
            <h3 className="otp-medium__heading">Receive OTP Via</h3>
            <div className="otp-medium__blocks">
              <div className="otp-medium__block">
                <img src={whatsapp} alt="" className="otp-medium__block-img" />
                <p className="otp-medium__block-name">Whatsapp (Instant)</p>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={smsType === "whatsapp" ? true : false}
                  onChange={(e) =>
                    setSMSType(e.target.checked ? "whatsapp" : "sms")
                  }
                />
              </div>
              <div className="otp-medium__block">
                <img src={sms} alt="" className="otp-medium__block-img" />
                <p className="otp-medium__block-name">SMS (0 - 5 MIN)</p>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={smsType === "sms" ? true : false}
                  onChange={(e) =>
                    setSMSType(e.target.checked ? "sms" : "whatsapp")
                  }
                />
              </div>
            </div>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                password && smsType && "form-button--active"
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(ChangePin);
