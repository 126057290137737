import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { setMode } from "./store/appSlice";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

import Home from "./pages/Home/Home";
import Rates from "./pages/Rates/Rates";
import FAQ from "./pages/FAQ/FAQ";
import AboutUs from "./pages/AboutUs/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import AMLPolicy from "./pages/AMLPolicy/AMLPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

import Login from "./pages/Login/Login";
import SignOut from "./pages/SignOut/SignOut";
import SignIn from "./pages/SIgnIn/SignIn";
// import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import NewForgotPassword from "./pages/ForgotPassword/NewForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NewResetPassword from "./pages/ResetPassword/NewResetPassword";
import OldUserData from "./pages/OldUserData/OldUserData";

import NewRegister from "./pages/NewRegister/NewRegister";
// import LoginDetails from "./pages/Register/LoginDetails";
import VerifyEmail from "./pages/Register/VerifyEmail";
import AddTelephone from "./pages/Register/AddTelephone";
import VerifyTelephone from "./pages/Register/VerifyTelephone";
import DateOfBirth from "./pages/Register/DateOfBirth";
import TransactionPin from "./pages/Register/TransactionPin";
import NewReferralCode from "./pages/NewRegister/NewRefferalCode";
// import ReferralCode from "./pages/Register/ReferralCode";
import RegisterVerify from "./pages/NewRegister/RegisterVerify";

import UserBlocked from "./pages/UserBlocked/UserBlocked";

import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardWalletPage from "./pages/DashboardWalletPage/DashboardWalletPage";

import InfluencerDashboard from "./pages/InfluencerDashboard/InfluencerDashboard";

import ConvertSKP from "./pages/ConvertSKP/ConvertSKP";
import SKPConverted from "./pages/ConvertSKP/SKPConverted";

import Services from "./pages/Services/Services";

import CreateVirtualCard from "./pages/CreateVirtualCard/CreateVirtualCard";
import VirtualCards from "./pages/VirtualCards/VirtualCards";
import VirtualCardDetails from "./pages/VirtualCardDetails/VirtualCardDetails";
import TopupVirtualCard from "./pages/TopupVirtualCard/TopupVirtualCard";
import TopupVirtualCardFinal from "./pages/TopupVirtualCard/TopupVirtualCardFinal";
// import DeleteVirtualCard from "./pages/DeleteVirtualCard/DeleteVirtualCard";
import FreezeVirtualCard from "./pages/FreezeVirtualCard/FreezeVirtualCard";
import UnfreezeVirtualCard from "./pages/UnfreezeVirtualCard/UnfreezeVirtualCard";

import BulkOTC from "./pages/BulkOTC/BulkOTC";
import BulkOTCSuccessful from "./pages/BulkOTC/BulkOTCSuccessful";
import AccountStatement from "./pages/AccountStatement/AccountStatement";

import Wallet from "./pages/Wallet/Wallet";

import Deposit from "./pages/Deposit/Deposit";
import DepositAmount from "./pages/Deposit/DepositAmount";
import DepositDetails1 from "./pages/Deposit/DepositDetails";
import DepositBankDetails from "./pages/Deposit/DepositBankDetails";
import DepositProcessing from "./pages/Deposit/DepositProcessing";

import DepositDispute from "./pages/DepositDispute/DepositDispute";

import Withdraw from "./pages/Withdraw/Withdraw";
import WithdrawOTP from "./pages/Withdraw/WithdrawOTP";
import WithdrawProcessing from "./pages/Withdraw/WithdrawProcessing";

import Transfer from "./pages/Transfer/Transfer";
import TransferFinal from "./pages/Transfer/TransferFinal";
import TransferSuccessful from "./pages/Transfer/TransferSuccessful";

import BuyCrypto from "./pages/BuyCrypto/BuyCrypto";
import BuyCryptoFinal from "./pages/BuyCryptoFinal/BuyCryptoFinal";

import SellCrypto from "./pages/SellCrypto/SellCrypto";
import SellCryptoFinal from "./pages/SellCryptoFinal/SellCryptoFinal";

import SwapCrypto from "./pages/SwapCrypto/SwapCrypto";
import SwapCryptoFinal from "./pages/SwapCryptoFinal/SwapCryptoFinal";

import SwapCryptoNew from "./pages/SwapCryptoNew/SwapCryptoNew";

import TransferCrypto from "./pages/TransferCrypto/TransferCrypto";
import TransferCryptoFinal from "./pages/TransferCryptoFinal/TransferCryptoFinal";

import DepositCrypto from "./pages/DepositCrypto/DepositCrypto";

import WithdrawCrypto from "./pages/WithdrawCrypto/WithdrawCrypto";
import WithdrawCryptoFinal from "./pages/WithdrawCryptoFinal/WithdrawCryptoFinal";

import GiftCards from "./pages/GiftCards/GiftCards";

import GiftCardCountry from "./pages/GiftCardCountry/GiftCardCountry";
import BuyGiftCardCountry from "./pages/BuyGiftCardCountry/BuyGiftCardCountry";

import GiftCardReceipt from "./pages/GiftCardReceipt/GiftCardReceipt";

import GiftCardOrderCreate from "./pages/GiftCardOrderCreate/GiftCardOrderCreate";
import BuyGiftCardOrderCreate from "./pages/BuyGiftCardOrderCreate/BuyGiftCardOrderCreate";
import BuyGiftCardOTP from "./pages/BuyGiftCardOTP/BuyGiftCardOTP";

import Orders from "./pages/Orders/Orders";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import OrderDispute from "./pages/OrderDispute/OrderDispute";

// import PayBills from "./pages/PayBills/PayBills";
import PayBillsNew from "./pages/PayBills/PayBillsNew";

import BuyBill from "./pages/PayBills/Bill/BuyBill";
import BillConfirm from "./pages/PayBills/Bill/BillConfirm";
import BillSuccessful from "./pages/PayBills/Bill/BillSuccessful";

// import BuyAirtime from "./pages/PayBills/Airtime/BuyAirtime";
// import AirtimeConfirm from "./pages/PayBills/Airtime/AirtimeConfirm";
// import AirtimeSuccessful from "./pages/PayBills/Airtime/AirtimeSuccessful";

// import BuyData from "./pages/PayBills/Data/BuyData";
// import DataConfirm from "./pages/PayBills/Data/DataConfirm";
// import DataSuccessful from "./pages/PayBills/Data/DataSuccessful";

// import BuyCable from "./pages/PayBills/Cable/BuyCable";
// import CableConfirm from "./pages/PayBills/Cable/CableConfirm";
// import CableSuccessful from "./pages/PayBills/Cable/CableSuccessful";

// import BuyElectricity from "./pages/PayBills/Electricity/BuyElectricity";
// import ElectricityConfirm from "./pages/PayBills/Electricity/ElectricityConfirm";
// import ElectricitySuccessful from "./pages/PayBills/Electricity/ElectricitySuccessful";

import Transactions from "./pages/Transactions/Transactions";

import TransactionDetails from "./pages/TransactionDetails/TransactionDetails";
import DepositDetails from "./pages/DepositDetails/DepositDetails";
import WithdrawalDetails from "./pages/WithdrawalDetails/WithdrawalDetails";

import Account from "./pages/Account/Account";

import VerifyIdentity from "./pages/Account/VerifyIdentity";
import Profile from "./pages/Account/Profile";
import Banks from "./pages/Banks/Banks";
import Security from "./pages/Account/Security";
import Refer from "./pages/Account/Refer";

import AddBank from "./pages/Bank/AddBank";
import BankAdded from "./pages/Bank/BankAdded";

import ChangePin from "./pages/Pin/ChangePin";
import ResetPin from "./pages/Pin/ResetPin";
import PinChanged from "./pages/Pin/PinChanged";

import NotFound from "./pages/NotFound/NotFound";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("uiMode")) {
      // Listen for changes
      window.matchMedia("(prefers-color-scheme: dark)").addListener((event) => {
        const newMode = event.matches ? "dark" : "light";

        dispatch(setMode(newMode));
      });
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rates" element={<Rates />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/aml-policy" element={<AMLPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/login" element={<Login />} />
        <Route path="/sign-out" element={<SignOut />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<NewForgotPassword />} />
        <Route path="/reset-password" element={<NewResetPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route path="/old-user-data" element={<OldUserData />} />
        <Route path="/blocked" element={<UserBlocked />} />

        <Route path="/register" element={<NewRegister />} />
        <Route path="/register/referral-code" element={<NewReferralCode />} />
        <Route path="/register/verify" element={<RegisterVerify />} />

        {/* <Route path="/register" element={<LoginDetails />} /> */}
        <Route path="/register/verify-email" element={<VerifyEmail />} />
        <Route path="/register/telephone" element={<AddTelephone />} />
        <Route
          path="/register/verify-telephone"
          element={<VerifyTelephone />}
        />
        <Route path="/register/date-of-birth" element={<DateOfBirth />} />
        <Route path="/register/transaction-pin" element={<TransactionPin />} />
        {/* <Route path="/register/referral-code" element={<ReferralCode />} /> */}

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard-wallet" element={<DashboardWalletPage />} />

        <Route path="/influencer-dashboard" element={<InfluencerDashboard />} />

        <Route path="/convert-skp" element={<ConvertSKP />} />
        <Route path="/skp-converted" element={<SKPConverted />} />

        <Route path="/services" element={<Services />} />

        <Route path="/create-virtual-card" element={<CreateVirtualCard />} />
        <Route path="/virtual-cards" element={<VirtualCards />} />
        <Route path="/virtual-card" element={<VirtualCardDetails />} />
        <Route path="/virtual-card/:id/topup" element={<TopupVirtualCard />} />
        <Route
          path="/virtual-card/topup/final"
          element={<TopupVirtualCardFinal />}
        />
        {/* <Route
          path="/virtual-card/:id/delete"
          element={<DeleteVirtualCard />}
        /> */}
        <Route
          path="/virtual-card/:id/freeze"
          element={<FreezeVirtualCard />}
        />
        <Route
          path="/virtual-card/:id/unfreeze"
          element={<UnfreezeVirtualCard />}
        />

        <Route path="/bulk-otc" element={<BulkOTC />} />
        <Route path="/bulk-otc/successful" element={<BulkOTCSuccessful />} />
        <Route path="/account-statement" element={<AccountStatement />} />

        <Route path="/wallet" element={<Wallet />} />

        <Route path="/buy-crypto" element={<BuyCrypto />} />
        <Route path="/buy-crypto-final" element={<BuyCryptoFinal />} />

        <Route path="/sell-crypto" element={<SellCrypto />} />
        <Route path="/sell-crypto-final" element={<SellCryptoFinal />} />

        <Route path="/swap-crypto" element={<SwapCryptoNew />} />

        <Route path="/swap-crypto-old" element={<SwapCrypto />} />
        <Route path="/swap-crypto-final" element={<SwapCryptoFinal />} />

        <Route path="/transfer-crypto" element={<TransferCrypto />} />
        <Route
          path="/transfer-crypto-final"
          element={<TransferCryptoFinal />}
        />

        <Route path="/deposit-crypto" element={<DepositCrypto />} />

        <Route path="/withdraw-crypto" element={<WithdrawCrypto />} />
        <Route
          path="/withdraw-crypto-final"
          element={<WithdrawCryptoFinal />}
        />

        <Route path="/deposit" element={<Deposit />} />
        <Route path="/deposit/amount" element={<DepositAmount />} />
        <Route path="/deposit/details" element={<DepositDetails1 />} />
        <Route path="/deposit/make-payment" element={<DepositBankDetails />} />
        <Route path="/deposit/processing" element={<DepositProcessing />} />

        <Route path="/deposit-dispute/:hash" element={<DepositDispute />} />

        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw/otp" element={<WithdrawOTP />} />
        <Route path="/withdraw/processing" element={<WithdrawProcessing />} />

        <Route path="/transfer" element={<Transfer />} />
        <Route path="/transfer-final" element={<TransferFinal />} />
        <Route path="/transfer-successful" element={<TransferSuccessful />} />

        <Route path="/gift-cards" element={<GiftCards />} />
        <Route path="/gift-cards/country" element={<GiftCardCountry />} />
        <Route
          path="/buy-gift-cards/country"
          element={<BuyGiftCardCountry />}
        />
        <Route path="/gift-cards/receipt" element={<GiftCardReceipt />} />
        <Route
          path="/gift-cards/order-create"
          element={<GiftCardOrderCreate />}
        />
        <Route
          path="/buy-gift-cards/order-create"
          element={<BuyGiftCardOrderCreate />}
        />
        <Route path="/buy-gift-cards/otp/:hash" element={<BuyGiftCardOTP />} />

        <Route path="/orders" element={<Orders />} />
        <Route path="/order/:hash" element={<OrderDetails />} />
        <Route path="/order-dispute/:hash" element={<OrderDispute />} />

        {/* <Route path="/pay-bills" element={<PayBills />} /> */}
        <Route path="/pay-bills" element={<PayBillsNew />} />

        <Route path="/pay-bills/:serviceType" element={<BuyBill />} />
        <Route path="/pay-bills-confirm" element={<BillConfirm />} />
        <Route path="/pay-bills-success" element={<BillSuccessful />} />

        {/* <Route path="/pay-bills/airtime" element={<BuyAirtime />} />
        <Route path="/pay-bills/airtime/confirm" element={<AirtimeConfirm />} />
        <Route
          path="/pay-bills/airtime/success"
          element={<AirtimeSuccessful />}
        /> */}

        {/* <Route path="/pay-bills/data" element={<BuyData />} /> 
        <Route path="/pay-bills/data/confirm" element={<DataConfirm />} />
        <Route path="/pay-bills/data/success" element={<DataSuccessful />} /> */}

        {/* <Route path="/pay-bills/cable" element={<BuyCable />} />
        <Route path="/pay-bills/cable/confirm" element={<CableConfirm />} />
        <Route path="/pay-bills/cable/success" element={<CableSuccessful />} /> */}

        {/* <Route path="/pay-bills/electricity" element={<BuyElectricity />} />
        <Route
          path="/pay-bills/electricity/confirm"
          element={<ElectricityConfirm />}
        />
        <Route
          path="/pay-bills/electricity/success"
          element={<ElectricitySuccessful />}
        /> */}

        <Route path="/transactions" element={<Transactions />} />
        <Route
          path="/transaction-details/:type/:hash"
          element={<TransactionDetails />}
        />
        <Route path="/deposit/:hash" element={<DepositDetails />} />
        <Route path="/withdrawal/:hash" element={<WithdrawalDetails />} />

        <Route path="/account" element={<Account />}>
          <Route path="verify-identity" element={<VerifyIdentity />} />
          <Route path="profile" element={<Profile />} />
          <Route path="banks" element={<Banks />} />
          <Route path="security" element={<Security />} />
          <Route path="refer" element={<Refer />} />
        </Route>

        <Route path="/add-bank" element={<AddBank />} />
        <Route path="/bank-added" element={<BankAdded />} />

        <Route path="/change-pin" element={<ChangePin />} />
        <Route path="/reset-pin" element={<ResetPin />} />
        <Route path="/pin-changed" element={<PinChanged />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
