import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  chevronBackOutline,
  closeCircleOutline,
} from "ionicons/icons";

import withAuth from "../../hoc/withAuth/withAuth";
import giftCardHoc from "../../hoc/giftCard/giftCard";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { clearGiftCardData } from "../../store/giftcardSlice";

import useAlert from "../../hooks/useAlert/useAlert";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";

type tDenominations = {
  id: number;
  denomination: string;
  sell_rate: string;
  selected?: boolean;
}[];

const GiftCardOrderCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { giftCard, cardCountry, cardType } = useSelector(
    (state: tRootState) => state.giftcard
  );

  assertNotNull(giftCard);
  assertNotNull(cardCountry);
  assertNotNull(cardType);

  const [denominations, setDenominations] = useState<tDenominations | null>(
    null
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const fileInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [images, setImages] = useState<File[]>([]);

  const [amount, setAmount] = useState<number | "">("");

  const [confirmed, setConfirmed] = useState(false);

  const [message, setMessage, clearMessage] = useAlert();

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const newImages = [
        ...images,
        ...new Array(
          files.length + images.length >= 5 ? 5 - images.length : files.length
        )
          .fill(null)
          .map((_, i) => files[i]),
      ];

      setImages(newImages.slice(0, 5));
    }
  };

  const removeGiftCard = (index: number) => {
    setImages(images.filter((_, i) => index !== i));
  };

  const getRate = () => {
    // should never get gere
    if (!denominations) return 0;

    const denomination = denominations.filter((den) => den.selected)[0];

    return denomination ? +denomination.sell_rate : 0;
  };

  const getTotal = () => {
    return getRate() * (amount || 0);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!denominations || !giftCard || !cardCountry || !cardType) return;

    if (!amount) return setMessage("warning", "Enter amount");

    if (+amount > 5000)
      return setMessage("warning", "Maximum gift card you can trade is $5,000");

    const selectedDenominations = denominations
      .filter((den) => den.selected)
      .map((den) => den.id);

    if (!selectedDenominations.length)
      return setMessage("warning", "Select denomination");

    if (!images.length) return setMessage("warning", "Add giftcard image");

    if (!confirmed)
      return setMessage(
        "warning",
        "You have to agree to the information above by checking I confirm"
      );

    const submitBtn = submitBtnRef.current;

    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Creating order`;
    submitBtn.setAttribute("disabled", "disabled");

    const form_data = new FormData();

    form_data.append("service_id", giftCard.id.toString());
    form_data.append("card_country_id", cardCountry.id.toString());
    form_data.append("card_type_id", cardType.id.toString());
    form_data.append("amount", amount.toString());
    form_data.append("denomination", selectedDenominations[0].toString());

    images.forEach((image) => {
      form_data.append("images[]", image);
    });

    api_client({
      method: "POST",
      url: "/order/sell/create",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      data: form_data,
    })
      .then((res) => {
        if (res.data.success) {
          setMessage("success", res.data.message);

          navigate(`/order/${res.data.order.order_hash}`);

          setTimeout(() => {
            dispatch(clearGiftCardData());
          }, 5000);
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtn) {
          submitBtn.removeAttribute("disabled");
          submitBtn.innerHTML = "Create Order";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [amount, denominations, confirmed, clearMessage]);

  useEffect(() => {
    if (!giftCard?.id || !cardCountry?.id || !cardType?.id) return;

    api_client({
      url: `/order/denoms/${giftCard.id}/${cardCountry.id}/${cardType.id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success || !res.data.message.length) throw new Error("");

        setDenominations(
          (res.data.message as tDenominations).map((den) => ({
            ...den,
            selected: false,
          }))
        );
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [accessToken, giftCard?.id, cardCountry?.id, cardType?.id, navigate]);

  if (!denominations) return <Preloader />;

  return (
    <DashboardLayout>
      <input
        type="file"
        style={{ display: "none" }}
        multiple
        ref={fileInputRef}
        onChange={handleFileInputChange}
        accept=".png,.jpg,jpeg"
      />
      <ul className="breadcrumb">
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            Gift Cards
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            {giftCard.name}
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards/country" className="breadcrumb__link">
            {cardCountry.country_name}
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards/receipt" className="breadcrumb__link">
            {cardType.type_name}
          </Link>
        </li>
        <li className="breadcrumb__item breadcrumb__item--active">
          <Link to="#" className="breadcrumb__link">
            Create Order
          </Link>
        </li>
      </ul>
      <div className="giftcards-pg__header">
        <div className="giftcards-pg__header-main">
          <h3 className="giftcards-pg__heading">Create Gift Card Order</h3>
          <div className="step-radio-btns">
            <input
              type="radio"
              className="radio"
              name="step"
              value="1"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="2"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="3"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="4"
              checked
              readOnly
            />
          </div>
        </div>
      </div>
      <form className="giftcard-block" onSubmit={handleSubmit}>
        <div className="giftcard-block__form">
          <div className="form-group">
            <label>How much gift card are you selling</label>
            <div className="input-group">
              <button type="button">$</button>
              <input
                type="text"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setAmount(+e.target.value)
                      : null
                    : setAmount("")
                }
              />
            </div>
          </div>
          <ul className="giftcard-block__denominations">
            {denominations.map((denomination) => {
              const changeHandler = () => {
                const new_denominations = denominations.map((den) =>
                  den.id === denomination.id
                    ? { ...den, selected: !den.selected }
                    : den
                );
                setDenominations(new_denominations);
              };

              return (
                <li
                  className={cls(
                    "giftcard-block__denomination",
                    denomination.selected &&
                      "giftcard-block__denomination--active"
                  )}
                  key={denomination.id}
                >
                  <input
                    type="checkbox"
                    className="radio"
                    name="minimum"
                    checked={denomination.selected}
                    onChange={changeHandler}
                  />
                  <p
                    className="giftcard-block__denomination-text"
                    onClick={changeHandler}
                  >
                    {denomination.denomination}
                  </p>
                </li>
              );
            })}
          </ul>
          <div className="giftcard-block__rab">
            <p className="giftcard-block__rab-label">Rate</p>
            <div className="giftcard-block__rab-div">
              &#8358;{roundDP(getRate(), 2)} / USD
            </div>
            <p className="giftcard-block__rab-label">You Get</p>
            <div className="giftcard-block__rab-div">
              &#8358;{roundDP(getTotal(), 2)}
            </div>
          </div>
          <div className="giftcard-block__giftcards">
            {images.map((image, i) => {
              return (
                <div className="giftcard-block__giftcard" key={i}>
                  <button
                    type="button"
                    className="giftcard-block__giftcard-action"
                    onClick={() => removeGiftCard(i)}
                  >
                    <IonIcon icon={closeCircleOutline} />
                  </button>
                  <img src={URL.createObjectURL(image)} alt="" />
                </div>
              );
            })}
            {images.length < 5 ? (
              <div
                className="giftcard-block__giftcard giftcard-block__giftcard--add"
                onClick={() => fileInputRef.current.click()}
              >
                <IonIcon icon={addOutline} />
                <span>Add Image(s)</span>
              </div>
            ) : null}
          </div>
          <div className="giftcard-block__indemnity">
            Rate of this giftcard transaction could change if you upload your
            gift card in a wrong category or as a result of change in
            denomination, We recommend you chat with a REP for realtime update
            on this trade.
          </div>
          <div
            className="giftcard-block__indemnity"
            onClick={() => setConfirmed((s) => !s)}
          >
            <p>
              By clicking "Create Order," you confirm that the items you are
              selling, loading, or offering for sale on sekiapp.com were legally
              acquired and were not obtained through fraudulent or stolen means.
            </p>
            <p>
              You also state that you did not act as an agent of the website
              when obtaining these items.
            </p>
            <p>
              Additionally, you acknowledge that you have read and agreed to our{" "}
              <Link to="/terms-and-conditions">Terms of Service</Link>, and you
              understand that all transactions are final and non-reversible.
            </p>
            <div className="accept">
              <input
                type="checkbox"
                className="accept__checkbox"
                checked={confirmed}
                onChange={(e) => setConfirmed(e.target.checked)}
              />
              <p>
                Clicking this box means you have agreed to our trade terms and
                conditions
              </p>
            </div>
          </div>
          {message}
        </div>
        <div className="giftcard-btns giftcard-btns--left">
          <button onClick={() => navigate(-1)}>
            <IonIcon icon={chevronBackOutline} /> Go Back
          </button>
          <button
            className={cls(
              confirmed &&
                amount &&
                amount <= 5000 &&
                images.length &&
                denominations.length &&
                "active"
            )}
            ref={submitBtnRef}
          >
            Create Order
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(giftCardHoc(GiftCardOrderCreate));
