import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import HeroSection from "../../components/HeroSection/HeroSection";
import SocialsSection from "../../components/SocialsSection/SocialsSection";
import TestimonialsSection from "../../components/TestimonialsSection/TestimonialsSection";
import WCASection from "../../components/WCASection/WCASection";
import NewsSection from "../../components/NewsSection/NewsSection";
import MarketSection from "../../components/MarketSection/MarketSection";
import InfluencerSection from "../../components/InfluencerSection/InfluencerSection";
import InfluencerJoinSection from "../../components/InfluencerSection/InfluencerJoinSection";
import FeaturedSection from "../../components/FeaturedSection/FeaturedSection";
import VCardSection from "../../components/VCardSection/VCardSection";

const Home = () => {
  return (
    <HomeLayout>
      <HeroSection />
      <FeaturedSection />
      <FeaturesSection />
      <VCardSection />
      <MarketSection />
      <WCASection />
      <TestimonialsSection />
      <NewsSection />
      <InfluencerSection />
      <InfluencerJoinSection />
      <SocialsSection />
    </HomeLayout>
  );
};

export default Home;
