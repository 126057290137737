import { Link } from "react-router-dom";
import { tRootState } from "../../store";

import bitcoin from "../../assets/img/bitcoin.png";
import billPayment from "../../assets/img/bill-payment.png";
import giftCard from "../../assets/img/gift-card.png";
import instantSwap from "../../assets/img/instant-swap.png";
import { useSelector } from "react-redux";

const FeaturesSection = () => {
  const { loggedIn, accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  const isLoggedIn = loggedIn && accessToken && userDetails;

  return (
    <section className="features-section">
      <div className="container features-section__container">
        <h3 className="heading-secondary">Our Features</h3>
        {/* <p className="features-section__description">
        With only one click, all services provided on our website are
        delivered right to your home.
      </p>  */}
        <div className="features-section__features">
          <div className="feature feature--1">
            <img src={bitcoin} alt="" className="feature__img" />
            <h3 className="feature__heading">Crypto Exchange</h3>
            <p className="feature__text">
              Buy and store popular digital currencies, keep track of them in
              one place.
            </p>
            <Link
              to={isLoggedIn ? "/wallet" : "/login"}
              className="feature__link"
            >
              <span>Get Started</span> &rarr;
            </Link>
          </div>
          <div className="feature feature--2">
            <img src={giftCard} alt="" className="feature__img" />
            <h3 className="feature__heading">Trade Giftcard</h3>
            <p className="feature__text">
              Sell Over 200 Categories of Unused Giftcards and Receive payment
              Instantly
            </p>
            <Link
              to={isLoggedIn ? "/gift-cards" : "/login"}
              className="feature__link"
            >
              <span>Get Started</span> &rarr;
            </Link>
          </div>
          <div className="feature feature--3">
            <img src={billPayment} alt="" className="feature__img" />
            <h3 className="feature__heading">Bill Payment</h3>
            <p className="feature__text">
              Easily take care of all your utility Bills ( Airtime Top-up, Data
              Subscription, Cable TV, Electricity Bill Payment)
            </p>
            <Link
              to={isLoggedIn ? "/pay-bills" : "/login"}
              className="feature__link"
            >
              <span>Get Started</span> &rarr;
            </Link>
          </div>
          <div className="feature feature--4">
            <img src={instantSwap} alt="" className="feature__img" />
            <h3 className="feature__heading">Instant Swap</h3>
            <p className="feature__text">
              Fastest instant swap for different coin.
            </p>
            <Link
              to={isLoggedIn ? "/swap-crypto" : "/login"}
              className="feature__link"
            >
              <span className="mt-4">Get Started</span> &rarr;
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
