import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { clearCryptoData } from "../../store/cryptoSlice";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import { roundDP } from "../../utils/func";
import { getPrecision } from "../../utils/app";

const SwapCryptoFinal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { from, to, dollarAmount, rateInfo } = useSelector(
    (state: tRootState) => state.crypto.swap
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [pin, setPin] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const { fetchProfile } = useData();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Should never get here
    if (!from || !to) return;

    if (pin.length !== 4)
      return setMessage("warning", "Enter your transaction PIN");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/wallet/swap-coin",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        swap_from: from.symbol,
        swap_to: to.symbol,
        amount: dollarAmount,
        flow: 1,
        pin: pin,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        setMessage(
          "success",
          "Crypto swap successful. You would be redirected shortly..."
        );

        return fetchProfile();
      })
      .then((res) => {
        navigate("/wallet");
        const interval: number = window.setInterval(() => {
          dispatch(clearCryptoData("swap"));
          window.clearInterval(interval);
        }, 3000);
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = `Swap Now`;
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [pin, clearMessage]);

  if (!from || !to || !dollarAmount || !rateInfo)
    return <Navigate to="/swap-crypto-old" />;

  return (
    <DashboardLayout>
      <div className="crypto-final">
        <div className="crypto-final__header">
          <div className="crypto-final__back" onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </div>
          <p className="crypto-final__label">I want to swap</p>
          <div className="crypto-final__currency">
            <img src={from.image} alt="" />
            <p>
              ${rateInfo.amount} (
              {roundDP(rateInfo.amoun_in_coin, getPrecision(from.symbol))}{" "}
              <span>{from.symbol.toUpperCase()}</span>)
            </p>
          </div>
          <p className="crypto-final__label2">For</p>
          <div className="crypto-final__currency">
            <img src={to.image} alt="" />
            <p>
              ${rateInfo.amount_to_get} (
              {roundDP(rateInfo.amount_to_get_in_coin, getPrecision(to.symbol))}{" "}
              <span>{to.symbol.toUpperCase()}</span>)
            </p>
          </div>
        </div>
        <form className="crypto-final__body" onSubmit={handleSubmit}>
          <div className="crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span className="crypto-final__forgot-pin">Forgot PIN</span>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                pin.length === 4 && "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Swap Now
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default SwapCryptoFinal;
