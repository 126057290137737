import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../components/Preloader/Preloader";

import { copyData, getDate, roundDP } from "../../utils/func";

type tWithdrawalTransaction = {
  payment_hash: string;
  account_name: string;
  account_no: string;
  bank_name: string;
  status: number;
  amount: string;
  created_at: string;
};

const WithdrawalDetails = () => {
  const navigate = useNavigate();
  const { hash } = useParams();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [withdrawal, setWithdrawal] = useState<tWithdrawalTransaction | null>(
    null
  );

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    api_client({
      url: `payment/${hash}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setWithdrawal(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [hash, accessToken, navigate]);

  if (!withdrawal) return <Preloader />;

  return (
    <DashboardLayout>
      <div className="transaction-details">
        <h3 className="transaction-details__heading">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBackOutline} />
          </span>
          Withdrawal Details
        </h3>
        <p className="transaction-details__heading-subtext">
          Your Withdrawal Payment Information
        </p>
        <div className="transaction-details__infos">
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Hash</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {withdrawal.payment_hash}
              </p>
              <div
                className="copy"
                onClick={() => copyData(withdrawal.payment_hash, setCopied)}
              >
                <IonIcon icon={copy} />
                {copied ? "Copied!" : "Copy"}
              </div>
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Account Name</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {withdrawal.account_name}
              </p>
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Account Number</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {withdrawal.account_no}
              </p>
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Bank Name</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {withdrawal.bank_name}
              </p>
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Amount</p>
            <div className="transaction-details__info-right">
              &#8358;{roundDP(withdrawal.amount, 2)}
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Status</p>
            <div className="transaction-details__info-right">
              {withdrawal.status === 0 ? (
                <span className="badge badge--danger">Canceled</span>
              ) : withdrawal.status === 1 || withdrawal.status === 4 ? (
                <span className="badge badge--success">Sent</span>
              ) : withdrawal.status === 2 ? (
                <span className="badge badge--success">Approved</span>
              ) : withdrawal.status === 5 ? (
                <span className="badge badge--success">Refunded</span>
              ) : null}
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Date</p>
            <div className="transaction-details__info-right">
              {getDate(withdrawal.created_at)}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(WithdrawalDetails);
