import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateHideVerifButton,
  updateUserDetails,
} from "../../store/userSlice";

import api_client from "../../api/client";

const MatiButton = () => {
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    const button = document.getElementById("metamap-button");

    if (!button) return;

    button.addEventListener("mati:userFinishedSdk", ({ detail }) => {
      dispatch(updateHideVerifButton(true));

      api_client({
        url: "/pending-verification",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(
            updateUserDetails({
              ...userDetails,
              verif_status: "pending",
            })
          );
        })
        .catch((err) => {
          dispatch(updateHideVerifButton(false));
        })
        .finally(() => {});
    });
  }, [accessToken, dispatch, userDetails]);

  const data = JSON.stringify({
    email: userDetails.email ? userDetails.email : userDetails.username,
  });

  return (
    <mati-button
      clientid="642a8eae9c002d001a503ede"
      flowId="6470946b2639ee001c9182f7"
      metadata={`${data}`}
      color="#397305"
      id="metamap-button"
    />
  );
};

export default MatiButton;
