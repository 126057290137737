import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tWallet } from "./types/app.types";

type tCryptoWithdrawTransaction = {
  amount: number;
  amount_dollar: string;
  fees: number;
  fees_dollar: number;
  to_address: string;
  hash: string;
};

type tCryptoTransferTransaction = {
  amount: string;
  address: string;
  coin_name: string;
  coin_value: string;
  hash: string;
};

type CryptoType = {
  sell: {
    wallet: tWallet | null;
    dollarAmount: number | "";
    nairaAmount: number | null;
    cryptoAmount: number | null;
  };
  buy: {
    wallet: tWallet | null;
    dollarAmount: number | "";
    nairaAmount: number | null;
    cryptoAmount: number | null;
  };
  swap: {
    from: tWallet | null;
    to: tWallet | null;
    flow: 1 | 2;
    dollarAmount: number | "";
    rateInfo: {
      amoun_in_coin: number;
      amount: string;
      amount_to_get: number;
      amount_to_get_in_coin: number;
      charge: number;
      charge_in_coin: number;
    } | null;
  };
  withdraw: {
    wallet: tWallet | null;
    transaction: tCryptoWithdrawTransaction | null;
    message: string | null;
  };
  transfer: {
    wallet: tWallet | null;
    username: string;
    dollarAmount: number | "";
    description: string;

    transaction: tCryptoTransferTransaction | null;
    message: string | null;
  };
  bulk: {
    wallet: tWallet | null;
  };
};

const CryptoInitialState: CryptoType = {
  sell: {
    wallet: null,
    dollarAmount: "",
    nairaAmount: null,
    cryptoAmount: null,
  },
  buy: {
    wallet: null,
    dollarAmount: "",
    nairaAmount: null,
    cryptoAmount: null,
  },
  swap: {
    from: null,
    to: null,
    flow: 1,
    dollarAmount: "",
    rateInfo: null,
  },
  withdraw: {
    wallet: null,
    transaction: null,
    message: null,
  },
  transfer: {
    wallet: null,
    username: "",
    dollarAmount: "",
    description: "",

    transaction: null,
    message: null,
  },
  bulk: {
    wallet: null,
  },
};

const cryptoSlice = createSlice({
  name: "crypto",
  initialState: getState<CryptoType>("crypto", CryptoInitialState),
  reducers: {
    updateSellCrypto(state, { payload }: PayloadAction<CryptoType["sell"]>) {
      state.sell = payload;

      saveState("crypto", current(state));
    },
    updateBuyCrypto(state, { payload }: PayloadAction<CryptoType["buy"]>) {
      state.buy = payload;

      saveState("crypto", current(state));
    },
    updateSwapCrypto(state, { payload }: PayloadAction<CryptoType["swap"]>) {
      state.swap = payload;

      saveState("crypto", current(state));
    },
    updateWithdrawCrypto(
      state,
      { payload }: PayloadAction<CryptoType["withdraw"]>
    ) {
      state.withdraw = payload;

      saveState("crypto", current(state));
    },
    updateTransferCrypto(
      state,
      { payload }: PayloadAction<CryptoType["transfer"]>
    ) {
      state.transfer = payload;

      saveState("crypto", current(state));
    },
    updateBulkOTC(state, { payload }: PayloadAction<CryptoType["bulk"]>) {
      state.bulk = payload;

      saveState("crypto", current(state));
    },
    clearCryptoData(state, { payload }: PayloadAction<keyof CryptoType>) {
      state[payload] = CryptoInitialState[payload] as any; // Never use any

      saveState("paybills", current(state));
    },
  },
});

export const {
  updateSellCrypto,
  updateBuyCrypto,
  updateSwapCrypto,
  updateWithdrawCrypto,
  clearCryptoData,
  updateTransferCrypto,
  updateBulkOTC,
} = cryptoSlice.actions;

export default cryptoSlice.reducer;
