import background from "../../assets/img/auth-background.png";
import sekiLogoLight from "../../assets/img/seki-logo-light.png";
import sekiLogoDark from "../../assets/img/seki-logo-dark.png";

const Preloader = () => {
  return (
    <div
      className="preloader"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="preloader__logo-container">
        <img src={sekiLogoLight} className="light-block" alt="" />
        <img src={sekiLogoDark} className="dark-block" alt="" />
      </div>
    </div>
  );
};

export default Preloader;
