import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tGiftCard, tGiftCardCountry, tGiftCardType } from "./types/app.types";

type GiftCardType = {
  giftCard: tGiftCard | null;
  cardCountry: tGiftCardCountry | null;
  cardType: tGiftCardType | null;
};

const GiftCardInitialState: GiftCardType = {
  giftCard: null,
  cardCountry: null,
  cardType: null,
};

const giftcardSlice = createSlice({
  name: "giftcard",
  initialState: getState<GiftCardType>("giftcard", GiftCardInitialState),
  reducers: {
    updateGiftCard(state, { payload }: PayloadAction<tGiftCard>) {
      state.giftCard = payload;

      saveState("giftcard", current(state));
    },
    updateGiftCardCountry(state, { payload }: PayloadAction<tGiftCardCountry>) {
      state.cardCountry = payload;

      saveState("giftcard", current(state));
    },
    updateGiftCardType(state, { payload }: PayloadAction<tGiftCardType>) {
      state.cardType = payload;

      saveState("giftcard", current(state));
    },
    clearGiftCardData(state) {
      state.giftCard = null;
      state.cardCountry = null;
      state.cardType = null;

      saveState("giftcard", current(state));
    },
  },
});

export const {
  updateGiftCard,
  updateGiftCardCountry,
  updateGiftCardType,
  clearGiftCardData,
} = giftcardSlice.actions;

export default giftcardSlice.reducer;
