// import { useSelector, useDispatch } from "react-redux";

// import { IonIcon } from "@ionic/react";
// import { moonOutline, sunnyOutline } from "ionicons/icons";

// import { tRootState } from "../../store";

// import { changeMode } from "../../store/appSlice";

const ModeToggle = () => {
  // const dispatch = useDispatch();

  // const uiMode = useSelector((state: tRootState) => state.app.uiMode);

  return null;

  // return (
  //   <button
  //     className="mode-toggle"
  //     onClick={() => dispatch(changeMode(uiMode === "dark" ? "light" : "dark"))}
  //   >
  //     <IonIcon icon={uiMode === "dark" ? sunnyOutline : moonOutline} />{" "}
  //     {uiMode === "dark" ? "Light" : "Dark"}
  //   </button>
  // );
};

export default ModeToggle;
