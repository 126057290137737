import { FormEvent, useEffect, useRef, useState, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack, clipboardOutline } from "ionicons/icons";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import { tRootState } from "../../store";
import { addWithdrawInfo2 } from "../../store/nairaSlice";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";

const WithdrawOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const { transaction, fee, emailMessage } = useSelector(
    (state: tRootState) => state.naira.withdraw
  );

  assertNotNull(userDetails);

  const [OTP, setOTP] = useState("");
  const [gAuthOTP, setGAuthOTP] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const { fetchProfile } = useData();

  const resendOTP = (e: MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Resending OTP`;

    const interval = setInterval(() => {
      setMessage("success", "Email OTP has been resent successfully");
      target.innerHTML = "Resend OTP";
      clearInterval(interval);
    }, 3000);

    // axiosv2({
    //   method: "POST",
    //   url: "/wallet/withdraw/resend-otp",
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   data: JSON.stringify({ hash: transaction.hash }),
    // })
    //   .then((res) => {
    //     if (res.data.success) {
    //       set_message("success", res.data.message);
    //     } else {
    //       set_message("warning", res.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     set_message("error", err.message);
    //   })
    //   .finally(() => {
    //     target.innerHTML = "Resend OTP";
    //   });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // should never get here
    if (!transaction) return;

    if ([0, 2].includes(userDetails.withauth) && OTP.length !== 4)
      return setMessage("warning", "Enter OTP sent to your email");

    if ([1, 2].includes(userDetails.withauth) && gAuthOTP.length !== 6)
      return setMessage("warning", "Enter OTP in your google authentication");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/payment-request-otp",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        payment_hash: transaction.payment_hash,
        code: OTP,
        twoFACode: gAuthOTP,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        dispatch(
          addWithdrawInfo2({
            OTPMessage: res.data.message,
            longOTPMessage: res.data.long_msg,
          })
        );

        return fetchProfile();
      })
      .then(() => {
        navigate("/withdraw/processing");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = `Withdraw Now`;
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [OTP, gAuthOTP, clearMessage]);

  if (!transaction || !fee || !emailMessage) return <Navigate to="/withdraw" />;

  return (
    <DashboardLayout>
      <div className="naira-block">
        <div className="naira-block__header">
          <h3 className="naira-block__heading">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Withdrawal Details
          </h3>
          <p className="naira-block__sub-heading text-center">
            Here is your withdrawal details
          </p>
        </div>
        <form className="naira-block__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="text"
              className="form-input form-disabled"
              value={`NGN ${roundDP(transaction.amount, 2)}`}
              disabled
            />
          </div>
          <div className="form-group mb-medium">
            <label>Deposit Bank</label>
            <div className="select-box select-box--auto form-disabled">
              <div>
                <p>
                  <strong>Bank Name:</strong> {transaction.bank_name}
                </p>
                <p>
                  <strong>Account Name:</strong> {transaction.account_name}
                </p>
                <p>
                  <strong>Account No:</strong> {transaction.account_no}
                </p>
              </div>
            </div>
          </div>
          {userDetails.withauth === 0 ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">Email OTP</h3>
              <div className="crypto-final__heading-subtext">
                {emailMessage}
              </div>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 4);
                    if (isNumber(text)) setOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={4}
                  pin={OTP}
                  handler={setOTP}
                />
              </div>
              <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                Resend Code
              </span>
            </div>
          ) : null}
          {userDetails.withauth === 1 ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">
                Google authentication code
              </h3>
              <div className="crypto-final__heading-subtext">
                Input your Google authentication code to complete the
                transaction
              </div>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 6);
                    if (isNumber(text)) setGAuthOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={6}
                  pin={gAuthOTP}
                  handler={setGAuthOTP}
                />
              </div>
            </div>
          ) : null}
          {userDetails.withauth === 2 ? (
            <div className="crypto-final__pin-sections">
              <div className="crypto-final__pin-section">
                <div className="crypto-final__heading-subtext">
                  Kindly input the OTP sent to your email and your Google
                  authentication code to complete the transaction
                </div>
              </div>
              <div className="crypto-final__pin-section">
                <h3 className="crypto-final__heading">Email OTP</h3>
                <div
                  className="copy copy--bold"
                  onClick={() => {
                    navigator.clipboard.readText().then((clipText) => {
                      const text = clipText.slice(0, 4);
                      if (isNumber(text)) setOTP(text);
                    });
                  }}
                >
                  <IonIcon icon={clipboardOutline} />
                  Paste from clipboard
                </div>
                <div className="register__pin-input-group-2">
                  <PinGroup
                    type="text"
                    numInputs={4}
                    pin={OTP}
                    handler={setOTP}
                  />
                </div>
                <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                  Resend Code
                </span>
              </div>
              <div className="crypto-final__pin-section">
                <h3 className="crypto-final__heading">
                  Google authentication code
                </h3>
                <div
                  className="copy copy--bold"
                  onClick={() => {
                    navigator.clipboard.readText().then((clipText) => {
                      const text = clipText.slice(0, 6);
                      if (isNumber(text)) setGAuthOTP(text);
                    });
                  }}
                >
                  <IonIcon icon={clipboardOutline} />
                  Paste from clipboard
                </div>
                <div className="register__pin-input-group-2">
                  <PinGroup
                    type="text"
                    numInputs={6}
                    pin={gAuthOTP}
                    handler={setGAuthOTP}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                ((userDetails.withauth === 0 && OTP.length === 4) ||
                  (userDetails.withauth === 1 && gAuthOTP.length === 6) ||
                  (userDetails.withauth === 2 &&
                    OTP.length === 4 &&
                    gAuthOTP.length === 6)) &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Withdraw Now
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(WithdrawOTP);
