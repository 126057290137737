import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, chevronDownOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tCountries, tCountry } from "../../store/types/app.types";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";
import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../hooks/useSelectBox/useSelectBox";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import { isNumber } from "../../utils/func";

import sms from "../../assets/img/sms.png";
import whatsapp from "../../assets/img/whatsapp.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";
import sekiLogoLight from "../../assets/img/sekilogo-light.png";

const getCountrySelectData = (
  countries: tCountries | null
): tSelectBoxGeneric | null => {
  if (!countries) return countries;

  const data: tSelectBoxGeneric = {};

  for (const country of countries) {
    data[country.id] = `${country.country_name}(${country.contry_code})`;
  }

  return data;
};

const NewForgotPassword = () => {
  const navigate = useNavigate();

  const [countries, setCountries] = useState<tCountries | null>(null);

  const { fetchCountries } = useData();

  const countriesSelectData = getCountrySelectData(countries);

  const [countrySelectBox, countryId, openCountrySelectBox] =
    useSelectBox<string>(
      "Select Country",
      getSelectBoxData(countriesSelectData),
      null
    );

  const [country, setCountry] = useState<tCountry | null>(null);

  const [authType, setAuthType] = useState<"email" | "phone">("email");

  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState<number | "">("");

  const [smsType, setSMSType] = useState<"whatsapp" | "sms">("whatsapp");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      (authType === "email" && !email) ||
      (authType === "phone" && (!telephone || !country))
    )
      return setMessage("warning", "Fill in all fields");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/password/request-token-new",
      data: {
        mode: authType,
        phone_country_id: country?.id,
        [authType]: `${authType === "phone" ? `+234${telephone}` : email}`,
        option: smsType,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        navigate("/reset-password");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Send password reset code";
        }
      });
  };

  useEffect(() => {
    if (!countryId) return;

    setCountry(
      countries?.find((count) => count.id.toString() === countryId) || null
    );
  }, [countryId, countries]);

  useEffect(() => {
    clearMessage();
  }, [email, countryId, telephone, authType, smsType, clearMessage]);

  useEffect(() => {
    fetchCountries()
      .then((counts) => setCountries(counts))
      .catch((err) => {
        navigate("/error");
      });
  }, [fetchCountries, navigate]);

  return (
    <AuthLayout variation>
      {countrySelectBox}
      <div className="auth auth--1" style={{ paddingBottom: "12.8rem" }}>
        <div className="auth__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <h2 className="auth__heading">Forgot your password</h2>
        <p className="auth__heading-sub-text mb-small">
          Don't worry chief, just select the mode you registered with and we
          will send a code to you to reset your password.
        </p>
        <div className="tab-buttons tab-buttons--reg">
          <button
            className={authType === "email" ? "active" : ""}
            onClick={() => setAuthType("email")}
          >
            Email
          </button>
          <button
            className={authType === "phone" ? "active" : ""}
            onClick={() => setAuthType("phone")}
          >
            Phone
          </button>
        </div>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          {authType === "email" ? (
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="form-input"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          ) : (
            <div className="form-group">
              <div className="input-group">
                <button type="button" onClick={openCountrySelectBox}>
                  {country ? country.contry_code : "Select country"}
                  <IonIcon icon={chevronDownOutline} />
                </button>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={telephone}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setTelephone(+e.target.value)
                        : null
                      : setTelephone("")
                  }
                />
              </div>
              <label className="form-label text-center">
                <IonIcon icon={alertCircle} className="text-success" />
                Enter phone number without country code
              </label>
            </div>
          )}
          <div className="form-group mt-small">
            {authType === "phone" ? (
              <div className="otp-medium mb-small">
                <h3 className="otp-medium__heading">Receive OTP Via</h3>
                <div className="otp-medium__blocks">
                  <div className="otp-medium__block">
                    <img
                      src={whatsapp}
                      alt=""
                      className="otp-medium__block-img"
                    />
                    <p className="otp-medium__block-name">Whatsapp (Instant)</p>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={smsType === "whatsapp" ? true : false}
                      onChange={(e) =>
                        setSMSType(e.target.checked ? "whatsapp" : "sms")
                      }
                    />
                  </div>
                  <div className="otp-medium__block">
                    <img src={sms} alt="" className="otp-medium__block-img" />
                    <p className="otp-medium__block-name">SMS (0 - 5 MIN)</p>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={smsType === "sms" ? true : false}
                      onChange={(e) =>
                        setSMSType(e.target.checked ? "sms" : "whatsapp")
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {message}
          <div className="auth__footer auth__footer--1">
            <button
              className={cls(
                "form-button",
                ((authType === "email" && email) ||
                  (authType === "phone" && telephone)) &&
                  "form-button--active"
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Send password reset code
            </button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default NewForgotPassword;
