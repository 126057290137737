import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBack, chevronDownOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tNetwork, tWallet } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useCopy from "../../hooks/useCopy/useCopy";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Spinner from "../../loaders/Spinner/Spinner";
import Preloader from "../../components/Preloader/Preloader";

import SelectNetworkModal from "../../components/CryptoWallet/SelectNetworkModal/SelectNetworkModal";
import { DepositCryptoNoticeModal } from "../../components/CryptoWallet/DepositCryptoModal/DepositCryptoModal";

import { roundDP } from "../../utils/func";
import { getPrecision } from "../../utils/app";

const DepositCrypto = () => {
  const navigate = useNavigate();

  const walletSymbol = useSearchParams()[0].get("wallet")?.toLowerCase();

  const msg = useSelector((state: tRootState) => state.cache.warnings.memo);
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const wallets = useSelector((state: tRootState) => state.cache.wallets);

  const { fetchWallets } = useData();

  const [wallet, setWallet] = useState<undefined | tWallet>(
    wallets?.find((sWallet) => sWallet.symbol.toLowerCase() === walletSymbol)
  );

  const [network, setNetwork] = useState<null | tNetwork>(null);

  const [creatingAddress, setCreatingAddress] = useState(false);

  const [showNetworksModal, setShowNetworksModal] = useState(false);

  const [copiedAddress, copyAddress] = useCopy(network?.address || "");
  const [copiedMemo, copyMemo] = useCopy(network?.memo || "");

  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    if (wallets && walletSymbol && !wallet) {
      const walletSel = wallets?.find(
        (sWallet) => sWallet.symbol.toLowerCase() === walletSymbol
      );
      if (!walletSel) return navigate("/404");
      setWallet(walletSel);
    }
  }, [wallets, walletSymbol, wallet, navigate]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  const depositWallet = (
    walletId: number | string, // would always be number cause it's crypto wallet
    walletSymbol: string,
    networkId: number // should not be null - UPDATE: all crypto has network*
  ) => {
    setCreatingAddress(true);

    api_client({
      url: `/receive/${walletId}/coin/${walletSymbol}${
        networkId ? `/${networkId}` : ""
      }`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error(res.data.message);

        return fetchWallets();
      })
      .then((res) => {
        // Do nothing
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setCreatingAddress(false);
      });
  };

  if (!walletSymbol) return <Navigate to="/404" />;

  if (!wallet) return <Preloader />;

  return (
    <DashboardLayout>
      {creatingAddress ? <Spinner /> : null}
      <SelectNetworkModal
        type="deposit"
        wallet={wallet}
        selectedNetwork={network?.id || null}
        show={showNetworksModal}
        closeHandler={() => {
          setShowNetworksModal(false);
        }}
        handler={(network) => {
          setNetwork(network);
          setShowNetworksModal(false);
        }}
      />

      {showNotice && network ? (
        <DepositCryptoNoticeModal
          wallet={wallet}
          network={network}
          closeHandler={() => setShowNotice(false)}
        />
      ) : null}

      <div className="crypto-deposit-block">
        <div className="crypto-deposit-block__header">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </span>
          Deposit {wallet.coin}
        </div>
        <div className="crypto-deposit-block__body">
          <div className="form-group mb-medium">
            <label className="text-center">Select Network:</label>
            <div
              className="select-box select-box--md"
              onClick={() => setShowNetworksModal(true)}
            >
              {network ? <img src={wallet.image} alt="" /> : null}
              <p>
                {network
                  ? `${wallet.coin} - ${network.name}`
                  : "Select network"}
              </p>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          {network && !network.address ? (
            <button
              className="button"
              onClick={() =>
                depositWallet(wallet.id, wallet.symbol, network.id)
              }
            >
              Generate Address
            </button>
          ) : null}
          {network && network.address ? (
            <div className="wallet-deposit__recieve-steps">
              <div>
                Copy and paste your {wallet.coin} - {network.name} wallet from
                other wallet services or exchanges: address shown below or scan
                the QR code.
              </div>
              {network.memo && msg ? (
                <div className="bold text-danger">{msg}</div>
              ) : null}
              <div className="wallet-deposit__qr-container">
                <div className="minimum-deposit">
                  <IonIcon icon={alertCircle} />
                  Minimum Deposit -{" "}
                  <strong>
                    {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                    {wallet.symbol.toUpperCase()}
                  </strong>
                </div>
              </div>
              <div className="wallet-deposit__qr-container w-100">
                <QRCodeSVG
                  value={network.address}
                  className="wallet-deposit__qr-img wallet-deposit__qr-img--small"
                />
              </div>
              <div className="wallet-deposit__qr-link wallet-deposit__qr-link--small w-100">
                <div>{network.address}</div>
                <button onClick={() => copyAddress(() => setShowNotice(true))}>
                  {copiedAddress ? "Copied!" : "Copy Address"}
                </button>
              </div>
              {network.memo ? (
                <div className="wallet-deposit__qr-link wallet-deposit__qr-link--small w-100">
                  <div>{network.memo}</div>
                  <button onClick={() => copyMemo(() => setShowNotice(true))}>
                    {copiedMemo
                      ? "Copied!"
                      : wallet.symbol.toLowerCase() === "xrp"
                      ? "Copy Destination Tag"
                      : "Copy Memo"}
                  </button>
                </div>
              ) : null}
              <div className="wallet-deposit__qr-container">
                <div className="minimum-deposit">
                  If you deposit any amount below{" "}
                  {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()}, you risk losing your asset
                  forever.
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(DepositCrypto);
