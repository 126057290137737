import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack, chevronDownOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import {
  tCountries,
  tCountry,
  tVolume,
  tWallet,
} from "../../store/types/app.types";
import { updateBulkOTC } from "../../store/cryptoSlice";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";
import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../hooks/useSelectBox/useSelectBox";
import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import SelectCurrencyModal from "../../components/SelectCurrencyModal/SelectCurrencyModal";
import { isNumber } from "../../utils/func";

const getCountrySelectData = (
  countries: tCountries | null
): tSelectBoxGeneric | null => {
  if (!countries) return countries;

  const data: tSelectBoxGeneric = {};

  for (const country of countries) {
    data[country.id] = `${country.country_name}(${country.contry_code})`;
  }

  return data;
};

const BulkOTC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { volumes, volumesSelectData } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchVolumes, fetchCountries } = useData();

  const [countries, setCountries] = useState<tCountries | null>(null);

  const countriesSelectData = getCountrySelectData(countries);

  const [countrySelectBox, countryId, openCountrySelectBox] =
    useSelectBox<string>(
      "Select Country",
      getSelectBoxData(countriesSelectData),
      null
    );

  const [country, setCountry] = useState<tCountry | null>(null);

  const [wallet, setWallet] = useState<undefined | tWallet>(undefined);

  const [type, setType] = useState<null | "buy" | "sell">(null);

  const [volumeSelectBox, volumeId, openVolumeSelectBox] = useSelectBox<string>(
    "Select Volume",
    volumesSelectData,
    null
  );
  const [volume, setVolume] = useState<tVolume | null>(null);

  const [time, setTime] = useState<
    null | "immediately" | "within_24_hours" | "anytime_soon"
  >(null);

  const [telephone, setTelephone] = useState<number | "">("");

  const [contactType, setContactType] = useState<
    null | "whatsapp" | "phone-call"
  >(null);

  const [message, setMessage, clearMessage] = useAlert(true);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [showSelectWalletModal, setShowSelectWalletModal] = useState(false);

  const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value as any);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value as any);
  };

  const handleContactTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContactType(e.target.value as any);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !wallet ||
      !type ||
      !volume ||
      !time ||
      !country ||
      !telephone ||
      telephone.toString().length !== 10 ||
      !contactType
    )
      return setMessage("warning", "Fill in all fields");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/create-otc",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        coin: wallet.symbol,
        type,
        volume: volume.id,
        time,
        phone_number: `${country.contry_code}${telephone}`,
        contact_via: contactType,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        dispatch(updateBulkOTC({ wallet: wallet }));

        navigate("/bulk-otc/successful");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Submit";
        }
      });
  };

  useEffect(() => {
    if (!countryId) return;

    setCountry(
      countries?.find((count) => count.id.toString() === countryId) || null
    );
  }, [countryId, countries]);

  useEffect(() => {
    if (!volumeId) return;

    setVolume(volumes.find((vol) => volumeId === vol.id.toString())!);
  }, [volumeId, volumes]);

  useEffect(() => {
    clearMessage();
  }, [wallet, type, time, volume, country, telephone, clearMessage]);

  useEffect(() => {
    fetchVolumes();
  }, [fetchVolumes]);

  useEffect(() => {
    fetchCountries().then((counts) => setCountries(counts));
  }, [fetchCountries]);

  return (
    <DashboardLayout>
      {volumeSelectBox}
      {countrySelectBox}

      <SelectCurrencyModal
        show={showSelectWalletModal}
        type="crypto"
        selectHandler={(selWallet) => {
          setWallet(selWallet);
          setShowSelectWalletModal(false);
        }}
        closeHandler={() => setShowSelectWalletModal(false)}
        selectedWallet={wallet?.symbol}
      />
      <div className="bulk-otp">
        <div className="auth auth--md" style={{ margin: "auto" }}>
          <h3 className="naira-block__heading">
            <span onClick={() => navigate("/dashboard")}>
              <IonIcon icon={arrowBack} />
            </span>
            OTC Desk
          </h3>
          <p className="auth__heading-sub-text">
            Kindly fill the bulk crypto details
          </p>
          <form className="auth__form-main" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label">Coin</label>
              <div
                className="select-box select-box--md"
                onClick={() => setShowSelectWalletModal(true)}
              >
                {wallet ? <img src={wallet.image} alt="" /> : null}
                <p>{wallet ? wallet.coin : "Select wallet"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">I want to</label>
              <div className="checkbox-group">
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="type"
                    value="sell"
                    onChange={handleTypeChange}
                  />
                  <span>Sell</span>
                </div>
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="type"
                    value="buy"
                    onChange={handleTypeChange}
                  />
                  <span>Buy</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Volume</label>
              <div
                className="select-box select-box--md"
                onClick={() => openVolumeSelectBox()}
              >
                <p>{volume ? volume.volume : "Select Volume"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Ready to trade</label>
              <div className="checkbox-group checkbox-group--spread">
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="trade"
                    value="immediately"
                    onChange={handleTimeChange}
                  />
                  <span>Immediately</span>
                </div>
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="trade"
                    value="within_24_hours"
                    onChange={handleTimeChange}
                  />
                  <span>Within 24 hours</span>
                </div>
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="trade"
                    value="anytime_soon"
                    onChange={handleTimeChange}
                  />
                  <span>Anytime soon</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">How can we contact you?</label>
              <div className="checkbox-group">
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="contactType"
                    value="whatsapp"
                    onChange={handleContactTypeChange}
                  />
                  <span>Whatsapp</span>
                </div>
                <div className="check-control">
                  <input
                    type="radio"
                    className="radio-1"
                    name="contactType"
                    value="phone-call"
                    onChange={handleContactTypeChange}
                  />
                  <span>Phone call</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Phone number</label>
              <div className="input-group input-group--primary">
                <button type="button" onClick={openCountrySelectBox}>
                  {country ? country.contry_code : "Select country"}
                  <IonIcon icon={chevronDownOutline} />
                </button>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={telephone}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setTelephone(+e.target.value)
                        : null
                      : setTelephone("")
                  }
                  maxLength={10}
                />
              </div>
              {/* <label className="form-label text-center">
                <IonIcon icon={alertCircle} className="text-success" />
                Enter phone number without country code
              </label> */}
            </div>
            <div
              className="giftcard-block__indemnity text-center"
              style={{
                backgroundColor: "var(--pay-bills-right-info-bg)",
                color: "var(--text-color)",
                display: "block",
              }}
            >
              <span className="text-success">OTC DESK</span> is an advanced
              trading arm that allows you carry out heavy transactions with
              instant payment and improved{" "}
              <span className="text-success">RATES</span>. Daily Account limits
              are not a barrier for <span className="text-success">OTC</span>{" "}
              trade.
            </div>
            <div className="auth__footer">
              {message}
              <button
                className={cls(
                  "form-button",
                  wallet &&
                    type &&
                    volume &&
                    time &&
                    country &&
                    telephone &&
                    contactType &&
                    "form-button--active"
                )}
                ref={submitBtnRef}
                style={{ width: "80%", alignSelf: "center" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(BulkOTC);
