const GiftCardsLoader = ({ len }: { len?: number }) => {
  return (
    <>
      {new Array(len || 8).fill(null).map((_, i) => (
        <div className="giftcard" key={i}>
          <div className="giftcard__pl-div">
            <img
              src="https://team.mybtcnigeria.xyz/images/panel/anazon.jpg"
              alt=""
            />
          </div>
          <p className="giftcard__name">
            <span className="color-transparent placeholder">Amazon</span>
          </p>
        </div>
      ))}
    </>
  );
};

export default GiftCardsLoader;
