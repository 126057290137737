import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { searchOutline, caretUp, caretDown } from "ionicons/icons";

import { updateMarket } from "../../store/appSlice";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tMarket } from "../../store/types/app.types";

import Pagination from "../Pagination/Pagination";

import MarketLoader from "../../loaders/MarketLoader/MarketLoader";

import { getPagination, getSkip, roundDP } from "../../utils/func";

type SortType = "price" | "price_change" | "coin_name" | "market_cap";

const DIVISION = +(process.env.REACT_APP_MARKET_DIVISION || 10);

const getPrice = (price: number | string) => {
  if (+price > 0 && +price < 1) return price;

  return roundDP(price, 2);
};

const MarketSection = () => {
  const dispatch = useDispatch();

  const market = useSelector((state: tRootState) => state.app.market);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<
    "all" | "gainers" | "losers" | "new-listings"
  >("all");

  const [marketData, setMarketData] = useState<null | tMarket>(market);

  const [page, setPage] = useState(1);

  const [sort, setSort] = useState<{
    key: SortType | null;
    type: ("asc" | "desc") | null;
  }>({ key: null, type: null });

  const sortData = (key: SortType) => {
    setSort({
      key: key,
      type: sort.key === key ? (sort.type === "asc" ? "desc" : "asc") : "asc",
    });
  };

  useEffect(() => {
    if (!market) return;

    let mktData: tMarket;

    if (filter === "all") {
      mktData = [...market];
    } else if (filter === "gainers") {
      mktData = market.filter((data) => Number(data.price_change) > 0);
      setSort({ key: "price_change", type: "asc" });
    } else if (filter === "losers") {
      mktData = market.filter((data) => Number(data.price_change) <= 0);
      setSort({ key: "price_change", type: "desc" });
    } else if (filter === "new-listings") {
      mktData = [...market];
      setSort({ key: "coin_name", type: "desc" });
    } else {
      mktData = [];
    }

    if (search) {
      mktData = mktData.filter(
        (data) =>
          new RegExp(search, "i").test(data.coin_name) ||
          new RegExp(search, "i").test(data.symbol)
      );
    }

    const [key, type] = [sort.key, sort.type];

    if (key && type) {
      mktData.sort((a, b) => {
        if (key === "coin_name") {
          if (type === "asc") {
            return a[key] > b[key] ? -1 : 1;
          } else if (type === "desc") {
            return b[key] > a[key] ? -1 : 1;
          }
        }

        if (key === "market_cap") {
          return sort.type === "asc"
            ? b.market_cap.market_cap - a.market_cap.market_cap
            : a.market_cap.market_cap - b.market_cap.market_cap;
        }

        const sortKey = key as Exclude<SortType, "coin_name" | "market_cap">;
        return sort.type === "asc"
          ? b[sortKey] - a[sortKey]
          : a[sortKey] - b[sortKey];
      });
    }

    setMarketData(mktData);
    setPage(1);
  }, [market, filter, search, sort.key, sort.type]);

  useEffect(() => {
    setSort({ key: "market_cap", type: "asc" });
  }, []);

  const fetchMarketData = useCallback(() => {
    api_client({
      url: "/all-wallets",
    })
      .then((res) => {
        dispatch(updateMarket(res.data));
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    fetchMarketData();

    const interval = setInterval(fetchMarketData, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchMarketData]);

  let topGainers =
    market?.filter((data) => Number(data.price_change) > 0) || [];
  topGainers.sort((a, b) => b.price_change - a.price_change);
  topGainers = topGainers.slice(0, 3);

  let bottomLosers =
    market?.filter((data) => Number(data.price_change) <= 0) || [];
  bottomLosers.sort((a, b) => a.price_change - b.price_change);
  bottomLosers = bottomLosers.slice(0, 3);

  let newListings = market?.slice() || [];
  newListings.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
  newListings = newListings.slice(0, 3);

  // For pagination
  const pagination = marketData
    ? getPagination(marketData.length, DIVISION)
    : 0;
  const skip = getSkip(page, DIVISION);

  return (
    <section className="market-section">
      <div className="container market-section__container">
        {market ? (
          <div className="market-section__coins-grid mb-medium">
            <div className="coins-block">
              <h3 className="coins-block__heading">Top Gainers</h3>
              <table className="coins-block__coins">
                <tbody>
                  {topGainers.map((mktData, i) => (
                    <tr key={mktData.id}>
                      <td>
                        <div className="market__currency">
                          <img src={mktData.logo} alt="" />
                          <span className="market__currency-name">
                            {mktData.coin_name} | <span>{mktData.symbol}</span>
                          </span>
                        </div>
                      </td>
                      <td>${getPrice(mktData.price)}</td>
                      <td>
                        <div
                          className={`market__change market__change--success`}
                        >
                          <IonIcon icon={caretUp} />
                          {Number(mktData.price_change).toFixed(2)}%
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="coins-block">
              <h3 className="coins-block__heading">Bottom Losers</h3>
              <table className="coins-block__coins">
                <tbody>
                  {bottomLosers.map((mktData, i) => (
                    <tr key={mktData.id}>
                      <td>
                        <div className="market__currency">
                          <img src={mktData.logo} alt="" />
                          <span className="market__currency-name">
                            {mktData.coin_name} | <span>{mktData.symbol}</span>
                          </span>
                        </div>
                      </td>
                      <td>${getPrice(mktData.price)}</td>
                      <td>
                        <div
                          className={`market__change market__change--danger`}
                        >
                          <IonIcon icon={caretDown} />
                          {Number(mktData.price_change).toFixed(2)}%
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="coins-block">
              <h3 className="coins-block__heading">New coins on the block</h3>
              <table className="coins-block__coins">
                <tbody>
                  {newListings.map((mktData, i) => (
                    <tr key={mktData.id}>
                      <td>
                        <div className="market__currency">
                          <img src={mktData.logo} alt="" />
                          <span className="market__currency-name">
                            {mktData.coin_name} | <span>{mktData.symbol}</span>
                          </span>
                        </div>
                      </td>
                      <td>${getPrice(mktData.price)}</td>
                      <td>
                        <div
                          className={`market__change market__change--${
                            +mktData.price_change > 0 ? "success" : "danger"
                          }`}
                        >
                          <IonIcon
                            icon={
                              +mktData.price_change > 0 ? caretUp : caretDown
                            }
                          />
                          {Number(mktData.price_change).toFixed(2)}%
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        <div className="market__filter-block">
          <div className="market__filter market__filter-1">
            <div className="market__filter-btns">
              <button
                className={`market__filter-btn ${
                  filter === "all" ? "market__filter-btn--active" : ""
                }`}
                onClick={() => setFilter("all")}
              >
                All
              </button>
              <button
                className={`market__filter-btn ${
                  filter === "gainers" ? "market__filter-btn--active" : ""
                }`}
                onClick={() => setFilter("gainers")}
              >
                Gainers
              </button>
              <button
                className={`market__filter-btn ${
                  filter === "new-listings" ? "market__filter-btn--active" : ""
                }`}
                onClick={() => setFilter("new-listings")}
              >
                New Listings
              </button>
              <button
                className={`market__filter-btn ${
                  filter === "losers" ? "market__filter-btn--active" : ""
                }`}
                onClick={() => setFilter("losers")}
              >
                Losers
              </button>
            </div>
            <form className="market__search-form">
              <button>
                <IonIcon icon={searchOutline} />
              </button>
              <input
                type="text"
                placeholder="Search Coin"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </div>
        </div>
        <div className="table-responsive" style={{ display: "block" }}>
          <table className="table">
            <tbody>
              <tr>
                <th style={{ paddingLeft: "1.8rem" }}>
                  <div className="market__table-heading">
                    Name
                    <div
                      className="market__icon-stack"
                      onClick={() => sortData("coin_name")}
                    >
                      <IonIcon
                        icon={caretUp}
                        className={
                          sort.key === "coin_name" && sort.type === "asc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                      <IonIcon
                        icon={caretDown}
                        className={
                          sort.key === "coin_name" && sort.type === "desc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="market__table-heading">
                    Last Price
                    <div
                      className="market__icon-stack"
                      onClick={() => sortData("price")}
                    >
                      <IonIcon
                        icon={caretUp}
                        className={
                          sort.key === "price" && sort.type === "asc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                      <IonIcon
                        icon={caretDown}
                        className={
                          sort.key === "price" && sort.type === "desc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="market__table-heading">
                    Change &#40;24H&#41;
                    <div
                      className="market__icon-stack"
                      onClick={() => sortData("price_change")}
                    >
                      <IonIcon
                        icon={caretUp}
                        className={
                          sort.key === "price_change" && sort.type === "asc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                      <IonIcon
                        icon={caretDown}
                        className={
                          sort.key === "price_change" && sort.type === "desc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="market__table-heading">
                    Market Cap{" "}
                    <div
                      className="market__icon-stack"
                      onClick={() => sortData("market_cap")}
                    >
                      <IonIcon
                        icon={caretUp}
                        className={
                          sort.key === "market_cap" && sort.type === "asc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                      <IonIcon
                        icon={caretDown}
                        className={
                          sort.key === "market_cap" && sort.type === "desc"
                            ? "market__icon-stack--active"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </th>
              </tr>
              {!marketData ? <MarketLoader /> : null}
              {marketData?.slice(skip, skip + DIVISION).map((mktData, i) => (
                <tr key={mktData.id}>
                  <td>
                    <div className="market__currency">
                      <img src={mktData.logo} alt="" />
                      <span className="market__currency-name">
                        {mktData.coin_name} | <span>{mktData.symbol}</span>
                      </span>
                    </div>
                  </td>
                  <td>${getPrice(mktData.price)}</td>
                  <td>
                    <div
                      className={`market__change market__change--${
                        +mktData.price_change > 0 ? "success" : "danger"
                      }`}
                    >
                      <IonIcon
                        icon={+mktData.price_change > 0 ? caretUp : caretDown}
                      />
                      {Number(mktData.price_change).toFixed(2)}%
                    </div>
                  </td>
                  <td>${getPrice(mktData.market_cap?.market_cap || 0)}</td>
                </tr>
              ))}
              {marketData && !marketData.length ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    No coin in current filter
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        <Pagination pagination={pagination} page={page} setPage={setPage} />
      </div>
    </section>
  );
};

export default MarketSection;
