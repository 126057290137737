import withAuth from "../../hoc/withAuth/withAuth";

import DashboardWallet from "../../components/DashboardWallet/DashboardWallet";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

const DashboardWalletPage = () => {
  return (
    <DashboardLayout>
      <DashboardWallet />
    </DashboardLayout>
  );
};

export default withAuth(DashboardWalletPage);
