import { ReactNode } from "react";

import HomeHeader from "../../components/HomeHeader/HomeHeader";
import HomeFooter from "../../components/HomeFooter/HomeFooter";
import ModeToggle from "../../components/ModeToggle/ModeToggle";

const HomeLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <ModeToggle />
      <HomeHeader />
      {children}
      <HomeFooter />
    </>
  );
};

export default HomeLayout;
