import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { arrowBack, arrowForwardOutline } from "ionicons/icons";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import VerifyAccountModal from "../../components/VerifyAccountModal/VerifyAccountModal";
import ManualUnverified from "../../components/VerifyAccountModal/ManualUnverifiedModal";

import { assertNotNull } from "../../utils/func";

import bank from "../../assets/img/bank.png";

const Deposit = () => {
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const { depositFee, depositFeeText } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchDepositFee } = useData();

  const [loading, setLoading] = useState(!depositFee);

  assertNotNull(userDetails);

  useEffect(() => {
    fetchDepositFee()
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [fetchDepositFee]);

  // if (!userDetails.is_verified || userDetails.manual_unverified === 1)
  //   return <Navigate to="/404" />;

  if (loading) return <Preloader />;

  return (
    <DashboardLayout>
      {!userDetails.is_verified ? (
        <VerifyAccountModal
          closeHandler={() => navigate("/dashboard")}
          show={true}
        />
      ) : userDetails.manual_unverified ? (
        <ManualUnverified
          closeHandler={() => navigate("/dashboard")}
          show={true}
        />
      ) : null}
      <div className="naira-block">
        <div className="naira-block__header">
          <h3 className="naira-block__heading">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Deposit Method
          </h3>
          <p className="naira-block__sub-heading text-center">
            Choose a method to deposit money into your NGN wallet
          </p>
        </div>
        <div
          className="deposit-method"
          onClick={() => navigate("/deposit/amount")}
        >
          <img src={bank} alt="" className="deposit-method__img" />
          <div className="deposit-method__main">
            <h3 className="deposit-method__heading">
              Peer to Peer Bank Transfer
            </h3>
            {depositFee ? (
              <>
                <p className="deposit-method__fee">Fee: NGN {depositFee}</p>
                <em className="deposit-method__label">
                  <strong>Note: </strong>
                  {depositFeeText}
                </em>
              </>
            ) : null}
            {/* <p className="deposit-method__label">Instant</p> */}
          </div>
          <span className="deposit-method__icon-box">
            <IonIcon icon={arrowForwardOutline} />
          </span>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Deposit);
