import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardQuickActions from "../../components/DashboardQuickActions/DashboardQuickActions";
import DashboardServices from "../../components/DashboardServices/DashboardServices";
import ProfileProgress from "../../components/ProfileProgress/ProfileProgress";
import RecentTransactions from "../../components/RecentTransactions/RecentTransactions";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import UserWelcome from "../../components/UserWelcome/UserWelcome";
import DashboardWallet from "../../components/DashboardWallet/DashboardWallet";
import StatusSection from "../../components/StatusSection/StatusSection";
import SetPin from "../../components/SetPin/SetPin";

import { assertNotNull } from "../../utils/func";

import DashboardBanners from "../../components/DashboardBanners/DashboardBanners";
import DashboardPopup from "../../components/DashboardPopup/DashboardPopup";

const Dashboard = () => {
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  const [openSetPin, setOpenSetPin] = useState(!userDetails.is_pin_set);

  return (
    <DashboardLayout showNews>
      {openSetPin ? <SetPin closeHandler={() => setOpenSetPin(false)} /> : null}

      <DashboardPopup />

      <section>
        <UserWelcome />
        <DashboardWallet />
      </section>
      <ProfileProgress />
      <DashboardServices />
      <DashboardBanners />
      <DashboardQuickActions />
      <section className="transactions-section">
        <div className="transactions-section__header">
          <h3 className="heading-secondary">Recent</h3>
          <Link to="/transactions" className="transactions-section__link">
            View All
          </Link>
        </div>
        <div className="transactions-section__main">
          <RecentTransactions />
        </div>
        <div className="transactions-section__footer">
          <button className="button" onClick={() => navigate("/transactions")}>
            View All Transactions
          </button>
        </div>
      </section>
      <StatusSection />
    </DashboardLayout>
  );
};

export default withAuth(Dashboard);
