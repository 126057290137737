import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowUpCircleOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tTransactionLimits } from "../../store/cacheSlice";

import useData from "../../hooks/useData/useData";

import { assertNotNull, roundDP } from "../../utils/func";

const TransactionLimit = ({ type }: { type: tTransactionLimits }) => {
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const limit = useSelector(
    (state: tRootState) => state.cache.transactionLimits[type]
  );

  const { fetchTransactionLimit } = useData();

  assertNotNull(userDetails);

  useEffect(() => {
    fetchTransactionLimit(type);
  }, [fetchTransactionLimit, type]);

  if (!limit) return null;

  return (
    <div className="transaction-limit">
      <div className="transaction-limit__div">
        <p>
          Daily Transaction Limit: {limit.unit}{" "}
          {roundDP(limit.daily_transaction_limit, 2)}
        </p>
        {!userDetails.is_verified ? (
          <span
            className="transaction-limit__span cursor-pointer"
            onClick={() => navigate("/account/verify-identity")}
          >
            Upgrade Now
            <IonIcon icon={arrowUpCircleOutline} />
          </span>
        ) : null}
      </div>
      <div className="profile-progress__progress">
        <div
          style={{
            width: `${(+limit.used / +limit.daily_transaction_limit) * 100}%`,
          }}
        ></div>
      </div>
      <div className="transaction-limit__div">
        <span className="transaction-limit__span">
          <i className="transaction-limit__check transaction-limit__check--primary"></i>
          {limit.unit} {roundDP(limit.used, 2)} used
        </span>
        <span className="transaction-limit__span">
          {limit.unit} {roundDP(limit.remaining, 2)} remaining
          <i className="transaction-limit__check"></i>
        </span>
      </div>
    </div>
  );
};

export default TransactionLimit;
