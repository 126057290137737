import { IonIcon } from "@ionic/react";
import {
  chevronForwardOutline,
  closeOutline,
  personOutline,
} from "ionicons/icons";

export type tSelectWithdrawalType = {
  type: "fiat" | "crypto";
  internalAction: () => void;
  externalAction: () => void;
  closeHandler: () => void;
};

const SelectWithdrawalType = ({
  type,
  internalAction,
  externalAction,
  closeHandler,
}: tSelectWithdrawalType) => {
  return (
    <>
      <div className="withdrawal-type">
        <div className="withdrawal-type__header">
          <h3 className="withdrawal-type__heading">Select Withdrawal Method</h3>
          <span className="withdrawal-type__close" onClick={closeHandler}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="withdrawal-type__methods">
          <div className="withdrawal-type__method" onClick={internalAction}>
            <div className="withdrawal-type__method-icon-box">
              <IonIcon icon={personOutline} />
            </div>
            <p className="withdrawal-type__method-text">Send to SekiApp user</p>
            <span className="badge badge--danger">FREE</span>
            <IonIcon
              icon={chevronForwardOutline}
              className="withdrawal-type__method-icon"
            />
          </div>
          <div className="withdrawal-type__method" onClick={externalAction}>
            <div className="withdrawal-type__method-icon-box">
              <IonIcon icon={personOutline} />
            </div>
            <p className="withdrawal-type__method-text">
              Withdraw to{" "}
              {type === "crypto" ? "an external wallet" : "bank account"}
            </p>
            <IonIcon
              icon={chevronForwardOutline}
              className="withdrawal-type__method-icon"
            />
          </div>
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default SelectWithdrawalType;
