import { IonIcon } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons";

export default function Pagination({
  pagination,
  page,
  setPage,
}: {
  pagination: number;
  page: number;
  setPage: (newPage: number) => void;
}) {
  if (!pagination) return null;

  return (
    <div className="pagination-2">
      <div className="pagination-2__buttons">
        <button
          className="pagination-2__button"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <IonIcon icon={arrowBackOutline} />
          Previous
        </button>
        <span className="pagination-2__text">
          Page {page} of {pagination}
        </span>
        <button
          className="pagination-2__button"
          disabled={page === pagination}
          onClick={() => setPage(page + 1)}
        >
          Next
          <IonIcon icon={arrowForwardOutline} />
        </button>
      </div>
    </div>
  );
}
