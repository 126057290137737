import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { tRootState } from "../../store";
import { triggerAction, updateTriggerPage } from "../../store/assetSlice";

import useData from "../../hooks/useData/useData";

import crypto from "../../assets/img/crypto.png";
import giftCards from "../../assets/img/gift-cards.png";
// import transfer from "../../assets/img/transfer.png";
import virtualCard from "../../assets/img/virtual-card.png";
import airtime from "../../assets/img/airtime.png";
import data from "../../assets/img/data.png";
import electricity from "../../assets/img/electricity.png";

const DashboardServices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const availableServices = useSelector(
    (state: tRootState) => state.paybills.availableServices
  );

  const { fetchBillServices } = useData();

  useEffect(() => {
    fetchBillServices();
  }, [fetchBillServices]);

  return (
    <section className="services-section">
      <div className="services-section__header">
        <h3 className="services-section__heading">Services</h3>
        <Link to="/services" className="services-section__link">
          View all
        </Link>
      </div>
      <div className="services-section__services">
        <div
          className="service"
          onClick={() => {
            dispatch(triggerAction("crypto"));
            dispatch(updateTriggerPage("/dashboard"));
            navigate("/dashboard-wallet");
          }}
        >
          <img src={crypto} alt="" className="service__img" />
          <p>Crypto</p>
        </div>
        <div
          className="service"
          onClick={() => {
            dispatch(triggerAction("giftcards"));
            dispatch(updateTriggerPage("/dashboard"));
            navigate("/dashboard-wallet");
          }}
        >
          <img src={giftCards} alt="" className="service__img" />
          <p>Gift cards</p>
        </div>
        {/* <div
          className="service"
          onClick={() => {
            dispatch(triggerAction("transfer"));
            dispatch(updateTriggerPage("/dashboard"));
            navigate("/dashboard-wallet");
          }}
        >
          <img src={transfer} alt="" className="service__img" />
          <p>Transfer</p>
        </div> */}
        <div className="service" onClick={() => navigate("/virtual-cards")}>
          <img src={virtualCard} alt="" className="service__img" />
          <p>Virtual Card</p>
        </div>
        {availableServices.airtime ? (
          <div
            className="service"
            onClick={() => navigate("/pay-bills/airtime")}
          >
            <img src={airtime} alt="" className="service__img" />
            <p>Airtime</p>
          </div>
        ) : null}
        {availableServices.data ? (
          <div className="service" onClick={() => navigate("/pay-bills/data")}>
            <img src={data} alt="" className="service__img" />
            <p>Data</p>
          </div>
        ) : null}
        {availableServices.meterToken ? (
          <div
            className="service"
            onClick={() => navigate("/pay-bills/meterToken")}
          >
            <img src={electricity} alt="" className="service__img" />
            <p>Electricity</p>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default DashboardServices;
