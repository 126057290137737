import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import { alertCircle, closeOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { updateHideVerifButton } from "../../store/userSlice";

import { assertNotNull } from "../../utils/func";

import MatiButton from "../MatiButton/MatiButton";

const VerifyAccountModal = ({
  show,
  closeHandler,
}: {
  show: boolean;
  closeHandler: () => void;
}) => {
  const dispatch = useDispatch();

  const { userDetails, hideVerifButton } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const { is_verified, verif_status, reason } = userDetails;

  useEffect(() => {
    dispatch(updateHideVerifButton(false));
  }, [verif_status, dispatch]);

  if (is_verified || !show) return null;

  return (
    <>
      <div className="verify-account-modal">
        <span onClick={closeHandler} className="verify-account-modal__close">
          <IonIcon icon={closeOutline} />
        </span>
        <div className="verify-account-modal__header">
          <IonIcon icon={alertCircle} />
          <p>
            Account{" "}
            {verif_status === "pending" ? "verification pending" : "unverified"}
          </p>
        </div>
        <div className="verify-account-modal__body">
          {verif_status === "pending" ? (
            <p>
              Chief, your verification request has been sent. Check back in the
              next few minutes to get the status of your verification.
            </p>
          ) : (
            <>
              {verif_status === "rejected" ? (
                <p>
                  <b>Last verification failed:</b> <i>{reason}</i>
                </p>
              ) : null}
              <p>
                You are required to verify your account before you can deposit
                money.
              </p>
              {!hideVerifButton ? (
                <div>
                  <MatiButton />
                </div>
              ) : (
                <p>
                  <span className="fas fa-spinner fa-spin"></span> Finishing
                  Up...
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default VerifyAccountModal;
