import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBack, checkmark, close } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { addTransferInfo1 } from "../../store/nairaSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import SetAuth from "../../components/SetAuth/SetAuth";
import PinGroup from "../../components/PinGroup/PinGroup";
import RingLoader from "../../loaders/RingLoader/RingLoader";
import Preloader from "../../components/Preloader/Preloader";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import BeneficiariesModal from "../../components/BeneficiariesModal/BeneficiariesModal";
import TransactionLimit from "../../components/TransactionLimit/TransactionLimit";

import { roundDP, isNumber, assertNotNull } from "../../utils/func";
import ContactVerification from "../../components/ContactVerification/ContactVerification";
import SetPin from "../../components/SetPin/SetPin";

const Transfer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const { wallets, nairaTransferBeneficiaries } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(userDetails);

  const { fetchBeneficiaries } = useData();

  const [message, setMessage, clearMessage] = useAlert(true);

  const [username, setUsername] = useState("");

  const [usernameExists, setUsernameExists] = useState<boolean | null>(null);

  const [verifyingUsername, setVerifyingUsername] = useState(false);
  const [errorVerifyingUsername, setErrorVerifyingUsername] = useState(false);

  const uverifyInterval = useRef<number | null>(null);

  const [amount, setAmount] = useState<number | "">("");

  const [description, setDescription] = useState("");

  const [newBeneficiary, setNewBeneficiary] = useState(false);

  const [alias, setAlias] = useState("");

  const [pin, setPin] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [showBeneficiariesModal, setShowBeneficiariesModal] = useState(false);

  const [authMethodCallback, setAuthMethodCallback] = useState<
    (() => void) | null
  >(null);
  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username) return setMessage("warning", "Enter receipient username");
    if (verifyingUsername) return;
    if (!usernameExists)
      return setMessage("warning", "Invalid username entered");

    if (!amount) return setMessage("warning", "Enter amount");

    if (newBeneficiary && !alias)
      return setMessage(
        "warning",
        "Alias is required to add a new beneficiary"
      );
    if (newBeneficiary && alias.length > 10)
      return setMessage(
        "warning",
        "Alias should not be more than 10 characters long"
      );

    if (amount > +userDetails.balance)
      return setMessage(
        "warning",
        `You don't have sufficient balance to transfer NGN ${amount}`
      );

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Enter transaction PIN");

    setContactVerificationCallback(
      () => () =>
        setAuthMethodCallback(() => () => {
          submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
          submitBtnRef.current.setAttribute("disabled", "disabled");

          api_client({
            method: "POST",
            url: "/internal-naira-transfer",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            data: {
              username: username,
              amount: amount,
              pin: pin,
              beneficiary: newBeneficiary,
              alias,
              description,
            },
          })
            .then((res) => {
              if (!res.data.success) {
                setMessage("warning", res.data.message);
                throw new Error("");
              }

              dispatch(
                addTransferInfo1({
                  transaction: res.data.transfer,
                  emailMessage: res.data.message,
                })
              );

              navigate("/transfer-final");
            })
            .catch((err) => {
              if (err.message) setMessage("error", err.message);
            })
            .finally(() => {
              if (submitBtnRef.current) {
                submitBtnRef.current.removeAttribute("disabled");
                submitBtnRef.current.innerHTML = "Continue";
              }
            });
        })
    );
  };

  useEffect(() => {
    if (uverifyInterval.current) window.clearInterval(uverifyInterval.current);

    if (!username) {
      setUsernameExists(null);
      setVerifyingUsername(false);
      setErrorVerifyingUsername(false);
      return;
    }

    setVerifyingUsername(true);
    setErrorVerifyingUsername(false);
    setUsernameExists(null);

    uverifyInterval.current = window.setInterval(() => {
      api_client({
        url: `/check-username/${username}`,
      })
        .then((res) => {
          setUsernameExists(res.data.success);
        })
        .catch((err) => {
          setErrorVerifyingUsername(true);
        })
        .finally(() => {
          setVerifyingUsername(false);
          if (uverifyInterval.current)
            window.clearInterval(uverifyInterval.current);
        });
    }, 3000);
  }, [username]);

  useEffect(() => {
    clearMessage();
  }, [username, amount, description, newBeneficiary, alias, pin, clearMessage]);

  useEffect(() => {
    fetchBeneficiaries("naira-transfer");
  }, [fetchBeneficiaries]);

  if (!wallets) return <Preloader />;

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />
      <SetAuth
        callback={authMethodCallback}
        closeHandler={() => setAuthMethodCallback(null)}
      />
      <BeneficiariesModal
        show={showBeneficiariesModal}
        isActive={(ben) => username === ben.recipient}
        transfer
        beneficiaries={nairaTransferBeneficiaries}
        handler={(ben) => {
          setUsername(ben.recipient);
          setShowBeneficiariesModal(false);
        }}
        closeHandler={() => setShowBeneficiariesModal(false)}
      />
      <form className="crypto-block" onSubmit={handleSubmit}>
        <div className="crypto-block__header crypto-block__header--1">
          <div className="crypto-block__back">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} /> Back
            </span>
          </div>
          <h3 className="crypto-block__heading">Naira Transfer</h3>
          <p className="crypto-block__sub-heading text-center">
            Transfer to any SekiApp user for{" "}
            <span className="text-success">FREE</span> with their unique
            username
          </p>
        </div>
        <div className="crypto-block__body crypto-block__body--1">
          <div className="crypto-block__body-main mt-0">
            {nairaTransferBeneficiaries && nairaTransferBeneficiaries.length ? (
              <div className="beneficiaries">
                <div className="beneficiaries__header">
                  <h3 className="beneficiaries__heading">Beneficiaries</h3>
                  <span
                    className="transactions-section__link"
                    onClick={() => setShowBeneficiariesModal(true)}
                  >
                    View All
                  </span>
                </div>
                <div className="beneficiaries__beneficiaries">
                  {nairaTransferBeneficiaries.map((beneficiary) => {
                    return (
                      <div
                        className={cls(
                          "beneficiary",
                          username === beneficiary.recipient &&
                            "beneficiary--active"
                        )}
                        onClick={() => {
                          setUsername(beneficiary.recipient);
                        }}
                        key={beneficiary.id}
                      >
                        <div
                          className="beneficiary__name-logo"
                          style={{ backgroundColor: beneficiary.background }}
                        >
                          {beneficiary.recipient_alias[0].toUpperCase()}
                        </div>
                        <p className="beneficiary__name" title={`${alias}`}>
                          {beneficiary.recipient_alias.slice(0, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <div className="form-input-group">
                <input
                  type="text"
                  placeholder="Enter recipient username"
                  name="username"
                  autoComplete="off"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <button type="button">
                  {verifyingUsername ? <RingLoader /> : null}
                  {typeof usernameExists === "boolean" && usernameExists ? (
                    <IonIcon icon={checkmark} className="text-success" />
                  ) : null}
                  {typeof usernameExists === "boolean" && !usernameExists ? (
                    <IonIcon icon={close} className="text-warning" />
                  ) : null}
                </button>
              </div>
              {typeof usernameExists === "boolean" && !usernameExists ? (
                <span className="form-bottom-label text-warning">
                  <IonIcon icon={alertCircle} className="active" />
                  Username does not exist
                </span>
              ) : null}
              {errorVerifyingUsername ? (
                <span className="form-bottom-label text-danger">
                  <IonIcon icon={alertCircle} className="active" />
                  Error verifying username
                </span>
              ) : null}
            </div>
            <div className="form-group form-group--md">
              <p>MAX: &#8358;{roundDP(userDetails.balance, 2)}</p>
              <div className="input-group">
                <button type="button">&#8358;</button>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setAmount(+e.target.value)
                        : null
                      : setAmount("")
                  }
                />
              </div>
              <AmountPercentage
                totalAmount={userDetails.balance}
                handler={setAmount}
              />
            </div>
            <textarea
              rows={3}
              className="form-input"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            {!nairaTransferBeneficiaries?.some(
              (beneficiary) => username === beneficiary.recipient
            ) ? (
              <div className="accept">
                <input
                  type="checkbox"
                  className="accept__checkbox"
                  checked={newBeneficiary}
                  onChange={(e) => setNewBeneficiary(e.target.checked)}
                />
                <p>Save as beneficiary</p>
              </div>
            ) : null}
            {newBeneficiary ? (
              <div className="form-group">
                <label>Add Nickname</label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="e.g Johnny"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  maxLength={10}
                />
              </div>
            ) : null}
            <TransactionLimit type="internal_naira" />
          </div>
        </div>
        {userDetails.is_pin_set ? (
          <div className="crypto-block__pin crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span
              className="crypto-final__forgot-pin"
              onClick={() => navigate("/change-pin")}
            >
              Forgot PIN
            </span>
          </div>
        ) : null}
        <div className="crypto-block__footer">
          {message}
          <button
            className={cls(
              "form-button",
              username &&
                usernameExists &&
                amount &&
                (!newBeneficiary ||
                  (newBeneficiary && alias && alias.length <= 10)) &&
                (!userDetails.is_pin_set || pin.length === 4) &&
                "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Continue
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(Transfer);
