import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";
import validator from "validator";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  checkmark,
  checkmarkCircle,
  chevronDownOutline,
  close,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { login } from "../../store/userSlice";
import { tCountries, tCountry, tUser } from "../../store/types/app.types";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";
import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../hooks/useSelectBox/useSelectBox";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import RingLoader from "../../loaders/RingLoader/RingLoader";

import { isNumber } from "../../utils/func";

import sms from "../../assets/img/sms.png";
import whatsapp from "../../assets/img/whatsapp.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";
import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import { updateCodeSend } from "../../store/appSlice";

const getCountrySelectData = (
  countries: tCountries | null
): tSelectBoxGeneric | null => {
  if (!countries) return countries;

  const data: tSelectBoxGeneric = {};

  for (const country of countries) {
    data[country.id] = `${country.country_name}(${country.contry_code})`;
  }

  return data;
};

const NewRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const referral = useSearchParams()[0].get("referral");

  const codeSend = useSelector((state: tRootState) => state.app.codeSend);

  const { fetchDashboardPopup, fetchBillServices } = useData();

  const [resendBlocked, setResendBlocked] = useState<number>(0);
  const [resendBlockedInterval, setResendBlockedInterval] = useState<
    number | null
  >(null);
  const [resendBlockedFor, setResendBlockedFor] = useState<string>("");

  const [countries, setCountries] = useState<tCountries | null>(null);

  const { fetchCountries } = useData();

  const countriesSelectData = getCountrySelectData(countries);

  const [countrySelectBox, countryId, openCountrySelectBox] =
    useSelectBox<string>(
      "Select Country",
      getSelectBoxData(countriesSelectData),
      null
    );

  const [country, setCountry] = useState<tCountry | null>(null);

  const [authType, setAuthType] = useState<"email" | "phone">("email");

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState<number | "">("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [smsType, setSMSType] = useState<"whatsapp" | "sms">("whatsapp");
  const [verificationCode, setVerificationCode] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordValidations, setPasswordValidations] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const uverifyInterval = useRef<number | null>(null);

  const [usernameExists, setUsernameExists] = useState<boolean | null>(null);

  const [verifyingUsername, setVerifyingUsername] = useState(false);
  const [errorVerifyingUsername, setErrorVerifyingUsername] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !name ||
      !username ||
      (authType === "email" && !email) ||
      (authType === "phone" && (!telephone || !country)) ||
      !password ||
      !confirmPassword ||
      !verificationCode
    )
      return setMessage("warning", "Fill in all fields");

    if (authType === "email" && !validator.isEmail(email))
      return setMessage("warning", "Enter a valid email address");

    if (authType === "phone" && !validator.isNumeric(telephone.toString()))
      return setMessage("warning", "Enter a valid phone number");

    if (password !== confirmPassword)
      return setMessage("warning", "Passwords do not match");

    if (verifyingUsername) return;

    if (typeof usernameExists !== "boolean" || usernameExists)
      return setMessage("warning", "Username unverified");

    if (!passwordValidations.every((v) => v))
      return setMessage("warning", "Password does not pass all validations");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    let token: string;
    let user: tUser;

    api_client({
      method: "POST",
      url: "/register-new",
      data: {
        mode: authType,
        name,
        username,
        phone_country_id: country?.id,
        [authType]: `${
          authType === "phone" ? `${country?.contry_code}${telephone}` : email
        }`,
        verification_code: verificationCode,
        password,
        password_confirmation: confirmPassword,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        token = res.data.token;

        // do in the background
        fetchBillServices(token);

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${token}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        user = res.data.message;

        return fetchDashboardPopup(token);
      })
      .then(() => {
        localStorage.removeItem("login_timeout");

        dispatch(login({ accessToken: token, userDetails: user }));

        navigate("/register/referral-code");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Next";
        }
      });
  };

  const handleFetchRegistrationCode = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target! as HTMLButtonElement;

    if (!name || !username)
      return setMessage("warning", "Name and username is required");

    if (verifyingUsername) return;

    if (typeof usernameExists !== "boolean" || usernameExists)
      return setMessage("warning", "Username unverified");

    if (authType === "email" && !email)
      return setMessage("warning", "Email address is required");

    if (authType === "email" && !validator.isEmail(email))
      return setMessage("warning", "Enter a valid email address");

    const country = countries?.find(
      (currentCountry) => currentCountry.id.toString() === countryId
    );

    if (
      authType === "phone" &&
      (!validator.isNumeric(telephone.toString()) || !country)
    )
      return setMessage("warning", "Valid phone number is required");

    target.innerHTML = `<i class="fas fa-spinner fa-spin"></i>`;
    target.setAttribute("disabled", "disabled");

    api_client({
      url: `/register-otp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        mode: authType,
        name: name,
        username: username,
        country_id: country?.id,
        option: smsType,
        [authType]: `${
          authType === "phone" ? `${country?.contry_code}${telephone}` : email
        }`,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        setMessage("success", res.data.message);

        dispatch(
          updateCodeSend({
            key:
              authType === "phone"
                ? `${country?.contry_code}${telephone.toString()}`
                : email,
            value: (Date.now() + 120000).toString(),
          })
        );
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        target.removeAttribute("disabled");
        target.innerHTML = "Request Code";
      });
  };

  useEffect(() => {
    if (uverifyInterval.current) window.clearInterval(uverifyInterval.current);

    if (!username) {
      setUsernameExists(null);
      setVerifyingUsername(false);
      setErrorVerifyingUsername(false);
      return;
    }

    setVerifyingUsername(true);
    setErrorVerifyingUsername(false);
    setUsernameExists(null);

    uverifyInterval.current = window.setInterval(() => {
      api_client({
        url: `/check-username/${username}`,
      })
        .then((res) => {
          setUsernameExists(res.data.success);
        })
        .catch((err) => {
          setErrorVerifyingUsername(true);
        })
        .finally(() => {
          setVerifyingUsername(false);
          if (uverifyInterval.current)
            window.clearInterval(uverifyInterval.current);
        });
    }, 3000);
  }, [username]);

  useEffect(() => {
    if (!countryId) return;

    setCountry(
      countries?.find((count) => count.id.toString() === countryId) || null
    );
  }, [countryId, countries]);

  useEffect(() => {
    const newPasswordValidations = [false, false, false, false, false];

    newPasswordValidations[0] = password.length >= 8;

    newPasswordValidations[1] = password
      .split("")
      .some((c) => "abcdefghijklmnopqrstuvwxyz".split("").includes(c));

    newPasswordValidations[2] = password
      .split("")
      .some((c) =>
        "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("").includes(c)
      );

    newPasswordValidations[3] = password
      .split("")
      .some((c) => "1234567890".split("").includes(c));

    newPasswordValidations[4] = password
      .split("")
      .some((c) => "~`@#$%^&*()-_=+{}[];:'\",.<>/?|\\/".split("").includes(c));

    setPasswordValidations(newPasswordValidations);
  }, [password]);

  useEffect(() => {
    clearMessage();
  }, [
    name,
    username,
    email,
    countryId,
    telephone,
    verificationCode,
    authType,
    smsType,
    password,
    confirmPassword,
    clearMessage,
  ]);

  useEffect(() => {
    if (resendBlocked <= 0 && typeof resendBlockedInterval === "number") {
      setResendBlocked(0);
      window.clearInterval(resendBlockedInterval);
      return setResendBlockedInterval(null);
    }

    if (resendBlocked > 0 && resendBlockedInterval === null) {
      setResendBlockedInterval(
        window.setInterval(() => {
          setResendBlocked((b) => b - 1000);
        }, 1000)
      );
    }
  }, [resendBlocked, resendBlockedInterval]);

  useEffect(() => {
    let codeCheck: string = "";

    if (authType === "email" && email && validator.isEmail(email)) {
      codeCheck = email;
    }

    if (
      authType === "phone" &&
      validator.isNumeric(telephone.toString()) &&
      country
    ) {
      codeCheck = `${country.contry_code}${telephone.toString()}`;
    }

    if (codeCheck && codeSend[codeCheck]) {
      const resendBlockedUntil = new Date(+codeSend[codeCheck]).getTime();

      const currentDate = new Date().getTime();

      if (currentDate < resendBlockedUntil) {
        setResendBlocked(resendBlockedUntil - currentDate);
        setResendBlockedFor(codeCheck);
      }
    }
  }, [authType, email, telephone, country, codeSend, setResendBlocked]);

  useEffect(() => {
    fetchCountries()
      .then((counts) => {
        setCountries(counts);
      })
      .catch((err) => {
        navigate("/error");
      });
  }, [fetchCountries, navigate]);

  useEffect(() => {
    if (referral) localStorage.setItem("referral", referral);
  }, [referral]);

  let resendBlockedDate: null | string = null;

  if (resendBlocked) {
    const date = new Date(resendBlocked);
    resendBlockedDate = `${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
  }

  return (
    <AuthLayout variation>
      {countrySelectBox}
      <div className="auth auth--1">
        <div className="auth__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <h2 className="auth__heading">Create an account</h2>
        <div className="tab-buttons tab-buttons--reg">
          <button
            className={authType === "email" ? "active" : ""}
            onClick={() => setAuthType("email")}
          >
            Email
          </button>
          <button
            className={authType === "phone" ? "active" : ""}
            onClick={() => setAuthType("phone")}
          >
            Phone
          </button>
        </div>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="form-input"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Username</label>
            <div className="form-input-group">
              <input
                type="text"
                placeholder="Enter Username"
                name="username"
                autoComplete="off"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <button>
                {verifyingUsername ? <RingLoader /> : null}
                {typeof usernameExists === "boolean" && !usernameExists ? (
                  <IonIcon icon={checkmark} className="text-success" />
                ) : null}
                {typeof usernameExists === "boolean" && usernameExists ? (
                  <IonIcon icon={close} className="text-warning" />
                ) : null}
              </button>
            </div>
            {typeof usernameExists === "boolean" && usernameExists ? (
              <span className="form-bottom-label text-warning">
                <IonIcon icon={alertCircle} className="active" />
                Username has been used
              </span>
            ) : null}
            {errorVerifyingUsername ? (
              <span className="form-bottom-label text-danger">
                <IonIcon icon={alertCircle} className="active" />
                Error verifying username
              </span>
            ) : null}
          </div>
          {authType === "email" ? (
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="form-input"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          ) : (
            <div className="form-group">
              <label>Phone number</label>
              <div className="input-group">
                <button type="button" onClick={openCountrySelectBox}>
                  {country ? country.contry_code : "Select country"}
                  <IonIcon icon={chevronDownOutline} />
                </button>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={telephone}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setTelephone(+e.target.value)
                        : null
                      : setTelephone("")
                  }
                  // maxLength={10}
                />
              </div>
              <label className="form-label text-center">
                <IonIcon icon={alertCircle} className="text-success" />
                Enter phone number without country code
              </label>
            </div>
          )}
          <div className="form-group">
            <label>Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
            <div className="form-requirements">
              <div className={cls(passwordValidations[0] && "active")}>
                <IonIcon
                  icon={passwordValidations[0] ? checkmarkCircle : alertCircle}
                />
                At least 8 characters
              </div>
              <div className={cls(passwordValidations[1] && "active")}>
                <IonIcon
                  icon={passwordValidations[1] ? checkmarkCircle : alertCircle}
                />
                Lowercase letter (a-z)
              </div>
              <div className={cls(passwordValidations[2] && "active")}>
                <IonIcon
                  icon={passwordValidations[2] ? checkmarkCircle : alertCircle}
                />
                Uppercase letter (A-Z)
              </div>
              <div className={cls(passwordValidations[3] && "active")}>
                <IonIcon
                  icon={passwordValidations[3] ? checkmarkCircle : alertCircle}
                />
                Number (0-9)
              </div>
              <div className={cls(passwordValidations[4] && "active")}>
                <IonIcon
                  icon={passwordValidations[4] ? checkmarkCircle : alertCircle}
                />
                Special character (#,*)
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <div className="form-input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Re-type password"
                name="password"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword((sp) => !sp)}
              >
                <IonIcon
                  icon={showConfirmPassword ? eyeOffOutline : eyeOutline}
                />
              </button>
            </div>
            {password && confirmPassword && password !== confirmPassword ? (
              <label className="form-bottom-label text-warning">
                <IonIcon icon={alertCircle} />
                <p>Passwords do not match</p>
              </label>
            ) : null}
          </div>
          <div className="form-group mt-small">
            {authType === "phone" ? (
              <div className="otp-medium mb-small">
                <h3 className="otp-medium__heading">Receive OTP Via</h3>
                <div className="otp-medium__blocks">
                  <div className="otp-medium__block">
                    <img
                      src={whatsapp}
                      alt=""
                      className="otp-medium__block-img"
                    />
                    <p className="otp-medium__block-name">Whatsapp (Instant)</p>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={smsType === "whatsapp" ? true : false}
                      onChange={(e) =>
                        setSMSType(e.target.checked ? "whatsapp" : "sms")
                      }
                    />
                  </div>
                  <div className="otp-medium__block">
                    <img src={sms} alt="" className="otp-medium__block-img" />
                    <p className="otp-medium__block-name">SMS (0 - 5 MIN)</p>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={smsType === "sms" ? true : false}
                      onChange={(e) =>
                        setSMSType(e.target.checked ? "sms" : "whatsapp")
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <label className="form-label">Verification code</label>
            <div className="input-group input-group--primary">
              <input
                type="password"
                placeholder="Verification code"
                name="password"
                autoComplete="new-password"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              {resendBlocked &&
              resendBlockedFor ===
                (authType === "phone"
                  ? `${country?.contry_code}${telephone.toString()}`
                  : email) ? (
                <button type="button" style={{ opacity: "0.7" }} disabled>
                  Resend in {resendBlockedDate}
                </button>
              ) : (
                <button type="button" onClick={handleFetchRegistrationCode}>
                  Request Code
                </button>
              )}
            </div>
          </div>
          {message}
          <div className="auth__footer auth__footer--1">
            <button
              className={cls(
                "form-button",
                name &&
                  username &&
                  ((authType === "email" &&
                    email &&
                    validator.isEmail(email)) ||
                    (authType === "phone" &&
                      telephone &&
                      validator.isNumeric(telephone.toString()))) &&
                  password &&
                  confirmPassword &&
                  password === confirmPassword &&
                  verificationCode &&
                  "form-button--active"
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Sign Up
            </button>
            <p>
              Already have an account{" "}
              <Link to="/login">Sign in to your account </Link>
            </p>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default NewRegister;
