import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { tRootState } from "../../store";
import {
  triggerAction,
  updateSubAction,
  updateTriggerPage,
} from "../../store/assetSlice";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import useData from "../../hooks/useData/useData";

import crypto from "../../assets/img/crypto.png";
import giftCards from "../../assets/img/gift-cards.png";
import transfer from "../../assets/img/transfer.png";
import virtualCard from "../../assets/img/virtual-card.png";
import airtime from "../../assets/img/airtime.png";
import data from "../../assets/img/data.png";
import electricity from "../../assets/img/electricity.png";
import cableTv from "../../assets/img/cable-tv.png";
import betting from "../../assets/img/betting.png";
import epin from "../../assets/img/epin.png";

const Services = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const availableServices = useSelector(
    (state: tRootState) => state.paybills.availableServices
  );

  const { fetchBillServices } = useData();

  useEffect(() => {
    fetchBillServices();
  }, [fetchBillServices]);

  return (
    <DashboardLayout>
      <section className="services-section-1-1">
        <div className="services-section-1__header">
          <h3 className="services-section-1__heading">Services</h3>
        </div>
        <div className="services-section-1__services">
          <div
            className="service"
            onClick={() => {
              dispatch(triggerAction("crypto"));
              dispatch(updateSubAction("buy"));
              dispatch(updateTriggerPage("/services"));
              navigate("/dashboard-wallet");
            }}
          >
            <img src={crypto} alt="" className="service__img" />
            <p>Buy Crypto</p>
          </div>
          <div
            className="service"
            onClick={() => {
              dispatch(triggerAction("giftcards"));
              dispatch(updateSubAction("sell"));
              dispatch(updateTriggerPage("/services"));
              navigate("/dashboard-wallet");
            }}
          >
            <img src={giftCards} alt="" className="service__img" />
            <p>Sell Gift Card</p>
          </div>
          <div
            className="service"
            onClick={() => {
              dispatch(triggerAction("transfer"));
              dispatch(updateTriggerPage("/services"));
              navigate("/dashboard-wallet");
            }}
          >
            <img src={transfer} alt="" className="service__img" />
            <p>Transfer</p>
          </div>
          <div
            className="service"
            onClick={() => {
              dispatch(triggerAction("crypto"));
              dispatch(updateSubAction("sell"));
              dispatch(updateTriggerPage("/services"));
              navigate("/dashboard-wallet");
            }}
          >
            <img src={crypto} alt="" className="service__img" />
            <p>Sell Crypto</p>
          </div>
          {availableServices.epin ? (
            <div
              className="service"
              onClick={() => navigate("/pay-bills/epin")}
            >
              <img src={epin} alt="" className="service__img" />
              <p>E-pin</p>
            </div>
          ) : null}
          {availableServices.airtime ? (
            <div
              className="service"
              onClick={() => navigate("/pay-bills/airtime")}
            >
              <img src={airtime} alt="" className="service__img" />
              <p>Airtime</p>
            </div>
          ) : null}
          {availableServices.data ? (
            <div
              className="service"
              onClick={() => navigate("/pay-bills/data")}
            >
              <img src={data} alt="" className="service__img" />
              <p>Data</p>
            </div>
          ) : null}
          {availableServices.cableTv ? (
            <div
              className="service"
              onClick={() => navigate("/pay-bills/cableTv")}
            >
              <img src={cableTv} alt="" className="service__img" />
              <p>Cable Tv</p>
            </div>
          ) : null}
          {availableServices.meterToken ? (
            <div
              className="service"
              onClick={() => navigate("/pay-bills/meterToken")}
            >
              <img src={electricity} alt="" className="service__img" />
              <p>Electricity</p>
            </div>
          ) : null}
          <div className="service" onClick={() => navigate("/swap-crypto")}>
            <img src={crypto} alt="" className="service__img" />
            <p>Swap Crypto</p>
          </div>
          {availableServices.betting ? (
            <div
              className="service"
              onClick={() => navigate("/pay-bills/betting")}
            >
              <img src={betting} alt="" className="service__img" />
              <p>Betting</p>
            </div>
          ) : null}
          <div className="service" onClick={() => navigate("/virtual-cards")}>
            <img src={virtualCard} alt="" className="service__img" />
            <p>Virtual Card</p>
          </div>
          <div
            className="service"
            onClick={() => {
              dispatch(triggerAction("giftcards"));
              dispatch(updateSubAction("buy"));
              dispatch(updateTriggerPage("/services"));
              navigate("/dashboard-wallet");
            }}
          >
            <img src={giftCards} alt="" className="service__img" />
            <p>Buy Gift Card</p>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default Services;
