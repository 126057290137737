import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  swapHorizontalOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tTransactions } from "../../store/types/app.types";

import { updateRecentTransactions } from "../../store/userSlice";

import api_client from "../../api/client";

import { MONTHS_SHORT } from "../../data";

import RecentTransactionsLoader from "../../loaders/RecentTransactionsLoader/RecentTransactionsLoader";

import { roundDP } from "../../utils/func";
import { getPrecision } from "../../utils/app";

const filterData: {
  [key: string]: [string, "deposit" | "withdraw" | "trade"];
} = {
  withdrawal: ["Naira Withdraw", "withdraw"],
  deposit: ["Naira Deposit", "deposit"],
  sell_orders: ["Sell Order", "trade"],
  buy_orders: ["Buy Order", "trade"],
  internal_wallet: ["Crypto Transfer", "withdraw"],
  internal_naira: ["Naira Transfer", "withdraw"],
  bills: ["Bill Payment", "withdraw"],
  "Wallet Sent": ["Crypto Withdraw", "withdraw"],
  "Wallet Received": ["Crypto Deposit", "deposit"],
  "Wallet Swap": ["Crypto Swap", "trade"],
  skp_conversion: ["SekiPoints Conversion", "deposit"],
  "skp_conversion Swap": ["SekiPoints Conversion", "deposit"],
  virtual_cards: ["Virtual Cards", "trade"],
};

const RecentTransactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accessToken, recentTransactions } = useSelector(
    (state: tRootState) => state.user
  );

  const [error, setError] = useState(false);

  useEffect(() => {
    api_client({
      url: "/transactions/history/all/all",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const trans: tTransactions = (res.data.message as tTransactions)
            .slice(0, 20)
            .map((trns) => {
              trns.transaction_type = filterData[trns.type][0];
              trns.transaction_group = filterData[trns.type][1];

              if (
                ["internal_wallet", "internal_naira"].includes(trns.type) &&
                +trns.amount > 0
              ) {
                trns.transaction_group = "deposit";
              }

              const [date, time] = trns.date.split(" ");

              const calcDate = new Date(date);

              const totalDate = `${
                MONTHS_SHORT[calcDate.getMonth()]
              }, ${calcDate.getDate()}`;

              trns.date = totalDate;
              trns.time = time;
              trns.year = calcDate.getFullYear();

              return trns;
            });
          dispatch(updateRecentTransactions(trans));
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setError(true);
      })
      .finally(() => {});
  }, [dispatch, accessToken]);

  if (!recentTransactions && error)
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={5} className="text-center">
              Error fetching recent transactions
            </td>
          </tr>
        </tbody>
      </table>
    );

  if (!recentTransactions) return <RecentTransactionsLoader />;

  if (!recentTransactions.length)
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={5} className="text-center">
              You have no recent transaction
            </td>
          </tr>
        </tbody>
      </table>
    );

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Transaction</th>
              <th>Order Hash</th>
              <th>Payment Details</th>
              <th>Amount</th>
              <th className="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {recentTransactions.map((transaction, i) => {
              let clickHandler = () => {};

              if (transaction.type === "withdrawal") {
                clickHandler = () => {
                  navigate(`/withdrawal/${transaction.hash}`);
                };
              } else if (transaction.type === "deposit") {
                clickHandler = () => {
                  navigate(`/deposit/${transaction.hash}`);
                };
              } else if (transaction.type === "internal_wallet") {
                clickHandler = () => {
                  navigate(
                    `/transaction-details/internal_wallets/${transaction.hash}`
                  );
                };
              } else if (transaction.type === "internal_naira") {
                clickHandler = () => {
                  navigate(
                    `/transaction-details/internal_naira/${transaction.id}`
                  );
                };
              } else if (
                transaction.type === "buy_orders" ||
                transaction.type === "sell_orders"
              ) {
                clickHandler = () => {
                  navigate(`/order/${transaction.hash}`);
                };
              } else if (transaction.type === "Wallet Sent") {
                clickHandler = () => {
                  navigate(
                    `/transaction-details/crypto_sent/${transaction.hash}`
                  );
                };
              } else if (transaction.type === "Wallet Received") {
                clickHandler = () => {
                  navigate(
                    `/transaction-details/crypto_received/${transaction.hash}`
                  );
                };
              } else if (transaction.type === "Wallet Swap") {
                clickHandler = () => {
                  navigate(
                    `/transaction-details/crypto_swap/${transaction.hash}`
                  );
                };
              } else if (transaction.type === "bills") {
                clickHandler = () => {
                  navigate(`/transaction-details/bills/${transaction.hash}`);
                };
              } else if (transaction.type === "virtual_cards") {
                clickHandler = () =>
                  navigate(
                    `/transaction-details/virtual_cards/${transaction.id}`
                  );
              }

              return (
                <tr key={i} onClick={clickHandler}>
                  <td>
                    <div
                      className={`transaction-label transaction-label--${transaction.transaction_group}`}
                    >
                      <div className="transaction-label__icon-box">
                        <IonIcon
                          icon={
                            transaction.transaction_group === "deposit"
                              ? arrowDownOutline
                              : transaction.transaction_group === "withdraw"
                              ? arrowUpOutline
                              : swapHorizontalOutline
                          }
                        />
                      </div>
                      <p className="bold">{transaction.transaction_type}</p>
                    </div>
                  </td>
                  <td>{transaction.hash_hidden}</td>
                  <td className="bold">{transaction.content}</td>
                  {transaction.type === "Wallet Swap" ? (
                    <td
                      className={`text-${
                        +transaction.amount > 0 ? "success" : "danger"
                      }`}
                    >
                      {+transaction.amount > 0 ? "+" : "-"} {transaction.from}{" "}
                      {roundDP(
                        Math.abs(+transaction.amount_coin_swap),
                        getPrecision(transaction.from)
                      )}
                      <span className="text-success">
                        {" "}
                        (+ {transaction.to}{" "}
                        {roundDP(
                          Math.abs(+transaction.amount_coin_get),
                          getPrecision(transaction.to)
                        )}
                        )
                      </span>
                    </td>
                  ) : (
                    <td
                      className={`text-${
                        +transaction.amount > 0 ? "success" : "danger"
                      }`}
                    >
                      {+transaction.amount > 0 ? "+" : "-"}{" "}
                      {transaction.currency}{" "}
                      {roundDP(Math.abs(+transaction.amount), 2)}
                    </td>
                  )}
                  <td>
                    <div className="transaction-date">
                      <p>{transaction.date}</p>
                      <p>{transaction.time}</p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="transactions-flex">
        {recentTransactions.map((transaction, i) => {
          let clickHandler = () => {};

          if (transaction.type === "withdrawal") {
            clickHandler = () => {
              navigate(`/withdrawal/${transaction.hash}`);
            };
          } else if (transaction.type === "deposit") {
            clickHandler = () => {
              navigate(`/deposit/${transaction.hash}`);
            };
          } else if (transaction.type === "internal_wallet") {
            clickHandler = () => {
              navigate(
                `/transaction-details/internal_wallets/${transaction.hash}`
              );
            };
          } else if (transaction.type === "internal_naira") {
            clickHandler = () => {
              navigate(`/transaction-details/internal_naira/${transaction.id}`);
            };
          } else if (
            transaction.type === "buy_orders" ||
            transaction.type === "sell_orders"
          ) {
            clickHandler = () => {
              navigate(`/order/${transaction.hash}`);
            };
          } else if (transaction.type === "Wallet Sent") {
            clickHandler = () => {
              navigate(`/transaction-details/crypto_sent/${transaction.hash}`);
            };
          } else if (transaction.type === "Wallet Received") {
            clickHandler = () => {
              navigate(
                `/transaction-details/crypto_received/${transaction.hash}`
              );
            };
          } else if (transaction.type === "Wallet Swap") {
            clickHandler = () => {
              navigate(`/transaction-details/crypto_swap/${transaction.hash}`);
            };
          } else if (transaction.type === "bills") {
            clickHandler = () => {
              navigate(`/transaction-details/bills/${transaction.hash}`);
            };
          } else if (transaction.type === "virtual_cards") {
            clickHandler = () =>
              navigate(`/transaction-details/virtual_cards/${transaction.id}`);
          }

          return (
            <div className="transaction-mobile" key={i} onClick={clickHandler}>
              <div
                className={`transaction-label transaction-label--${transaction.transaction_group}`}
              >
                <div className="transaction-label__icon-box">
                  <IonIcon
                    icon={
                      transaction.transaction_group === "deposit"
                        ? arrowDownOutline
                        : transaction.transaction_group === "withdraw"
                        ? arrowUpOutline
                        : swapHorizontalOutline
                    }
                  />
                </div>
              </div>
              <div className="transaction-mobile__main">
                <p className="transaction-mobile__type">
                  {transaction.transaction_type}
                </p>
                <p className="transaction-mobile__text">
                  {transaction.content}
                </p>
                <p className="transaction-mobile__hash">
                  #{transaction.hash_hidden}
                </p>
              </div>
              <div className="transaction-mobile__right">
                <p
                  className={`transaction-mobile__amount text-${
                    +transaction.amount > 0 ? "success" : "danger"
                  }`}
                >
                  {+transaction.amount > 0 ? "+" : "-"} {transaction.currency}{" "}
                  {roundDP(Math.abs(+transaction.amount), 2)}
                  {transaction.type === "Wallet Swap" ? (
                    <span className="text-success">
                      {" "}
                      (+ {roundDP(Math.abs(+transaction.amount_get), 2)})
                    </span>
                  ) : null}
                </p>
                <p className="transaction-mobile__date">
                  {transaction.date} {transaction.time}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RecentTransactions;
