import { Link } from "react-router-dom";

import coinBag from "../../assets/img/coin-bag.png";
import coin from "../../assets/img/coin.png";

import wcu1 from "../../assets/img/wcu-1.png";
import wcu2 from "../../assets/img/wcu-2.png";
import wcu3 from "../../assets/img/wcu-3.png";
import wcu4 from "../../assets/img/wcu-4.png";
import wcu5 from "../../assets/img/wcu-5.png";

const WCASection = () => {
  return (
    <section className="cta-section">
      <div className="container cta-section__container">
        <div className="cta">
          <div className="cta__main">
            <img src={coinBag} alt="" className="cta__main-img" />
            <div className="cta__grid">
              <div>
                <h3 className="cta__heading">Why Choose Us</h3>
                <p className="cta__text">
                  Get ready to experience financial freedom with SEKIAPP - the
                  go-to exchange crypto company for all your digital asset
                  needs. Let us show you why we're the best choice for you:
                </p>
                <Link to="/login" className="cta__link">
                  Start Trading Now
                </Link>
              </div>
              <div>
                <div className="cta__info">
                  <div className="cta__info-icon-block">
                    <img src={wcu1} alt="" />
                  </div>
                  <div className="cta__info-main">
                    <h3 className="cta__sub-heading">Free like a bird</h3>
                    <p className="cta__text">
                      We believe you deserve to have control over your money.
                      That's why we're giving you a platform to buy, sell and
                      store digital assets without restrictions. It's all yours,
                      baby!
                    </p>
                  </div>
                </div>
                <div className="cta__info">
                  <div className="cta__info-icon-block">
                    <img src={wcu3} alt="" />
                  </div>
                  <div className="cta__info-main">
                    <h3 className="cta__sub-heading">
                      Customer Support on Speed Dial
                    </h3>
                    <p className="cta__text">
                      We're here for you 24/7, ready to answer any questions or
                      help you with any issues. Our customer support team is
                      always on standby to ensure you have the best experience
                      with us.
                    </p>
                  </div>
                </div>
                <div className="cta__info">
                  <div className="cta__info-icon-block">
                    <img src={wcu5} alt="" />
                  </div>
                  <div className="cta__info-main">
                    <h3 className="cta__sub-heading">Blazing Fast</h3>
                    <p className="cta__text">
                      Time is money, and we don't want to waste a single second
                      of yours. That's why our platform is super fast and
                      efficient. Transactions are completed in just a few
                      clicks, so you can keep your money moving.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="cta__info">
                  <div className="cta__info-icon-block">
                    <img src={wcu2} alt="" />
                  </div>
                  <div className="cta__info-main">
                    <h3 className="cta__sub-heading">Easy Peasy</h3>
                    <p className="cta__text">
                      No need to be tech-savvy; our platform is easy to
                      navigate, even for newbies. With a few clicks, you can
                      easily buy, sell and store your assets.
                    </p>
                  </div>
                </div>
                <div className="cta__info">
                  <div className="cta__info-icon-block">
                    <img src={wcu4} alt="" />
                  </div>
                  <div className="cta__info-main">
                    <h3 className="cta__sub-heading">Secured like AsoRock</h3>
                    <p className="cta__text">
                      We take security seriously, so you can be rest assured
                      that your assets are safe with us. Our platform is built
                      on the latest blockchain technology, which ensures that
                      your digital assets are secured at all times.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cta__footer-1">
              <p>
                Last last, SEKIAPP is the only perfect choice for anyone looking
                to buy, sell and store digital assets in Nigeria. With our
                commitment to freedom, speed, security, customer support, and
                ease of use, you're guaranteed a fantastic experience.
              </p>
              <p>
                Odogwu, come{" "}
                <Link to="/login" className="cta__link-2">
                  join us today
                </Link>{" "}
                and be the boss of your assets!
              </p>
            </div>
          </div>
          <div className="cta__footer">
            <img src={coin} alt="" className="cta__footer-img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WCASection;
