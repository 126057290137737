import { useState, useEffect } from "react";

import { IonIcon } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tCryptoRates, tGiftCardRates } from "../../store/types/app.types";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import Preloader from "../../components/Preloader/Preloader";

const Rates = () => {
  const [cryptoRates, setCryptoRates] = useState<null | tCryptoRates>(null);
  const [giftcardRates, setGiftcardRates] = useState<null | tGiftCardRates>(
    null
  );

  const [activeCryptoRate, setActiveCryptoRate] = useState<number | null>(null);
  const [activeGiftCardRate, setActiveGiftCardRate] = useState<number | null>(
    null
  );

  useEffect(() => {
    api_client({
      url: "/get-rate",
    })
      .then((res) => {
        if (res.data.success) {
          setCryptoRates(res.data.rate[0].cryptos);
          setGiftcardRates(res.data.rate.slice(1));
        } else {
          // handle error
        }
      })
      .catch((err) => {
        // handle error
      })
      .finally(() => {
        // find use
      });
  }, []);

  if (!cryptoRates || !giftcardRates) return <Preloader />;

  return (
    <HomeLayout>
      <div className="section">
        <div className="section__header">
          <div className="section__heading">Rates</div>
        </div>
        <div className="section__main rates">
          <div className="rates__block">
            <h3 className="rates__heading">Crypto Currencies</h3>
            <div
              className={`accordion ${
                activeCryptoRate === 0 ? "accordion--open" : ""
              }`}
            >
              <div
                className="accordion__header"
                onClick={() => {
                  setActiveCryptoRate((ind) => (ind === 0 ? null : 0));
                  setActiveGiftCardRate(null);
                }}
              >
                <p>Rates</p>
                <span className="accordion__toggle">
                  <IonIcon icon={addOutline} />
                  <IonIcon icon={removeOutline} />
                </span>
              </div>
              <div className="accordion__body accordion__body--no-padding">
                <div className="table-responsive table-responsive--no-hide">
                  <table className="rate-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Sell Rate</th>
                        <th>Buy Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cryptoRates.map((rate) => (
                        <tr key={rate.id}>
                          <td>
                            <div className="rates__coin">
                              <img src={rate.image} alt="" />
                              <p>{rate.name}</p>
                            </div>
                          </td>
                          <td>&#8358;{rate.sell_rate}</td>
                          <td>&#8358;{rate.buy_rate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="rates__block">
            <h3 className="rates__heading">Gift Cards</h3>
            <div className="rates__rates">
              {giftcardRates.map((rate, i) => (
                <div
                  className={`accordion ${
                    activeGiftCardRate === i ? "accordion--open" : ""
                  }`}
                  key={rate.id}
                >
                  <div
                    className="accordion__header"
                    onClick={() => {
                      setActiveGiftCardRate((ind) => (ind === i ? null : i));
                      setActiveCryptoRate(null);
                    }}
                  >
                    <img
                      src={rate.image}
                      alt=""
                      className="accordion__header-img"
                    />
                    <p>{rate.name}</p>
                    <span className="accordion__toggle">
                      <IonIcon icon={addOutline} />
                      <IonIcon icon={removeOutline} />
                    </span>
                  </div>
                  <div className="accordion__body accordion__body--no-padding">
                    <div
                      className="table-responsive"
                      style={{ display: "block" }}
                    >
                      <table className="rate-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Denomination</th>
                            <th>Sell Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rate.sub.map((denoms) => (
                            <tr key={denoms.id}>
                              <td>{denoms.name}</td>
                              <td>{denoms.denomination}</td>
                              <td>&#8358;{denoms.sell_rate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Rates;
