import { tUser } from "../store/types/app.types";

import avatarMan from "../assets/img/avatar-man.png";
import avatarWoman from "../assets/img/avatar-woman.png";
import avatarNeutral from "../assets/img/avatar-neutral.png";

const registration_steps = [
  "",
  "/register",
  "/register/verify-email",
  "/register/telephone",
  "/register/verify-telephone",
  "/register/personal-details",
  "/register/date-of-birth",
  "/register/transaction-pin",
  "/register/referral-code",
];

export const getRegistrationStep = (user: tUser | null): [number, string] => {
  if (!user) return [1, registration_steps[1]];

  if (!user.email_verified_at) return [2, registration_steps[2]];
  if (!user.phone) return [3, registration_steps[3]];
  if (!user.sms_verified_at) return [4, registration_steps[4]];
  if (!user.names && !user?.username) return [5, registration_steps[5]];
  if (!user.date_of_birth) return [6, registration_steps[6]];
  if (!user.pin) return [7, registration_steps[7]];
  if (user.pin) return [8, registration_steps[8]];

  // Unusable value
  return [0, ""];
};

export const getProfilePicture = (gender: string) => {
  if (gender === "Male") return avatarMan;
  if (gender === "Female") return avatarWoman;
  return avatarNeutral;
};
