import { CSSProperties, useEffect, useState } from "react";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";

import { tVirtualCard } from "../../store/types/app.types";

import { roundDP } from "../../utils/func";

import sekilogoCard from "../../assets/img/sekilogo-card.png";
import mastercard from "../../assets/img/mastercard-logo.png";
import visa from "../../assets/img/visa.png";
import frozenCard from "../../assets/img/frozen-card.png";

const VirtualCard = ({
  card,
  style,
  showBalance,
  clickHandler,
  showLoader = false,
}: {
  card: tVirtualCard;
  style?: CSSProperties;
  showBalance?: boolean;
  clickHandler?: () => void;
  showLoader?: boolean;
}) => {
  const [show, setShow] = useState(
    typeof showBalance === "undefined" ? true : showBalance
  );

  useEffect(() => {
    if (typeof showBalance === "undefined") return;

    setShow(showBalance);
  }, [showBalance]);

  const useStyle = style ? style : {};

  if (clickHandler) useStyle.cursor = "pointer";

  return (
    <div
      className={cls(
        "vcard",
        card.status === "BLOCKED" && "blocked",
        card.status === "FROZEN" && "frozen"
      )}
      style={useStyle}
      onClick={clickHandler ? () => clickHandler() : () => null}
    >
      {card.status === "PENDING" && showLoader ? (
        <div className="loader vcard__loader"></div>
      ) : null}
      <img src={card.card_theme} alt="" className="vcard__bg" />
      {card.status === "FROZEN" && (
        <img src={frozenCard} alt="" className="vcard__bg" />
      )}
      <div className="vcard__main">
        <div className="vcard__header">
          <div className="vcard__top-left">
            <p
              className={cls(
                "vcard__status",
                card.status === "ACTIVE" && "vcard__status--active",
                card.status === "FROZEN" && "vcard__status--frozen",
                card.status === "PENDING" && "vcard__status--pending",
                card.status === "BLOCKED" && "vcard__status--blocked"
              )}
            >
              {card.status}
            </p>
            <p>{card.alias.toUpperCase()}</p>
          </div>
          <img src={sekilogoCard} alt="" className="vcard__app-logo" />
        </div>
        <div className="vcard__balance-block">
          {typeof showBalance === "undefined" ? (
            <div className="vcard__balance-toggle">
              <span onClick={() => setShow((sh) => !sh)}>
                <IonIcon icon={show ? eyeOff : eye} />
              </span>
              Balance
            </div>
          ) : null}
          <div className={cls("vcard__balance", show && "vcard__balance--1")}>
            ${" "}
            <span className={cls(!show && "hide")}>
              {show ? roundDP(card.balance, 2) : "*****"}
            </span>
          </div>
        </div>
        <div className="vcard__footer">
          <div className="vcard__card-info">
            {card.status !== "BLOCKED" && (
              <>
                <p>
                  Valid Till{" "}
                  {show ? <span>{card.expiry}</span> : <span>**/**</span>}
                </p>
                {show ? <p>{card.number}</p> : <p>************</p>}
              </>
            )}
          </div>
          <img
            src={card.brand === "MASTERCARD" ? mastercard : visa}
            alt=""
            className="vcard__card-logo"
          />
        </div>
      </div>
    </div>
  );
};

export default VirtualCard;
