import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  arrowForwardOutline,
  chevronDown,
  phonePortrait,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tBillsTransactions } from "../../store/types/app.types";

import api_client from "../../api/client";

import { Entries } from "../../utils/utils.types";

import withAuth from "../../hoc/withAuth/withAuth";

import PageLayout from "../../layouts/PageLayout/PageLayout";

import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";
import useData from "../../hooks/useData/useData";

import PayBillsLoader from "../../loaders/PayBillsLoader/PayBillsLoader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

import { getDate, roundDP } from "../../utils/func";

import tag from "../../assets/img/tag.png";
import pbiImg from "../../assets/img/pbi-img.png";

const filterData = {
  all: "All",
  airtime: "Airtime",
  data: "Data Bundles",
  cableTv: "Cable TV",
  meterToken: "Electricity",
};

const getBadge = (status: string): string => {
  if (status === "Processing") return "warning";
  if (["Refunded", "Refund"].includes(status)) return "info";

  return "success";
};

const PayBillsTransactions = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [url, setUrl] = useState(`/bills/all`);

  const [filterSelectBox, filter, openFilterSelectBox, , setFilter] =
    useSelectBox<keyof typeof filterData>(
      "Filter Transaction",
      getSelectBoxData(filterData),
      "all"
    );
  const [bills, setBills] = useState<tBillsTransactions>([]);

  const [links, setLinks] = useState<
    {
      url: string | null;
      label: string;
      active: boolean;
    }[]
  >([]);
  const [edgeLinks, setEdgeLinks] = useState<{
    first_page_url: string;
    last_page_url: string;
  }>({ first_page_url: "", last_page_url: "" });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setUrl(`/bills/${filter}`);
  }, [filter]);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(false);

    api_client({
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setBills(res.data.message.data);
          setLinks(res.data.message.links);
          setEdgeLinks({
            first_page_url: res.data.message.first_page_url,
            last_page_url: res.data.message.last_page_url,
          });
        } else {
          setBills([]);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, accessToken]);

  return (
    <>
      {filterSelectBox}
      <div className="pay-bills__transactions">
        <div className="pay-bills__transactions-header">
          <h3 className="wallet-transactions__heading text-center">
            Transaction History
          </h3>
          <div className="tab-buttons tab-buttons--sm tab-buttons--auto tab-buttons--bills">
            {(Object.entries(filterData) as Entries<typeof filterData>).map(
              ([key, value]) => (
                <button
                  className={cls(filter === key && "active")}
                  onClick={() => setFilter(key)}
                  key={key}
                >
                  {value}
                </button>
              )
            )}
          </div>
          <div className="transactions-pg__filter pay-bills__filter">
            Filter:
            <div className="select-box" onClick={() => openFilterSelectBox()}>
              {filterData[filter!]} <IonIcon icon={chevronDown} />
            </div>
          </div>
        </div>
        <div className="table-responsive table-responsive--no-hide">
          <table className="table">
            <thead>
              <tr>
                <th>Transaction</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {loading ? <PayBillsLoader /> : null}
              {error ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    Error fetching bills transactions
                  </td>
                </tr>
              ) : null}
              {!loading && !error && !bills.length ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    You have no bills transactions
                  </td>
                </tr>
              ) : null}
              {!loading && !error
                ? bills.map((bill) => (
                    <tr
                      key={bill.id}
                      onClick={() =>
                        navigate(
                          `/transaction-details/bills/${bill.aidapay_hash}`
                        )
                      }
                    >
                      <td>
                        <div className="transaction-label transaction-label--deposit">
                          <div className="transaction-label__icon-box">
                            <IonIcon icon={phonePortrait} />
                          </div>
                          <p className="bold">{bill.service_type}</p>
                        </div>
                      </td>
                      <td>&#8358;{roundDP(bill.amount, 2)}</td>
                      <td>
                        <span
                          className={`badge badge--${getBadge(bill.status)}`}
                        >
                          {bill.status}
                        </span>
                      </td>
                      <td>{getDate(bill.created_at)}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
        {!loading && !error && bills.length ? (
          <div className="pagination">
            {edgeLinks.first_page_url ? (
              <button onClick={() => setUrl(edgeLinks.first_page_url)}>
                &#171;
              </button>
            ) : null}
            {links.map((link, i) => {
              return isNaN(+link.label) || link.active ? (
                <button
                  className={link.active ? "active" : ""}
                  onClick={() => setUrl(link.url || "")}
                  key={link.label}
                >
                  {i === 0 ? (
                    <>&#8249;</>
                  ) : i === links.length - 1 ? (
                    <>&#8250;</>
                  ) : (
                    link.label
                  )}
                </button>
              ) : null;
            })}
            {edgeLinks.last_page_url ? (
              <button onClick={() => setUrl(edgeLinks.last_page_url)}>
                &#187;
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

const PayBillsNew = () => {
  const navigate = useNavigate();

  const billServices = useSelector(
    (state: tRootState) => state.paybills.services
  );

  const { fetchBillServices } = useData();

  const [error, setError] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    setError(false);

    fetchBillServices()
      .then((res) => {
        // do nothing;
      })
      .catch((err) => {
        if (!billServices?.length) setError(true);
      });
  }, [fetchBillServices, billServices?.length, reload]);

  return (
    <PageLayout>
      <div className="pay-bills__header">
        <h3 className="pay-bills__heading">Pay Bills</h3>
        <p className="pay-bills__description">
          No more stress over bill payment! SEKIAPP got you covered. With just a
          tap, take care of all your bills in a jiffy. Chillax and let SEKIAPP
          do the boring part for you. More time for the fun things in life!
        </p>
      </div>
      {billServices ? (
        <div className="pay-bills__services">
          {billServices.map((service) => (
            <div
              className="bill-service"
              onClick={() => navigate(`/pay-bills/${service.service_type}`)}
              key={service.id}
            >
              <img src={service.img_url} alt="" className="bill-service__bg" />
              <h3 className="bill-service__heading">{service.service_name}</h3>
              <p className="bill-service__text">{service.description}</p>
            </div>
          ))}
        </div>
      ) : error ? (
        <div>
          Error fetching services.{" "}
          <span className="link" onClick={() => setReload((rl) => !rl)}>
            Try again
          </span>
        </div>
      ) : (
        <VerticalBarLoader />
      )}

      <PayBillsTransactions />
      <div className="pay-bills__infos">
        <div className="pay-bills__info pay-bills__info--1">
          <div className="pay-bills__info-main">
            <h3 className="pay-bills__info-heading">
              Refer a Friend get Awarded
            </h3>
            <p className="pay-bills__info-text">
              Get Discounted Price for your billing at every referral on
              SekiApp.
            </p>
            <Link to="/account/refer" className="pay-bills__info-link">
              <span>Grab Offer Now</span>
              <IonIcon icon={arrowForwardOutline} />
            </Link>
          </div>
          <img src={tag} alt="" />
        </div>
        <div className="pay-bills__info pay-bills__info--2">
          <div className="pay-bills__info-main">
            <h3 className="pay-bills__info-heading">Special Offer for you</h3>
            <p className="pay-bills__info-text">
              You can now purchase airtime at 10% discount rate (MTN & Airtel
              only).
            </p>
            <Link to="/pay-bills/airtime" className="pay-bills__info-link">
              <span>Grab Offer Now</span>
              <IonIcon icon={arrowForwardOutline} />
            </Link>
          </div>
          <img src={pbiImg} alt="" />
        </div>
      </div>
    </PageLayout>
  );
};

export default withAuth(PayBillsNew);
