import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import {
  tCryptoDepositTransaction,
  tCryptoWithdrawTransaction,
} from "../../../store/types/app.types";

import { copyData, getDate, roundDP } from "../../../utils/func";
import { getPrecision } from "../../../utils/app";

const CryptoDepositWithdrawTransaction = ({
  transaction,
}: {
  transaction: tCryptoDepositTransaction | tCryptoWithdrawTransaction;
}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  return (
    <div className="transaction-details">
      <h3 className="transaction-details__heading">
        <span onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        Crypto {transaction.__type === "crypto_sent" ? "Withdraw" : "Deposit"}
      </h3>
      <p className="transaction-details__heading-subtext">
        Crypto {transaction.__type === "crypto_sent" ? "Withdraw" : "Deposit"}{" "}
        Transaction Details
      </p>
      <div className="transaction-details__infos">
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount</p>
          <div className="transaction-details__info-right">
            {roundDP(transaction.amount, getPrecision(transaction.coin))}{" "}
            {transaction.coin.toUpperCase()} ($
            {roundDP(transaction.amount_dollar, 2)})
          </div>
        </div>
        {transaction.__type === "crypto_sent" ? (
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Fee</p>
            <div className="transaction-details__info-right">
              {roundDP(transaction.fees, getPrecision(transaction.coin))}{" "}
              {transaction.coin.toUpperCase()} ($
              {roundDP(transaction.fees_dollar, 2)})
            </div>
          </div>
        ) : null}
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">To address</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.to_address}
            </p>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Hash</p>
          <div className="transaction-details__info-right">
            {transaction.url !== "#" ? (
              <a
                href={transaction.url}
                target="_blank"
                rel="noreferrer"
                className="transaction-details__info-text text-uppercase link-primary"
              >
                {transaction.hash}
              </a>
            ) : (
              <p className="transaction-details__info-text text-uppercase">
                {transaction.hash}
              </p>
            )}
            <div
              className="copy"
              onClick={() => copyData(transaction.hash, setCopied)}
            >
              <IonIcon icon={copy} />
              {copied ? "Copied!" : "Copy"}
            </div>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Status</p>
          <div className="transaction-details__info-right">
            {+transaction.status === 1 || +transaction.status === 3 ? (
              <span className="badge badge--warning">Pending</span>
            ) : +transaction.status === 5 ? (
              <span className="badge badge--success">Confirmed</span>
            ) : null}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Date</p>
          <div className="transaction-details__info-right">
            {getDate(transaction.created_at)}
          </div>
        </div>
        {transaction.url !== "#" ? (
          <a
            href={transaction.url}
            target="_blank"
            rel="noreferrer"
            className="form-button form-button--active"
          >
            VIEW ON EXPLORER
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default CryptoDepositWithdrawTransaction;
