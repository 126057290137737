const GiftCardTransactionsLoader = () => {
  return (
    <>
      {new Array(10).fill(null).map((_, i) => (
        <div className="giftcard-transactions__transaction" key={i}>
          <div className="giftcard-transactions__transaction-img placeholder"></div>
          <div className="giftcard-transactions__transaction-main">
            <p className="giftcard-transactions__transaction-info">
              <span className="color-transparent placeholder">
                Amazon USA E-Code
              </span>
            </p>
            <p className="giftcard-transactions__transaction-hash">
              <span className="color-transparent placeholder">
                #JJAJKAs****AJSJAKS
              </span>
            </p>
          </div>
          <p className="giftcard-transactions__transaction-amount">
            <span className="color-transparent placeholder">$100.00</span>
          </p>
        </div>
      ))}
    </>
  );
};

export default GiftCardTransactionsLoader;
