import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack, searchOutline } from "ionicons/icons";

import { tRootState } from "../../store";

import { selectNetwork, selectWallet } from "../../store/cacheSlice";

import useData from "../../hooks/useData/useData";

import withAuth from "../../hoc/withAuth/withAuth";

import { assertNotNull, roundDP } from "../../utils/func";

import DashboardFooter from "../../components/DashboardFooter/DashboardFooter";
import DashbordHeader from "../../components/DashboardHeader/DashboardHeader";
import NewsSection from "../../components/NewsSection/NewsSection";

import CryptoWallet from "../../components/CryptoWallet/CryptoWallet";
import NairaWallet from "../../components/NairaWallet/NairaWallet";
import WalletBulk from "../../components/WalletBulk/WalletBulk";

import WalletPageLoader from "../../loaders/WalletPageLoader/WalletPageLoader";

import ModeToggle from "../../components/ModeToggle/ModeToggle";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import { getPrecision } from "../../utils/app";

const Wallet = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const wallets = useSelector((state: tRootState) => state.cache.wallets);
  const walletId = useSelector(
    (state: tRootState) => state.cache.wallet.walletId
  );

  assertNotNull(userDetails);

  const { fetchWallets, fetchWarnings } = useData();

  const [hideSidebar, setHideSidebar] = useState(false);

  const mainContentRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<"all" | "fiat" | "crypto">("all");

  const wallet = wallets?.find((wallet) => wallet.id === (walletId || "NGN"));

  useEffect(() => {
    fetchWallets();
    fetchWarnings();
  }, [fetchWallets, fetchWarnings]);

  return (
    <>
      <ModeToggle />
      <DashbordHeader />
      <main className="wallet">
        <aside
          className={cls(
            "wallet__sidebar",
            hideSidebar && "wallet__sidebar--hidden"
          )}
        >
          <div className="wallet__sidebar-header">
            <WalletBulk sm sidebar />
            <form className="search-form">
              <div className="search-form__icon-block">
                <IonIcon icon={searchOutline} />
              </div>
              <input
                type="text"
                placeholder="Search Coin"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
            <div className="tab-buttons tab-buttons--sm">
              <button
                className={cls(filter === "all" && "active")}
                onClick={() => setFilter("all")}
              >
                All
              </button>
              <button
                className={cls(filter === "crypto" && "active")}
                onClick={() => setFilter("crypto")}
              >
                Crypto
              </button>
              <button
                className={cls(filter === "fiat" && "active")}
                onClick={() => setFilter("fiat")}
              >
                Fiat
              </button>
            </div>
            <div className="wallet__sidebar-total">
              <p>Total Balance</p>
              <p>
                Approx. USD{" "}
                {roundDP(
                  filter === "all"
                    ? userDetails.total_balance_in_dollar
                    : filter === "fiat"
                    ? userDetails.total_naira_balance_in_dollar
                    : userDetails.total_coin_balance_in_dollar,
                  2
                )}
              </p>
            </div>
          </div>
          <div className="wallet__sidebar-main currencies">
            {wallets ? (
              wallets
                .filter((wallet) => {
                  return (
                    (new RegExp(search, "i").test(wallet.coin) ||
                      new RegExp(search, "i").test(wallet.symbol)) &&
                    (filter === "all" || wallet.type === filter)
                  );
                })
                .map((sWallet) => {
                  return (
                    <div
                      className={cls(
                        "currency",
                        sWallet.id === wallet?.id && "currency--active"
                      )}
                      key={sWallet.id}
                      onClick={() => {
                        if (sWallet.id !== wallet?.id) {
                          dispatch(selectWallet(sWallet.id));
                          dispatch(selectNetwork(null));
                        }
                        mainContentRef.current?.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        setHideSidebar(true);
                      }}
                    >
                      <div className="currency__img-block">
                        <img src={sWallet.image} alt="" />
                      </div>
                      <div className="currency__main">
                        <p className="currency__name">{sWallet.coin}</p>
                        <div className="currency__price">
                          <p className="currency__price-main">
                            {roundDP(
                              sWallet.balance,
                              getPrecision(sWallet.symbol)
                            )}{" "}
                            {sWallet.symbol}
                          </p>
                          <p>
                            Approx. USD {roundDP(sWallet.dollar_balance, 2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <WalletPageLoader />
            )}
          </div>
        </aside>
        {wallet ? (
          <section className="wallet__main" ref={mainContentRef}>
            <div className="wallet__main-content">
              <div
                className="wallet__show-all"
                onClick={() => setHideSidebar(false)}
              >
                <IonIcon icon={arrowBack} /> See all wallets
              </div>
              {wallet.type === "crypto" ? (
                <CryptoWallet wallet={wallet} />
              ) : (
                <NairaWallet wallet={wallet} />
              )}
              <NewsSection noPadding={true} />
            </div>
            <DashboardFooter />
          </section>
        ) : (
          <section className="wallet__main flex-center">
            <VerticalBarLoader />
          </section>
        )}
      </main>
    </>
  );
};

export default withAuth(Wallet);
