import { useEffect } from "react";
import { useSelector } from "react-redux";

import { tRootState } from "../../store";
import { tDashboardBanners } from "../../store/types/app.types";

import useData from "../../hooks/useData/useData";

import DashboardBanner from "./DashboardBanner/DashboardBanner";

const DashboardBanners = () => {
  const dashboardBanners = useSelector(
    (state: tRootState) => state.cache.dashboardBanners
  );

  const { fetchDashboardBanners } = useData();

  useEffect(() => {
    fetchDashboardBanners();
  }, [fetchDashboardBanners]);

  if (!dashboardBanners || !dashboardBanners.length) return null;

  const windowWidth = window.innerWidth;

  let banners1: tDashboardBanners = [];
  let banners2: tDashboardBanners = [];

  if (windowWidth > 950) {
    banners1 = dashboardBanners.slice(
      0,
      Math.round(dashboardBanners.length / 2)
    );
    banners2 = dashboardBanners.slice(Math.round(dashboardBanners.length / 2));
  } else {
    banners1 = dashboardBanners;
  }

  return (
    <div className="dashboard-banners">
      <DashboardBanner banners={banners1} />
      <DashboardBanner banners={banners2} />
    </div>
  );
};

export default DashboardBanners;
