import { IonIcon } from "@ionic/react";
import { alertCircle } from "ionicons/icons";

const DepositWarningModal2 = ({
  show,
  closeHandler,
}: {
  show: boolean;
  closeHandler: () => void;
}) => {
  if (!show) return null;

  return (
    <>
      <div className="deposit-naira-modal">
        <div className="deposit-naira-modal__heading">
          <IonIcon icon={alertCircle} />
          IMPORTANT NOTICE
        </div>
        <p>
          SekiApp reserves the right to hold and refund money back if we feel
          the transaction or nature you run your account is not safe for our
          business community, we may further proceed to close such an account.
        </p>
        <p>
          We also do not tolerate DEPOSITING MONEY and REQUESTING TO WITHDRAW
          same value almost immediately, you will loose access to your
          account!!!
        </p>
        <div className="text-right">
          <button className="button" onClick={closeHandler}>
            I agree
          </button>
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default DepositWarningModal2;
