import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import {
  selectNetwork,
  selectWallet,
  updateDashboardPopup,
} from "../../store/cacheSlice";

const DashboardPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashboardPopup = useSelector(
    (state: tRootState) => state.cache.dashboardPopup
  );

  if (!dashboardPopup) return null;

  const popupImg = (
    <img src={dashboardPopup.image} alt="" className="dashboard-popup__img" />
  );

  return (
    <>
      <div className="dashboard-popup">
        <span
          className="dashboard-popup__close"
          onClick={() => dispatch(updateDashboardPopup(null))}
        >
          <IonIcon icon={closeOutline} />
        </span>
        <div
          onClick={() => {
            if (dashboardPopup.route === "link") {
              const linkElement = document.createElement("a");

              linkElement.href = dashboardPopup.url;
              linkElement.target = "_blank";

              linkElement.style.display = "none";

              linkElement.click();

              dispatch(updateDashboardPopup(null));
            } else {
              if (dashboardPopup.page === "wallet_detail") {
                dispatch(selectWallet(+dashboardPopup.coin_id));
                dispatch(selectNetwork(null));

                navigate("/wallet");

                dispatch(updateDashboardPopup(null));

                return;
              }

              navigate(dashboardPopup.page);

              dispatch(updateDashboardPopup(null));
            }
          }}
        >
          {popupImg}
        </div>
        {/* <img
          src={popup1Mobile}
          alt=""
          className="dashboard-popup__img mobile"
        /> */}
      </div>
      <div
        className="overlay"
        onClick={() => dispatch(updateDashboardPopup(null))}
      ></div>
    </>
  );
};

export default DashboardPopup;
