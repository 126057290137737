import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronDown } from "ionicons/icons";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";

import { Entries } from "../../../utils/utils.types";

import TransactionsLoader from "../../../loaders/TransactionsLoader/TransactionsLoader";

import { tTransactions, tWallet } from "../../../store/types/app.types";

import { DAYS } from "../../../data";
import useSelectBox, {
  getSelectBoxData,
} from "../../../hooks/useSelectBox/useSelectBox";
import { roundDP } from "../../../utils/func";
import { getPrecision } from "../../../utils/app";

type tDateTransactions = {
  [date: string]: tTransactions;
};

const filterData = {
  all: "All",
  pending: "Pending",
  orders: "Orders",
  swaps: "Swaps",
  sent_wallets: "Withdrawals",
  received_wallets: "Deposits",
  internal_wallets: "Transfers",
};

const getBadge = (status: string): string => {
  if (status === "Refunded") return "info";
  if (["Unconfirmed"].includes(status)) return "warning";

  return "success";
};

const CryptoTransactions = ({ wallet }: { wallet: tWallet }) => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [filterSelectBox, filter, openFilterSelectBox, , setFilter] =
    useSelectBox<keyof typeof filterData>(
      "Filter Transaction",
      getSelectBoxData(filterData),
      "all"
    );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [dateTransactions, setDateTransactions] = useState<tDateTransactions>(
    {}
  );

  useEffect(() => {
    setError(false);
    setLoading(true);

    api_client({
      method: "GET",
      url:
        filter === "pending"
          ? `/pending-crypto-transactions/${wallet.symbol}`
          : `/transaction-history/coin/${wallet.symbol}/${filter}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        const formattedTransactions: tDateTransactions = {};

        (res.data.message as tTransactions).map((transaction) => {
          const [date, time] = transaction.date.split(" ");

          const calcDate = new Date(date);

          const totalDate = `${date}, ${DAYS[calcDate.getDay()].toUpperCase()}`;

          transaction.date = totalDate;
          transaction.time = time.slice(0, 5);

          formattedTransactions[totalDate] =
            formattedTransactions[totalDate] || [];
          formattedTransactions[totalDate].push(transaction);

          return transaction;
        });

        setDateTransactions(formattedTransactions);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, filter, wallet.symbol]);

  return (
    <>
      {filterSelectBox}
      <div className="wallet-transactions">
        <div className="wallet-transactions__header">
          <div className="wallet-transactions__header-main">
            <h3 className="wallet-transactions__heading">
              Transaction history
            </h3>
            <div className="tab-buttons tab-buttons--sm tab-buttons--auto tab-buttons--wallet">
              {(Object.entries(filterData) as Entries<typeof filterData>).map(
                ([key, value]) => (
                  <button
                    className={cls(filter === key && "active")}
                    onClick={() => setFilter(key)}
                    key={key}
                  >
                    {value}
                  </button>
                )
              )}
            </div>
            <div className="transactions-pg__filter wallet-transactions__filter">
              Filter:
              <div className="select-box" onClick={() => openFilterSelectBox()}>
                {filterData[filter!]} <IonIcon icon={chevronDown} />
              </div>
            </div>
          </div>
          <Link to="/transactions" className="transactions-section__link">
            View All
          </Link>
        </div>
        {loading ? <TransactionsLoader /> : null}
        {error ? (
          <div className="info-block">Error fetching transactions</div>
        ) : null}
        {!loading && !error && !Object.keys(dateTransactions).length ? (
          <div className="info-block">No transactions</div>
        ) : null}
        {!loading && !error && Object.keys(dateTransactions).length
          ? (
              Object.entries(dateTransactions) as Entries<
                typeof dateTransactions
              >
            ).map(([date, transactions]) => (
              <div className="wallet-transaction-block" key={date}>
                <div className="wallet-transaction-block__header" key={date}>
                  <p>{date}</p>
                  <div></div>
                </div>
                <div className="wallet-transaction-block__main">
                  {transactions.map((transaction) => {
                    let clickHandler = () => {};

                    if (transaction.type === "internal_wallet") {
                      clickHandler = () => {
                        navigate(
                          `/transaction-details/internal_wallets/${transaction.hash}`
                        );
                      };
                    } else if (
                      transaction.type === "buy orders" ||
                      transaction.type === "sell orders"
                    ) {
                      clickHandler = () => {
                        navigate(`/order/${transaction.hash}`);
                      };
                    } else if (transaction.type === "Wallet Sent") {
                      clickHandler = () => {
                        navigate(
                          `/transaction-details/crypto_sent/${transaction.hash}`
                        );
                      };
                    } else if (transaction.type === "Wallet Received") {
                      clickHandler = () => {
                        navigate(
                          `/transaction-details/crypto_received/${transaction.hash}`
                        );
                      };
                    } else if (transaction.type === "Wallet Swap") {
                      clickHandler = () => {
                        navigate(
                          `/transaction-details/crypto_swap/${transaction.hash}`
                        );
                      };
                    } else if (transaction.type === "bills") {
                      clickHandler = () => {
                        navigate(
                          `/transaction-details/bills/${transaction.hash}`
                        );
                      };
                    } else if (transaction.type === "virtual_cards") {
                      clickHandler = () =>
                        navigate(
                          `/transaction-details/virtual_cards/${transaction.id}`
                        );
                    }

                    return (
                      <div
                        className="transaction"
                        onClick={clickHandler}
                        key={transaction.id}
                      >
                        <div className="transaction__time">
                          {transaction.time}
                        </div>
                        <div className="transaction__main">
                          <div className="transaction__content">
                            <p className="transaction__text">
                              {transaction.content}
                            </p>
                            <p>#{transaction.hash_hidden}</p>
                          </div>
                          <div className="transaction__status-amount">
                            <span
                              className={`badge badge--${getBadge(
                                transaction.status
                              )}`}
                            >
                              {transaction.status}
                            </span>
                            {transaction.type === "Wallet Swap" ? (
                              <span
                                className={`text-${
                                  transaction.status === "Refunded"
                                    ? "info"
                                    : +transaction.amount < 0
                                    ? "danger"
                                    : "success"
                                }`}
                              >
                                {+transaction.amount > 0 ? "+" : "-"}{" "}
                                {transaction.from}{" "}
                                {roundDP(
                                  Math.abs(+transaction.amount_coin_swap),
                                  getPrecision(transaction.from)
                                )}
                                <span className="text-success">
                                  {" "}
                                  (+ {transaction.to}{" "}
                                  {roundDP(
                                    Math.abs(+transaction.amount_coin_get),
                                    getPrecision(transaction.to)
                                  )}
                                  )
                                </span>
                              </span>
                            ) : (
                              <span
                                className={`text-${
                                  transaction.status === "Refunded"
                                    ? "info"
                                    : +transaction.amount < 0
                                    ? "danger"
                                    : "success"
                                }`}
                              >
                                {transaction.currency}{" "}
                                {Number(transaction.amount).toLocaleString()}{" "}
                                {transaction.type === "Wallet Swap" ? (
                                  <span className="text-success">
                                    ({transaction.amount_get})
                                  </span>
                                ) : null}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export default CryptoTransactions;
