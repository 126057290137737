import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { pieChart, searchOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import DashboardWallet from "../../components/DashboardWallet/DashboardWallet";
import GiftCardsLoader from "../../loaders/GiftCardsLoader/GiftCardsLoader";
import {
  tGiftCardTransactions,
  tTopGiftCardTransactions,
} from "../../store/types/app.types";
import { Entries } from "../../utils/utils.types";

import emptyTransactionsImg from "../../assets/img/empty-transactions.png";
import GiftCardTransactionsLoader from "../../loaders/GiftCardTransactionsLoader/GiftCardTransactionsLoader";
import TopGiftCardsLoader from "../../loaders/TopGiftCardsLoader/TopGiftCardsLoader";
import {
  updateGiftCard,
  updateGiftCardCountry,
} from "../../store/giftcardSlice";
import { updateGiftCard as updateBuyGiftCard } from "../../store/buyGiftcardSlice";

const filterData = {
  pending: "Pending",
  paid: "Paid",
  canceled: "Canceled",
  all: "All",
};

type tGCRecentTransactions = {
  [key in keyof typeof filterData]: tGiftCardTransactions | null;
};

const GiftCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { giftCards, buyGiftCards } = useSelector(
    (state: tRootState) => state.cache
  );

  const [tab, setTab] = useState<"sell" | "buy">("sell");

  const { fetchGiftcards, fetchBuyGiftcards } = useData();

  const [search, setSearch] = useState("");

  const [transactions, setTransactions] = useState<tGCRecentTransactions>({
    pending: null,
    paid: null,
    canceled: null,
    all: null,
  });
  const [filter, setFilter] = useState<keyof typeof filterData>("pending");

  const [topGiftCards, setTopGiftCards] =
    useState<tTopGiftCardTransactions | null>(null);

  useEffect(() => {
    api_client({
      url: "/top-giftcards",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      if (res.data.success) {
        setTopGiftCards(res.data.message);
      }
    });
  }, [accessToken]);

  useEffect(() => {
    api_client({
      url: `/recent_orders/${filter}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setTransactions((trans) => ({
          ...trans,
          [filter]: res.data.message,
        }));
      })
      .catch((err) => {
        setTransactions((trans) => ({ ...trans, [filter]: [] }));
      });
  }, [filter, accessToken]);

  useEffect(() => {
    fetchGiftcards();
    fetchBuyGiftcards();
  }, [fetchGiftcards, fetchBuyGiftcards]);

  return (
    <DashboardLayout>
      <DashboardWallet />
      <div className="tab-buttons">
        <button
          className={cls(tab === "sell" && "active")}
          onClick={() => setTab("sell")}
        >
          Sell
        </button>
        <button
          className={cls(tab === "buy" && "active")}
          onClick={() => setTab("buy")}
        >
          Buy
        </button>
      </div>
      <div className="giftcards-pg">
        <section className="giftcards-pg__main">
          <div className="giftcards-pg__header mb-medium">
            <div className="giftcards-pg__header-main">
              <h3 className="giftcards-pg__heading">Select Gift Card Type</h3>
              <div className="step-radio-btns">
                <input
                  type="radio"
                  className="radio"
                  name="step"
                  value="1"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  className="radio"
                  name="step"
                  value="2"
                  disabled
                />
                <input
                  type="radio"
                  className="radio"
                  name="step"
                  value="3"
                  disabled
                />
                <input
                  type="radio"
                  className="radio"
                  name="step"
                  value="4"
                  disabled
                />
              </div>
            </div>
            <div className="giftcards-pg__header-right">
              <form className="search-form">
                <div className="search-form__icon-block">
                  <IonIcon icon={searchOutline} />
                </div>
                <input
                  type="text"
                  placeholder="Search Giftcard"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </form>
            </div>
          </div>
          {tab === "sell" ? (
            <div className="giftcards-pg__grid mb-large">
              {giftCards ? (
                giftCards
                  .filter((giftCard) =>
                    new RegExp(search, "i").test(giftCard.name)
                  )
                  .map((giftCard) => (
                    <div
                      className="giftcard"
                      key={giftCard.id}
                      onClick={() => {
                        dispatch(updateGiftCard(giftCard));
                        navigate("/gift-cards/country");
                      }}
                    >
                      <img src={giftCard.service_img} alt="" />
                      <p className="giftcard__name">{giftCard.name}</p>
                    </div>
                  ))
              ) : (
                <GiftCardsLoader len={9} />
              )}
            </div>
          ) : (
            <div className="giftcards-pg__grid mb-large">
              {buyGiftCards ? (
                buyGiftCards
                  .filter((giftCard) =>
                    new RegExp(search, "i").test(giftCard.productName)
                  )
                  .map((giftCard) => (
                    <div
                      className="giftcard"
                      key={giftCard.productName}
                      onClick={() => {
                        dispatch(updateBuyGiftCard(giftCard));
                        navigate("/buy-gift-cards/country");
                      }}
                    >
                      <img src={giftCard.imageUrl} alt="" />
                      <p className="giftcard__name">{giftCard.productName}</p>
                    </div>
                  ))
              ) : (
                <GiftCardsLoader len={9} />
              )}
            </div>
          )}
          <div className="giftcards-pg__quick-actions">
            <div className="mt-giftcards">
              <div className="mt-giftcards__header">Most Traded Gift card</div>
              <div className="mt-giftcards__cards">
                {topGiftCards ? (
                  topGiftCards.map((giftCard) => (
                    <div
                      className="mt-giftcards__card"
                      key={giftCard.id}
                      onClick={
                        giftCard.card_country.country_name
                          ? () => {
                              dispatch(
                                updateGiftCard(giftCard.services as any)
                              );
                              dispatch(
                                updateGiftCardCountry(
                                  giftCard.card_country as any
                                )
                              );
                              navigate("/gift-cards/receipt");
                            }
                          : () => {
                              dispatch(
                                updateGiftCard(giftCard.services as any)
                              );
                              navigate("/gift-cards/country");
                            }
                      }
                    >
                      <img
                        src={giftCard.services.service_img}
                        alt=""
                        className="mt-giftcards__card-img"
                      />
                      <div className="mt-giftcards__card-info">
                        <h3 className="mt-giftcards__card-name">
                          {giftCard.services.name}
                          {giftCard.card_country
                            ? ` - ${giftCard.card_country.country_name}`
                            : ""}
                        </h3>
                      </div>
                    </div>
                  ))
                ) : (
                  <TopGiftCardsLoader />
                )}
              </div>
            </div>
            <div className="quick-action quick-action--2">
              <div className="quick-action__icon-box">
                <IonIcon icon={pieChart} />
              </div>
              <h3 className="quick-action__heading">Check Rates</h3>
              <h3 className="quick-action__text">
                Know your gift cards and crypto value at a glance
              </h3>
              <Link to="/rates" className="quick-action__link">
                Check Rates
              </Link>
            </div>
          </div>
        </section>
        <aside className="giftcards-pg__aside">
          <div className="giftcard-transactions">
            <div className="giftcard-transactions__header">
              <h3 className="giftcard-transactions__heading">
                Recent Transactions
              </h3>
              <ul className="giftcard-transactions__menu">
                {(Object.entries(filterData) as Entries<typeof filterData>).map(
                  ([key, value]) => (
                    <li
                      className={cls(
                        "giftcard-transactions__menu-item",
                        key === filter &&
                          "giftcard-transactions__menu-item--active"
                      )}
                      onClick={key === filter ? () => {} : () => setFilter(key)}
                      key={key}
                    >
                      {value}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="giftcard-transactions__body">
              {transactions[filter] && !transactions[filter]?.length ? (
                <div className="giftcard-transactions__empty">
                  <img
                    src={emptyTransactionsImg}
                    className="giftcard-transactions__empty-img"
                    alt=""
                  />
                  <p className="giftcard-transactions__empty-text">
                    No Giftcard Trades Yet
                  </p>
                  <p className="giftcard-transactions__empty-sub-text">
                    This place is empty because you have not traded anything...
                  </p>
                </div>
              ) : null}
              <div className="giftcard-transactions__transactions">
                {!transactions[filter] ? <GiftCardTransactionsLoader /> : null}
                {transactions[filter]
                  ? transactions[filter]?.map((transaction) => (
                      <div
                        className="giftcard-transactions__transaction"
                        key={transaction.id}
                        onClick={() =>
                          navigate(`/order/${transaction.order_hash}`)
                        }
                      >
                        <img
                          src={transaction.service_img}
                          alt=""
                          className="giftcard-transactions__transaction-img"
                        />
                        <div className="giftcard-transactions__transaction-main">
                          <p className="giftcard-transactions__transaction-info">
                            {transaction.service.name}
                            {transaction.card_country.country_name
                              ? ` - ${transaction.card_country.country_name}`
                              : ""}
                            {transaction.card_type.type_name
                              ? ` - ${transaction.card_type.type_name}`
                              : ""}
                          </p>
                          <p className="giftcard-transactions__transaction-hash">
                            #{transaction.order_hash}
                          </p>
                        </div>
                        <p className="giftcard-transactions__transaction-amount">
                          ${transaction.order_amt}
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </aside>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(GiftCards);
