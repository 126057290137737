import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import { updateNews } from "../../store/appSlice";

import NewsLoader from "../../loaders/NewsLoader/NewsLoader";

import { convertRemToPixels } from "../../utils/func";

const NewsSection = ({ noPadding = false }: { noPadding?: boolean }) => {
  const dispatch = useDispatch();

  const news = useSelector((state: tRootState) => state.app.news);

  const newsRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const newsLeftRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const newsRightRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  useEffect(() => {
    function triggerScrollEvent() {
      const scrollEvent = new Event("scroll", { bubbles: true });
      newsRef.current.dispatchEvent(scrollEvent);
    }

    newsLeftRef.current.addEventListener("click", function () {
      newsRef.current.scrollLeft -= convertRemToPixels(35); // Adjust the scroll amount as needed
      triggerScrollEvent();
    });

    newsRightRef.current.addEventListener("click", function () {
      newsRef.current.scrollLeft += convertRemToPixels(35); // Adjust the scroll amount as needed
      triggerScrollEvent();
    });

    newsRef.current.addEventListener("scroll", function () {
      const isScrollableToLeft = newsRef.current.scrollLeft > 0;
      const isScrollableToRight =
        newsRef.current.scrollLeft <
        newsRef.current.scrollWidth - newsRef.current.clientWidth;

      newsLeftRef.current.style.display = isScrollableToLeft ? "flex" : "none";
      newsRightRef.current.style.display = isScrollableToRight
        ? "flex"
        : "none";
    });
  }, []);

  useEffect(() => {
    api_client({ method: "GET", url: "/seki-news" })
      .then((res) => {
        if (res.data.success) {
          dispatch(updateNews(res.data.message));
        } else {
        }
      })
      .catch((err) => {});
  }, [dispatch]);

  return (
    <section className={cls("blog-section", noPadding && "p-0")}>
      <h3 className="heading-secondary text-center mb-medium">In The News</h3>
      <div className="blogs-wrapper">
        <button
          className="blogs-wrapper__control blogs-wrapper__control--left"
          ref={newsLeftRef}
          style={{ display: "none" }}
        >
          <IonIcon icon={chevronBackOutline} />
        </button>
        <button
          className="blogs-wrapper__control blogs-wrapper__control--right"
          ref={newsRightRef}
        >
          <IonIcon icon={chevronForwardOutline} />
        </button>
        <div className="blogs" ref={newsRef}>
          {!news ? <NewsLoader /> : null}
          {news?.map((singleNews, i) => (
            <div className="blog" key={singleNews.id}>
              <div className="blog__img-block">
                <img src={singleNews.image} className="blog__img" alt="" />
              </div>
              <div className="blog__body">
                <h3 className="blog__title">{singleNews.title}</h3>
                <p
                  className="blog__text"
                  dangerouslySetInnerHTML={{
                    __html: `${singleNews.description.slice(0, 80)}${
                      singleNews.description.length > 80 ? "..." : ""
                    }`,
                  }}
                />
                <a
                  href={singleNews.new_url}
                  target="_blank"
                  className="blog__link"
                  rel="noreferrer"
                >
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
