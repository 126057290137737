import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tTransactions, tUser } from "./types/app.types";

type UserType = {
  loggedIn: boolean;
  accessToken: string | null;
  userDetails: tUser | null;
  hideVerifButton: boolean;
  settings: {
    hideTotalBalance: boolean;
    balanceWallet: "USD" | "NGN";
    hideNairaBalance: boolean;
    hideBonusBalance: boolean;
    hideWalletBalance: boolean;
  };
  recentTransactions: null | tTransactions;
  lastActivity: number | null;
  lastLoginCheck: number | null;
};

const UserInitialState: UserType = {
  loggedIn: false,
  accessToken: null,
  userDetails: null,
  hideVerifButton: false,
  settings: {
    hideTotalBalance: false,
    balanceWallet: "USD",
    hideNairaBalance: false,
    hideBonusBalance: false,
    hideWalletBalance: false,
  },
  recentTransactions: null,
  lastActivity: null,
  lastLoginCheck: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: getState<UserType>("user", UserInitialState),
  reducers: {
    login(
      state,
      {
        payload,
      }: PayloadAction<
        Omit<
          UserType,
          | "loggedIn"
          | "settings"
          | "recentTransactions"
          | "hideVerifButton"
          | "lastActivity"
          | "lastLoginCheck"
        >
      >
    ) {
      state.loggedIn = true;
      state.accessToken = payload.accessToken;
      state.userDetails = payload.userDetails;
      state.lastActivity = Date.now();
      state.lastLoginCheck = Date.now();

      saveState("user", current(state));
    },
    updateUserDetails(state, { payload }: PayloadAction<tUser>) {
      state.userDetails = payload;

      saveState("user", current(state));
    },
    updateUserDetailsKey(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: keyof tUser; value: any }>
    ) {
      if (!state.userDetails) return;

      state.userDetails = { ...state.userDetails, [key]: value };

      saveState("user", current(state));
    },
    updateLastActivity(state) {
      state.lastActivity = Date.now();

      saveState("user", current(state));
    },
    updateLastLoginCheck(state) {
      state.lastLoginCheck = Date.now();

      saveState("user", current(state));
    },
    updateSetting(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: keyof UserType["settings"]; value: any }>
    ) {
      state.settings = { ...state.settings, [key]: value };

      saveState("user", current(state));
    },
    updateRecentTransactions(state, { payload }: PayloadAction<tTransactions>) {
      state.recentTransactions = payload;

      saveState("user", current(state));
    },
    updateHideVerifButton(state, { payload }: PayloadAction<boolean>) {
      state.hideVerifButton = payload;

      saveState("user", current(state));
    },
    logout(state) {
      state.loggedIn = false;
      state.accessToken = null;
      state.userDetails = null;

      //   resetAppState();
    },
  },
});

export const {
  login,
  updateUserDetails,
  updateUserDetailsKey,
  updateLastActivity,
  updateLastLoginCheck,
  updateRecentTransactions,
  updateSetting,
  updateHideVerifButton,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
