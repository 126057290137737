import { IonIcon } from "@ionic/react";
import {
  businessOutline,
  checkmarkOutline,
  close,
  searchOutline,
} from "ionicons/icons";
import { tDepositBank, tdepositBanks } from "../../../store/types/app.types";
import cls from "classnames";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SelectDepositBank = ({
  banks,
  selectedBank,
  handler,
  show,
  closeHandler,
}: {
  banks: tdepositBanks;
  selectedBank: number | null;
  show: boolean;
  closeHandler: () => void;
  handler: (bank: tDepositBank) => void;
}) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const closeFunc = () => {
    setSearch("");
    closeHandler();
  };

  if (!show) return null;

  return (
    <>
      <div className="currency-modal">
        <div className="currency-modal__header">
          <span className="currency-modal__close" onClick={closeFunc}>
            <IonIcon icon={close} />
          </span>
          <h3 className="currency-modal__heading">Select Bank</h3>
        </div>
        <form className="search-form">
          <div className="search-form__icon-block">
            <IonIcon icon={searchOutline} />
          </div>
          <input
            type="text"
            placeholder="Search Account"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        <div className="currency-modal__list">
          {banks
            .filter((bank) => {
              const regexpSrch = new RegExp(search, "i");

              return (
                regexpSrch.test(bank.bank_name) ||
                regexpSrch.test(bank.bank_account_no) ||
                regexpSrch.test(bank.bank_account_name)
              );
            })
            .map((bank) => (
              <div
                className={cls(
                  "currency1",
                  bank.id === selectedBank && "currency1--active"
                )}
                key={bank.id}
                onClick={() => {
                  setSearch("");
                  handler(bank);
                }}
              >
                <div className="currency1__img-block">
                  <IonIcon
                    icon={businessOutline}
                    className="account-bank__icon"
                  />
                </div>
                <div className="currency1__main">
                  <p className="currency1__abbr">{bank.bank_name}</p>
                  <p className="currency1__name">{bank.bank_account_name}</p>
                  <p className="currency1__name">{bank.bank_account_no}</p>
                </div>
                <span className="currency1__check">
                  <IonIcon icon={checkmarkOutline} />
                </span>
              </div>
            ))}
          {!banks.length ? (
            <div className="currency1">
              <div className="currency1__main text-center">
                You have not added any deposit banks
              </div>
            </div>
          ) : null}
          <div
            className="text-center mt-small"
            onClick={() => navigate("/add-bank")}
          >
            <button className="button">Add Deposit Bank</button>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={closeFunc}></div>
    </>
  );
};

export default SelectDepositBank;
