import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";

import { tRootState } from "../../store";
import { updateUserDetails } from "../../store/userSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import RegisterLayout from "../../layouts/RegisterLayout/RegisterLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import authPin from "../../assets/img/auth-pin.png";

const TransactionPin = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [pin, setPin] = useState("");
  const [pinConfirm, setPinConfirm] = useState("");

  const [message, setMessage, clearMessage] = useAlert();

  const { fetchWarnings } = useData();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pin.length !== 4) return setMessage("warning", "Pin is required");
    if (pinConfirm.length !== 4)
      return setMessage("warning", "Confirm Pin is required");

    if (pin !== pinConfirm) return setMessage("warning", "Pins do not match");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/set-pin",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        pin: pin,
        pin_confirmation: pinConfirm,
        type: "web",
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        dispatch(updateUserDetails(res.data.message));

        fetchWarnings();
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Create Pin";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [pin, pinConfirm, clearMessage]);

  return (
    <RegisterLayout>
      <div className="register__img-box">
        <img src={authPin} alt="" />
      </div>
      <h3 className="register__heading">Create Transaction pin</h3>
      <p className="register__heading-sub-text">
        You’ll need to create a four digit pin to authorize your transaction
      </p>
      <form className="register__form" onSubmit={handleSubmit}>
        <div className="auth__form-main">
          <div className="register__form-main">
            <div className="form-group">
              <label className="form-label">Enter Pin</label>
              <div className="register__pin-input-group-2">
                <PinGroup
                  numInputs={4}
                  pin={pin}
                  handler={setPin}
                  dontFocus={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Confirm Pin</label>
              <div className="register__pin-input-group-2">
                <PinGroup
                  numInputs={4}
                  pin={pinConfirm}
                  handler={setPinConfirm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="auth__footer">
          {message}
          <button
            className={cls(
              "form-button",
              pin.length === 4 &&
                pinConfirm.length === 4 &&
                pin === pinConfirm &&
                "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Create Pin
          </button>
        </div>
      </form>
    </RegisterLayout>
  );
};

export default withAuth(TransactionPin);
