import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import { tVirtualCardTransactionDetails } from "../../../store/types/app.types";

import { getDate, roundDP, copyData } from "../../../utils/func";

const VirtualCardTransaction = ({
  transaction,
}: {
  transaction: tVirtualCardTransactionDetails;
}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  return (
    <div className="transaction-details">
      <h3 className="transaction-details__heading">
        <span onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        Virtual Card {transaction.flow === "credit" ? "Funding" : "Payment"}
      </h3>
      <p className="transaction-details__heading-subtext">
        Virtual Card {transaction.flow === "credit" ? "Funding" : "Payment"}{" "}
        Details
      </p>
      <div className="transaction-details__infos">
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount</p>
          <div className="transaction-details__info-right">
            {transaction.currency} {roundDP(transaction.amount, 2)}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Flow</p>
          <div className="transaction-details__info-right">
            <span
              className={`badge badge--${
                transaction.flow === "credit" ? "success" : "danger"
              }`}
              style={{ textTransform: "capitalize" }}
            >
              {transaction.flow}
            </span>
          </div>
        </div>
        {transaction.from_wallet ? (
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Wallet From</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {transaction.from_wallet.toUpperCase()}
              </p>
            </div>
          </div>
        ) : null}
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Description</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.content}
            </p>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Reference</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.reference}
            </p>
            <div
              className="copy"
              onClick={() => copyData(transaction.reference, setCopied)}
            >
              <IonIcon icon={copy} />
              {copied ? "Copied!" : "Copy"}
            </div>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Status</p>
          <div className="transaction-details__info-right">
            <span className="badge badge--success"> COMPLETED </span>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Date</p>
          <div className="transaction-details__info-right">
            {getDate(transaction.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualCardTransaction;
