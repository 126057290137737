import { tUIMode } from "../store/appSlice";

export const STYLES: {
  [key: string]: {
    light: string;
    dark: string;
  };
} = {
  "--light-block": { light: "block", dark: "none" },
  "--light-inline-block": { light: "inline-block", dark: "none" },

  "--dark-block": { dark: "block", light: "none" },
  "--dark-inline-block": { dark: "nline-block", light: "none" },

  "--body-bg": { light: "#fafafa", dark: "#0a1401" },

  "--util-bg": { light: "#fff", dark: "#0c1901" },

  "--text-color": { light: "#2b2b2b", dark: "#e6e6e6" },
  "--text-color-primary": { light: "rgba(56, 115, 5, 0.6)", dark: "#fff" },

  "--header-bg": { light: "#fff", dark: "#0e1d01" },

  "--hero-bg": { light: "#ecf2e6", dark: "#0c1901" },
  "--hero-heading-color": { light: "#0f0f1f", dark: "#e6e6e6" },
  "--hero-description-color": { light: "#397305", dark: "#e6e6e6" },

  "--stats-bg": { light: "#fff", dark: "#141414" },
  "--stats-icon-bg": { light: "#fff", dark: "#e6e6e6" },

  "--border": { light: "#d7d7d7", dark: "#244903" },
  "--border-2": { light: "#f7f7f7", dark: "#1a1a1a" },
  "--border-3": { light: "#ccc", dark: "#333" },
  "--util-border": { light: "#eaeaea", dark: "#132702" },

  "--feature-bg-1": { light: "#f2f2ec", dark: "#2d2d1f" },
  "--feature-bg-2": { light: "#fff2e4", dark: "#4d2800" },
  "--feature-bg-3": { light: "#e3f3fe", dark: "#032d4a" },
  "--feature-bg-4": { light: "#f2ebfe", dark: "#1d0449" },

  "--feature-heading-color": { light: "#0f0f1f", dark: "#e6e6e6" },
  "--feature-description-color": { light: "#22223a", dark: "#b3b3b3" },
  "--feature-link-color": { light: "#141414", dark: "#e6e6e6" },

  "--market-bg": { light: "#ffffff", dark: "#141414" },
  "--market-table-bg": { light: "#ffffff", dark: "transparent" },
  "--market-border": { light: "#eaeaea", dark: "#333333" },

  "--sort-icon": { light: "#bdbdbd", dark: "#333333" },
  "--sort-icon-active": { light: "#000", dark: "#e6e6e6" },

  "--why-choose-bg": { light: "#1a3502", dark: "#1a3502" },

  "--testimonial-bg": { light: "#fff", dark: "#0e1d01" },
  "--testimonial-bg-2": { light: "#ecf2e6", dark: "#112201" },

  "--footer-bg": { light: "#ecf2e6", dark: "#0e1d01" },

  "--accordion-bg": { light: "#fff", dark: "#0c1901" },
  "--accordion-shadow": { light: "#f7f7f7", dark: "rgba(0, 0, 0, 0.2)" },
  "--accordion-border": { light: "#f7f7f7", dark: "transparent" },
  "--accordion-hover": { light: "#f7f7f7", dark: "transparent" },

  "--section-header-bg": { light: "#ecf2e6", dark: "#0e1d01" },
  "--section-search-form-bg": { light: "#fff", dark: "#0c1901" },

  "--form-bg": { light: "transparent", dark: "#0c1901" },

  "--preloader-bg-1": { light: "#e8f8d8", dark: "#334d1a" },
  "--preloader-bg-2": { light: "#d5eebb", dark: "#3b581d" },

  "--placeholder-bg": { light: "darkgray", dark: "#141414" },
  "--placeholder-bg-2": {
    light: "linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%)",
    dark: "linear-gradient(to right, #183102 10%, #132702 18%, #183102 33%)",
  },

  "--form-button-inactive-bg": { light: "#eaeaea", dark: "#33382e" },
  "--form-button-inactive-text-color": { light: "#999", dark: "#777" },

  "--alert-bg": { light: "#fff", dark: "#141414" },
  "--alert-bg-dark": { light: "#f1f1f1", dark: "#222" },

  "--not-found-error-text-color": { light: "#dddddd", dark: "#222" },

  "--dashboard-header-submenu-hover": { light: "#f3f8ee", dark: "#102201" },

  "--services-bg": { light: "#f7fdf2", dark: "#0e1b04" },

  "--service-bg-1": { light: "#fcecd8", dark: "#2f1b04" },
  "--service-bg-2": { light: "#e7f2de", dark: "#18250e" },
  "--service-bg-3": { light: "#dff8ff", dark: "#002733" },

  "--transaction-label-bg-deposit": { light: "#f2f9ec", dark: "#253913" },
  "--transaction-label-bg-withdraw": { light: "#ffe5e5", dark: "#4d0000" },
  "--transaction-label-bg-trade": { light: "#fff5e5", dark: "#4d2f00" },

  "--table-hover": { light: "#f7f7f7", dark: "#222" },

  "--currencies-scrollbar-track": { light: "#f7f7f7", dark: "#222" },
  "--currencies-scrollbar-track-light": { light: "#efefef", dark: "#222" },
  "--currencies-scrollbar-thumb": { light: "#e2ecd8", dark: "#444" },

  "--currency-bg-1": { light: "#f9f9f9", dark: "#0e1d01" },
  "--currency-bg-1-hover": { light: "#f5f5f5", dark: "#102201" },
  "--currency-bg-2": { light: "#efefef", dark: "#132702" },
  "--currency-bg-2-hover": { light: "#e7e7e7", dark: "#102201" },

  "--wallet-card-action-bg": { light: "#f8fbf7", dark: "#142702" },

  "--wallet-card-action-active-color": { light: "#244b01", dark: "#fff" },
  "--wallet-card-action-active-icon-bg": { light: "#244b01", dark: "#448c04" },
  "--wallet-card-action-active-icon-color": {
    light: "#448c04",
    dark: "#fff",
  },

  "--mt-giftcards-header": { light: "#ebebeb", dark: "#19260d" },

  "--giftcard-transactions-header": { light: "#f8fbf7", dark: "#19260d" },

  "--pay-bills-info-bg-2": { light: "#eef3ea", dark: "rgba(34, 54, 40, 0.4)" },
  "--pay-bills-right-header-bg": {
    light: "#f8fbf7",
    dark: "rgba(34, 54, 40, 0.4)",
  },
  "--pay-bills-right-info-bg": { light: "#f9f9f9", dark: "#1e2617" },

  "--currency-modal-bg": { light: "#f9f9f9", dark: "#131313" },
  "--currency1-bg": { light: "#fff", dark: "#102201" },

  "--form-disabled": { light: "#f1f1f1", dark: "#2e3229" },

  "--crypto-block-header": { light: "#f4f4f5", dark: "#19260d" },
  "--crypto-block-balance-header": { light: "#fcfcfc", dark: "#33382e" },

  "--chat-bg": { light: "#edfade", dark: "#1b2c07" },
  "--chat-bg-1": { light: "#f9f9f9", dark: "#1e2518" },
  "--chat-bg-1-border": { light: "#eaeaea", dark: "transparent" },

  "--account-bg-green": { light: "#ecf2e6", dark: "#193102" },
  "--account-bg-yellow": { light: "#fff6e0", dark: "#332400" },

  "--shadow-bg": { light: "rgba(0, 0, 0, 0.15)", dark: "rgba(0, 0, 0, 0.8)" },

  "--radio-bg": { light: "#ccc", dark: "#1e2617" },

  "--usd-color": { light: "#397305", dark: "#fff" },

  "--info-bg": { light: "#e6ece1", dark: "#0c1901" },

  "--wallet-actions-mobile-bg": { light: "#eff6e8", dark: "#0e1b04" },
  "--text-color-dark": { light: "#141414", dark: "#e6e6e6" },

  "--vcard-bg": { light: "#f2f5ef", dark: "#2d3922" },
  "--vcard-user-bg": { light: "#fafafa", dark: "#1e2617" },

  "--dark-primary-bg": { light: "#ffffff", dark: "#448c04" },
  "--light-primary-color": { light: "#448c04", dark: "#ffffff" },

  "--vcard-actions-bg": { light: "#f2f5ef", dark: "#0f130b" },
  "--vcard-transaction-bg": { light: "#fafafa", dark: "#132701" },

  "--vcard-card-info-bg": { light: "#f2f5ef", dark: "#1e2617" },

  "--options-bg": { light: "#f6f6f6", dark: "#102201" },
  "--option-bg": { light: "#ffffff", dark: "#173201" },

  "--virtual-account-bg": { light: "#ffffff", dark: "#0a1503" },

  "--featured-heading-color": { light: "#bfbfbf", dark: "#808080" },

  "--featured-color": { light: "#1a1a1a", dark: "#d9d9d9" },
  "--featured-active-color": { light: "#fff", dark: "#d9d9d9" },

  "--vcard-home-bg": { light: "#e2eff9", dark: "#091c2a" },

  "--vcard-heading-color": { light: "#0f0f1f", dark: "#d9d9d9" },
  "--vcard-text-color": { light: "#545454", dark: "#b3b3b3" },

  "--cnotice-bg": { light: "#ffffff", dark: "#0f1d01" },
  "--cnotice-main-bg": { light: "#fdf2e7", dark: "#2f1904" },
  "--cnotice-info-bg": { light: "#ffffff", dark: "#0a1401" },
};

export const set_mode = (mode: "light" | "dark") => {
  for (const [variable, style] of Object.entries(STYLES)) {
    document.documentElement.style.setProperty(variable, style[mode]);
  }

  localStorage.setItem("uiMode", mode);
};

// gets operating system ui mode
export const getOSUIMode = (): tUIMode =>
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

export const getPrecision = (walletSymbol: string): number => {
  if (["usdt", "usdc"].includes(walletSymbol)) return 4;

  if (walletSymbol === "ngn") return 2;

  return 6;
};
