const TransactionsLoader = () => {
  return (
    <>
      {new Array(2).fill(null).map((_, i) => (
        <div className="wallet-transaction-block" key={i}>
          <div className="wallet-transaction-block__header">
            <p>
              <span className="color-transparent placeholder">
                2023-06-24, SATURDAY
              </span>
            </p>
            <div></div>
          </div>
          <div className="wallet-transaction-block__main">
            {new Array(5).fill(null).map((_, i) => (
              <div className="transaction" key={i}>
                <div className="transaction__time">
                  <span className="color-transparent placeholder">11:00</span>
                </div>
                <div className="transaction__main">
                  <div className="transaction__content">
                    <p className="transaction__text">
                      <span className="color-transparent placeholder">
                        Withdrawal from naira account
                      </span>
                    </p>
                    <p>
                      <span className="color-transparent placeholder">
                        #JJKASJ***JAKSAJKAS
                      </span>
                    </p>
                  </div>
                  <div className="transaction__status-amount">
                    <span className="badge placeholder color-transparent">
                      Failed
                    </span>
                    <span className="text-success">
                      <span className="color-transparent placeholder">
                        + &#8358; 2,000
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default TransactionsLoader;
