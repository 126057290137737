import influencerJoingBg from "../../assets/img/influencer-join-bg.png";

const InfluencerJoinSection = () => {
  return (
    <section className="influencer-join-section">
      <div className="container">
        <div className="ijcard">
          <img src={influencerJoingBg} alt="" className="ijcard__bg" />
          <div className="ijcard__top-heading">______Join Today</div>
          <div className="ijcard__main">
            <div className="ijcard__heading">
              Join Our Influencer's Community
            </div>
            <div className="ijcard__right">
              <p className="ijcard__text">
                Are you a young, sociable, and influential person in the crypto,
                finance, and web3 space? Are you interested in making
                &gt;20,000,000 in a month as passive income?
              </p>
              <a
                href="https://onelink.to/sekipartner"
                target="_blank"
                rel="noreferrer"
                className="ijcard__button"
                style={{ textDecoration: "none" }}
              >
                Click here to start earning!
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfluencerJoinSection;
