import { FormEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { updateUserDetails } from "../../store/userSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";
import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../hooks/useSelectBox/useSelectBox";

import RegisterLayout from "../../layouts/RegisterLayout/RegisterLayout";

import { MONTHS } from "../../data";

import { assertNotNull } from "../../utils/func";

import authBio from "../../assets/img/auth-bio.png";

const getDayData = (): tSelectBoxGeneric => {
  const data: tSelectBoxGeneric = {};

  new Array(31).fill(null).forEach((_, i) => {
    const value = "" + (i + 1);

    data[value] = value;
  });

  return data;
};

const getMonthData = (): tSelectBoxGeneric => {
  const data: tSelectBoxGeneric = {};

  MONTHS.forEach((month, i) => {
    const value = "" + (i + 1);

    data[value] = month;
  });

  return data;
};

const getYearData = (): tSelectBoxGeneric => {
  const data: tSelectBoxGeneric = {};

  new Array(new Date().getFullYear() - 1949).fill(null).forEach((_, i) => {
    const value = "" + (1950 + i);

    data[value] = value;
  });

  return data;
};

const DateOfBirth = () => {
  const dispatch = useDispatch();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const dayData = getDayData();
  const [daySelectBox, day, openDaySelectBox] = useSelectBox<string>(
    "Select Day",
    getSelectBoxData(dayData),
    null
  );

  const monthData = getMonthData();
  const [monthSelectBox, month, openMonthSelectBox] = useSelectBox<string>(
    "Select Month",
    getSelectBoxData(monthData),
    null
  );

  const yearData = getYearData();
  const [yearSelectBox, year, openYearSelectBox] = useSelectBox<string>(
    "Select Year",
    getSelectBoxData(yearData),
    null
  );

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!day || !month || !year)
      return setMessage("warning", "Fill in your date of birth");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/add-dob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        day: +day,
        month: +month,
        year: +year,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        dispatch(updateUserDetails(res.data.message));
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Next";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [day, month, year, clearMessage]);

  return (
    <RegisterLayout>
      {daySelectBox}
      {monthSelectBox}
      {yearSelectBox}
      <div className="register__img-box">
        <img src={authBio} alt="" />
      </div>
      <h3 className="register__heading">
        Nice to have you on board, Chief
        <span> {userDetails.names} </span>
      </h3>
      <p className="register__heading-sub-text">
        Kindly enter your date of birth
      </p>
      <form className="register__form" onSubmit={handleSubmit}>
        <div className="auth__form-main">
          <div className="register__dob-input-group">
            <div className="select-box" onClick={openDaySelectBox}>
              <p>{day ? dayData[day] : "Day"}</p>
              <IonIcon icon={chevronDownOutline} />
            </div>
            <div className="select-box" onClick={openMonthSelectBox}>
              <p>{month ? monthData[month] : "Month"}</p>
              <IonIcon icon={chevronDownOutline} />
            </div>
            <div className="select-box" onClick={openYearSelectBox}>
              <p>{year ? yearData[year] : "Year"}</p>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
        </div>
        <div className="auth__footer">
          {message}
          <button
            className={cls(
              "form-button",
              day && month && year && "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Next
          </button>
        </div>
      </form>
    </RegisterLayout>
  );
};

export default withAuth(DateOfBirth);
