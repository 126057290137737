import { useEffect, useRef } from "react";

import { Testimonials } from "../../data";

const TestimonialsSection = () => {
  const testimonialsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const slides = testimonialsRef.current;

    let curSlide = 0;
    const maxSlide = slides.length;

    const goToSlide = (slide: number) => {
      slides.forEach((s, i) => {
        if (!s) return;

        if (i === slide) {
          s.classList.add("testimonial--active");
        } else {
          s.classList.remove("testimonial--active");
        }

        s.style.transform = `translateX(${100 * (i - slide) - 50}%) scale(${
          i === slide ? 1 : 0.8
        })`;
      });
    };

    const prevSlide = () => {
      if (curSlide === 0) {
        curSlide = maxSlide - 1;
      } else {
        curSlide--;
      }
      goToSlide(curSlide);
    };

    const nextSlide = () => {
      if (curSlide === maxSlide - 1) {
        curSlide = 0;
      } else {
        curSlide++;
      }
      goToSlide(curSlide);
    };

    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") prevSlide();
      if (e.key === "ArrowRight") nextSlide();
    });

    // Go to next slide every 10 seconds
    setInterval(nextSlide, 10000);
  }, []);

  return (
    <section className="testimonials-section">
      <div className="testimonials-section__container container">
        <h3 className="heading-secondary text-center mb-medium">
          What People Say About Us
        </h3>
        <div className="testimonials">
          {Testimonials.map((testimonial, i) => (
            <div
              className={`testimonial ${i === 0 ? "testimonial--active" : ""}`}
              style={{
                transform: `translateX(${100 * i - 50}%) scale(${
                  i === 0 ? 1 : 0.8
                })`,
              }}
              key={i}
              ref={(el) => (testimonialsRef.current[i] = el!)}
            >
              <div className="testimonial__main">
                <span
                  className="testimonial__quote"
                  dangerouslySetInnerHTML={{
                    __html: "&CloseCurlyDoubleQuote;",
                  }}
                />
                <div className="testimonial__msg">{testimonial.message}</div>
                {testimonial.author ? (
                  <p className="testimonial__author">- {testimonial.author}</p>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
