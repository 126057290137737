import { ReactNode } from "react";
import cls from "classnames";

import authBackground from "../../assets/img/auth-background.png";
import ModeToggle from "../../components/ModeToggle/ModeToggle";

const AuthLayout = ({
  children,
  variation,
}: {
  children: ReactNode;
  variation?: boolean;
}) => {
  return (
    <main
      className={cls("auth-container", variation && "auth-container--1")}
      style={{ backgroundImage: `url(${authBackground})` }}
    >
      {children}
      <ModeToggle />
    </main>
  );
};

export default AuthLayout;
