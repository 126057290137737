import { useEffect, useState, FormEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  arrowBack,
  checkmark,
  checkmarkCircle,
  chevronDownOutline,
  close,
} from "ionicons/icons";

import { tRootState } from "../../../store";
import {
  tServicePackage,
  tServicePackages,
  tServiceProvider,
} from "../../../store/types/app.types";

import api_client from "../../../api/client";

import { tBillData, updateBillData } from "../../../store/paybillsSlice";

import withAuth from "../../../hoc/withAuth/withAuth";

import useData from "../../../hooks/useData/useData";
import useAlert from "../../../hooks/useAlert/useAlert";
import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../../hooks/useSelectBox/useSelectBox";

import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../../components/Preloader/Preloader";
import BeneficiariesModal from "../../../components/BeneficiariesModal/BeneficiariesModal";

import TransactionLimit from "../../../components/TransactionLimit/TransactionLimit";
import RingLoader from "../../../loaders/RingLoader/RingLoader";

import { assertNotNull, isNumber, roundDP } from "../../../utils/func";

const getServicePackagesData = (
  servicePackages: tServicePackages | null
): tSelectBoxGeneric | null => {
  if (!servicePackages || !servicePackages.length) return null;

  const data: tSelectBoxGeneric = {};

  for (const servicePackage of servicePackages) {
    data[servicePackage.id] = `${servicePackage.package_name} (₦${roundDP(
      servicePackage.provider_amount,
      2
    )})`;
  }

  return data;
};

const BuyBill = () => {
  const { serviceType } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const {
    services,
    beneficiaries: billsBeneficiaries,
    serviceProviders: billServiceProviders,
    servicePackages: billServicePackages,
  } = useSelector((state: tRootState) => state.paybills);

  assertNotNull(userDetails);

  const {
    fetchBillServices,
    fetchBillBeneficiaries,
    fetchServiceProviders,
    fetchServicePackages,
  } = useData();

  const [service, setService] = useState(
    services?.find((service) => service.service_type === serviceType) || null
  );

  const [providerType, setProviderType] = useState<
    null | "prepaid" | "postpaid"
  >("prepaid");

  const serviceProviders = billServiceProviders[serviceType || ""];

  const [serviceProvider, setServiceProvider] =
    useState<tServiceProvider | null>(null);

  const beneficiaries = billsBeneficiaries[serviceType || ""]?.filter(
    (ben) => +ben.main_id === serviceProvider?.id
  );

  const servicePackages = billServicePackages[serviceProvider?.id || ""];

  const [
    servicePackageSelectBox,
    servicePackageId,
    openServicePackageSelectBox,
  ] = useSelectBox<string>(
    "Select Service Package",
    getSelectBoxData(getServicePackagesData(servicePackages)),
    null
  );
  const [servicePackage, setServicePackage] = useState<tServicePackage | null>(
    null
  );

  const [recipient, setRecipient] = useState<number | string>("");

  const recipientInBeneficiaries = beneficiaries?.find(
    (ben) => ben.recipient === recipient
  );

  const isFetching = useRef(false);

  const recVerifyInterval = useRef<number | null>(null);

  const [verifyingRecipient, setVerifyingRecipient] = useState(false);
  const [errorVerifyingRecipient, setErrorVerifyingRecipient] = useState(false);

  const [verification, setVerification] = useState("");

  const [amount, setAmount] = useState<number | string>("");

  const [newBeneficiary, setNewBeneficiary] = useState(false);
  const [alias, setAlias] = useState("");

  const [numberOfPins, setNumberOfPins] = useState<number | "">("");
  const [pin, setPin] = useState<number | "">("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const [showBeneficiariesModal, setShowBeneficiariesModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!service) return;

    if (!serviceProvider)
      return setMessage("warning", "Select service provider");

    if (service.has_package && !servicePackage)
      return setMessage("warning", "Select service package");

    if (!amount) return setMessage("warning", "Amount is required");

    if (amount && +amount > +userDetails.balance)
      return setMessage("warning", "Insufficient funds");

    if (service.has_recipient && !recipient)
      return setMessage("warning", "Recipient is required");

    if (service.has_recipient_validation && verifyingRecipient) return;

    if (service.has_recipient_validation && !verification)
      return setMessage("warning", "Invalid recipient");

    if (service.service_type === "epin" && (!numberOfPins || !pin))
      return setMessage("warning", "Number of pins and Pin value is required");

    if (newBeneficiary) {
      if (!alias)
        return setMessage(
          "warning",
          "You need to provide nickname to add new beneficiary"
        );

      if (alias.length > 10)
        return setMessage(
          "warning",
          "Nickname provided should not be greater than 10 characters in length"
        );
    }

    const billData: tBillData = {
      service,
      serviceProvider,
      newBeneficiary,
      success: false,
    };

    if (servicePackage) billData.servicePackage = servicePackage;
    if (amount) billData.amount = +amount;
    if (recipient) billData.recipient = recipient.toString();
    if (verification) billData.recipientValidation = verification;
    if (numberOfPins) billData.numberOfPins = numberOfPins;
    if (pin) billData.pinValue = pin;
    if (newBeneficiary && alias) billData.alias = alias;

    dispatch(updateBillData(billData));

    navigate("/pay-bills-confirm");
  };

  useEffect(() => {
    clearMessage();
  }, [
    serviceProvider,
    servicePackage,
    recipient,
    amount,
    newBeneficiary,
    alias,
    clearMessage,
  ]);

  useEffect(() => {
    if (!servicePackageId || !servicePackages) return setServicePackage(null);

    const selectedServicePackage = servicePackages.find(
      (servicePackage) => servicePackage.id.toString() === servicePackageId
    );

    setServicePackage(selectedServicePackage || null);
    if (selectedServicePackage)
      setAmount(+selectedServicePackage.provider_amount);
  }, [servicePackages, servicePackageId]);

  useEffect(() => {
    if (service?.service_type !== "epin") return;

    if (!numberOfPins || !pin) setAmount("");

    setAmount(+numberOfPins * +pin);
  }, [service?.service_type, numberOfPins, pin]);

  useEffect(() => {
    if (!service?.has_recipient_validation || !recipient) {
      setVerifyingRecipient(false);
      setErrorVerifyingRecipient(false);
      setVerification("");
      return;
    }

    if (recVerifyInterval.current)
      window.clearInterval(recVerifyInterval.current);

    setVerifyingRecipient(true);
    setErrorVerifyingRecipient(false);
    setVerification("");

    recVerifyInterval.current = window.setInterval(() => {
      if (isFetching.current) {
        window.clearInterval(recVerifyInterval.current!);
        return;
      }

      isFetching.current = true;

      api_client({
        url: `/validate-recipient-new`,
        method: "POST",
        data: {
          bill_type: service?.service_type,
          provider_code: serviceProvider?.baxi_service_type,
          recipient: recipient,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          setVerification(res.data.message);
        })
        .catch((err) => {
          setErrorVerifyingRecipient(true);
        })
        .finally(() => {
          setVerifyingRecipient(false);
          if (recVerifyInterval.current)
            window.clearInterval(recVerifyInterval.current);

          isFetching.current = false;
        });
    }, 3000);
  }, [
    accessToken,
    serviceProvider?.baxi_service_type,
    recipient,
    service?.has_recipient_validation,
    service?.service_type,
  ]);

  useEffect(() => {
    if (!serviceType) return;

    fetchBillBeneficiaries(serviceType);
  }, [fetchBillBeneficiaries, serviceType]);

  useEffect(() => {
    if (!serviceProvider?.id || !service?.has_package) return;

    fetchServicePackages(serviceProvider.id.toString())
      .then((res) => {
        // do nothing
      })
      .catch((err) => {
        if (!servicePackages?.length) navigate("/404");
      });
  }, [
    fetchServicePackages,
    serviceProvider?.id,
    service?.has_package,
    servicePackages?.length,
    navigate,
  ]);

  useEffect(() => {
    if (!serviceType) return;

    fetchServiceProviders(serviceType)
      .then((res) => {
        // do nothing
      })
      .catch((err) => {
        if (!serviceProviders?.length) navigate("/404");
      });
  }, [
    fetchServiceProviders,
    serviceType,
    accessToken,
    serviceProviders?.length,
    navigate,
  ]);

  useEffect(() => {
    fetchBillServices()
      .then((bServices) => {
        const fService = bServices.find(
          (serv) => serv.service_type === serviceType
        );

        if (fService) {
          if (!service?.id) setService(fService);
        } else {
          navigate("/error");
        }
      })
      .catch((err) => {
        if (!service?.id) navigate("/error");
      });
  }, [fetchBillServices, navigate, service?.id, serviceType]);

  if (!service) return <Preloader />;

  if (!serviceProviders) return <Preloader />;

  if (serviceProvider && service.has_package && !servicePackages)
    return <Preloader />;

  const formComplete =
    serviceProvider &&
    (!service.has_package || servicePackage) &&
    (!service.has_recipient || recipient) &&
    (!service.has_recipient_validation || verification) &&
    (service.service_type !== "epin" || (numberOfPins && pin)) &&
    amount &&
    +amount <= userDetails.balance &&
    (!newBeneficiary || (alias && alias.length < 10));

  return (
    <DashboardLayout>
      {servicePackageSelectBox}
      {serviceProvider ? (
        <>
          {service.has_recipient ? (
            <BeneficiariesModal
              show={showBeneficiariesModal}
              image={serviceProvider.provider_image}
              bill
              beneficiaries={beneficiaries || null}
              isActive={(ben) => recipient === ben.recipient}
              handler={(ben) => {
                setRecipient(ben.recipient);
                setNewBeneficiary(false);

                setShowBeneficiariesModal(false);
              }}
              closeHandler={() => setShowBeneficiariesModal(false)}
            />
          ) : null}
          <div className="pay-bills-block">
            <div className="pay-bills-block__header">
              <span
                className="pay-bills-block__back"
                onClick={() => setServiceProvider(null)}
              >
                <IonIcon icon={arrowBack} />
              </span>
              <img src={serviceProvider.provider_image} alt="" />
              <h3 className="pay-bills-block__heading">
                Buy {serviceProvider.provider_name} {service.service_name}
              </h3>
            </div>
            <form className="pay-bills-block__form" onSubmit={handleSubmit}>
              {beneficiaries && beneficiaries.length ? (
                <div className="beneficiaries mb-small">
                  <div className="beneficiaries__header">
                    <h3 className="beneficiaries__heading">Beneficiaries</h3>
                    <span
                      className="transactions-section__link"
                      onClick={() => setShowBeneficiariesModal(true)}
                    >
                      View All
                    </span>
                  </div>
                  <div className="beneficiaries__beneficiaries">
                    {beneficiaries.map((beneficiary) => (
                      <div
                        className={cls(
                          "beneficiary",
                          recipient === beneficiary.recipient &&
                            "beneficiary--active"
                        )}
                        onClick={() => {
                          setRecipient(beneficiary.recipient);
                          setNewBeneficiary(false);
                        }}
                        key={beneficiary.id}
                      >
                        <img
                          src={serviceProvider.provider_image}
                          alt=""
                          className="beneficiary__name-logo"
                        />
                        <p
                          className="beneficiary__name"
                          title={beneficiary.recipient_alias}
                        >
                          {beneficiary.recipient_alias.slice(0, 10)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {service.has_package ? (
                <div className="form-group">
                  <label>Service Package</label>
                  <div
                    className="select-box"
                    onClick={openServicePackageSelectBox}
                  >
                    <p>
                      {servicePackage
                        ? servicePackage.package_name
                        : "Select service package"}
                    </p>
                    <IonIcon icon={chevronDownOutline} />
                  </div>
                </div>
              ) : null}

              {service.has_recipient ? (
                <div className="form-group">
                  <label>Recepient</label>
                  <div className="form-input-group">
                    <input
                      type="text"
                      placeholder="Enter Recipient"
                      autoComplete="off"
                      value={recipient}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setRecipient(e.target.value)
                            : null
                          : setRecipient("")
                      }
                    />
                    <button type="button">
                      {verifyingRecipient ? <RingLoader /> : null}
                      {verification ? (
                        <IonIcon icon={checkmark} className="text-success" />
                      ) : null}
                      {errorVerifyingRecipient ? (
                        <IonIcon icon={close} className="text-warning" />
                      ) : null}
                    </button>
                  </div>
                  {verification ? (
                    <span className="form-bottom-label text-success">
                      <IonIcon icon={checkmarkCircle} className="active" />
                      {verification}
                    </span>
                  ) : null}
                  {errorVerifyingRecipient ? (
                    <span className="form-bottom-label text-danger">
                      <IonIcon icon={alertCircle} className="active" />
                      Could not verify recepient
                    </span>
                  ) : null}
                </div>
              ) : null}

              {service.service_type === "epin" ? (
                <>
                  <div className="form-group">
                    <label>Number of Pins</label>
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Enter number of pins"
                      value={numberOfPins}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setNumberOfPins(+e.target.value)
                            : null
                          : setNumberOfPins("")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Pin Value</label>
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Enter pin value"
                      value={pin}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setPin(+e.target.value)
                            : null
                          : setPin("")
                      }
                    />
                  </div>
                </>
              ) : null}

              {(!service.has_package && service.service_type !== "epin") ||
              amount ? (
                <div className="form-group">
                  <label>Amount</label>
                  <div className="input-group">
                    <button type="button">&#8358;</button>
                    <input
                      type="text"
                      placeholder="Enter amount"
                      value={amount}
                      onChange={
                        service.has_package || service.service_type === "epin"
                          ? () => null
                          : (e) =>
                              e.target.value
                                ? isNumber(e.target.value)
                                  ? setAmount(e.target.value)
                                  : null
                                : setAmount("")
                      }
                      disabled={
                        service.has_package || service.service_type === "epin"
                      }
                      className={
                        service.has_package || service.service_type === "epin"
                          ? "form-disabled"
                          : ""
                      }
                    />
                  </div>
                </div>
              ) : null}

              {service.has_recipient ? (
                <>
                  {!recipientInBeneficiaries ? (
                    <div className="accept">
                      <input
                        type="checkbox"
                        className="accept__checkbox"
                        checked={newBeneficiary}
                        onChange={(e) => setNewBeneficiary(e.target.checked)}
                      />
                      <p>Save as beneficiary</p>
                    </div>
                  ) : null}
                  {newBeneficiary ? (
                    <div className="form-group">
                      <label>Add Nickname</label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="e.g Johnny"
                        value={alias}
                        onChange={(e) => setAlias(e.target.value)}
                        maxLength={10}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}

              <TransactionLimit type="bills" />

              <div className="auth__footer">
                {message}
                <button
                  className={cls(
                    "form-button",
                    formComplete && "form-button--active"
                  )}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="pay-bills-block pay-bills-block--fh">
          <div className="pay-bills-block__header">
            <span
              className="pay-bills-block__back"
              onClick={() => navigate(-1)}
            >
              <IonIcon icon={arrowBack} />
            </span>
            <h3 className="pay-bills-block__heading">Select Provider</h3>
          </div>
          {service.service_type === "meterToken" ? (
            <div className="checkbox-group mb-medium">
              <div className="check-control">
                <input
                  type="radio"
                  className="radio-1"
                  name="providerType"
                  value="all"
                  checked={providerType === null}
                  onChange={() => setProviderType(null)}
                />
                <span>All</span>
              </div>
              <div className="check-control">
                <input
                  type="radio"
                  className="radio-1"
                  name="providerType"
                  value="prepaid"
                  checked={providerType === "prepaid"}
                  onChange={() => setProviderType("prepaid")}
                />
                <span>Pre-paid</span>
              </div>
              <div className="check-control">
                <input
                  type="radio"
                  className="radio-1"
                  name="providerType"
                  value="postpaid"
                  checked={providerType === "postpaid"}
                  onChange={() => setProviderType("postpaid")}
                />
                <span>Post-paid</span>
              </div>
            </div>
          ) : null}
          <div className="pay-bills-block__services">
            {serviceProviders
              .filter((sp) => {
                if (service.service_type !== "meterToken" || !providerType)
                  return true;

                return new RegExp(providerType!, "i").test(sp.provider_name);
              })
              .map((sp) => (
                <div
                  className={cls(
                    "pay-bills-block__service"
                    //   sp.id === serviceProvider?.id &&
                    //     "pay-bills-block__service--active"
                  )}
                  key={sp.id}
                  onClick={() => {
                    setServiceProvider(sp);
                  }}
                >
                  <img
                    src={sp.provider_image}
                    alt=""
                    className="pay-bills-block__service-img"
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default withAuth(BuyBill);
