import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";

import { tRootState } from "../../store";

import { getRegistrationStep } from "../../utils/user";

import ModeToggle from "../../components/ModeToggle/ModeToggle";

import authBackground from "../../assets/img/auth-background.png";
import sekiLogo from "../../assets/img/sekilogo-dark.png";

const RegisterLayout = ({ children }: { children: ReactNode }) => {
  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  let step = getRegistrationStep(userDetails)[0];

  return (
    <>
      <ModeToggle />
      <main className="register">
        <div className="register__left">
          <div className="register__logo-box">
            <Link to="/">
              <img src={sekiLogo} alt="" />
            </Link>
          </div>
          <div className="register__steps">
            <div
              className={cls(
                "register-step",
                step === 1 && "register-step--active",
                step > 1 && "register-step--completed"
              )}
            >
              <div className="register-step__left">
                <button className="register-step__btn">
                  <IonIcon icon={checkmark} />
                </button>
                <div className="register-step__line"></div>
              </div>
              <div className="register-step__main">
                <h3 className="register-step__heading">Personal Information</h3>
                <p className="register-step__text">
                  Fill in your personal information
                </p>
              </div>
            </div>
            <div
              className={cls(
                "register-step",
                step === 2 && "register-step--active",
                step > 2 && "register-step--completed"
              )}
            >
              <div className="register-step__left">
                <button className="register-step__btn">
                  <IonIcon icon={checkmark} />
                </button>
                <div className="register-step__line"></div>
              </div>
              <div className="register-step__main">
                <h3 className="register-step__heading">Email Verification</h3>
                <p className="register-step__text">Verify your email address</p>
              </div>
            </div>
            <div
              className={cls(
                "register-step",
                [3, 4].includes(step) && "register-step--active",
                step > 4 && "register-step--completed"
              )}
            >
              <div className="register-step__left">
                <button className="register-step__btn">
                  <IonIcon icon={checkmark} />
                </button>
                <div className="register-step__line"></div>
              </div>
              <div className="register-step__main">
                <h3 className="register-step__heading">Telephone Number</h3>
                <p className="register-step__text">
                  Enter and verify your telephone number
                </p>
              </div>
            </div>
            <div
              className={cls(
                "register-step",
                step === 6 && "register-step--active",
                step > 6 && "register-step--completed"
              )}
            >
              <div className="register-step__left">
                <button className="register-step__btn">
                  <IonIcon icon={checkmark} />
                </button>
                <div className="register-step__line"></div>
              </div>
              <div className="register-step__main">
                <h3 className="register-step__heading">Date of Birth</h3>
                <p className="register-step__text">Enter your date of birth</p>
              </div>
            </div>
            <div
              className={cls(
                "register-step",
                step === 7 && "register-step--active",
                step > 7 && "register-step--completed"
              )}
            >
              <div className="register-step__left">
                <button className="register-step__btn">
                  <IonIcon icon={checkmark} />
                </button>
                <div className="register-step__line"></div>
              </div>
              <div className="register-step__main">
                <h3 className="register-step__heading">Transaction PIN</h3>
                <p className="register-step__text">Create a transaction PIN</p>
              </div>
            </div>
            <div
              className={cls(
                "register-step",
                step === 8 && "register-step--active",
                step > 8 && "register-step--completed"
              )}
            >
              <div className="register-step__left">
                <button className="register-step__btn">
                  <IonIcon icon={checkmark} />
                </button>
                <div className="register-step__line"></div>
              </div>
              <div className="register-step__main">
                <h3 className="register-step__heading">Referral</h3>
                <p className="register-step__text">
                  Enter referral code or skip step
                </p>
              </div>
            </div>
          </div>
          <p className="register__copyright">
            SekiApp is an evolution from My BTC Nigeria (2016-2021) <br />
            With <span>♥</span> from all of us at SekiApp (c) 2021-
            {new Date().getFullYear()}
          </p>
        </div>
        <div
          className="register__main"
          style={{ backgroundImage: `url(${authBackground})` }}
        >
          <div className="register__main-content">{children}</div>
        </div>
      </main>
    </>
  );
};

export default RegisterLayout;
