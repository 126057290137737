import { tTestimonials } from "../store/types/app.types";

export const Testimonials: tTestimonials = [
  {
    message:
      "I have been using SEKIAPP for over a year now, and I am blown away by the speed and efficiency of their platform. It's always been easy to store, sell, and buy my digital assets, and I have never encountered any issues. The customer service is top notch, and I highly recommend SEKIAPP to anyone looking to trade digital assets in Nigeria.",
  },
  {
    message:
      "SEKIAPP has been a game-changer for me in trading crypto. The fees are minimal, and the platform is user-friendly, making it easy for anyone to navigate. I have also had great experiences with their customer service team, who always quickly resolve any issues I may have.",
  },
  {
    message:
      "I was hesitant to start trading crypto in Nigeria because of safety concerns, but SEKIAPP has put my mind at ease. Their platform is secure, and I have never had any issues with fraud or scams. I feel confident using SEKIAPP, and I highly recommend it to anyone looking to trade crypto in Nigeria.",
  },
  {
    message:
      "SEKIAPP has revolutionised the way I trade crypto in Nigeria. The platform is fast, efficient, and user-friendly, making it easy for me to store, sell, and buy digital assets. I also love that there are no fees for using their service, which is a huge bonus.",
  },
  {
    message:
      "I have used other crypto exchange platforms in Nigeria, but SEKIAPP is the best. The customer service is excellent, and the platform is reliable and secure. I have never had any issues with their service, and I highly recommend SEKIAPP to anyone looking to trade crypto in Nigeria.",
  },
  {
    message:
      "I was new to trading crypto when I started using SEKIAPP, but their platform made it easy for me to get started. The user-friendly interface and helpful customer service team made it a breeze to store, sell, and buy digital assets. I am now a regular user of SEKIAPP, and I highly recommend it to anyone looking to trade crypto in Nigeria.",
  },
  {
    message:
      "SEKIAPP has been a lifesaver for me regarding trading crypto in Nigeria. The platform is fast, efficient, and user-friendly, making it easy for me to manage my digital assets. I also appreciate the security measures to protect my assets and the excellent customer service, team.",
  },
  {
    message:
      "I have been using SEKIAPP for a few months and am extremely satisfied with their service. The platform is easy to use, and the customer service team is always available to help with any issues I may have. I highly recommend SEKIAPP to anyone looking to trade crypto in Nigeria.",
  },
  {
    message:
      "I have been using SEKIAPP for a year, and I am extremely impressed with their service. The platform is user-friendly and efficient, and I have never had any issues with storing, selling or buying digital assets. The customer service team is also top-notch and always available to help with any issues.",
  },
  {
    message:
      "SEKIAPP has made trading crypto in Nigeria a breeze for me. The platform is fast, efficient, and user-friendly, and I have never had any issues with their service. I also appreciate the security measures to protect my assets and the excellent customer service, team. I highly recommend SEKIAPP to anyone looking to trade crypto in Nigeria.",
  },
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
