import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack } from "ionicons/icons";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";
import { updateBillSuccessful } from "../../../store/paybillsSlice";

import withAuth from "../../../hoc/withAuth/withAuth";

import useData from "../../../hooks/useData/useData";
import useAlert from "../../../hooks/useAlert/useAlert";

import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";

import SetPin from "../../../components/SetPin/SetPin";
import PinGroup from "../../../components/PinGroup/PinGroup";

import { assertNotNull, roundDP } from "../../../utils/func";

const BillConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const billData = useSelector((state: tRootState) => state.paybills.billData);

  assertNotNull(userDetails);

  const { fetchBillBeneficiaries, fetchProfile } = useData();

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert(true);

  const [pin, setPin] = useState("");

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Should never get here
    if (!billData) return;

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Enter your pin");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    const {
      service,
      serviceProvider,
      servicePackage,
      recipient,
      numberOfPins,
      pinValue,
      amount,
      newBeneficiary,
      alias,
    } = billData;

    const data: any = {
      service_provider_id: serviceProvider.id,
      pin,
      bill_type: service.service_type,
      hasPackage: service.has_package ? "1" : "0",
      beneficiary: newBeneficiary ? 1 : 0,
    };

    if (recipient) data.recipient = recipient;
    if (servicePackage) {
      data.package_id = servicePackage.id;
      data.package_code = servicePackage.aidapay_package_code;
    }
    if (amount && !service.has_package) {
      data.amount = amount;
    }
    if (newBeneficiary && alias) data.alias = alias;

    if (numberOfPins) data.numberOfPins = numberOfPins;
    if (pinValue) data.pinValue = pinValue;
    if (numberOfPins && pinValue) data.amount = numberOfPins * pinValue;

    api_client({
      method: "POST",
      url: "/pay-bills",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        dispatch(updateBillSuccessful());

        // Fetch Profile
        return fetchProfile();
      })
      .then(() => {
        // Fetch beneficiaries if new beneficiary added
        // Still disputing if this should be background or not
        return new Promise((resolve, _reject) => {
          if (newBeneficiary) {
            fetchBillBeneficiaries(service.service_type)
              .then(() => {})
              .catch(() => {})
              .finally(() => {
                resolve("Done");
              });
          } else {
            resolve("Done");
          }
        });
      })
      .then(() => {
        navigate("/pay-bills-success");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Purchase";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [pin, clearMessage]);

  if (!billData) return <Navigate to="/pay-bills" />;

  const {
    service,
    serviceProvider,
    servicePackage,
    recipient,
    recipientValidation,
    numberOfPins,
    pinValue,
    amount,
    newBeneficiary,
    alias,
  } = billData;

  const formComplete =
    serviceProvider &&
    (!service.has_package || servicePackage) &&
    (!service.has_recipient || recipient) &&
    (!service.has_recipient_validation || recipientValidation) &&
    (service.service_type !== "epin" || (numberOfPins && pinValue)) &&
    amount &&
    +amount <= userDetails.balance &&
    (!newBeneficiary || (alias && alias.length < 10));

  if (!formComplete)
    return (
      <Navigate to={`/pay-bills${service ? `/${service.service_type}` : ""}`} />
    );

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <div className="pay-bills-block">
        <div className="pay-bills-block__header-2">
          <h3 className="pay-bills-block__heading-2">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Confirmation
          </h3>
          <div className="pay-bills-block__subheading">
            Full Information about your Bill Payment
          </div>
        </div>
        <form className="pay-bills-block__form" onSubmit={handleSubmit}>
          <div className="pay-bills-block__infos">
            <div className="pay-bills-block__info">
              <p>Service Type</p>
              <p>{service.service_name}</p>
            </div>
            <div className="pay-bills-block__info">
              <p>Service Provider</p>
              <p>{serviceProvider.provider_name}</p>
            </div>
            {servicePackage ? (
              <div className="pay-bills-block__info">
                <p>Package</p>
                <p>{servicePackage.package_name}</p>
              </div>
            ) : null}
            {amount ? (
              <div className="pay-bills-block__info">
                <p>Amount</p>
                <p>&#8358;{roundDP(amount, 2)}</p>
              </div>
            ) : null}
            {recipient ? (
              <div className="pay-bills-block__info">
                <p>Recipient</p>
                <p>{recipient}</p>
              </div>
            ) : null}
            {recipientValidation ? (
              <div className="pay-bills-block__info">
                <p>Recipient Info</p>
                <p>{recipientValidation}</p>
              </div>
            ) : null}
            {numberOfPins ? (
              <div className="pay-bills-block__info">
                <p>Number of Pins</p>
                <p>{numberOfPins}</p>
              </div>
            ) : null}
            {pinValue ? (
              <div className="pay-bills-block__info">
                <p>Pin Value</p>
                <p>{pinValue}</p>
              </div>
            ) : null}
            <div className="pay-bills-block__info">
              <p>Processing Fee</p>
              <p>
                &#8358;{" "}
                {serviceProvider.fee_type === "fixed"
                  ? roundDP(serviceProvider.processing_fee, 2)
                  : roundDP((serviceProvider.processing_fee / 100) * amount, 2)}
              </p>
            </div>
          </div>
          {userDetails.is_pin_set ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">Enter PIN</h3>
              <div className="register__pin-input-group-2">
                <PinGroup numInputs={4} pin={pin} handler={setPin} />
              </div>
              <span
                className="crypto-final__forgot-pin"
                onClick={() => navigate("/change-pin")}
              >
                Forgot PIN
              </span>
            </div>
          ) : null}
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                (pin.length === 4 || !userDetails.is_pin_set) &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Purchase
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(BillConfirm);
