import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  tBanks,
  tBeneficiaries,
  tBillServices,
  tCountries,
  tOrderDetails,
  tServices,
  tVirtualCardThemeTypes,
  tVirtualCards,
  tVolumes,
  tWallet,
  tWallets,
} from "../../store/types/app.types";
import {
  tTransactionLimits,
  updateBillsBeneficiaries,
  updateBulkCryptoBanner,
  updateBuyGiftCards,
  updateCryptoTransferBeneficiaries,
  updateDashboardBanners,
  updateDashboardPopup,
  updateDepositAddBanks,
  updateDepositBanks,
  updateDepositFee,
  updateGiftCards,
  updateInfluencers,
  updateNairaTransferBeneficiaries,
  updateServices,
  updateTransactionLimit,
  updateVcardWallets,
  updateVirtualCard,
  updateVirtualCardSummary,
  updateVirtualCardThemes,
  updateVirtualCards,
  updateVolumes,
  updateWallets,
  updateWarnings,
  updateWebBulkCryptoBanner,
  updateWithdrawalBanks,
  updateWithdrawalBeneficiaries,
} from "../../store/cacheSlice";
import { updateUserDetails } from "../../store/userSlice";
import {
  updateBillServices,
  updateBillsBeneficiaries as updateBillsBeneficiaries2,
  updateServicePackages,
  updateServiceProviders,
} from "../../store/paybillsSlice";

import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";

// import { assertNotNull } from "../../utils/func";
import { getRandomBG } from "../../utils/func";

import nairaImg from "../../assets/img/naira.png";

// causing huge side effects
const useData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const {
    wallets: walletsCached,
    vcardWallets: vcardWalletsCached,
    giftCards: giftcardsCached,
    buyGiftCards: buyGiftcardsCached,
    virtualCards,
  } = useSelector((state: tRootState) => state.cache);

  // assertNotNull(); // Probably cleaner to do this but causing error in Login page

  const fetchWallets = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: "/wallets",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("Error fetching wallets");

          let hasValue = false;

          const wallets: tWallets = (res.data.message as tWallets).map(
            (wallet) => {
              if (wallet.balance !== 0) hasValue = true;

              return {
                ...wallet,
                type: "crypto",
              };
            }
          );

          const naira: tWallet = {
            id: "NGN",
            coin: "Naira",
            symbol: "ngn",
            balance: userDetails?.balance!,
            image: nairaImg,
            dollar_balance: userDetails?.total_naira_balance_in_dollar!,
            type: "fiat",
            capability: 2,
            has_multiple_network: 0,
            networks: [],
            minimum_value: 2000,
            current_price: "",
            price_change: "",
          };

          if (hasValue) {
            wallets.push(naira);
          }

          wallets.sort((wallet1, wallet2) => {
            return +wallet2.dollar_balance - +wallet1.dollar_balance;
          });

          if (!hasValue) {
            wallets.unshift(naira);
          }

          dispatch(updateWallets(wallets));

          //   if (cb) cb();

          resolve("Wallets fetched successfully");
        })
        .catch((err) => {
          if (!walletsCached?.length) return navigate("/error");
          resolve("Error fetching wallets");
        });
    });
  }, [
    accessToken,
    dispatch,
    userDetails?.balance,
    userDetails?.total_naira_balance_in_dollar,
    walletsCached?.length,
    navigate,
  ]);

  const fetchVcardWallets = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: "/wallets/all?category=vcard-funding",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("Error fetching wallets");

          const wallets: tWallets = res.data.message;

          wallets.sort((wallet1, wallet2) => {
            return +wallet2.dollar_balance - +wallet1.dollar_balance;
          });

          dispatch(updateVcardWallets(wallets));

          //   if (cb) cb();

          resolve("Wallets fetched successfully");
        })
        .catch((err) => {
          if (!vcardWalletsCached?.length) return navigate("/error");
          resolve("Error fetching wallets");
        });
    });
  }, [accessToken, dispatch, vcardWalletsCached?.length, navigate]);

  const fetchGiftcards = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/order/services/list",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateGiftCards(res.data.message));

          resolve("Gift cards fetched successfully");
        })
        .catch((err) => {
          if (!giftcardsCached?.length) navigate("/error");
        });
    });
  }, [accessToken, dispatch, giftcardsCached?.length, navigate]);

  const fetchBillServices = useCallback(
    (token?: string) => {
      return new Promise<tBillServices>((resolve, reject) => {
        api_client({
          method: "GET",
          url: "/service-types/web",
          headers: {
            Authorization: `Bearer ${token || accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(updateBillServices(res.data.message));

            resolve(res.data.message);
          })
          .catch((err) => {
            reject("Error fetching services");
          });
      });
    },
    [accessToken, dispatch]
  );

  const fetchBuyGiftcards = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/cards/list-cards",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateBuyGiftCards(res.data.message));

          resolve("Gift cards fetched successfully");
        })
        .catch((err) => {
          if (!buyGiftcardsCached?.length) navigate("/error");
        });
    });
  }, [accessToken, dispatch, buyGiftcardsCached?.length, navigate]);

  const fetchVirtualCards = useCallback(() => {
    return new Promise<tVirtualCards>((resolve, _reject) => {
      api_client({
        method: "GET",
        url: "/virtual/list-my-cards",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          const newVCards = res.data.message;

          dispatch(updateVirtualCards(newVCards));
          resolve(newVCards);
        })
        .catch((err) => {
          if (!virtualCards?.length) navigate("/error");
        });
    });
  }, [virtualCards?.length, dispatch, accessToken, navigate]);

  const fetchVirtualCard = useCallback(
    (virtualCardId: string) => {
      return new Promise((resolve, _reject) => {
        api_client({
          method: "GET",
          url: `/virtual/card/single/${virtualCardId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            const virtualCardDetails = res.data.message;

            dispatch(
              updateVirtualCard({
                virtualCardId: virtualCardId,
                virtualCardDetails,
              })
            );
            resolve("Virtualcard details fetched");
          })
          .catch((_err) => {
            resolve("Virtual card details fetched");
          });
      });
    },
    [accessToken, dispatch]
  );

  const fetchVirtualCardSummary = useCallback(
    (type: tVirtualCardThemeTypes) => {
      return new Promise((resolve, reject) => {
        api_client({
          method: "GET",
          url: `/virtual/create-card-summary/${type}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            const summary = res.data;

            dispatch(
              updateVirtualCardSummary({
                key: type,
                cardSummary: {
                  wallet: summary.wallet,
                  card_type: summary.card_type,
                  amount: summary.amount,
                  currency: summary.currency,
                  fiat_amount: summary.fiat_amount,
                  min_fund: summary.min_fund,
                  min_fund_text: summary.min_fund_text,
                },
              })
            );

            resolve("Virtual card themes fetched successfully");
          })
          .catch((err) => {
            navigate("/error");
          });
      });
    },
    [accessToken, dispatch, navigate]
  );

  const fetchVirtualCardThemes = useCallback(
    (type: tVirtualCardThemeTypes) => {
      return new Promise((resolve, reject) => {
        api_client({
          method: "GET",
          url: `/virtual/list-themes/${type}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(
              updateVirtualCardThemes({
                key: type,
                cardThemes: res.data.message,
              })
            );

            resolve("Virtual card themes fetched successfully");
          })
          .catch((err) => {
            resolve("Error fetching virtual card themes");
          });
      });
    },
    [accessToken, dispatch]
  );

  const fetchDashboardPopup = useCallback(
    (token: string) => {
      return new Promise((resolve, _reject) => {
        api_client({
          method: "GET",
          url: `/dashboard-popup/web`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(updateDashboardPopup(res.data.message));

            resolve("Dashboard popup fetched successfully");
          })
          .catch(() => {
            resolve("Error fetching dashboard popup");
          });
      });
    },
    [dispatch]
  );

  const fetchBanks = useCallback(
    (type: "withdraw" | "deposit" | "deposit-add") => {
      return new Promise((resolve, reject) => {
        api_client({
          url:
            type === "withdraw"
              ? "/allbanks"
              : type === "deposit"
              ? "list-banks"
              : "all-deposit-banks",
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            let banks: tBanks = [];

            if (type !== "deposit") {
              banks = res.data.message;

              banks.sort((a, b) => (a.bank_name < b.bank_name ? -1 : 1));
            }

            type === "withdraw"
              ? dispatch(updateWithdrawalBanks(banks))
              : type === "deposit"
              ? dispatch(
                  updateDepositBanks({
                    banks: res.data.bank_list,
                    min: res.data.min,
                    max: res.data.max,
                  })
                )
              : dispatch(updateDepositAddBanks(banks));

            resolve("Banks fetched successfully");
          })
          .catch((err) => {
            reject("Error fetching banks");
          });
      });
    },
    [dispatch, accessToken]
  );

  const fetchOrder = useCallback(
    (orderHash: string) => {
      return new Promise<tOrderDetails>((resolve, reject) => {
        api_client({
          url: `/order/${orderHash}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            resolve(res.data.message);
          })
          .catch(() => {
            reject("Error fetching order");
          });
      });
    },
    [accessToken]
  );

  const fetchCountries = useCallback(() => {
    return new Promise<tCountries>((resolve, reject) => {
      api_client({
        url: `/countries`,
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          resolve(res.data.message);
        })
        .catch(() => {
          reject("Error fetching countries");
        });
    });
  }, []);

  const fetchVolumes = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: `/get-otc-volumes`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          const volumes: tVolumes = res.data.message;

          const volumesSelectData: SelectBoxDataType = [];

          for (const volume of volumes) {
            volumesSelectData.push({
              key: volume.id.toString(),
              value: {
                text: volume.volume,
              },
            });
          }

          dispatch(updateVolumes({ volumes, volumesSelectData }));
        })
        .catch(() => {
          resolve("Error fetching volumes");
        });
    });
  }, [accessToken, dispatch]);

  const fetchServices = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: `/account-statement/list-services`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          const services: tServices = res.data.message;

          const servicesSelectData: SelectBoxDataType = [];

          for (const service of services) {
            servicesSelectData.push({
              key: service.id,
              value: {
                text: service.name,
              },
            });
          }

          dispatch(updateServices({ services, servicesSelectData }));
        })
        .catch(() => {
          resolve("Error fetching services");
        });
    });
  }, [accessToken, dispatch]);

  const fetchTransactionLimit = useCallback(
    (type: tTransactionLimits) => {
      return new Promise((resolve, reject) => {
        api_client({
          url: `/transactions-limits/${type}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            dispatch(updateTransactionLimit({ key: type, value: res.data }));
          })
          .catch((err) => {
            resolve("Error fetching transaction limit");
          });
      });
    },
    [dispatch, accessToken]
  );

  const fetchDepositFee = useCallback(
    (amount?: number) => {
      return new Promise<{ fee: number; text: string }>((resolve, reject) => {
        api_client({
          url: `/naira-deposit-fee${amount ? `?amount=${amount}` : ""}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (res.data.success) {
              const data = { fee: +res.data.message, text: res.data.text };

              if (!amount) dispatch(updateDepositFee(data));

              resolve(data);
            } else {
              throw new Error("");
            }
          })
          .catch((err) => {
            reject("Error fetching deposit fee");
          });
      });
    },
    [dispatch, accessToken]
  );

  // No unhandled error
  const fetchBeneficiaries = useCallback(
    (type: "bills" | "withdrawal" | "crypto-transfer" | "naira-transfer") => {
      return new Promise((resolve, reject) => {
        api_client({
          url:
            type === "withdrawal"
              ? "/withdrawals/beneficiaries"
              : type === "bills"
              ? "/bills/beneficiaries/list"
              : type === "crypto-transfer"
              ? "/internal-transfers/beneficiaries"
              : "internal-naira/beneficiaries",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(
              type === "withdrawal"
                ? updateWithdrawalBeneficiaries(
                    (res.data.message as tBeneficiaries).map((beneficiary) => ({
                      ...beneficiary,
                      background: getRandomBG(),
                    }))
                  )
                : type === "bills"
                ? updateBillsBeneficiaries(res.data.message)
                : type === "crypto-transfer"
                ? updateCryptoTransferBeneficiaries(
                    (res.data.message as tBeneficiaries).map((beneficiary) => ({
                      ...beneficiary,
                      walletRef: walletsCached?.find(
                        (sWallet) =>
                          sWallet.symbol.toLowerCase() ===
                          beneficiary.main.toLowerCase()
                      ),
                    }))
                  )
                : updateNairaTransferBeneficiaries(
                    (res.data.message as tBeneficiaries).map((beneficiary) => ({
                      ...beneficiary,
                      background: getRandomBG(),
                    }))
                  )
            );

            resolve("Beneficiaries fetched successfully");
          })
          .catch((err) => {
            resolve("Error fetching beneficiaries");
          });
      });
    },
    [dispatch, walletsCached, accessToken]
  );

  // No unhandled error
  const fetchBillBeneficiaries = useCallback(
    (serviceType: string) => {
      return new Promise((resolve, reject) => {
        api_client({
          url: `/bills/beneficiaries/list/new?type=${serviceType}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(
              updateBillsBeneficiaries2({
                key: serviceType,
                beneficiaries: res.data.message,
              })
            );

            resolve("Beneficiaries fetched successfully");
          })
          .catch((err) => {
            resolve("Error fetching beneficiaries");
          });
      });
    },
    [dispatch, accessToken]
  );

  const fetchServiceProviders = useCallback(
    (serviceType: string) => {
      return new Promise((resolve, reject) => {
        api_client({
          url: `/service-providers/${serviceType}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(
              updateServiceProviders({
                key: serviceType,
                providers: res.data.message,
              })
            );

            resolve("Service providers fetched successfully");
          })
          .catch((err) => {
            reject("Error fetching service providers");
          });
      });
    },
    [dispatch, accessToken]
  );

  const fetchServicePackages = useCallback(
    (serviceProviderId: string) => {
      return new Promise((resolve, reject) => {
        api_client({
          url: `/service-packages/${serviceProviderId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(
              updateServicePackages({
                key: serviceProviderId,
                packages: res.data.message,
              })
            );

            resolve("Service packages fetched successfully");
          })
          .catch((err) => {
            reject("Error fetching service packages");
          });
      });
    },
    [dispatch, accessToken]
  );

  const fetchWarnings = useCallback(
    (acToken?: string) => {
      return new Promise((resolve, reject) => {
        api_client({
          method: "GET",
          url: "/get-warnings",
          headers: {
            Authorization: `Bearer ${acToken || accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) throw new Error("");

            dispatch(updateWarnings(res.data.message));

            resolve("Warnings fetched successfully");
          })
          .catch((err) => {
            resolve("Error fetching warnings");
          });
      });
    },
    [accessToken, dispatch]
  );

  const fetchBulkCryptoBanner = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/bulk-crypto-banner",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateBulkCryptoBanner(res.data.message[0]));

          resolve("Bulk crypto banner fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching bulk crypto banner");
        });
    });
  }, [accessToken, dispatch]);

  const fetchWebBulkCryptoBanner = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/web/bulk-crypto-banner",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateWebBulkCryptoBanner(res.data.message[0]));

          resolve("Bulk crypto banner fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching bulk crypto banner");
        });
    });
  }, [accessToken, dispatch]);

  const fetchProfile = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/get-profile",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateUserDetails(res.data.message));

          resolve("Profile fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching user profile");
        });
    });
  }, [accessToken, dispatch]);

  const fetchInfluencers = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: "/influencer-list/top7",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateInfluencers(res.data.message));
        })
        .catch((err) => {
          resolve("Error fetching influencers");
        });
    });
  }, [accessToken, dispatch]);

  const fetchDashboardBanners = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: "/dashboard-banner-web",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          dispatch(updateDashboardBanners(res.data.message));
        })
        .catch((err) => {
          resolve("Error fetching dashboard banners");
        });
    });
  }, [accessToken, dispatch]);

  return {
    fetchWallets,
    fetchVcardWallets,
    fetchGiftcards,
    fetchWarnings,
    fetchBeneficiaries,
    fetchBillBeneficiaries,
    fetchBanks,
    fetchProfile,
    fetchOrder,
    fetchCountries,
    fetchInfluencers,
    fetchBulkCryptoBanner,
    fetchWebBulkCryptoBanner,
    fetchTransactionLimit,
    fetchDashboardBanners,
    fetchDepositFee,
    fetchVolumes,
    fetchBuyGiftcards,
    fetchServices,
    fetchVirtualCardSummary,
    fetchVirtualCardThemes,
    fetchVirtualCards,
    fetchBillServices,
    fetchServiceProviders,
    fetchServicePackages,
    fetchVirtualCard,
    fetchDashboardPopup,
  };
};

export default useData;
