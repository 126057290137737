import { useSelector } from "react-redux";
import { ReactNode } from "react";

import { tRootState } from "../../store";

import { roundDP } from "../../utils/func";

import DashboardLayout from "../DashboardLayout/DashboardLayout";

import coins from "../../assets/img/coins.png";

const PageLayout = ({ children }: { children: ReactNode }) => {
  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  )!;

  return (
    <>
      <DashboardLayout>
        <div className="pay-bills">
          <div className="pay-bills__main">{children}</div>
          <div className="pay-bills__right">
            <div className="pay-bills__wallet-block">
              <p className="pay-bills__wallet-label">Wallet Balance</p>
              <p className="pay-bills__wallet-balance">
                &#8358; {roundDP(userDetails.balance, 2)}
              </p>
            </div>
            <div className="pay-bills__right-info">
              <h3 className="pay-bills__right-info-heading">
                Store, Sell, Buy & Swap Crypto, Giftcards
              </h3>
              <p className="pay-bills__right-info-text">
                Get Discounted Price for your billing at every referral on
                SekiApp.
              </p>
              <div className="pay-bills__right-info-img-block">
                <img src={coins} alt="" />
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default PageLayout;
