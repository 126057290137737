import { useState } from "react";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline, close, searchOutline } from "ionicons/icons";

import { tBeneficiaries, tBeneficiary } from "../../store/types/app.types";

type tBeneficiariesModal = {
  show: boolean;
  image?: string;
  withdrawal?: boolean;
  transfer?: boolean;
  crypto_transfer?: boolean;
  tel?: boolean;
  model_num?: boolean;
  iuc_num?: boolean;
  bill?: boolean;
  isActive: (ben: tBeneficiary) => boolean;
  beneficiaries: tBeneficiaries | null;
  handler: (ben: tBeneficiary) => void;
  closeHandler: () => void;
};

const BeneficiariesModal = ({
  show,
  image,
  withdrawal,
  transfer,
  crypto_transfer,
  tel,
  model_num,
  iuc_num,
  bill,
  isActive,
  beneficiaries,
  handler,
  closeHandler,
}: tBeneficiariesModal) => {
  const [search, setSearch] = useState("");

  if (!beneficiaries || !show) return null;

  return (
    <>
      <div className="currency-modal">
        <div className="currency-modal__header">
          <span className="currency-modal__close" onClick={closeHandler}>
            <IonIcon icon={close} />
          </span>
          <h3 className="currency-modal__heading">Select Beneficiary</h3>
        </div>
        <form className="search-form">
          <div className="search-form__icon-block">
            <IonIcon icon={searchOutline} />
          </div>
          <input
            type="text"
            placeholder="Search beneficiary"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        <div className="currency-modal__list">
          {beneficiaries
            .filter(
              (ben) =>
                new RegExp(search, "i").test(ben.recipient) ||
                new RegExp(search, "i").test(ben.recipient_alias) ||
                (withdrawal && new RegExp(search, "i").test(ben.sub_main))
            )
            .map((beneficiary) => (
              <div
                className={cls(
                  "currency1",
                  isActive(beneficiary) && "currency1--active"
                )}
                key={beneficiary.id}
                onClick={() => handler(beneficiary)}
              >
                <div className="currency1__img-block">
                  {image ? (
                    <img src={image} alt="" />
                  ) : crypto_transfer ? (
                    <img src={beneficiary.walletRef?.image} alt="" />
                  ) : (
                    <div
                      className="currency1__img"
                      style={{ backgroundColor: beneficiary.background }}
                    >
                      {beneficiary.recipient_alias[0].toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="currency1__divider"></div>
                <div className="currency1__main">
                  <p className="currency1__abbr">
                    {beneficiary.recipient_alias}
                  </p>
                  <p className="currency1__name">
                    {transfer
                      ? "Username"
                      : tel
                      ? "Phone Number"
                      : model_num
                      ? "Model Number"
                      : iuc_num
                      ? "IUC Number"
                      : bill
                      ? "Recipient no"
                      : beneficiary.main.toUpperCase()}{" "}
                    - {beneficiary.recipient}
                  </p>
                  {withdrawal ? (
                    <p className="currency1__name">{beneficiary.sub_main}</p>
                  ) : null}
                </div>
                <span className="currency1__check">
                  <IonIcon icon={checkmarkOutline} />
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default BeneficiariesModal;
