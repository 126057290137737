import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  checkmarkCircle,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import sekiLogoDark from "../../assets/img/sekilogo-dark.png";
import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import PinGroup from "../../components/PinGroup/PinGroup";

const NewResetPassword = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [OTP, SetOTP] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordValidations, setPasswordValidations] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [hideBtn, setHideBtn] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password || !confirmPassword || !OTP)
      return setMessage("warning", "Fill in all fields");

    if (password !== confirmPassword)
      return setMessage("warning", "Passwords do not match");

    if (!passwordValidations.every((v) => v))
      return setMessage("warning", "Password does not pass all validations");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/password/reset-password",
      data: {
        code: OTP,
        password,
        new_password_confirmation: confirmPassword,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setMessage(
            "success",
            `${res.data.message}. <span class="fas fa-spinner fa-spin"></span> Redirecting to login...`
          );

          setHideBtn(true);

          const interval = window.setInterval(() => {
            navigate("/login");
            window.clearInterval(interval);
          }, 4000);
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Reset Password";
        }
      });
  };

  useEffect(() => {
    const newPasswordValidations = [false, false, false, false, false];

    newPasswordValidations[0] = password.length >= 8;

    newPasswordValidations[1] = password
      .split("")
      .some((c) => "abcdefghijklmnopqrstuvwxyz".split("").includes(c));

    newPasswordValidations[2] = password
      .split("")
      .some((c) =>
        "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("").includes(c)
      );

    newPasswordValidations[3] = password
      .split("")
      .some((c) => "1234567890".split("").includes(c));

    newPasswordValidations[4] = password
      .split("")
      .some((c) => "~`@#$%^&*()-_=+{}[];:'\",.<>/?|\\/".split("").includes(c));

    setPasswordValidations(newPasswordValidations);
  }, [password]);

  useEffect(() => {
    clearMessage();
  }, [OTP, password, confirmPassword, clearMessage]);

  return (
    <AuthLayout variation>
      <div className="auth auth--1" style={{ paddingBottom: "12.8rem" }}>
        <div className="auth__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <h2 className="auth__heading">Reset Password</h2>
        <p className="auth__heading-sub-text">
          Reset password OTP has been sent to your email address or phone
          number. To reset password, create a new password and input your OTP to
          complete the process.
        </p>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>New Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
            <div className="form-requirements">
              <div className={cls(passwordValidations[0] && "active")}>
                <IonIcon
                  icon={passwordValidations[0] ? checkmarkCircle : alertCircle}
                />
                At least 8 characters
              </div>
              <div className={cls(passwordValidations[1] && "active")}>
                <IonIcon
                  icon={passwordValidations[1] ? checkmarkCircle : alertCircle}
                />
                Lowercase letter (a-z)
              </div>
              <div className={cls(passwordValidations[2] && "active")}>
                <IonIcon
                  icon={passwordValidations[2] ? checkmarkCircle : alertCircle}
                />
                Uppercase letter (A-Z)
              </div>
              <div className={cls(passwordValidations[3] && "active")}>
                <IonIcon
                  icon={passwordValidations[3] ? checkmarkCircle : alertCircle}
                />
                Number (0-9)
              </div>
              <div className={cls(passwordValidations[4] && "active")}>
                <IonIcon
                  icon={passwordValidations[4] ? checkmarkCircle : alertCircle}
                />
                Special character (#,*)
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <div className="form-input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Re-type password"
                name="password"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword((sp) => !sp)}
              >
                <IonIcon
                  icon={showConfirmPassword ? eyeOffOutline : eyeOutline}
                />
              </button>
            </div>
            {password && confirmPassword && password !== confirmPassword ? (
              <label className="form-bottom-label text-warning">
                <IonIcon icon={alertCircle} />
                <p>Passwords do not match</p>
              </label>
            ) : null}
          </div>
          <div className="form-group mt-small">
            <div className="crypto-final__pin-section">
              <label className="form-label">OTP</label>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={6}
                  pin={OTP}
                  handler={SetOTP}
                />
              </div>
            </div>
          </div>
          <div className="auth__footer">
            {message}
            {!hideBtn ? (
              <button
                className={cls(
                  "form-button",
                  password &&
                    confirmPassword &&
                    password === confirmPassword &&
                    OTP &&
                    OTP.length === 6 &&
                    "form-button--active"
                )}
                type="submit"
                ref={submitBtnRef}
                style={{ width: "80%", alignSelf: "center" }}
              >
                Reset Password
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default NewResetPassword;
