import { FormEvent, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import useAlert from "../../hooks/useAlert/useAlert";

type tSendImageMessage = {
  images: File[];
  setImages: (imgs: File[]) => void;
  maxImages: number;
  addMoreImagesHandler?: () => void;
  url: string;
  id_key: string;
  id_value: string | number;
  images_key: string;
  finishHandler: () => void;
};

const SendImageMessage = ({
  images,
  setImages,
  maxImages,
  addMoreImagesHandler,
  url,
  id_key,
  id_value,
  images_key,
  finishHandler,
}: tSendImageMessage) => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [selectedImage, setSelectedImage] = useState(0);

  const [chatMessage, setChatMessage] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    const form_data = new FormData();

    form_data.append(id_key, id_value.toString());
    form_data.append("message", chatMessage);

    if (maxImages > 1) {
      images.forEach((image) => {
        form_data.append(`${images_key}[]`, image);
      });
    } else {
      form_data.append(images_key, images[0]);
    }

    api_client({
      method: "POST",
      url,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      data: form_data,
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error();
        }

        finishHandler();
        closeHandler();
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.innerHTML = "Submit";
          submitBtnRef.current.removeAttribute("disabled");
        }
      });
  };

  const removeImage = (index: number) => {
    if (selectedImage === index && selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    }

    setImages(images.filter((_, i) => i !== index));
  };

  const closeHandler = () => {
    setImages([]);
    setChatMessage("");
  };

  useEffect(() => {
    clearMessage();
  }, [chatMessage, images, clearMessage]);

  if (!images.length) return null;

  return (
    <>
      <div className="classic-modal classic-modal--file-prev">
        {images[selectedImage] ? (
          <img
            src={URL.createObjectURL(images[selectedImage])}
            className="classic-modal__img"
            alt=""
          />
        ) : null}
        {maxImages > 1 ? (
          <div className="classic-modal__previews">
            {images.map((image, i) => (
              <div
                className={cls(
                  "classic-modal__preview",
                  i === selectedImage && "classic-modal__preview--active"
                )}
                key={i}
              >
                <button
                  className="classic-modal__preview-action"
                  type="button"
                  onClick={() => removeImage(i)}
                >
                  <IonIcon icon={closeOutline} />
                </button>
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  onClick={() => setSelectedImage(i)}
                />
              </div>
            ))}
            {images.length < maxImages ? (
              <div
                className="classic-modal__preview"
                onClick={addMoreImagesHandler}
              >
                <IonIcon icon={addOutline} />
              </div>
            ) : null}
          </div>
        ) : null}
        <form className="chat-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Type message"
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
          />
          <button ref={submitBtnRef}>Send</button>
        </form>
        {message}
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default SendImageMessage;
