import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { chevronDown, receipt } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tTransactions } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import PageLayout from "../../layouts/PageLayout/PageLayout";

import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";

import TransactionsLoader from "../../loaders/TransactionsLoader/TransactionsLoader";
import SelectSortDates from "./SelectSortDates/SelectSortDates";

import { DAYS } from "../../data";

import { roundDP } from "../../utils/func";
import { Entries } from "../../utils/utils.types";
import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";
import { getPrecision } from "../../utils/app";

type tDateTransactions = {
  [date: string]: tTransactions;
};

const filterData = {
  all: "All",
  sell_orders: "Sell Orders",
  buy_orders: "Buy Orders",
  bills: "Bills",
  withdrawals: "Fiat Withdrawals",
  deposits: "Fiat Deposits",
  internal_naira: "Fiat Transfer",
  crypto_sent: "Crypto Withdrawals",
  crypto_received: "Crypto Deposits",
  crypto_swap: "Crypto Swap",
  internal_wallets: "Crypto Transfer",
};

const sortData = {
  daily: "Today",
  weekly: "This Week",
  monthly: "This Month",
  last_month: "Last Month",
  custom: "Custom",
};

const getBadge = (status: string): string => {
  if (status === "Refunded") return "info";

  return "success";
};

const Transactions = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [sortDates, setSortDates] = useState({ start: "", end: "" });
  const [showDateSort, setShowDateSort] = useState(false);

  const sortSelectBoxData: SelectBoxDataType = [
    {
      key: "daily",
      value: { text: "Today" },
    },
    {
      key: "weekly",
      value: { text: "This Week" },
    },
    {
      key: "monthly",
      value: { text: "This Month" },
    },
    {
      key: "last_month",
      value: { text: "Last Month" },
    },
    {
      key: "custom",
      value: {
        text: "Custom",
        clickHandler: () => {
          setSortDates({ start: "", end: "" });
          setShowDateSort(true);
        },
      },
    },
  ];

  const [filterSelectBox, filter, openFilterSelectBox] = useSelectBox<
    keyof typeof filterData
  >("Filter Transactions", getSelectBoxData(filterData), "all");
  const [sortSelectBox, sort, openSortSelectBox] = useSelectBox<
    keyof typeof sortData
  >("Sort Transactions", sortSelectBoxData, "daily");

  const [dateTransactions, setDateTransactions] = useState<tDateTransactions>(
    {}
  );

  useEffect(() => {
    if (sort === "custom" && (!sortDates.start || !sortDates.end)) return;

    setError(false);
    setLoading(true);

    api_client({
      method: "GET",
      url: `/transactions/history${filter ? `/${filter}` : ""}${
        sort
          ? sort === "custom"
            ? `/custom?start=${sortDates.start}&end=${sortDates.end}`
            : `/${sort}`
          : ""
      }`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        const formattedTransactions: tDateTransactions = {};

        (res.data.message as tTransactions).map((transaction) => {
          const [date, time] = transaction.date.split(" ");

          const calcDate = new Date(date);

          const totalDate = `${date}, ${DAYS[calcDate.getDay()].toUpperCase()}`;

          transaction.date = totalDate;
          transaction.time = time.slice(0, 5);

          formattedTransactions[totalDate] =
            formattedTransactions[totalDate] || [];
          formattedTransactions[totalDate].push(transaction);

          return transaction;
        });

        setDateTransactions(formattedTransactions);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, filter, sort, sortDates.start, sortDates.end]);

  useEffect(() => {
    if (sort === "custom") {
      setShowDateSort(true);
    }
  }, [sort]);

  return (
    <PageLayout>
      {filterSelectBox}
      {sortSelectBox}
      <SelectSortDates
        show={showDateSort}
        closeHandler={() => setShowDateSort(false)}
        handler={(startDate, endDate) => {
          setSortDates({ start: startDate, end: endDate });
          setShowDateSort(false);
        }}
      />
      <div className="transactions-pg__header">
        <h3 className="transactions-pg__heading">Transactions</h3>
        <div className="transactions-pg__filters">
          <div className="transactions-pg__filter">
            Filter:
            <div className="select-box" onClick={() => openFilterSelectBox()}>
              {filterData[filter!]} <IonIcon icon={chevronDown} />
            </div>
          </div>
          <div className="transactions-pg__filter">
            Sort:
            <div className="select-box" onClick={() => openSortSelectBox()}>
              {sortData[sort!]}
              {sort! === "custom" && sortDates.start && sortDates.end
                ? ` : ${sortDates.start} - ${sortDates.end}`
                : ""}{" "}
              <IonIcon icon={chevronDown} />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          className="button"
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.8rem",
          }}
          onClick={() => navigate("/account-statement")}
        >
          <IonIcon icon={receipt} /> Account Statement
        </button>
      </div>
      {loading ? <TransactionsLoader /> : null}
      {error ? (
        <div className="info-block">Error fetching transactions</div>
      ) : null}
      {!loading && !error && !Object.keys(dateTransactions).length ? (
        <div className="info-block">No transactions</div>
      ) : null}
      {!loading && !error && Object.keys(dateTransactions).length
        ? (
            Object.entries(dateTransactions) as Entries<typeof dateTransactions>
          ).map(([date, transactions]) => (
            <div className="wallet-transaction-block" key={date}>
              <div className="wallet-transaction-block__header" key={date}>
                <p>{date}</p>
                <div></div>
              </div>
              <div className="wallet-transaction-block__main">
                {transactions.map((transaction) => {
                  let clickHandler = () => {};

                  if (transaction.type === "withdrawal") {
                    clickHandler = () => {
                      navigate(`/withdrawal/${transaction.hash}`);
                    };
                  } else if (transaction.type === "deposit") {
                    clickHandler = () => {
                      navigate(`/deposit/${transaction.hash}`);
                    };
                  } else if (transaction.type === "internal_wallet") {
                    clickHandler = () => {
                      navigate(
                        `/transaction-details/internal_wallets/${transaction.hash}`
                      );
                    };
                  } else if (transaction.type === "internal_naira") {
                    clickHandler = () => {
                      navigate(
                        `/transaction-details/internal_naira/${transaction.id}`
                      );
                    };
                  } else if (
                    transaction.type === "buy_orders" ||
                    transaction.type === "sell_orders"
                  ) {
                    clickHandler = () => {
                      navigate(`/order/${transaction.hash}`);
                    };
                  } else if (transaction.type === "Wallet Sent") {
                    clickHandler = () => {
                      navigate(
                        `/transaction-details/crypto_sent/${transaction.hash}`
                      );
                    };
                  } else if (transaction.type === "Wallet Received") {
                    clickHandler = () => {
                      navigate(
                        `/transaction-details/crypto_received/${transaction.hash}`
                      );
                    };
                  } else if (transaction.type === "Wallet Swap") {
                    clickHandler = () => {
                      navigate(
                        `/transaction-details/crypto_swap/${transaction.hash}`
                      );
                    };
                  } else if (transaction.type === "bills") {
                    clickHandler = () => {
                      navigate(
                        `/transaction-details/bills/${transaction.hash}`
                      );
                    };
                  } else if (transaction.type === "virtual_cards") {
                    clickHandler = () =>
                      navigate(
                        `/transaction-details/virtual_cards/${transaction.id}`
                      );
                  }

                  return (
                    <div
                      className="transaction"
                      onClick={clickHandler}
                      key={transaction.id}
                    >
                      <div className="transaction__time">
                        {transaction.time}
                      </div>
                      <div className="transaction__main">
                        <div className="transaction__content">
                          <p className="transaction__text">
                            {transaction.content}
                          </p>
                          <p>#{transaction.hash_hidden}</p>
                        </div>
                        <div className="transaction__status-amount">
                          <span
                            className={`badge badge--${getBadge(
                              transaction.status
                            )}`}
                          >
                            {transaction.status}
                          </span>
                          {transaction.type === "Wallet Swap" ? (
                            <span
                              className={`text-${
                                transaction.status === "Refunded"
                                  ? "info"
                                  : +transaction.amount < 0
                                  ? "danger"
                                  : "success"
                              }`}
                            >
                              {+transaction.amount > 0 ? "+" : "-"}{" "}
                              {transaction.from}{" "}
                              {roundDP(
                                Math.abs(+transaction.amount_coin_swap),
                                getPrecision(transaction.from)
                              )}
                              <span className="text-success">
                                {" "}
                                (+ {transaction.to}{" "}
                                {roundDP(
                                  Math.abs(+transaction.amount_coin_get),
                                  getPrecision(transaction.to)
                                )}
                                )
                              </span>
                            </span>
                          ) : (
                            <span
                              className={`text-${
                                transaction.status === "Refunded"
                                  ? "info"
                                  : +transaction.amount < 0
                                  ? "danger"
                                  : "success"
                              }`}
                            >
                              {transaction.currency}{" "}
                              {Number(transaction.amount).toLocaleString()}{" "}
                              {transaction.type === "Wallet Swap" ? (
                                <span className="text-success">
                                  ({transaction.amount_get})
                                </span>
                              ) : null}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        : null}
    </PageLayout>
  );
};

export default withAuth(Transactions);
