import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

const AboutUs = () => {
  return (
    <HomeLayout>
      <div className="section">
        <div className="section__header">
          <div className="section__heading">About Us</div>
        </div>
        <div className="section__main section__content">
          <p>
            Welcome to SEKIAPP, a Nigerian exchange crypto company powered by
            freedom and speed. We are dedicated to providing YOU with a fast and
            secure platform to buy, sell, and store digital assets.
          </p>
          <p>
            At SEKIAPP, we believe that everyone has the right to financial
            freedom. That's why we offer our services for free, allowing you to
            trade and manage your digital assets without any fees or hidden
            costs. Our platform is designed to be user-friendly, so you can
            easily navigate and manage your portfolio.
          </p>
          <p>
            We understand that security is a top concern for our customers.
            That's why we have implemented advanced security measures to ensure
            the safety of your digital assets. We use top-of-the-line encryption
            technology and constantly monitor our systems to protect against
            potential threats.
          </p>
          <p>
            In addition to security, we prioritise speed. Our platform is built
            to handle high-volume trades so that you can buy and sell your
            digital assets quickly and efficiently. We also offer instant
            deposits and withdrawals, so you can easily access your funds when
            needed.
          </p>
          <p>
            At SEKIAPP, we are committed to providing our customers with the
            best possible experience. We are constantly innovating and improving
            our platform to ensure that you have the best tools and resources.
            If you have any questions or need assistance, our customer support
            team is always here to help.
          </p>
          <p>
            Thank you for choosing SEKIAPP. We are excited to be a part of your
            digital asset journey and look forward to helping you achieve
            financial freedom.
          </p>
          <div>
            <h3>Why Choose Us</h3>
            <p>
              Welcome to SEKIAPP, the Nigerian exchange crypto company powered
              by freedom and speed. If you are looking to store, sell, and buy
              digital assets in Nigeria, we are the perfect choice for you. Here
              are a few reasons why:
            </p>
            <ul>
              <li>
                Freedom: At SEKIAPP, we believe that everyone has the right to
                control their financial assets. That's why we offer a platform
                that allows you to buy, sell, and store digital assets without
                restrictions.
              </li>
              <li>
                Speed: We understand that time is money, so we have made our
                platform as fast as possible. You can complete transactions in
                just a few clicks without having to wait for long periods.
              </li>
              <li>
                Security: We take the security of your assets very seriously,
                which is why we have implemented advanced security measures to
                protect your digital assets. Our platform is built on the latest
                blockchain technology, which ensures that your assets are safe
                and secure at all times.
              </li>
              <li>
                Customer Support: We know that our users may have questions or
                need assistance at some point, which is why we offer 24/7
                customer support. Our team is always available to answer any
                questions you may have and help you with any issues you may
                encounter.
              </li>
              <li>
                Easy to Use: We have designed our platform to be user-friendly
                and easy to navigate, even for those new to the world of digital
                assets. With a few clicks, you can easily buy, sell, and store
                your assets on our platform.
              </li>
            </ul>
          </div>
          <p>
            In summary, SEKIAPP is the perfect choice for anyone looking to buy,
            sell, and store digital assets in Nigeria. With our commitment to
            freedom, speed, security, customer support, and ease of use, we are
            confident that you will have a positive experience on our platform.
            Join us today and take control of your financial assets.
          </p>
        </div>
      </div>
    </HomeLayout>
  );
};

export default AboutUs;
