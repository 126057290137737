import faNewspaper from "../../assets/img/fa-newspaper.png";
import faInstagram from "../../assets/img/fa-instagram.png";
import faLinkedin from "../../assets/img/fa-linkedin.png";
import faTwitter from "../../assets/img/fa-twitter.png";

const SocialsSection = () => {
  return (
    <section className="socials-section">
      <div className="container socials-section__container">
        <h3 className="heading-secondary mb-medium">Get in touch with us</h3>
        <div className="socials-section__socials">
          <a
            href="https://blog.sekiapp.com/"
            className="socials-section__social"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={faNewspaper}
              className="socials-section__social-img"
              alt=""
            />
            <h3 className="socials-section__social-heading">SekiApp Blog</h3>
            <p className="socials-section__social-description">
              Learn about latest crypto news and activities on sekiApp blog
              platform
            </p>
          </a>
          <a
            href="https://instagram.com/seki_app/"
            target="_blank"
            rel="noreferrer"
            className="socials-section__social"
          >
            <img
              src={faInstagram}
              className="socials-section__social-img"
              alt=""
            />
            <h3 className="socials-section__social-heading">Instagram</h3>
            <p className="socials-section__social-description">
              Get our latest update and post on daily activities and tips
            </p>
          </a>
          <a
            href="https://www.linkedin.com/company/sekiapp/"
            target="_blank"
            rel="noreferrer"
            className="socials-section__social"
          >
            <img
              src={faLinkedin}
              className="socials-section__social-img"
              alt=""
            />
            <h3 className="socials-section__social-heading">Linkedin</h3>
            <p className="socials-section__social-description">
              Get our latest update and post on daily activities and tips
            </p>
          </a>
          <a
            href="https://twitter.com/seki_app/"
            target="_blank"
            rel="noreferrer"
            className="socials-section__social"
          >
            <img
              src={faTwitter}
              className="socials-section__social-img"
              alt=""
            />
            <h3 className="socials-section__social-heading">Twitter</h3>
            <p className="socials-section__social-description">
              Get our latest update and post on daily activities and tips
            </p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default SocialsSection;
