import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, alertOutline, checkmarkOutline } from "ionicons/icons";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import MatiButton from "../../components/MatiButton/MatiButton";

import { assertNotNull } from "../../utils/func";

import emailImg from "../../assets/img/email.png";
import pin from "../../assets/img/pin.png";
import SetPin from "../../components/SetPin/SetPin";
import ContactVerification from "../../components/ContactVerification/ContactVerification";

const VerifyIdentity = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const verifyAccount = searchParams.get("verifyAccount");

  const { userDetails, hideVerifButton } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const { is_verified, verif_status, reason, is_pin_set, verify_with } =
    userDetails;

  const verifButtonRef = useRef<HTMLDivElement>(null);

  const [openSetPin, setOpenSetPin] = useState(false);

  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  useEffect(() => {
    if (!verifyAccount) return;

    setSearchParams((sp) => {
      sp.delete("verifyAccount");

      return sp;
    });

    const matiButton = verifButtonRef.current?.querySelector(
      "mati-button"
    )! as HTMLButtonElement;

    if (matiButton) matiButton.click();
  }, [verifyAccount, setSearchParams]);

  return (
    <>
      {openSetPin ? <SetPin closeHandler={() => setOpenSetPin(false)} /> : null}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />

      <div className="action-cards">
        <div
          className="action-card"
          onClick={() => {
            if (verify_with === "email")
              setContactVerificationCallback(() => () => {});
          }}
        >
          <img src={emailImg} alt="" className="{classes.ActionCardImg}" />
          <div className="action-card__info">
            <p className="action-card__heading">Verify Email</p>
            <p className="action-card__text">
              {verify_with === "email"
                ? "Add and verify your email address"
                : "You have successfully verified your email address"}
            </p>
          </div>
          <button
            className={cls(
              "action-card__btn",
              verify_with === "email" && "action-card__btn--warning"
            )}
          >
            <IonIcon
              icon={verify_with !== "email" ? checkmarkOutline : alertOutline}
            />
          </button>
        </div>
        <div
          className="action-card"
          onClick={() => {
            if (verify_with === "phone")
              setContactVerificationCallback(() => () => {});
          }}
        >
          <img src={emailImg} alt="" className="{classes.ActionCardImg}" />
          <div className="action-card__info">
            <p className="action-card__heading">Verify Phone Number</p>
            <p className="action-card__text">
              {verify_with === "phone"
                ? "Add and verify your phone number"
                : "You have successfully verified your phone number"}
            </p>
          </div>
          <button
            className={cls(
              "action-card__btn",
              verify_with === "phone" && "action-card__btn--warning"
            )}
          >
            <IonIcon
              icon={verify_with !== "phone" ? checkmarkOutline : alertOutline}
            />
          </button>
        </div>
        <div
          className="action-card"
          onClick={() => {
            if (!is_pin_set) setOpenSetPin(true);
          }}
        >
          <img src={pin} alt="" className="{classes.ActionCardImg}" />
          <div className="action-card__info">
            <p className="action-card__heading">Create PIN</p>
            <p className="action-card__text">
              Your PIN is used to authorise transactions you make on your
              account.
            </p>
          </div>
          <button
            className={cls(
              "action-card__btn",
              !is_pin_set && "action-card__btn--warning"
            )}
          >
            <IonIcon icon={is_pin_set ? checkmarkOutline : alertOutline} />
          </button>
        </div>
      </div>
      <div className="account-alert">
        <IonIcon icon={alertCircle} />
        <p>Please add the required info to complete your account set up</p>
      </div>
      <div className="account-tiers">
        <div
          className={cls(
            "account-tier account-tier--active",
            is_verified && "account-tier--completed"
          )}
        >
          <div className="account-tier__left">
            <button className="account-tier__btn"></button>
            <span className="account-tier__line"></span>
          </div>
          <div className="account-tier__main">
            <h3 className="account-tier__title">User verification</h3>

            {is_verified === 0 && verif_status === "pending" ? (
              <p>
                Chief, your verification request has been sent. Check back in
                the next few minutes to get the status of your verification.
              </p>
            ) : null}

            {is_verified === 0 && verif_status === "rejected" ? (
              <p>
                <b>Last verification failed:</b> <i>{reason}</i>
              </p>
            ) : null}

            <button
              className={[
                "badge badge--btn",
                is_verified
                  ? "badge--success"
                  : verif_status === "pending"
                  ? "badge--warning"
                  : "badge--danger",
              ].join(" ")}
            >
              Status:{" "}
              {is_verified
                ? "Verified"
                : verif_status === "pending"
                ? "Pending Verification"
                : "Unverified"}
            </button>

            {!is_verified && verif_status !== "pending" && !hideVerifButton ? (
              <div ref={verifButtonRef}>
                <MatiButton />
              </div>
            ) : null}

            {verif_status !== "pending" && hideVerifButton ? (
              <div className="mt-8">
                <span className="fas fa-spinner fa-spin"></span> Finishing Up...
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(VerifyIdentity);
