const RecentTransactionsLoader = () => {
  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Transaction</th>
              <th>Order Hash</th>
              <th>Payment Details</th>
              <th>Amount</th>
              <th className="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {new Array(10).fill(null).map((_, i) => (
              <tr key={i}>
                <td>
                  <div className="transaction-label">
                    <div className="transaction-label__icon-box placeholder"></div>
                    <p className="bold color-transparent placeholder">
                      Naira Transfer
                    </p>
                  </div>
                </td>
                <td>
                  <span className="color-transparent placeholder">
                    #qM4Z*****iHF
                  </span>
                </td>
                <td className="bold">
                  <span className="color-transparent placeholder">
                    Received &#8358;2,000 from aiyeboy
                  </span>
                </td>
                <td className="text-success">
                  <span className="color-transparent placeholder">
                    + &#8358;2,000.00
                  </span>
                </td>
                <td>
                  <div className="transaction-date">
                    <p>
                      <span className="color-transparent placeholder">
                        Jun, 22
                      </span>
                    </p>
                    <p>
                      <span className="color-transparent placeholder">
                        12:00:39
                      </span>
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="transactions-flex">
        {new Array(10).fill(null).map((_, i) => (
          <div className="transaction-mobile" key={i}>
            <div className="transaction-label">
              <div className="transaction-label__icon-box placeholder"></div>
            </div>
            <div className="transaction-mobile__main">
              <p className="transaction-mobile__type">
                <span className="color-transparent placeholder">
                  Naira Transfer
                </span>
              </p>
              <p className="transaction-mobile__text">
                <span className="color-transparent placeholder">
                  Sent &#8358;6,400 to dessy
                </span>
              </p>
              <p className="transaction-mobile__hash">
                <span className="color-transparent placeholder">
                  #JJHAII****jJKAJSKAS
                </span>
              </p>
            </div>
            <div className="transaction-mobile__right">
              <p className="transaction-mobile__amount text-success">
                <span className="color-transparent placeholder">
                  + &#8358;2,000.00
                </span>
              </p>
              <p className="transaction-mobile__date">
                <span className="color-transparent placeholder">
                  Jun 22, 2023
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RecentTransactionsLoader;
