import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

const AMLPolicy = () => {
  return (
    <HomeLayout>
      <div className="section">
        <div className="section__header">
          <div className="section__heading">ANTI MONEY LAUNDERING POLICY</div>
        </div>
        <div className="section__main section__content">
          <div>
            <h3>ANTI MONEY LAUNDERING POLICY.</h3>
            <p>
              Money laundering is the attempt to conceal or disguise the nature,
              location, source, ownership or control of illegally obtained
              money. Money laundering is most commonly associated with tax
              avoidance, participating in a criminal group or racketeering,
              currency counterfeiting, forgery bribery.
            </p>
            <p>
              However, individuals may attempt to launder money in order to
              conceal their identity or finance their operations. ‘Suspicious
              activity or transactions’ can not be defined as it can vary on a
              transaction basis it can however be described to include;
              transactions which are structured to avoid reporting and record
              keeping requirements; altered or false identification or
              inconsistent information or any transaction involving criminal
              activity in the Company’s view; entity that belongs to a person or
              organization considered as a terrorist; nature of transaction and
              frequency of deposits/withdrawals.
            </p>
            <p>
              SekiApp is committed to fighting money laundering and complying
              fully with Anti-Money Laundering laws in Nigeria. We understand
              that we have responsibilities to help fight the global battle
              against money laundering and our commitment will supersede all
              other privacy obligations contained in our policies. Accordingly,
              SekiApp will take all reasonable and appropriate steps to prevent
              persons engaged in money laundering, fraud, or other financial
              crimes from utilizing our products and services. Our AML policies,
              procedures and internal controls are designed to ensure compliance
              with all applicable BSA regulations and FINRA rules and will be
              reviewed and updated on a regular basis to ensure appropriate
              policies, procedures and internal controls are in place to account
              for both changes in regulations and changes in our business. Key
              components of our AML and CTF framework include the following:
            </p>
          </div>
          <div>
            <h3>(i) COMPLIANCE OFFICER</h3>
            <p>
              Coordinating and monitoring day-to-day compliance with the
              relevant legislation, regulations, rules and industry guidance and
              applicable money laundering laws and regulations Monitoring
              transactions to detect unusual suspicious activities Prompt
              preparation and delivery of all relevant returns to the regulatory
              bodies in line with SEC and FIU Rules and Regulations
              Communicating AML/CFT issues to all stakeholders
            </p>
          </div>
          <div>
            <h3>(ii) RISK COMMITTEE</h3>
            <p>
              The appointment of an independent risk committee which reports to
              our board of directors regularly on all risk and compliance
              matters using a risk-based approach to the assessment and
              management of money laundering and terrorist financing risks and
              Comply with the requirements of the Money Laundering (Prohibition)
              Act, 2011 (as amended), Terrorism (Prevention) Act, 2011 (as
              amended) and Terrorism Prevention (Freezing of International
              Terrorists Funds and other Related Measures) Regulations 2013,
              including related laws and Regulations
            </p>
          </div>
          <div>
            <h3>(iii) INTERNAL CONTROL</h3>
            <p>
              Formulate and implement internal controls and other procedures
              that will deter criminals from using its facilities for money
              laundering and terrorist financing and to ensure that its
              obligations under subsisting laws and Regulations are met
            </p>
          </div>
          <div>
            <h3>(iv) KNOW YOUR CUSTOMER</h3>
            <p>
              Establishing and maintaining a risk-based approach to Customer Due
              Diligence (CDD), including customer identification, verification
              and KYC procedures. To ensure we meet these standards, our
              customers are required to provide certain personal details when
              opening an account with SekiApp and also generate tokens, OTP and
              PIN for any withdrawal request. The nature, and extent, of what is
              required is guided by the customer’s deposit and withdrawal
              limits, account to be used and in some cases, the customer’s
              country of residence. In certain circumstances, SekiApp may
              perform enhanced due diligence procedures for customers presenting
              a higher risk, such as those transacting large volumes etc.
              Maintaining appropriate KYC records for the minimum prescribed
              periods;
            </p>
            <p>
              SekiApp shall ensure timely and accurate rendition of all AML/CFT
              returns as specified in the SEC AML/CFT Rules and Regulations as
              well as other relevant Regulations/Act/Guidelines/Circulars that
              may be issued from time to time by various government agencies. We
              shall exercise due diligence in identifying and reporting a
              suspicious transaction.
            </p>
            <p>
              SekiApp shall also create awareness amongst its employees on
              AML/CTF through a robust training program on the framework.
            </p>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default AMLPolicy;
