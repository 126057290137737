import { IonIcon } from "@ionic/react";
import { tDepositBank } from "../../../store/types/app.types";
import { alertCircle } from "ionicons/icons";

const DepositWarningModal = ({
  bank,
  show,
  closeHandler,
}: {
  bank: tDepositBank;
  show: boolean;
  closeHandler: () => void;
}) => {
  if (!show) return null;

  return (
    <>
      <div className="deposit-naira-modal">
        <div className="deposit-naira-modal__heading">
          <IonIcon icon={alertCircle} />
          Warning
        </div>
        <p>
          Hi, kindly make sure you are paying the P2P customer with the
          following details.
        </p>
        <div>
          <p>
            <strong>Bank:</strong> {bank.bank_name}
          </p>
          <p>
            <strong>Account name:</strong> {bank.bank_account_name}
          </p>
          <p>
            <strong>Account numbers:</strong> {bank.bank_account_no}
          </p>
        </div>
        <p>
          Failure to do this your Payment will not be acknowledged and it will
          be tagged a fraud payment. <br />
          Thank you
        </p>
        <div className="text-right">
          <button className="button" onClick={closeHandler}>
            I agree
          </button>
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default DepositWarningModal;
