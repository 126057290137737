import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { clipboardOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tOrderDetails } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import { assertNotNull, isNumber } from "../../utils/func";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import PinGroup from "../../components/PinGroup/PinGroup";

const BuyGiftCardOTP = () => {
  const { hash } = useParams();

  const navigate = useNavigate();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  const [order, setOrder] = useState<tOrderDetails | null>(null);

  assertNotNull(userDetails);

  const { fetchOrder, fetchProfile } = useData();

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [OTP, setOTP] = useState("");
  const [gAuthOTP, setGAuthOTP] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const resendOTP = (e: MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Resending OTP`;

    const interval = setInterval(() => {
      setMessage("success", "Email OTP has been resent successfully");
      target.innerHTML = "Resend OTP";
      clearInterval(interval);
    }, 3000);

    // api_client({
    //   method: "POST",
    //   url: "/wallet/internal-naira-transfer/resend-otp",
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   data: JSON.stringify({ hash: transaction.hash }),
    // })
    //   .then((res) => {
    //     if (res.data.success) {
    //       setMessage("success", res.data.message);
    //     } else {
    //       setMessage("warning", res.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     setMessage("error", err.message);
    //   })
    //   .finally(() => {
    //     target.innerHTML = "Resend OTP";
    //   });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Should never reach here
    if (!order) return;

    if ([0, 2].includes(userDetails.withauth) && OTP.length !== 4)
      return setMessage("warning", "Enter OTP sent to your email");

    if ([1, 2].includes(userDetails.withauth) && gAuthOTP.length !== 6)
      return setMessage("warning", "Enter OTP in your google authentication");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/cards/purchase-card-otp",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        order_hash: order.order_hash,
        code: OTP,
        twoFACode: gAuthOTP,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return fetchProfile();
      })
      .then((res) => {
        navigate(`/order/${order.order_hash}`);
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled");
        submitBtnRef.current.innerHTML = "Finish";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [OTP, gAuthOTP, clearMessage]);

  // Fetch Order
  useEffect(() => {
    if (!hash) return;

    fetchOrder(hash)
      .then((order) => {
        if (order.type !== "buy" || order.service_type !== "Gift Card")
          throw new Error("");

        if (order.otp_completed) navigate(`/order/${order.order_hash}`);

        setOrder(order);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [hash, fetchOrder, navigate]);

  if (!order) return <Preloader />;

  return (
    <DashboardLayout>
      <ul className="breadcrumb">
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            Gift Cards
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            {order.service.name}
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/buy-gift-cards/country" className="breadcrumb__link">
            {order.card_country.country_name}
          </Link>
        </li>
        <li className="breadcrumb__item breadcrumb__item--active">
          <Link to="#" className="breadcrumb__link">
            OTP
          </Link>
        </li>
      </ul>
      <div className="giftcards-pg__header">
        <div className="giftcards-pg__header-main">
          <h3 className="giftcards-pg__heading">Create Gift Card Order</h3>
          <div className="step-radio-btns">
            <input
              type="radio"
              className="radio"
              name="step"
              value="1"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="2"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="3"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="4"
              checked
              readOnly
            />
          </div>
        </div>
      </div>
      <form className="giftcard-block" onSubmit={handleSubmit}>
        {userDetails.withauth === 0 ? (
          <div className="crypto-final__pin-section">
            <h3 className="crypto-final__heading">Email OTP</h3>
            <div className="crypto-final__heading-subtext">
              Enter the email sent to your Email address
            </div>
            <div
              className="copy copy--bold"
              onClick={() => {
                navigator.clipboard.readText().then((clipText) => {
                  const text = clipText.slice(0, 4);
                  if (isNumber(text)) setOTP(text);
                });
              }}
            >
              <IonIcon icon={clipboardOutline} />
              Paste from clipboard
            </div>
            <div className="register__pin-input-group-2">
              <PinGroup type="text" numInputs={4} pin={OTP} handler={setOTP} />
            </div>
            <span className="crypto-final__forgot-pin" onClick={resendOTP}>
              Resend Code
            </span>
          </div>
        ) : null}
        {userDetails.withauth === 1 ? (
          <div className="crypto-final__pin-section">
            <h3 className="crypto-final__heading">
              Google authentication code
            </h3>
            <div className="crypto-final__heading-subtext">
              Input your Google authentication code to complete the transaction
            </div>
            <div
              className="copy copy--bold"
              onClick={() => {
                navigator.clipboard.readText().then((clipText) => {
                  const text = clipText.slice(0, 6);
                  if (isNumber(text)) setGAuthOTP(text);
                });
              }}
            >
              <IonIcon icon={clipboardOutline} />
              Paste from clipboard
            </div>
            <div className="register__pin-input-group-2">
              <PinGroup
                type="text"
                numInputs={6}
                pin={gAuthOTP}
                handler={setGAuthOTP}
              />
            </div>
          </div>
        ) : null}
        {userDetails.withauth === 2 ? (
          <div className="crypto-final__pin-sections crypto-final__pin-sections--1">
            <div className="crypto-final__pin-section">
              <div className="crypto-final__heading-subtext">
                Kindly input the OTP sent to your email and your Google
                authentication code to complete the transaction
              </div>
            </div>
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">Email OTP</h3>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 4);
                    if (isNumber(text)) setOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={4}
                  pin={OTP}
                  handler={setOTP}
                />
              </div>
              <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                Resend Code
              </span>
            </div>
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">
                Google authentication code
              </h3>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 6);
                    if (isNumber(text)) setGAuthOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={6}
                  pin={gAuthOTP}
                  handler={setGAuthOTP}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="auth__footer">
          {message}
          <button
            className={cls(
              "form-button",
              ((userDetails.withauth === 0 && OTP.length === 4) ||
                (userDetails.withauth === 1 && gAuthOTP.length === 6) ||
                (userDetails.withauth === 2 &&
                  OTP.length === 4 &&
                  gAuthOTP.length === 6)) &&
                "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Finish
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(BuyGiftCardOTP);
