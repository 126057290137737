import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import { tBills2Transaction } from "../../../store/types/app.types";

import { copyData, getDate, roundDP } from "../../../utils/func";

const BillTransaction = ({
  transaction,
}: {
  transaction: tBills2Transaction;
}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  return (
    <div className="transaction-details">
      <h3 className="transaction-details__heading">
        <span onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        Bill Payment Details
      </h3>
      <p className="transaction-details__heading-subtext">
        Bill Payment Transaction Details
      </p>
      <div className="transaction-details__infos">
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Service Type</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.service_type}
            </p>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount</p>
          <div className="transaction-details__info-right">
            &#8358;{roundDP(transaction.amount_to_pay, 2)}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Recipient</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.recipient}
            </p>
          </div>
        </div>
        {transaction.token ? (
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Token</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {transaction.token}
              </p>
            </div>
          </div>
        ) : null}
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Hash</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.aidapay_hash}
            </p>
            <div
              className="copy"
              onClick={() => copyData(transaction.aidapay_hash, setCopied)}
            >
              <IonIcon icon={copy} />
              {copied ? "Copied!" : "Copy"}
            </div>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Status</p>
          <div className="transaction-details__info-right">
            {+transaction.status === 0 ? (
              <span className="badge badge--warning">Processing</span>
            ) : null}
            {+transaction.status === 1 ? (
              <span className="badge badge--success">Completed</span>
            ) : null}
            {+transaction.status === -1 ||
            transaction.status.toString() === "Refund" ? (
              <span className="badge badge--info">Refunded</span>
            ) : null}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Date</p>
          <div className="transaction-details__info-right">
            {getDate(transaction.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillTransaction;
