import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { getOSUIMode, set_mode } from "../utils/app";

import { tFAQs, tMarket, tNews } from "./types/app.types";

export type tUIMode = "light" | "dark";

type AppType = {
  uiMode: tUIMode;
  influencerImg: null | string;
  market: null | tMarket;
  news: null | tNews;
  faqs: null | tFAQs;
  codeSend: { [key: string]: string };
};

const AppInitialState: AppType = {
  uiMode: (localStorage.getItem("uiMode") as tUIMode | null) || getOSUIMode(),
  influencerImg: null,
  market: null,
  news: null,
  faqs: null,
  codeSend: {},
};

const saveAppState = (currentState: any) => {
  const originalData = { ...currentState };
  delete (originalData as any)["uiMode"];

  saveState("app", originalData);
};

const appSlice = createSlice({
  name: "app",
  initialState: getState<AppType>("app", AppInitialState),
  reducers: {
    setMode(state, { payload }: PayloadAction<tUIMode>) {
      state.uiMode = payload;

      saveAppState(current(state));
    },
    changeMode(state, { payload }: PayloadAction<tUIMode>) {
      state.uiMode = payload;

      set_mode(state.uiMode);

      saveAppState(current(state));
    },
    updateFaqs(state, { payload }: PayloadAction<tFAQs>) {
      state.faqs = payload;

      saveAppState(current(state));
    },
    updateNews(state, { payload }: PayloadAction<tNews>) {
      state.news = payload;

      saveAppState(current(state));
    },
    updateMarket(state, { payload }: PayloadAction<tMarket>) {
      state.market = payload;

      saveAppState(current(state));
    },
    updateInfluencerImg(state, { payload }: PayloadAction<string>) {
      state.influencerImg = payload;

      saveAppState(current(state));
    },
    updateCodeSend(
      state,
      { payload }: PayloadAction<{ key: string; value: string }>
    ) {
      state.codeSend[payload.key] = payload.value;

      saveAppState(current(state));
    },
  },
});

export const {
  setMode,
  changeMode,
  updateFaqs,
  updateNews,
  updateMarket,
  updateInfluencerImg,
  updateCodeSend,
} = appSlice.actions;

export default appSlice.reducer;
