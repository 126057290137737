import { useRef, useState, useEffect, FormEvent, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack, clipboardOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { clearCryptoData } from "../../store/cryptoSlice";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";
import { getPrecision } from "../../utils/app";

const WithdrawCryptoFinal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const {
    message: otpMessage,
    wallet,
    transaction,
  } = useSelector((state: tRootState) => state.crypto.withdraw);

  assertNotNull(userDetails);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [OTP, setOTP] = useState("");
  const [gAuthOTP, setGAuthOTP] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const { fetchProfile } = useData();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Should never reach here
    if (!transaction || !wallet) return;

    if ([0, 2].includes(userDetails.withauth) && OTP.length !== 4)
      return setMessage("warning", "Enter OTP sent to your email");

    if ([1, 2].includes(userDetails.withauth) && gAuthOTP.length !== 6)
      return setMessage("warning", "Enter OTP in your google authentication");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/wallet/transfer/send-otp",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        hash: transaction.hash,
        code: OTP,
        twoFACode: gAuthOTP,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        setMessage(
          "success",
          "Crypto withdrawal successful. You would be redirected shortly..."
        );

        return fetchProfile();
      })
      .then((res) => {
        navigate("/wallet");
        const interval: number = window.setInterval(() => {
          dispatch(clearCryptoData("withdraw"));
          window.clearInterval(interval);
        }, 3000);
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = `Withdraw Now`;
        }
      });
  };

  const resendOTP = (e: MouseEvent<HTMLSpanElement>) => {
    // Should never reach here
    if (!transaction) return;

    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Resending`;

    api_client({
      method: "POST",
      url: "/wallet/transfer/resend-otp",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ hash: transaction.hash }),
    })
      .then((res) => {
        if (res.data.success) {
          setMessage("success", res.data.message);
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        setMessage("error", err.message);
      })
      .finally(() => {
        target.innerHTML = "Resend Code";
      });
  };

  const cancelPendingTransfer = (e: MouseEvent<HTMLSpanElement>) => {
    // Should never reach here
    if (!transaction || !wallet) return;

    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Canceling...`;

    api_client({
      url: `/crypto/cancel-transfer?hash=${transaction.hash}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          navigate(`/withdraw-crypto?wallet=${wallet.symbol}`);
        } else {
          alert("An error occured. Try again");
        }
      })
      .finally(() => {
        target.innerHTML = "Cancel";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [OTP, gAuthOTP, clearMessage]);

  if (!otpMessage || !wallet || !transaction)
    return <Navigate to="/withdraw-crypto" />;

  return (
    <DashboardLayout>
      <div className="crypto-final">
        <div className="crypto-final__header">
          <div className="crypto-final__header-header">
            <div className="crypto-final__back" onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </div>
            <span
              className="crypto-final__cancel"
              onClick={cancelPendingTransfer}
            >
              Cancel
            </span>
          </div>
          <p className="crypto-final__label">
            I WANT TO WITHDRAW {wallet.coin.toUpperCase()} TO CRYPTO ADDRESS
          </p>
          <p className="crypto-final__address">{transaction.to_address}</p>
          <p className="crypto-final__amount-block">
            Amount{" "}
            <span>
              {roundDP(transaction.amount, getPrecision(wallet.symbol))}{" "}
              {wallet.symbol} ($
              {transaction.amount_dollar})
            </span>
          </p>
          <p className="crypto-final__amount-block">
            Fees{" "}
            <span>
              {roundDP(transaction.fees, getPrecision(wallet.symbol))}{" "}
              {wallet.symbol} ($
              {transaction.fees_dollar})
            </span>
          </p>
        </div>
        <form className="crypto-final__body" onSubmit={handleSubmit}>
          {userDetails.withauth === 0 ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">Email OTP</h3>
              <div className="crypto-final__heading-subtext">{otpMessage}</div>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 4);
                    if (isNumber(text)) setOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={4}
                  pin={OTP}
                  handler={setOTP}
                />
              </div>
              <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                Resend Code
              </span>
            </div>
          ) : null}
          {userDetails.withauth === 1 ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">
                Google authentication code
              </h3>
              <div className="crypto-final__heading-subtext">
                Input your Google authentication code to complete the
                transaction
              </div>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 6);
                    if (isNumber(text)) setGAuthOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={6}
                  pin={gAuthOTP}
                  handler={setGAuthOTP}
                />
              </div>
            </div>
          ) : null}
          {userDetails.withauth === 2 ? (
            <div className="crypto-final__pin-sections crypto-final__pin-sections--1">
              <div className="crypto-final__pin-section">
                <div className="crypto-final__heading-subtext">
                  Kindly input the OTP sent to your email and your Google
                  authentication code to complete the transaction
                </div>
              </div>
              <div className="crypto-final__pin-section">
                <h3 className="crypto-final__heading">Email OTP</h3>
                <div
                  className="copy copy--bold"
                  onClick={() => {
                    navigator.clipboard.readText().then((clipText) => {
                      const text = clipText.slice(0, 4);
                      if (isNumber(text)) setOTP(text);
                    });
                  }}
                >
                  <IonIcon icon={clipboardOutline} />
                  Paste from clipboard
                </div>
                <div className="register__pin-input-group-2">
                  <PinGroup
                    type="text"
                    numInputs={4}
                    pin={OTP}
                    handler={setOTP}
                  />
                </div>
                <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                  Resend Code
                </span>
              </div>
              <div className="crypto-final__pin-section">
                <h3 className="crypto-final__heading">
                  Google authentication code
                </h3>
                <div
                  className="copy copy--bold"
                  onClick={() => {
                    navigator.clipboard.readText().then((clipText) => {
                      const text = clipText.slice(0, 6);
                      if (isNumber(text)) setGAuthOTP(text);
                    });
                  }}
                >
                  <IonIcon icon={clipboardOutline} />
                  Paste from clipboard
                </div>
                <div className="register__pin-input-group-2">
                  <PinGroup
                    type="text"
                    numInputs={6}
                    pin={gAuthOTP}
                    handler={setGAuthOTP}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                ((userDetails.withauth === 0 && OTP.length === 4) ||
                  (userDetails.withauth === 1 && gAuthOTP.length === 6) ||
                  (userDetails.withauth === 2 &&
                    OTP.length === 4 &&
                    gAuthOTP.length === 6)) &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Withdraw now
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default WithdrawCryptoFinal;
