import { useState, ReactNode } from "react";

import SelectBox, {
  SelectBoxDataType,
} from "../../components/SelectBox/SelectBox";
import { Entries } from "../../utils/utils.types";

export type tSelectBoxGeneric = {
  [key: string]: string;
};

export const getSelectBoxData = (
  data: tSelectBoxGeneric | null
): SelectBoxDataType | null => {
  if (!data) return data;

  const newData: SelectBoxDataType = [];

  for (const [key, value] of Object.entries(
    data
  ) as Entries<tSelectBoxGeneric>) {
    newData.push({ key, value: { text: value } });
  }

  return newData;
};

const useSelectBox = <T extends string>(
  title: string,
  data: SelectBoxDataType | null,
  selectedValue: T | null
): [ReactNode, T | null, () => void, () => void, (sel: T) => void] => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<T | null>(selectedValue);

  const render =
    open && data ? (
      <SelectBox
        title={title}
        data={data}
        selected={selected}
        handler={(key) => {
          setSelected(key as T);
          setOpen(false);
        }}
        closeHandler={() => setOpen(false)}
      />
    ) : null;

  return [
    render,
    selected,
    () => {
      setOpen(true);
    },
    () => {
      setOpen(false);
    },
    (sel) => {
      setSelected(sel);
    },
  ];
};

export default useSelectBox;
