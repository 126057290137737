import { useState, useEffect, FormEvent, useRef } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import { assertNotNull } from "../../utils/func";

import PinGroup from "../PinGroup/PinGroup";

const SetPin = ({ closeHandler }: { closeHandler: () => void }) => {
  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );
  assertNotNull(userDetails);

  const { is_pin_set } = userDetails;

  const { fetchProfile } = useData();

  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pin.length !== 4)
      return setMessage("warning", "Pin should be 4 digits");

    if (confirmPin.length !== 4)
      return setMessage("warning", "Conirm pin should be 4 digits");

    if (pin !== confirmPin) return;

    const target = submitBtnRef.current! as HTMLButtonElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    api_client({
      url: `/set-pin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        pin: pin,
        pin_confirmation: confirmPin,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return fetchProfile();
      })
      .then((res) => {
        closeHandler();
      })
      .catch((err) => {
        if (err.message) setMessage("error", "An error occured. Try again");
      })
      .finally(() => {
        if (target) {
          target.removeAttribute("disabled");
          target.innerHTML = "Set PIN";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [pin, confirmPin, clearMessage]);

  if (is_pin_set) return null;

  return (
    <>
      <div className="withdraw-type-modal withdraw-type-modal--1">
        <span className="withdraw-type-modal__close" onClick={closeHandler}>
          <IonIcon icon={close} />
        </span>
        <div className="withdraw-type-modal__header">
          <h3 className="withdraw-type-modal__heading">Set PIN</h3>
          <p className="text-center">
            Set Transaction PIN to secure and complete your transactions
          </p>
        </div>
        <form
          className="auth__form-main"
          onSubmit={handleSubmit}
          style={{ marginTop: 0 }}
        >
          <div className="form-group mt-small" style={{ alignSelf: "center" }}>
            <label className="form-label">Enter PIN</label>
            <div className="register__pin-input-group-2">
              <PinGroup
                numInputs={4}
                pin={pin}
                handler={setPin}
                type="password"
              />
            </div>
          </div>
          <div className="form-group mt-small" style={{ alignSelf: "center" }}>
            <label className="form-label">Confirm PIN</label>
            <div className="register__pin-input-group-2">
              <PinGroup
                numInputs={4}
                pin={confirmPin}
                handler={setConfirmPin}
                type="password"
              />
            </div>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button withdraw-type-modal__button",
                pin.length === 4 &&
                  confirmPin.length === 4 &&
                  pin === confirmPin &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Set PIN
            </button>
          </div>
        </form>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default SetPin;
