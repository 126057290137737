import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";

import withAuth from "../../hoc/withAuth/withAuth";
import giftCardHoc from "../../hoc/giftCard/giftCard";

import { tGiftCardCountries } from "../../store/types/app.types";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../components/Preloader/Preloader";

import { assertNotNull } from "../../utils/func";
import { updateGiftCardCountry } from "../../store/giftcardSlice";

const GiftCardCountry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { giftCard, cardCountry } = useSelector(
    (state: tRootState) => state.giftcard
  );

  assertNotNull(giftCard);

  const [countries, setCountries] = useState<tGiftCardCountries | null>(null);

  useEffect(() => {
    if (!giftCard?.id) return;

    api_client({
      url: `/order/services/countries/${giftCard.id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setCountries(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [accessToken, giftCard?.id, navigate]);

  if (!countries) return <Preloader />;

  return (
    <DashboardLayout>
      <ul className="breadcrumb">
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            Gift Cards
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            {giftCard.name}
          </Link>
        </li>
        <li className="breadcrumb__item breadcrumb__item--active">
          <Link to="#" className="breadcrumb__link">
            Select Country
          </Link>
        </li>
      </ul>
      <div className="giftcards-pg__header">
        <div className="giftcards-pg__header-main">
          <h3 className="giftcards-pg__heading">Select Gift Card Country</h3>
          <div className="step-radio-btns">
            <input
              type="radio"
              className="radio"
              name="step"
              value="1"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="2"
              checked
              readOnly
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="3"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="4"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="giftcard-block">
        <div className="giftcard-block__countries">
          {countries.map((country) => (
            <div
              className={cls(
                "giftcard-block__country",
                country.id === cardCountry?.id &&
                  "giftcard-block__country--active"
              )}
              key={country.id}
              onClick={() => {
                dispatch(updateGiftCardCountry(country));
                navigate("/gift-cards/receipt");
              }}
            >
              <img
                src={country.country_image}
                alt=""
                className="giftcard-block__country-img"
              />
              {country.country_name}
            </div>
          ))}
        </div>
        <div className="giftcard-btns">
          <button onClick={() => navigate(-1)}>
            <IonIcon icon={chevronBackOutline} /> Go Back
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(giftCardHoc(GiftCardCountry));
