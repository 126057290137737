import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  checkmarkCircle,
  eyeOffOutline,
  eyeOutline,
  logoGoogle,
  mail,
} from "ionicons/icons";

import { tRootState } from "../../store";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import SetAuth from "../../components/SetAuth/SetAuth";
import SetPin from "../../components/SetPin/SetPin";

import { assertNotNull } from "../../utils/func";
import ContactVerification from "../../components/ContactVerification/ContactVerification";

const Security = () => {
  const navigate = useNavigate();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const withauth = userDetails.withauth;

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [hideBtn, setHideBtn] = useState(false);

  const [changePassword, setChangePassword] = useState({
    current_password: "",
    new_password: "",
    new_password_confirm: "",
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordValidations, setPasswordValidations] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [message, setMessage, clearMessage] = useAlert(true);

  const [callback, setCallback] = useState<(() => void) | null>(null);
  const [authType, setAuthType] = useState(withauth);

  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const [openSetPin, setOpenSetPin] = useState(false);

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setChangePassword({ ...changePassword, [target.name]: target.value });
  };

  const handleChangePasswordSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!changePassword.current_password)
      return setMessage("warning", "Enter current password");

    if (!changePassword.new_password)
      return setMessage("warning", "Enter new password");

    if (!changePassword.new_password_confirm)
      return setMessage("warning", "Confirm new password");

    if (passwordValidations.some((valid) => !valid))
      return setMessage("warning", "One or more password validations failed");

    if (changePassword.new_password !== changePassword.new_password_confirm)
      return setMessage("warning", "Passwords do not match");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Changing password`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/change-password",
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(changePassword),
    })
      .then((res) => {
        if (res.data.success) {
          setHideBtn(true);

          setMessage(
            "success",
            `${res.data.message}. <span class="fas fa-spinner fa-spin"></span> Redirecting...`
          );

          setTimeout(() => {
            navigate("/sign-out");
          }, 3000);
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        setMessage("error", err.message);
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled");
        submitBtnRef.current.innerHTML = "Change password";
      });
  };

  useEffect(() => {
    const password = changePassword.new_password;

    const newPasswordValidations = [false, false, false, false, false];

    newPasswordValidations[0] = password.length >= 8;

    newPasswordValidations[1] = password
      .split("")
      .some((c) => "abcdefghijklmnopqrstuvwxyz".split("").includes(c));

    newPasswordValidations[2] = password
      .split("")
      .some((c) =>
        "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("").includes(c)
      );

    newPasswordValidations[3] = password
      .split("")
      .some((c) => "1234567890".split("").includes(c));

    newPasswordValidations[4] = password
      .split("")
      .some((c) => "~`@#$%^&*()-_=+{}[];:'\",.<>/?|\\/".split("").includes(c));

    setPasswordValidations(newPasswordValidations);
  }, [changePassword.new_password]);

  useEffect(() => {
    clearMessage();
  }, [changePassword, clearMessage]);

  return (
    <>
      {callback && typeof authType !== "undefined" ? (
        <SetAuth
          callback={callback}
          defaultAuthType={authType}
          closeHandler={() => setCallback(null)}
          ignoreAuthSet
        />
      ) : null}
      {openSetPin ? <SetPin closeHandler={() => setOpenSetPin(false)} /> : null}

      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />

      <div className="account-section">
        <div className="account-section__header">
          <h3 className="heading-tertiary">Change Password</h3>
        </div>
        <div className="account-section__main">
          <form
            className="account-section__form"
            onSubmit={handleChangePasswordSubmit}
          >
            <div className="form-group">
              <label>Current Password</label>
              <div className="form-input-group">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Enter current password"
                  autoComplete="off"
                  name="current_password"
                  value={changePassword.current_password}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  onClick={() => setShowCurrentPassword((sh) => !sh)}
                >
                  <IonIcon
                    icon={showCurrentPassword ? eyeOffOutline : eyeOutline}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>New password</label>
              <div className="form-input-group">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  autoComplete="new-password"
                  name="new_password"
                  value={changePassword.new_password}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword((sh) => !sh)}
                >
                  <IonIcon
                    icon={showNewPassword ? eyeOffOutline : eyeOutline}
                  />
                </button>
              </div>
              <div className="form-requirements">
                <div className={cls(passwordValidations[0] && "active")}>
                  <IonIcon
                    icon={
                      passwordValidations[0] ? checkmarkCircle : alertCircle
                    }
                  />
                  At least 8 characters
                </div>
                <div className={cls(passwordValidations[1] && "active")}>
                  <IonIcon
                    icon={
                      passwordValidations[1] ? checkmarkCircle : alertCircle
                    }
                  />
                  Lowercase letter (a-z)
                </div>
                <div className={cls(passwordValidations[2] && "active")}>
                  <IonIcon
                    icon={
                      passwordValidations[2] ? checkmarkCircle : alertCircle
                    }
                  />
                  Uppercase letter (A-Z)
                </div>
                <div className={cls(passwordValidations[3] && "active")}>
                  <IonIcon
                    icon={
                      passwordValidations[3] ? checkmarkCircle : alertCircle
                    }
                  />
                  Number (0-9)
                </div>
                <div className={cls(passwordValidations[4] && "active")}>
                  <IonIcon
                    icon={
                      passwordValidations[4] ? checkmarkCircle : alertCircle
                    }
                  />
                  Special character (#,*)
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <div className="form-input-group">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm new password"
                  autoComplete="off"
                  name="new_password_confirm"
                  value={changePassword.new_password_confirm}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword((sh) => !sh)}
                >
                  <IonIcon
                    icon={showConfirmPassword ? eyeOffOutline : eyeOutline}
                  />
                </button>
              </div>
              {changePassword.new_password &&
              changePassword.new_password_confirm &&
              changePassword.new_password !==
                changePassword.new_password_confirm ? (
                <label className="form-bottom-label text-warning">
                  <IonIcon icon={alertCircle} />
                  <p>Passwords do not match</p>
                </label>
              ) : null}
            </div>
            {message}
            {!hideBtn ? (
              <button
                className={cls(
                  "form-button",
                  changePassword.current_password &&
                    changePassword.new_password &&
                    changePassword.new_password_confirm &&
                    changePassword.new_password ===
                      changePassword.new_password_confirm &&
                    !passwordValidations.some((valid) => !valid) &&
                    "form-button--active"
                )}
                ref={submitBtnRef}
              >
                Change Password
              </button>
            ) : null}
          </form>
        </div>
      </div>
      <div className="account-section">
        <div className="account-section__header">
          <h3 className="heading-tertiary">Transaction PIN</h3>
          <p>4-digit PIN used to authorize transactions on your account</p>
        </div>
        <div className="account-section__main">
          <div className="account-section__form">
            {userDetails.is_pin_set ? (
              <button
                className="form-button form-button--active"
                onClick={() => {
                  if (
                    userDetails.verify_with &&
                    userDetails.verify_with === "phone"
                  ) {
                    setContactVerificationCallback(() => () => {
                      navigate("/change-pin");
                    });
                  } else {
                    navigate("/change-pin");
                  }
                }}
              >
                Change Pin
              </button>
            ) : (
              <button
                className="form-button form-button--active"
                onClick={() => setOpenSetPin(true)}
              >
                Set Pin
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="account-section">
        <div className="account-section__header">
          <h3 className="heading-tertiary">OTP Confirmation</h3>
          <p>Change your otp confirmation method</p>
        </div>
        <div className="account-section__main">
          <div className="account-section__setting">
            <IonIcon icon={mail} />
            <p>Via Email</p>
            <input
              type="checkbox"
              className="checkbox"
              checked={withauth === 0}
              disabled={withauth === 0}
              onChange={() => {
                setAuthType(0);
                setCallback(() => () => setCallback(null));
              }}
            />
          </div>
        </div>
        <div className="account-section__main">
          <div className="account-section__setting">
            <IonIcon icon={logoGoogle} />
            <p>Via Google Auth</p>
            <input
              type="checkbox"
              className="checkbox"
              checked={withauth === 1}
              disabled={withauth === 1}
              onChange={() => {
                setAuthType(1);
                setCallback(() => () => setCallback(null));
              }}
            />
          </div>
        </div>
        <div className="account-section__main">
          <div className="account-section__setting">
            <IonIcon icon={mail} />
            <p>Via Email & Google Auth</p>
            <input
              type="checkbox"
              className="checkbox"
              checked={withauth === 2}
              disabled={withauth === 2}
              onChange={() => {
                setAuthType(2);
                setCallback(() => () => setCallback(null));
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(Security);
