import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { tRootState } from "../../store";

import ModeToggle from "../../components/ModeToggle/ModeToggle";

import authBackground from "../../assets/img/auth-background.png";
import img404 from "../../assets/img/404.png";

const NotFound = () => {
  const loggedIn = useSelector((state: tRootState) => state.user.loggedIn);

  return (
    <>
      <div
        className="not-found"
        style={{ backgroundImage: `url(${authBackground})` }}
      >
        <div className="not-found__main">
          <h3 className="not-found__error-text">Error</h3>
          <div className="not-found__img-block">
            <img src={img404} alt="" className="not-found__img" />
          </div>
          <p className="not-found__text">
            The page you were looking for was either removed or doesn’t exist
          </p>
          <div className="not-found__btn-group">
            <Link to="/" className="not-found__btn">
              Back to Home
            </Link>
            {loggedIn ? (
              <Link to="/dashboard" className="not-found__btn">
                Back to Dashboard
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <ModeToggle />
    </>
  );
};

export default NotFound;
