import { useState, useRef, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updatePinChangedMessage } from "../../store/cacheSlice";

import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";
import withAuth from "../../hoc/withAuth/withAuth";

const ResetPin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pinMessage = useSelector(
    (state: tRootState) => state.cache.pin.message
  );
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [code, setCode] = useState("");
  const [pin, setPin] = useState("");
  const [pinConfirm, setPinConfirm] = useState("");

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (code.length !== 4 || pin.length !== 4 || pinConfirm.length !== 4)
      return setMessage("warning", "Fill in all fields");

    if (pin !== pinConfirm) return setMessage("warning", "PINs do not match");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/reset-pin-new",
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        pin: pin,
        new_pin_confirmation: pin,
        otp: code,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        dispatch(updatePinChangedMessage(res.data.message));
        navigate("/pin-changed");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = `Next`;
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [code, pin, pinConfirm, clearMessage]);

  if (!pinMessage) return <Navigate to="/change-pin" />;

  return (
    <DashboardLayout>
      <div className="form-card">
        <div className="form-card__back mb-medium" onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
          Back
        </div>
        <div className="text-center mb-medium">
          <h3 className="heading-tertiary">Enter OTP sent to your device</h3>
          <p>{pinMessage}</p>
        </div>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-card__flex">
            <div className="form-group">
              <label className="form-label">Enter OTP</label>
              <div className="register__pin-input-group-2">
                <PinGroup numInputs={4} pin={code} handler={setCode} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Enter New Pin</label>
              <div className="register__pin-input-group-2">
                <PinGroup
                  numInputs={4}
                  pin={pin}
                  handler={setPin}
                  type="password"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Confirm New Pin</label>
              <div className="register__pin-input-group-2">
                <PinGroup
                  numInputs={4}
                  pin={pinConfirm}
                  handler={setPinConfirm}
                  type="password"
                />
              </div>
            </div>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                code.length === 4 &&
                  pin.length === 4 &&
                  pinConfirm.length === 4 &&
                  pin === pinConfirm &&
                  "form-button--active"
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(ResetPin);
