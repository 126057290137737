import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

export type tAssetAction =
  | ("deposit" | "withdraw" | "transfer" | "trade")
  | null;
export type tAssetSubAction = ("buy" | "sell") | null;
export type tAssetTab = ("all" | "fiat" | "crypto" | "giftcards") | null;

type AssetType = {
  action: tAssetAction;
  subAction: tAssetSubAction;
  tab: tAssetTab;
  triggerPage: null | string;
};

const AssetInitialState: AssetType = {
  action: null,
  subAction: null,
  tab: "all",
  triggerPage: null,
};

const assetSlice = createSlice({
  name: "asset",
  initialState: getState<AssetType>("asset", AssetInitialState),
  reducers: {
    updateAction(state, { payload }: PayloadAction<tAssetAction>) {
      state.action = payload;

      saveState("asset", current(state));
    },
    updateSubAction(state, { payload }: PayloadAction<tAssetSubAction>) {
      state.subAction = payload;

      saveState("asset", current(state));
    },
    updateTab(state, { payload }: PayloadAction<tAssetTab>) {
      state.tab = payload;

      saveState("asset", current(state));
    },
    updateTriggerPage(state, { payload }: PayloadAction<string>) {
      state.triggerPage = payload;

      saveState("asset", current(state));
    },
    triggerAction(
      state,
      { payload }: PayloadAction<"crypto" | "giftcards" | "transfer">
    ) {
      if (payload === "crypto") {
        state.action = "trade";
        state.subAction = "sell";
        state.tab = "crypto";
      } else if (payload === "giftcards") {
        state.action = "trade";
        state.subAction = "sell";
        state.tab = "giftcards";
      } else {
        state.action = "transfer";
        state.tab = "all";
      }

      saveState("asset", current(state));
    },
  },
});

export const {
  updateAction,
  updateSubAction,
  updateTab,
  updateTriggerPage,
  triggerAction,
} = assetSlice.actions;

export default assetSlice.reducer;
