import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { tRootState } from "../../store";

const giftCard =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const location = useLocation().pathname;

    const { giftCard, cardCountry } = useSelector(
      (state: tRootState) => state.buyGiftcard
    );

    if (!giftCard) return <Navigate to="/gift-cards" />;

    if (
      !cardCountry &&
      !["/buy-gift-cards/country", "/buy-gift-cards/country/"].includes(
        location
      )
    )
      return <Navigate to="/buy-gift-cards/country" />;

    return <Component {...props} />;
  };

export default giftCard;
