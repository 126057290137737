import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";

import { tRootState } from "../../store";

import { assertNotNull } from "../../utils/func";

const ProfileProgress = () => {
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  if (userDetails.is_verified) return null;

  return (
    <section
      className="profile-progress"
      onClick={() => navigate("/account/verify-identity?verifyAccount=true")}
    >
      <h3 className="profile-progress__header">
        Complete your KYC
        <IonIcon icon={arrowForwardOutline} />
      </h3>
      <p className="profile-progress__num">50%</p>
      <div className="profile-progress__progress">
        <div style={{ width: "50%" }}></div>
      </div>
    </section>
  );
};

export default ProfileProgress;
