import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  arrowBackOutline,
  chevronDownOutline,
  swapVerticalOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";
import { updateFundVirtualCard } from "../../store/vcardSlice";
import { updateFundVirtualCardRate } from "../../store/cacheSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";

import SetAuth from "../../components/SetAuth/SetAuth";
import SelectCurrencyModal from "../../components/SelectCurrencyModal/SelectCurrencyModal";

import Spinner from "../../loaders/Spinner/Spinner";
import Preloader from "../../components/Preloader/Preloader";

import { getPrecision } from "../../utils/app";
import { assertNotNull, isNumber, roundDP } from "../../utils/func";

const TopupVirtualCard = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    vcardWallets: wallets,
    virtualCards,
    fundVirtualCardRates,
  } = useSelector((state: tRootState) => state.cache);
  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );
  const { virtualCardSummary } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(userDetails);

  const { fetchVcardWallets, fetchVirtualCards, fetchVirtualCardSummary } =
    useData();

  const virtualCard = virtualCards?.find((vCard) => vCard.id.toString() === id);

  const [wallet, setWallet] = useState<tWallet | null>(null);

  const rate = fundVirtualCardRates[wallet?.symbol.toLowerCase() || ""];

  const [showWalletModal, setShowWalletModal] = useState(false);

  const [type, setType] = useState<"fiat" | "crypto">("crypto");

  const [amount, setAmount] = useState<number | string>("");
  const [cryptoAmount, setCryptoAmount] = useState<number | string>("");
  const [amountGet, setAmountGet] = useState<number | string>("");

  const [pin, setPin] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const [authMethodCallback, setAuthMethodCallback] = useState<
    (() => void) | null
  >(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!cardSummary) return;

    if (!wallet) return setMessage("warning", "Wallet is required");

    if (+amountGet < +cardSummary.min_fund)
      return setMessage("warning", cardSummary.min_fund_text);

    if (!cryptoAmount || +cryptoAmount === 0)
      return setMessage("warning", "Amount is required and cannot be zero");

    if (+cryptoAmount > +wallet.balance)
      return setMessage("warning", "Insufficient fund in wallet");

    if (pin.length !== 4) return setMessage("warning", "Enter pin");

    setAuthMethodCallback(() => () => {
      const submitBtn = submitBtnRef.current;

      submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
      submitBtn.setAttribute("disabled", "disabled");

      api_client({
        method: "POST",
        url: "/virtual/fund-card",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          card: virtualCard!.id,
          wallet: wallet.symbol.toLowerCase(),
          amount: wallet.symbol === "ngn" ? +cryptoAmount : +amount,
          pin: pin,
        },
      })
        .then((res) => {
          if (!res.data.success) {
            setMessage("warning", res.data.message);
            throw new Error("");
          }

          dispatch(
            updateFundVirtualCard({
              wallet: wallet,
              virtualCard: virtualCard!,
              cryptoAmount,
              fiatAmount: amount,
              receiveAmount: amountGet,
              otpMessage: res.data.message,
              transaction: res.data.transaction,
            })
          );

          navigate(`/virtual-card/topup/final`);
        })
        .catch((err) => {
          if (err.code === "ERR_BAD_REQUEST") {
            setMessage("warning", err.response.data.message);
          } else if (err.message) {
            setMessage("error", err.message);
          }
        })
        .finally(() => {
          if (submitBtn) {
            submitBtn.removeAttribute("disabled");
            submitBtn.innerHTML = "Proceed";
          }
        });
    });
  };

  const resetAmount = () => {
    setAmount("");
    setCryptoAmount("");
    setAmountGet("");
  };

  const cryptoAmountChangeHandler = (crypValue: string) => {
    if (!wallet || !rate || !isNumber(crypValue)) return;

    if (!crypValue) return resetAmount();

    const cryptoValue = +crypValue;

    if (cryptoValue > wallet.balance) return;

    if (wallet.symbol === "ngn") {
      const valueGet = cryptoValue / rate.m_rate;

      setCryptoAmount(crypValue);
      setAmountGet(valueGet.toFixed(2));

      return;
    }

    const value = cryptoValue / rate.from_rate;
    const valueGet = (value - value * rate.app_rt) / rate.cs_rt;

    setCryptoAmount(crypValue);
    setAmount(value.toFixed(2));
    setAmountGet(valueGet.toFixed(2));
  };

  const amountChangeHandler = (amtValue: string) => {
    if (!wallet || !rate || !isNumber(amtValue)) return;

    if (!amtValue) return resetAmount();

    const value = +amtValue;

    const cryptoValue = value * rate.from_rate;

    if (cryptoValue > wallet.balance) return;

    const valueGet = (value - value * rate.app_rt) / rate.cs_rt;

    setCryptoAmount(
      cryptoValue.toFixed(
        ["usdt", "usdc"].includes(wallet.symbol) ? rate.usd_dp : rate.coin_dp
      )
    );
    setAmount(amtValue);
    setAmountGet(valueGet.toFixed(2));
  };

  const amountGetChangeHandler = (amtGetValue: string) => {
    if (!wallet || !rate || !isNumber(amtGetValue)) return;

    if (!amtGetValue) return resetAmount();

    const valueGet = +amtGetValue;

    if (wallet.symbol === "ngn") {
      const cryptoValue = valueGet * rate.m_rate;

      if (cryptoValue > wallet.balance) return;

      setCryptoAmount(cryptoValue.toFixed(getPrecision(wallet.symbol)));
      setAmountGet(amtGetValue);

      return;
    }

    const value = (valueGet * rate.cs_rt) / (1 - rate.app_rt);
    const cryptoValue = value * rate.from_rate;

    if (cryptoValue > wallet.balance) return;

    setCryptoAmount(
      cryptoValue.toFixed(
        ["usdt", "usdc"].includes(wallet.symbol) ? rate.usd_dp : rate.coin_dp
      )
    );
    setAmount(value.toFixed(2));
    setAmountGet(amtGetValue);
  };

  useEffect(() => {
    clearMessage();
  }, [wallet, amount, amountGet, pin, clearMessage]);

  useEffect(() => {
    if (!wallet?.symbol) return;

    setAmount("");
    setCryptoAmount("");
    setAmountGet("");

    setType(wallet.symbol === "ngn" ? "crypto" : "fiat");

    api_client({
      url: `/virtual/fund-card-rate-new/${wallet.symbol}`,
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        dispatch(
          updateFundVirtualCardRate({
            key: wallet.symbol,
            rate: {
              symbol: wallet.symbol,
              from_rate: +res.data.from_rate,
              m_rate: +(+res.data.m_rate.toFixed(
                wallet.symbol === "ngn"
                  ? 2
                  : ["usdt", "usdc"].includes(wallet.symbol)
                  ? res.data.usd_dp
                  : res.data.coin_dp
              )),
              cs_rt: +res.data.cs_rt,
              app_rt: +res.data.app_rt,
              usd_dp: +res.data.usd_dp,
              coin_dp: +res.data.coin_dp,
            },
          })
        );
      })
      .catch((err) => {
        if (!rate?.symbol) navigate("/error");
      });
  }, [wallet?.symbol, rate?.symbol, accessToken, navigate, dispatch]);

  useEffect(() => {
    if (!wallets || wallet) return;

    setWallet(wallets.find((wl) => wl.symbol === "ngn")!);
  }, [wallets, wallet]);

  useEffect(() => {
    if (!virtualCard?.brand) return;

    fetchVirtualCardSummary(
      virtualCard.brand.toLowerCase() as "mastercard" | "visa"
    );
  }, [fetchVirtualCardSummary, virtualCard?.brand]);

  useEffect(() => {
    fetchVcardWallets();
  }, [fetchVcardWallets]);

  useEffect(() => {
    fetchVirtualCards().then((vCards) => {
      const fCard = vCards.find((vCard) => vCard.id.toString() === id);

      if (!fCard) navigate("/error");
    });
  }, [fetchVirtualCards, navigate, id]);

  if (!virtualCard) return <Preloader />;

  if (virtualCard.status !== "ACTIVE")
    return <Navigate to={`/virtual-card?card=${virtualCard.id}`} />;

  if (!wallets) return <Preloader />;

  const cardSummary =
    virtualCardSummary[
      virtualCard.brand.toLowerCase() as "mastercard" | "visa"
    ];

  if (!cardSummary) return <Preloader />;

  if (!wallet) return <Preloader />;

  const cryptoAmountMax = wallet.balance;
  const amountMax = rate ? cryptoAmountMax / rate.from_rate : 0;
  const amountGetMax = rate
    ? wallet.symbol === "ngn"
      ? cryptoAmountMax / rate?.from_rate
      : (amountMax - amountMax * rate.app_rt) / rate.cs_rt
    : 0;

  return (
    <DashboardLayout>
      <SetAuth
        callback={authMethodCallback}
        closeHandler={() => setAuthMethodCallback(null)}
      />

      <SelectCurrencyModal
        list="vcard"
        showBalance
        show={showWalletModal}
        selectHandler={(selWallet) => {
          setWallet(selWallet);
          setShowWalletModal(false);
        }}
        closeHandler={() => setShowWalletModal(false)}
        selectedWallet={wallet?.symbol}
      />

      {!rate ? <Spinner /> : null}

      <div className="crypto-block2 crypto-block2--md">
        <form
          className="crypto-block2__body crypto-block2__body--p0"
          onSubmit={handleSubmit}
        >
          <div className="crypto-block2__flex">
            <div className="virtual-card__header">
              <span className="virtual-card__back" onClick={() => navigate(-1)}>
                <IonIcon icon={arrowBackOutline} />
              </span>
            </div>
            <div className="vcard-create__header mb-small">
              <p className="vcard-create__heading">You are funding from</p>
              <div
                className="virtual-card__account"
                onClick={() => setShowWalletModal(true)}
              >
                <img
                  src={wallet.image}
                  alt=""
                  className="virtual-card__account-img"
                />
                <p className="virtual-card__account-name">{wallet.coin}</p>
                <span className="virtual-card__account-balance">
                  {roundDP(wallet.balance, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()}
                </span>
                <IonIcon
                  icon={chevronDownOutline}
                  className="virtual-card__account-caret"
                />
              </div>
            </div>
            <div className="crypto-block2__swap-block">
              {wallet.symbol !== "ngn" ? (
                <div className="tab-buttons-1">
                  <button
                    type="button"
                    className={cls(type === "fiat" && "active")}
                    onClick={() => setType("fiat")}
                  >
                    Fiat
                  </button>
                  <button
                    type="button"
                    className={cls(type === "crypto" && "active")}
                    onClick={() => setType("crypto")}
                  >
                    Crypto
                  </button>
                </div>
              ) : null}
              <div className="form-group form-group--md">
                <div className="form-group">
                  <label className="form-label">Amount</label>
                  {type === "fiat" ? (
                    <div className="form-group">
                      <div className="input-group">
                        <button type="button">
                          {wallet.symbol === "ngn"
                            ? wallet.symbol.toUpperCase()
                            : "USD"}
                        </button>
                        <input
                          type="text"
                          placeholder="Enter amount"
                          value={amount}
                          onChange={(e) => {
                            amountChangeHandler(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="input-group">
                        <button type="button">
                          {wallet.symbol.toUpperCase()}
                        </button>
                        <input
                          type="text"
                          placeholder="Enter amount"
                          value={cryptoAmount}
                          onChange={(e) => {
                            cryptoAmountChangeHandler(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {rate ? (
                  <AmountPercentage
                    totalAmount={
                      wallet.symbol === "ngn" || type === "crypto"
                        ? cryptoAmountMax
                        : amountMax
                    }
                    handler={(amount) => {
                      type === "crypto"
                        ? cryptoAmountChangeHandler(amount.toString())
                        : amountChangeHandler(amount.toString());
                    }}
                    precision={
                      type === "fiat" ? 2 : getPrecision(wallet.symbol)
                    }
                  />
                ) : null}
              </div>
            </div>
            <button className={"crypto-block2__swap-btn"} type="button">
              <IonIcon icon={swapVerticalOutline} />
            </button>
            <div className="form-group">
              <div className="form-group">
                <div className="flex-between">
                  <p>You Get</p>
                  <p>Current Balance: USD {roundDP(virtualCard.balance, 2)}</p>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <button type="button">USD</button>
                    <input
                      type="text"
                      placeholder="You get"
                      value={amountGet}
                      onChange={(e) => {
                        amountGetChangeHandler(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {rate ? (
                <div className="flex-between">
                  <label className="form-bottom-label">
                    {wallet.symbol.toUpperCase()}{" "}
                    {/* {roundDP(
                      cryptoAmountMax / amountGetMax,
                      getPrecision(wallet.symbol)
                    )} */}
                    {roundDP(
                      rate.m_rate,
                      wallet.symbol === "ngn"
                        ? 2
                        : ["usdt", "usdc"].includes(wallet.symbol)
                        ? rate.usd_dp
                        : rate.coin_dp
                    )}{" "}
                    - $1
                  </label>
                  <span>MAX: ${roundDP(amountGetMax, 2)}</span>
                </div>
              ) : null}
            </div>
            {!amountGet || +amountGet < +cardSummary.min_fund ? (
              <div className="virtual-card__message virtual-card__message--warning">
                <IonIcon icon={alertCircle} />
                {cardSummary.min_fund_text}
              </div>
            ) : null}
          </div>
          <div className="crypto-block__pin crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span
              className="crypto-final__forgot-pin"
              onClick={() => navigate("/change-pin")}
            >
              Forgot PIN
            </span>
          </div>
          <div className="crypto-block2__flex">
            {message}
            <button
              className={cls(
                "form-button",
                cryptoAmount &&
                  +cryptoAmount <= +wallet.balance &&
                  amountGet &&
                  +amountGet >= +cardSummary.min_fund &&
                  pin.length === 4 &&
                  "form-button--active"
              )}
              style={{ width: "80%", alignSelf: "center" }}
              ref={submitBtnRef}
            >
              Proceed
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(TopupVirtualCard);
