import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { tRootState } from "../../store";

import api_client from "../../api/client";

import { updateInfluencerImg } from "../../store/appSlice";

const InfluencerSection = () => {
  const dispatch = useDispatch();

  const influencerImg = useSelector(
    (state: tRootState) => state.app.influencerImg
  );

  useEffect(() => {
    api_client({
      url: "/influencer-banner",
      method: "GET",
    }).then((res) => {
      if (res.data.success)
        dispatch(updateInfluencerImg(res.data.message.image));
    });
  }, [dispatch]);

  if (!influencerImg) return null;

  return (
    <section className="influencers-section">
      <h3 className="heading-secondary text-center mb-medium">
        Meet our Influencers
      </h3>
      <div className="influencers-section__container">
        <div className="container ">
          <img
            src={influencerImg}
            alt=""
            className="influencers-section__img"
          />
        </div>
      </div>
    </section>
  );
};

export default InfluencerSection;
