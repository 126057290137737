import { useState, useEffect, FormEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  arrowBack,
  arrowDownOutline,
  chevronDownOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { updateSKPConverted } from "../../store/cacheSlice";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import SKPHistory from "../../components/SKPHistory/SKPHistory";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import Preloader from "../../components/Preloader/Preloader";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";

import sekilogo from "../../assets/img/seki-logo-light.png";
import naira from "../../assets/img/naira.png";
import useData from "../../hooks/useData/useData";

const pointsData = {
  skp: "Seki Points",
  iskp: "Influencer Seki Points",
};

const ConvertSKP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const [rate, setRate] = useState<{ user_skp: 1; influencer_skp: 1 } | null>(
    null
  );

  const [genderSelectBox, pointsType, openGenderSelectBox] = useSelectBox<
    keyof typeof pointsData
  >("Select Seki Points", getSelectBoxData(pointsData), "skp");

  const [amount, setAmount] = useState<number | "">("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const { fetchProfile } = useData();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!rate) return;

    if (!amount) return setMessage("warning", "Amount required");

    if (amount > userDetails[pointsType === "skp" ? "total_skp" : "iskp"])
      return setMessage("warning", "Insufficient seki points");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Converting points`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/convert-bonus",
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ amount, type: pointsType === "skp" ? 0 : 1 }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        dispatch(
          updateSKPConverted({
            type: pointsType!,
            points: amount,
            nairaValue:
              amount *
              (pointsType === "skp" ? rate.user_skp : rate.influencer_skp),
          })
        );

        // Fetch Profile
        return fetchProfile();
      })
      .then(() => {
        navigate("/skp-converted");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Convert";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [amount, pointsType, clearMessage]);

  useEffect(() => {
    api_client({
      url: "/skp-conversion-rate",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setRate(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [accessToken, navigate]);

  if (!rate) return <Preloader />;

  return (
    <DashboardLayout>
      {genderSelectBox}
      <div className="crypto-block2">
        <div className="crypto-block2__header">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </span>
          <h3 className="crypto-block2__heading">
            Convert your {pointsData[pointsType!]}
          </h3>
        </div>
        <form className="crypto-block2__body" onSubmit={handleSubmit}>
          <div className="crypto-block2__swap-block">
            <div className="form-group">
              <div className="crypto-block2__swap-heading">
                <p>You are converting your {pointsData[pointsType!]}</p>
                <p>
                  AVAIL. ={" "}
                  {pointsType === "skp"
                    ? userDetails.total_skp
                    : userDetails.iskp}{" "}
                  SKP
                </p>
              </div>
              <div
                className="select-box select-box--md"
                onClick={
                  userDetails.is_influencer ? openGenderSelectBox : () => null
                }
              >
                <img src={sekilogo} alt="" />
                <p>{pointsData[pointsType!]}</p>
                {userDetails.is_influencer ? (
                  <IonIcon icon={chevronDownOutline} />
                ) : null}
              </div>
            </div>
            <button className="crypto-block2__swap-btn crypto-block2__swap-draw-up">
              <IonIcon icon={arrowDownOutline} />
            </button>
            <div className="form-group">
              <div className="crypto-block2__swap-heading">
                <p>To receive NGN</p>
                <p>AVAIL. = {roundDP(userDetails.balance, 2)} NGN</p>
              </div>
              <div className="select-box select-box--md">
                <img src={naira} alt="" />
                <p>Naira</p>
                {/* <IonIcon icon={chevronDownOutline} /> */}
              </div>
            </div>
          </div>
          <div className="form-group form-group--md">
            <p>
              MAX:{" "}
              {pointsType === "skp" ? userDetails.total_skp : userDetails.iskp}{" "}
              SKP
            </p>
            <div className="input-group">
              <button type="button">SKP</button>
              <input
                type="text"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setAmount(+e.target.value)
                      : null
                    : setAmount("")
                }
              />
            </div>
            <AmountPercentage
              totalAmount={
                pointsType === "skp" ? userDetails.total_skp : userDetails.iskp
              }
              handler={setAmount}
            />
          </div>
          {amount ? (
            <div className="crypto-block__rate-info">
              <p>
                YOU ARE GETTING{" "}
                {roundDP(
                  amount *
                    (pointsType === "skp"
                      ? rate.user_skp
                      : rate.influencer_skp),
                  2
                )}{" "}
                NGN
              </p>
            </div>
          ) : null}
          <div className="auth__footer">
            {message}
            <button
              className={cls("form-button", amount && "form-button--active")}
              ref={submitBtnRef}
            >
              Convert
            </button>
          </div>
        </form>
      </div>
      <section className="transactions-section">
        <div className="transactions-section__header">
          <h3 className="heading-secondary">User Earning History</h3>
        </div>
        <div className="transactions-section__main">
          <SKPHistory />
        </div>
      </section>
    </DashboardLayout>
  );
};

export default withAuth(ConvertSKP);
