import { useState, useRef, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { closeOutline, star } from "ionicons/icons";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";

import useAlert from "../../../hooks/useAlert/useAlert";

type tCancelOrderModal = {
  show: boolean;
  is_reviewed: 0 | 1;
  order_id: number;
  finishHandler: () => void;
  closeHandler: () => void;
};

const ReviewOrderModal = ({
  show,
  is_reviewed,
  order_id,
  finishHandler,
  closeHandler,
}: tCancelOrderModal) => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: `/review/${order_id}/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ rating, message: review }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error();
        }

        setMessage("success", "Order reviewed successfully. Updating order...");
        finishHandler();
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.innerHTML = "Submit";
          submitBtnRef.current.removeAttribute("disabled");
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [rating, review, clearMessage]);

  if (!show || is_reviewed) return null;

  return (
    <>
      <div className="classic-modal">
        <div className="classic-modal__header">
          <h3 className="classic-modal__heading">Add Review</h3>
          <span className="classic-modal__close" onClick={closeHandler}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="classic-modal__body">
          <form className="auth__form-main mt-0" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Rating</label>
              <div className="stars">
                {new Array(5).fill(null).map((_, i) => (
                  <span
                    className={cls("star", rating >= i + 1 && "star--active")}
                    onClick={() => setRating(i + 1)}
                    key={i}
                  >
                    <IonIcon icon={star} />
                  </span>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label>Review</label>
              <textarea
                placeholder="Enter review"
                className="form-input"
                rows={3}
                value={review}
                onChange={(e) => setReview(e.target.value)}
              ></textarea>
            </div>
            <div className="auth__footer">
              {message}
              <button
                className={cls(
                  "form-button",
                  review && rating && "form-button--active"
                )}
                ref={submitBtnRef}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default ReviewOrderModal;
