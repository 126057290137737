import { MouseEvent, useState, useEffect, FormEvent, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  chevronDownOutline,
  clipboardOutline,
  close,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCountries, tCountry } from "../../store/types/app.types";

import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../hooks/useSelectBox/useSelectBox";
import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import PinGroup from "../PinGroup/PinGroup";

import { assertNotNull, isNumber } from "../../utils/func";

import sms from "../../assets/img/sms.png";
import whatsapp from "../../assets/img/whatsapp.png";
import validator from "validator";

const getCountrySelectData = (
  countries: tCountries | null
): tSelectBoxGeneric | null => {
  if (!countries) return countries;

  const data: tSelectBoxGeneric = {};

  for (const country of countries) {
    data[country.id] = `${country.country_name}(${country.contry_code})`;
  }

  return data;
};

const ContactVerification = ({
  callback,
  closeHandler,
  description,
}: {
  callback: (() => void) | null;
  closeHandler: () => void;
  description?: string;
}) => {
  const navigate = useNavigate();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );
  assertNotNull(userDetails);

  const { verify_with, verify_with_title, verify_with_text } = userDetails;

  const { fetchProfile, fetchCountries } = useData();

  const [countries, setCountries] = useState<tCountries | null>(null);

  const countriesSelectData = getCountrySelectData(countries);

  const [countrySelectBox, countryId, openCountrySelectBox] =
    useSelectBox<string>(
      "Select Country",
      getSelectBoxData(countriesSelectData),
      null
    );

  const [country, setCountry] = useState<tCountry | null>(null);

  const authType = verify_with;

  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState<number | "">("");

  const [smsType, setSMSType] = useState<"whatsapp" | "sms">("whatsapp");
  const [verificationCode, setVerificationCode] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      (authType === "email" && !email) ||
      (authType === "phone" && (!telephone || !country)) ||
      !verificationCode
    )
      return setMessage("warning", "Fill in all fields");

    if (verificationCode.length !== 6)
      return setMessage("warning", "Verification code should be 6 digits");

    const target = submitBtnRef.current! as HTMLButtonElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    api_client({
      url: `/contact/verify`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        type: authType,
        contact: `${
          authType === "phone" ? `${country?.contry_code}${telephone}` : email
        }`,
        phone_country_id: country?.id,
        otp: verificationCode,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return fetchProfile();
      })
      .then((res) => {
        // do nothing
      })
      .catch((err) => {
        if (err.message) setMessage("error", "An error occured. Try again");
      })
      .finally(() => {
        if (target) {
          target.removeAttribute("disabled");
          target.innerHTML = "Verify";
        }
      });
  };

  const handleFetchRegistrationCode = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target! as HTMLButtonElement;

    if (authType === "email" && !email)
      return setMessage("warning", "Email address is required");

    if (
      authType === "phone" &&
      (!validator.isNumeric(telephone.toString()) || !country)
    )
      return setMessage("warning", "Valid phone number is required");

    target.innerHTML = `<i class="fas fa-spinner fa-spin"></i>`;
    target.setAttribute("disabled", "disabled");

    api_client({
      url: `/contact/verify-otp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        type: authType,
        option: smsType,
        country_id: country?.id,
        contact: `${
          authType === "phone" ? `${country?.contry_code}${telephone}` : email
        }`,
      }),
    })
      .then((res) => {
        if (!res.data.status) {
          setMessage("warning", res.data.message);
        } else {
          setMessage("success", res.data.data.message);
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        target.removeAttribute("disabled");
        target.innerHTML = "Request Code";
      });
  };

  useEffect(() => {
    fetchCountries()
      .then((counts) => setCountries(counts))
      .catch((err) => {
        navigate("/error");
      });
  }, [fetchCountries, navigate]);

  useEffect(() => {
    if (!countryId) return;

    setCountry(
      countries?.find((count) => count.id.toString() === countryId) || null
    );
  }, [countryId, countries]);

  useEffect(() => {
    clearMessage();
  }, [email, telephone, smsType, countryId, verificationCode, clearMessage]);

  useEffect(() => {
    if (verify_with === null && callback) callback();
  }, [verify_with, callback]);

  if (verify_with === null || !callback) return null;

  return (
    <>
      {countrySelectBox}
      {!countrySelectBox ? (
        <>
          <div className="withdraw-type-modal withdraw-type-modal--1">
            <span className="withdraw-type-modal__close" onClick={closeHandler}>
              <IonIcon icon={close} />
            </span>
            <div className="withdraw-type-modal__header">
              <h3 className="withdraw-type-modal__heading">
                {verify_with_title}
              </h3>
              <p className="text-center">{description || verify_with_text}</p>
            </div>
            <form className="auth__form-main" onSubmit={handleSubmit}>
              {authType === "email" ? (
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <div className="input-group input-group--1">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="button" onClick={handleFetchRegistrationCode}>
                      Request Code
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="input-group">
                    <button type="button" onClick={openCountrySelectBox}>
                      {country ? country.contry_code : "Select country"}
                      <IonIcon icon={chevronDownOutline} />
                    </button>
                    <input
                      type="tel"
                      placeholder="Enter phone number"
                      value={telephone}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setTelephone(+e.target.value)
                            : null
                          : setTelephone("")
                      }
                      // maxLength={10}
                    />
                  </div>
                  <label className="form-label text-center">
                    <IonIcon icon={alertCircle} className="text-success" />
                    Enter phone number without country code
                  </label>
                </div>
              )}
              {authType === "phone" ? (
                <div className="form-group mt-small">
                  <div className="otp-medium mb-small">
                    <h3 className="otp-medium__heading">Receive OTP Via</h3>
                    <div className="otp-medium__blocks">
                      <div className="otp-medium__block">
                        <img
                          src={whatsapp}
                          alt=""
                          className="otp-medium__block-img"
                        />
                        <p className="otp-medium__block-name">
                          Whatsapp (Instant)
                        </p>
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={smsType === "whatsapp" ? true : false}
                          onChange={(e) =>
                            setSMSType(e.target.checked ? "whatsapp" : "sms")
                          }
                        />
                      </div>
                      <div className="otp-medium__block">
                        <img
                          src={sms}
                          alt=""
                          className="otp-medium__block-img"
                        />
                        <p className="otp-medium__block-name">
                          SMS (0 - 5 MIN)
                        </p>
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={smsType === "sms" ? true : false}
                          onChange={(e) =>
                            setSMSType(e.target.checked ? "sms" : "whatsapp")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <label className="form-label">Verification code</label>
                  <div className="input-group input-group--primary">
                    <input
                      type="password"
                      placeholder="Verification code"
                      name="password"
                      autoComplete="new-password"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button type="button" onClick={handleFetchRegistrationCode}>
                      Request Code
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="form-group mt-small"
                  style={{ alignSelf: "center" }}
                >
                  <label className="form-label" style={{ alignSelf: "center" }}>
                    Verification code
                  </label>
                  <div className="register__pin-input-group-2">
                    <PinGroup
                      numInputs={6}
                      pin={verificationCode}
                      handler={setVerificationCode}
                      type="text"
                      inputType="alpha-num"
                    />
                  </div>
                  <div
                    className="copy copy--bold"
                    onClick={() => {
                      navigator.clipboard.readText().then((clipText) => {
                        const text = clipText.slice(0, 6);
                        if (isNumber(text)) setVerificationCode(text);
                      });
                    }}
                    style={{ alignSelf: "center", marginTop: "8px" }}
                  >
                    <IonIcon icon={clipboardOutline} />
                    Paste from clipboard
                  </div>
                </div>
              )}
              <div className="auth__footer">
                {message}
                <button
                  className={cls(
                    "form-button withdraw-type-modal__button",
                    ((authType === "email" &&
                      email &&
                      validator.isEmail(email)) ||
                      (authType === "phone" &&
                        telephone &&
                        validator.isNumeric(telephone.toString()))) &&
                      verificationCode.length === 6 &&
                      "form-button--active"
                  )}
                  ref={submitBtnRef}
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
          <div className="overlay" onClick={closeHandler}></div>
        </>
      ) : null}
    </>
  );
};

export default ContactVerification;
