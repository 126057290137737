import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy, imagesOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tMessages, tOrderDetails } from "../../store/types/app.types";

import api_client from "../../api/client";

import useData from "../../hooks/useData/useData";

import withAuth from "../../hoc/withAuth/withAuth";

import {
  assertNotNull,
  copyData,
  getDate,
  roundDP,
  sleep,
} from "../../utils/func";

import Preloader from "../../components/Preloader/Preloader";
import DashbordHeader from "../../components/DashboardHeader/DashboardHeader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

import { getProfilePicture } from "../../utils/user";

import avatarNeutral from "../../assets/img/avatar-neutral.png";
import ModeToggle from "../../components/ModeToggle/ModeToggle";
import CancelOrderModal from "./Modals/CancelOrderModal";
import ReviewOrderModal from "./Modals/ReviewOrderModal";
import SendImageMessage from "../../components/SendImageMessage/SendImageMessage";
import ImgsPreview from "../../components/ImgsPreview/ImgsPreview";
import { getPrecision } from "../../utils/app";

const OrderDetails = () => {
  const navigate = useNavigate();

  const { hash } = useParams();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const { id: userId, gender } = userDetails;

  const { fetchOrder } = useData();

  const [reloadOrder, setReloadOrder] = useState(true);
  const [order, setOrder] = useState<tOrderDetails | null>(null);

  const [showChat, setShowChat] = useState(false);

  const [reloadMessages, setReloadMessages] = useState(true);
  const [messages, setMessages] = useState<tMessages | null>(null);

  const [defaultMessage, setDefaultMessage] = useState<string | undefined>(
    undefined
  );

  const pauseAutoScroll = useRef(false);
  const chatBlockRef = useRef<HTMLDivElement>(null);

  const [chatMessage, setChatMessage] = useState("");
  const sendChatMessageBtnRef = useRef<HTMLButtonElement>(null);

  const [images, setImages] = useState<File[]>([]);

  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [showReviewOrderModal, setShowReviewOrderModal] = useState(false);
  const [prevImgs, setPrevImgs] = useState<{
    images: string[];
    selectedImage: number;
  } | null>(null);

  const [copied, setCopied] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const handleFileInputChange = (e: FormEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;

    if (files?.length)
      setImages(
        [
          ...images,
          ...new Array(files.length).fill(null).map((_, i) => files[i]),
        ].slice(0, 10)
      );

    fileInputRef.current.value = "";
  };

  const sendChatMessage = (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!chatMessage || !order) return;

    const target = sendChatMessageBtnRef.current!;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    const form_data = new FormData();

    form_data.append("order_id", order.id.toString());
    form_data.append("message", chatMessage);

    api_client({
      method: "POST",
      url: "/orders/messages/send",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      data: form_data,
    })
      .then((res) => {
        if (res.data.success) setChatMessage("");
      })
      .catch((err) => {
        // do nothing
      })
      .finally(() => {
        if (target) {
          target.removeAttribute("disabled");
          target.innerHTML = "Send";
        }
      });
  };

  // Reload Messages
  useEffect(() => {
    if (
      typeof defaultMessage === "undefined" ||
      !order?.id ||
      !order?.service_type ||
      order?.service_type !== "Gift Card" ||
      order?.type === "buy"
    )
      return;

    api_client({
      url: `/orders/list-messages/${order.id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const data = res.data.message.data as tMessages;

          if (+res.data.message.current_page === 1) {
            data.push({
              id: -1,
              has_file: 0,
              file: "",
              from_user: {
                id: -1,
              },
              content: defaultMessage,
              created_at: "",
            });
          }

          data.reverse();

          setMessages(data);

          if (chatBlockRef.current) {
            chatBlockRef.current.addEventListener("scroll", (e) => {
              const target = e.target! as HTMLDivElement;

              pauseAutoScroll.current = true;
              if (
                target.scrollTop + target.offsetHeight >=
                target.scrollHeight - 400
              ) {
                pauseAutoScroll.current = false;
              }
            });

            if (!pauseAutoScroll.current) {
              chatBlockRef.current?.scrollTo({
                top: chatBlockRef.current.scrollHeight,
                behavior: "smooth",
              });
            }
          }
        }
      })
      .catch((err) => {
        // there should be handler for this
      })
      .finally(() => {
        if ([4, 1].includes(order?.status)) setReloadMessages((rm) => !rm);
        // Disputing wating
        // sleep(2000).then(() => setReloadMessages((rm) => !rm));
      });
  }, [
    order?.service_type,
    order?.id,
    order?.status,
    order?.type,
    reloadMessages,
    defaultMessage,
    accessToken,
  ]);

  // Reload Order
  useEffect(() => {
    if (!hash) return;

    fetchOrder(hash)
      .then((order) => {
        if (
          order.type === "buy" &&
          order.service_type === "Gift Card" &&
          !order.otp_completed
        )
          return navigate(`/buy-gift-cards/otp/${order.order_hash}`);

        setOrder(order);
      })
      .catch((err) => {
        if (!order?.id) navigate("/404");
      })
      .finally(() => {
        if (order?.status && [4, 1].includes(order?.status))
          sleep(10000).then(() => {
            setReloadOrder((ro) => !ro);
          });
      });
  }, [reloadOrder, fetchOrder, hash, navigate, order?.id, order?.status]);

  // Default Message
  useEffect(() => {
    api_client({
      url: "/order/chat/default",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setDefaultMessage(res.data.message);
      })
      .catch((err) => {
        setDefaultMessage("");
      });
  }, [accessToken]);

  if (!order) return <Preloader />;

  return (
    <>
      <DashbordHeader />
      <ModeToggle />
      <CancelOrderModal
        show={showCancelOrderModal}
        order_hash={order.order_hash}
        order_status={order.status}
        finishHandler={() => {
          setReloadOrder((ro) => !ro);
          // Reload messages might be a bit much as it happens instantly
          setReloadMessages((rm) => !rm);
        }}
        closeHandler={() => setShowCancelOrderModal(false)}
      />
      <ReviewOrderModal
        show={showReviewOrderModal}
        is_reviewed={order.is_reviewed}
        order_id={order.id}
        finishHandler={() => {
          setReloadOrder((ro) => !ro);
          // Reload messages might be a bit much as it happens instantly
          setReloadMessages((rm) => !rm);
        }}
        closeHandler={() => setShowReviewOrderModal(false)}
      />
      <input
        type="file"
        style={{ display: "none" }}
        multiple
        ref={fileInputRef}
        onChange={handleFileInputChange}
        accept=".png,.jpg,jpeg"
      />
      <SendImageMessage
        images={images}
        setImages={setImages}
        maxImages={10}
        addMoreImagesHandler={() => fileInputRef.current.click()}
        url="/orders/messages/send"
        id_key="order_id"
        id_value={order.id}
        images_key="image"
        finishHandler={() => {
          // Reload messages might be a bit much as it happens instantly
          setReloadMessages((rm) => !rm);
        }}
      />
      {prevImgs ? (
        <ImgsPreview
          images={prevImgs.images}
          selectedImg={prevImgs.selectedImage}
          closeHandler={() => setPrevImgs(null)}
        />
      ) : null}
      <main className="order-details">
        <div className="order-details__main">
          <div className="order-details__left">
            <ul className="breadcrumb mb-medium">
              <li className="breadcrumb__item">
                <Link to="/orders" className="breadcrumb__link">
                  Orders
                </Link>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">
                <Link to="#" className="breadcrumb__link">
                  Order Details
                </Link>
              </li>
            </ul>

            <div className="order-details__header mb-small">
              <h3 className="order-details__heading">Order Details</h3>
              {order.status === 0 ? (
                <span className="badge badge--str badge--danger">Canceled</span>
              ) : order.status === 1 ? (
                <span className="badge badge--str badge--info">New</span>
              ) : order.status === 3 ? (
                <span className="badge badge--str badge--success">
                  Completed
                </span>
              ) : order.status === 4 ? (
                <span className="badge badge--str badge--warning">
                  Processing...
                </span>
              ) : null}
            </div>

            <div className="order-details__hash mb-medium">
              <p>{order.order_hash}</p>
              <span
                className="copy copy--lg"
                onClick={() => copyData(order.order_hash, setCopied)}
              >
                <IonIcon icon={copy} /> {copied ? "Copied!" : "Copy"}
              </span>
            </div>

            <div className="order-details__info mb-medium">
              <p>{order.service.name}</p>
              {order.service_type === "Gift Card" ? (
                <>
                  <p>{order.card_country.country_name}</p>
                  <p>{order.card_type.type_name}</p>
                </>
              ) : null}
              <p>
                <button className="order-details__type">
                  {order.type.toUpperCase()}
                </button>
              </p>
            </div>

            {order.service_type === "Gift Card" &&
            order.type === "buy" &&
            order.token ? (
              <div className="order-details__amt-block mb-medium">
                <h3 className="order-details__amt-heading">Card Number</h3>
                <p className="order-details__amt">{order.token}</p>
              </div>
            ) : null}

            {order.service_type === "Gift Card" &&
            order.type === "buy" &&
            order.token ? (
              <div className="order-details__amt-block mb-medium">
                <h3 className="order-details__amt-heading">Card Pin</h3>
                <p className="order-details__amt">{order.card_pin}</p>
              </div>
            ) : null}

            <div className="order-details__date mb-medium">
              {getDate(order.created_at)}
            </div>

            <div className="order-details__amt-block mb-medium">
              <h3 className="order-details__amt-heading">Total</h3>
              <p className="order-details__amt">
                {order.type === "buy" ? (
                  order.service_type === "Gift Card" ? (
                    <>
                      &#8358;{roundDP(order.order_amt, 2)} for {order.qty} x $
                      {order.amt_paid}
                    </>
                  ) : (
                    <>
                      &#8358;{roundDP(order.order_amt, 2)} = ${order.amt_paid} (
                      {roundDP(
                        order.order_amt_coin,
                        getPrecision(order.service.symbol)
                      )}{" "}
                      {order.service.symbol.toUpperCase()})
                    </>
                  )
                ) : order.service_type === "Crypto" ||
                  order.service_type === "crypto" ? (
                  <>
                    ${roundDP(order.amt_received, 2)} (
                    {roundDP(
                      order.order_amt_coin,
                      getPrecision(order.service.symbol)
                    )}{" "}
                    {order.service.symbol.toUpperCase()}) = &#8358;
                    {order.amt_paid}
                  </>
                ) : (
                  <>
                    $ {roundDP(order.order_amt, 2)}{" "}
                    {order.user_order_naira_amt ? (
                      <>
                        at &#8358;
                        {roundDP(order.user_order_naira_amt, 2)}
                      </>
                    ) : null}
                  </>
                )}
              </p>
            </div>

            {order.service_type === "Gift Card" &&
            (order.status === 0 || order.status === 3) ? (
              <>
                <p className="mb-small">Order Update</p>

                <div className="order-details__amt-block mb-small">
                  <h3 className="order-details__amt-heading">Total Received</h3>
                  <p className="order-details__amt">
                    ${roundDP(order.amt_received, 2).toLocaleString()}
                  </p>

                  <div className="order-details__amt-block mb-small">
                    <h3 className="order-details__amt-heading">Total</h3>
                    <p className="order-details__amt">
                      &#8358;{roundDP(order.amt_paid, 2).toLocaleString()}
                    </p>
                  </div>
                </div>
              </>
            ) : null}

            {order.service_type === "Gift Card" && order.type === "sell" ? (
              <div className="order-details__actions">
                <button
                  className="order-action order-action--success order-action--mobile"
                  onClick={() => setShowChat(true)}
                >
                  {[4, 1].includes(order.status)
                    ? "Proceed to Chat"
                    : "View Chat"}
                </button>
                {order.status === 1 ? (
                  <button
                    className="order-action order-action--danger"
                    onClick={() => setShowCancelOrderModal(true)}
                  >
                    Cancel Order
                  </button>
                ) : null}
                {order.status === 3 && !order.is_reviewed ? (
                  <button
                    className="order-action order-action--primary"
                    onClick={() => setShowReviewOrderModal(true)}
                  >
                    Review Order
                  </button>
                ) : null}
                {[3, 0].includes(order.status) && order.can_have_dispue ? (
                  <button
                    className="order-action order-action--danger"
                    onClick={() =>
                      navigate(`/order-dispute/${order.order_hash}`)
                    }
                  >
                    {order.pending_dispute === null
                      ? "Create Dispute"
                      : ["0", "1"].includes(order.pending_dispute)
                      ? "View Ongoing Dispute"
                      : order.pending_dispute === "2"
                      ? "View Resolved Dispute"
                      : null}
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            className={cls(
              "order-details__right",
              showChat && "order-details__right--show",
              (!messages ||
                order.service_type !== "Gift Card" ||
                order.type === "buy") &&
                "order-details__right--centered"
            )}
          >
            {order.service_type !== "Gift Card" || order.type === "buy" ? (
              <div className="text-lg">Order has no chat</div>
            ) : null}
            {order.service_type === "Gift Card" &&
            order.type === "sell" &&
            !messages ? (
              <VerticalBarLoader />
            ) : null}
            {order.service_type === "Gift Card" &&
            order.type === "sell" &&
            messages ? (
              <>
                <div
                  className="order-details__back"
                  onClick={() => setShowChat(false)}
                >
                  <IonIcon icon={arrowBackOutline} />
                  <p>Back to order details</p>
                </div>
                <div className="order-details__chat-block" ref={chatBlockRef}>
                  {messages.map((message) => {
                    let msgFiles: string[] = [];

                    if (message.has_file) {
                      msgFiles = message.file.split(",");
                    }

                    return (
                      <div
                        className={cls(
                          "chat",
                          message.from_user.id === userId && "chat--right"
                        )}
                        key={message.id}
                      >
                        {
                          <img
                            src={
                              message.from_user.id === userId
                                ? getProfilePicture(gender)
                                : avatarNeutral
                            }
                            alt=""
                            className="chat__img"
                          />
                        }
                        <div className="chat__msg">
                          <div className="chat__main">
                            {message.has_file ? (
                              <div
                                className={cls(
                                  "chat__imgs",
                                  msgFiles.length === 1
                                    ? "chat__imgs--1"
                                    : msgFiles.length === 2
                                    ? "chat__imgs--2"
                                    : msgFiles.length === 3
                                    ? "chat__imgs--3"
                                    : null
                                )}
                              >
                                {msgFiles.slice(0, 4).map((file, i) => {
                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        setPrevImgs({
                                          images: msgFiles,
                                          selectedImage: i,
                                        });
                                      }}
                                    >
                                      <img src={file} alt="" />
                                      {i === 3 && msgFiles.length > 4 ? (
                                        <div className="chat__img-overlay">
                                          +{msgFiles.length - 4}&lrm;
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                            {message.content ? (
                              <p>{message.content}&lrm;</p>
                            ) : null}
                          </div>
                          {message.created_at ? (
                            <p className="chat__time">{message.created_at}</p>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {[4, 1].includes(order.status) ? (
                  <div className="order-details__chat-footer">
                    <form className="chat-form" onSubmit={sendChatMessage}>
                      <input
                        type="text"
                        placeholder="Type message"
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                      />
                      <span
                        className="chat-form__send-img"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <IonIcon icon={imagesOutline} />
                      </span>
                      <button ref={sendChatMessageBtnRef}>Send</button>
                    </form>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </main>
    </>
  );
};

export default withAuth(OrderDetails);
