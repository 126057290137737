import { useState, useRef, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";

import useAlert from "../../../hooks/useAlert/useAlert";

type tCancelOrderModal = {
  show: boolean;
  order_status: number;
  order_hash: string;
  finishHandler: () => void;
  closeHandler: () => void;
};

const CancelOrderModal = ({
  show,
  order_status,
  order_hash,
  finishHandler,
  closeHandler,
}: tCancelOrderModal) => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [reason, setReason] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reason) return setMessage("warning", "The reason field is required");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: `/cancel-order`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ order_hash, reason }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error();
        }

        setMessage("success", "Order canceled successfully. Updating order...");
        finishHandler();
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.innerHTML = "Submit";
          submitBtnRef.current.removeAttribute("disabled");
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [reason, clearMessage]);

  if (!show || order_status !== 1) return null;

  return (
    <>
      <div className="classic-modal">
        <div className="classic-modal__header">
          <h3 className="classic-modal__heading">Reason for canceling</h3>
          <span className="classic-modal__close" onClick={closeHandler}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="classic-modal__body">
          <form className="auth__form-main mt-0" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Reason</label>
              <textarea
                placeholder="Enter reason for canceling"
                className="form-input"
                rows={3}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></textarea>
            </div>
            <div className="auth__footer">
              {message}
              <button
                className={cls("form-button", reason && "form-button--active")}
                ref={submitBtnRef}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default CancelOrderModal;
