import { useState, useEffect, useRef, FormEvent, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { tRootState } from "../../store";
import { updateUserDetails } from "../../store/userSlice";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import RegisterLayout from "../../layouts/RegisterLayout/RegisterLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import { assertNotNull } from "../../utils/func";

import authBio from "../../assets/img/auth-bio.png";

const VerifyTelephone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const [code, setCode] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (code.length !== 6)
      return setMessage("warning", "Enter phone verification code");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/verify-phone",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        sms_code: code,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        dispatch(updateUserDetails(res.data.message));
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Next";
        }
      });
  };

  const resendCodeHandler = (e: MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Resending code`;

    api_client({
      method: "POST",
      url: "/resend-phone-verification-code",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setMessage("success", res.data.message);
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        setMessage("error", err.message);
      })
      .finally(() => {
        target.innerHTML = "Resend Code";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [code, clearMessage]);

  return (
    <RegisterLayout>
      <div className="register__img-box">
        <img src={authBio} alt="" />
      </div>
      <h3 className="register__heading">Verify your phone number</h3>
      <p className="register__heading-sub-text">
        Enter the code we sent to your phone {userDetails.phone}
      </p>
      <form className="register__form" onSubmit={handleSubmit}>
        <div className="auth__form-main">
          <div className="register__pin-input-group">
            <PinGroup type="text" numInputs={6} pin={code} handler={setCode} />
          </div>
          {userDetails.centri > 0 ? (
            <p
              className="register__resend-code"
              onClick={() => navigate("/register/telephone")}
            >
              <span>Change Telephone</span>
            </p>
          ) : null}
        </div>
        <div className="auth__footer">
          {message}
          <button
            className={cls(
              "form-button",
              code.length === 6 && "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Verify Telephone
          </button>
          <p className="register__resend-code text-right">
            <span onClick={resendCodeHandler}>Resend Code</span>
          </p>
        </div>
      </form>
    </RegisterLayout>
  );
};

export default withAuth(VerifyTelephone);
