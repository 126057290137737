import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import { copyData, getDate, roundDP } from "../../../utils/func";

import { tCryptoSwapTransaction } from "../../../store/types/app.types";
import { getPrecision } from "../../../utils/app";

const CryptoSwapTransaction = ({
  transaction,
}: {
  transaction: tCryptoSwapTransaction;
}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  return (
    <div className="transaction-details">
      <h3 className="transaction-details__heading">
        <span onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        Crypto Swap Details
      </h3>
      <p className="transaction-details__heading-subtext">
        Crypto Swap Transaction Details
      </p>
      <div className="transaction-details__infos">
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount Swap</p>
          <div className="transaction-details__info-right">
            {roundDP(
              transaction.amount_swap,
              getPrecision(transaction.coin_from)
            )}{" "}
            {transaction.coin_from.toUpperCase()}
            {/* ($
            {roundDP(transaction.amount_swap_dollar, 2)}) */}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount Get</p>
          <div className="transaction-details__info-right">
            {roundDP(transaction.amount_get, getPrecision(transaction.coin_to))}{" "}
            {transaction.coin_to.toUpperCase()}
            {/* ($
            {roundDP(transaction.amount_get_dollar, 2)}) */}
          </div>
        </div>
        {/* <div className="transaction-details__info">
          <p className="transaction-details__info-key">Fees</p>
          <div className="transaction-details__info-right">
            {roundDP(
              transaction.fees_coin_value,
              getPrecision(transaction.coin_from)
            )}{" "}
            {transaction.coin_from.toUpperCase()}
            ($
            {roundDP(transaction.fees, 2)})
          </div>
        </div> */}
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Hash</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.swap_hash}
            </p>
            <div
              className="copy"
              onClick={() => copyData(transaction.swap_hash, setCopied)}
            >
              <IonIcon icon={copy} />
              {copied ? "Copied!" : "Copy"}
            </div>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Status</p>
          <div className="transaction-details__info-right">
            <span className="badge badge--success"> Confirmed </span>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Date</p>
          <div className="transaction-details__info-right">
            {getDate(transaction.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoSwapTransaction;
