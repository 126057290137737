import { MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";
import { AxiosRequestConfig } from "axios";

import { IonIcon } from "@ionic/react";
import { clipboardOutline, close, copy } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import useAlert from "../../hooks/useAlert/useAlert";
import PinGroup from "../PinGroup/PinGroup";

import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

import { assertNotNull, copyData, isNumber } from "../../utils/func";
import useData from "../../hooks/useData/useData";

const SetAuth = ({
  callback,
  closeHandler,
  defaultAuthType,
  ignoreAuthSet = false,
}: {
  callback: (() => void) | null;
  closeHandler: () => void;
  defaultAuthType?: 0 | 1 | 2;
  ignoreAuthSet?: boolean;
}) => {
  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const { is_auth_set, is_g_auth_set } = userDetails;

  const { fetchProfile } = useData();

  const [authType, setAuthType] = useState<0 | 1 | 2>(defaultAuthType || 0);

  const [errorLoadinQR, setErrorLoadingQR] = useState(false);
  const [reload, setReload] = useState(true);

  const [code, setCode] = useState("");

  const [qrInfo, setQRInfo] = useState<{
    code: string;
    setupKey: string;
  } | null>(null);

  const [copied, setCopied] = useState(false);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSelect = (e: MouseEvent<HTMLButtonElement>) => {
    if (authType !== 0) {
      if (is_g_auth_set === 0 && !qrInfo)
        return setMessage(
          "warning",
          "Kindly complete the google authentication step"
        );

      if (!code) return setMessage("warning", "Code is required");
    }

    const target = e.target! as HTMLButtonElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    let requestData: Partial<AxiosRequestConfig> = {
      headers: { Authorization: `Bearer ${accessToken}` },
      method: "POST",
    };

    if (authType === 0) {
      requestData.url = "/enable-email";
    } else {
      requestData.url = "/enable-withdrawal-settings";
      requestData.data = {
        route: authType === 1 ? "auth" : "email-auth",
        otp: code,
      };
    }

    api_client(requestData)
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return fetchProfile();
      })
      .then((res) => {
        if (ignoreAuthSet && callback) callback();
      })
      .catch((err) => {
        if (err.message) setMessage("error", "An error occured. Try again");
      })
      .finally(() => {
        if (target) {
          target.removeAttribute("disabled");
          target.innerHTML = "Select";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [clearMessage, authType, qrInfo, code]);

  useEffect(() => {
    if (authType === 0 || is_g_auth_set === 1) return;

    api_client({
      url: "/withdrawal-auth-settings",
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setQRInfo({
          code: res.data.message.qr_code,
          setupKey: res.data.message.secret,
        });
      })
      .catch((err) => {
        setErrorLoadingQR(true);
      });
  }, [authType, reload, accessToken, is_g_auth_set]);

  useEffect(() => {
    if (ignoreAuthSet) return;
    if (is_auth_set === 1 && callback) callback();
  }, [is_auth_set, callback, ignoreAuthSet]);

  if ((is_auth_set === 1 && !ignoreAuthSet) || !callback) return null;

  let qrComponent;

  if (authType !== 0) {
    if (qrInfo || is_g_auth_set === 1) {
      qrComponent = (
        <div className="qr">
          {qrInfo ? (
            <>
              <p className="qr__text">
                Download and install the Google Authenticator app on your
                smartphone from the relevant app store. When prompted, use your
                smartphone camera to scan the generated QR code, OR, enter the
                setup key below into the Google Authenticator app on your
                smartphone.
              </p>
              <div className="qr__table-container">
                <table className="qr__table">
                  <tbody>
                    <tr>
                      <th className="qr__cell">QR Code:</th>
                      <td>
                        <div className="qr__block">
                          <img src={qrInfo.code} alt="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Setup key:</th>
                      <td>
                        <p className="qr__key">{qrInfo.setupKey}</p>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        <span
                          className="copy copy--lg"
                          onClick={() => copyData(qrInfo.setupKey, setCopied)}
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          <IonIcon icon={copy} /> {copied ? "Copied!" : "Copy"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          {qrInfo || is_g_auth_set === 1 ? (
            <>
              <p className="text-center">
                Kindly enter the code generated from your Google Authenticator
                to continue.
              </p>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 6);
                    if (isNumber(text)) setCode(text);
                  });
                }}
                style={{ alignSelf: "center" }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div
                className="register__pin-input-group-2"
                style={{ justifyContent: "center" }}
              >
                <PinGroup
                  numInputs={6}
                  pin={code}
                  handler={setCode}
                  type="text"
                />
              </div>
            </>
          ) : null}
        </div>
      );
    } else {
      qrComponent = !errorLoadinQR ? (
        <div className="qr">
          <VerticalBarLoader sm />
        </div>
      ) : (
        <div className="qr">
          <div className="text-center">
            An error occured.{" "}
            <span className="link" onClick={() => setReload((rl) => !rl)}>
              Try again
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="withdraw-type-modal withdraw-type-modal--1">
        <span className="withdraw-type-modal__close" onClick={closeHandler}>
          <IonIcon icon={close} />
        </span>
        <div className="withdraw-type-modal__header">
          <h3 className="withdraw-type-modal__heading">OTP Confirmation</h3>
          <p className="text-center">
            How would you like to receive your OTP to confirm transactions?
          </p>
        </div>
        <div className="withdraw-type-modal__options">
          <label>
            <input
              type="radio"
              name="type"
              className="radio-1"
              checked={authType === 0}
              onChange={(e) => setAuthType(0)}
            />
            <p>Via Email</p>
          </label>
          <label>
            <input
              type="radio"
              name="type"
              className="radio-1"
              checked={authType === 1}
              onChange={(e) => setAuthType(1)}
            />
            <p>Via Google Auth</p>
          </label>
          {authType === 1 ? qrComponent : null}
          <label>
            <input
              type="radio"
              name="type"
              value="external"
              className="radio-1"
              checked={authType === 2}
              onChange={(e) => setAuthType(2)}
            />
            <p>Via Email and Google Auth</p>
          </label>
          {authType === 2 ? qrComponent : null}
        </div>
        {message}
        <button
          className={cls(
            "form-button withdraw-type-modal__button",
            (authType === 0 ||
              ((is_g_auth_set === 1 || qrInfo) && code.length === 6)) &&
              "form-button--active"
          )}
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default SetAuth;
