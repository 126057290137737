import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { tRootState } from "../../store";
import { Navigate } from "react-router-dom";
import { useCallback } from "react";
import { updateSKPConverted } from "../../store/cacheSlice";
import { roundDP } from "../../utils/func";

const SKPConverted = () => {
  const dispatch = useDispatch();

  const skpConverted = useSelector(
    (state: tRootState) => state.cache.skpConverted
  );

  const clearData = useCallback(
    () => dispatch(updateSKPConverted(null)),
    [dispatch]
  );

  // useEffect(() => {
  //   const interval = window.setInterval(() => {
  //     clearData();
  //     window.clearInterval(interval);
  //   }, 4000);
  // }, [clearData]);

  if (!skpConverted) return <Navigate to={"/dashboard"} />;

  return (
    <DashboardLayout>
      <div className="naira-block naira-block--final">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <div className="naira-block__header mb-medium">
          <h3
            className="naira-block__heading"
            style={{ marginBottom: "1.2rem" }}
          >
            All done, congrats!
          </h3>
          <div className="naira-block__sub-heading text-center">
            <p style={{ marginBottom: "1.2rem" }}>
              You have successfully converted{" "}
              <span style={{ fontWeight: "700" }}>
                {skpConverted.points}SKP
              </span>{" "}
              FOR{" "}
              <span style={{ fontWeight: "700" }}>
                &#8358;
                {roundDP(skpConverted.nairaValue, 2)}
              </span>
              , The funds has been credited to your naira account.
            </p>
            <p>Cheers 🥂 to more earnings 😉</p>
          </div>
        </div>
        <div className="auth__footer mb-medium">
          <button
            className="form-button form-button--active"
            onClick={clearData}
          >
            Done
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SKPConverted;
