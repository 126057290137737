import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import userReducer from "./userSlice";
import cacheReducer from "./cacheSlice";
import assetReducer from "./assetSlice";
import paybillsReducer from "./paybillsSlice";
import cryptoReducer from "./cryptoSlice";
import giftcardReducer from "./giftcardSlice";
import buyGiftcardReducer from "./buyGiftcardSlice";
import nairaReducer from "./nairaSlice";
import vcardReducer from "./vcardSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    cache: cacheReducer,
    asset: assetReducer,
    paybills: paybillsReducer,
    crypto: cryptoReducer,
    giftcard: giftcardReducer,
    buyGiftcard: buyGiftcardReducer,
    naira: nairaReducer,
    vcard: vcardReducer,
  },
});

export type tRootState = ReturnType<typeof store.getState>;
