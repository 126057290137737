import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { addOutline, chevronBackOutline, removeOutline } from "ionicons/icons";

import withAuth from "../../hoc/withAuth/withAuth";
import giftCardHoc from "../../hoc/buyGiftCard/buyGiftCard";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { clearGiftCardData } from "../../store/giftcardSlice";

import useAlert from "../../hooks/useAlert/useAlert";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import PinGroup from "../../components/PinGroup/PinGroup";
import SetPin from "../../components/SetPin/SetPin";
import ContactVerification from "../../components/ContactVerification/ContactVerification";
import SetAuth from "../../components/SetAuth/SetAuth";

type tDenomination = {
  productId: string;
  hasRange: boolean;
  minDenomination: number;
  maxDenomination: number;
  fixedRecipientDenomination: number;
};
type tDenominations = tDenomination[];

const BuyGiftCardOrderCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const { giftCard, cardCountry } = useSelector(
    (state: tRootState) => state.buyGiftcard
  );

  assertNotNull(userDetails);
  assertNotNull(giftCard);
  assertNotNull(cardCountry);

  const [denominations, setDenominations] = useState<tDenominations | null>(
    null
  );

  const [quantity, setQuantity] = useState<number | "">(1);
  const [denomination, setDenomination] = useState<tDenomination | null>(null);

  const [fetchingRates, setFetchingRates] = useState(false);
  const [errorFetchingRates, setErrorFetchingRates] = useState(false);

  const frInterval = useRef<number | null>(null);

  const [rate, setRate] = useState<number | null>(null);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const [pin, setPin] = useState("");

  const [authMethodCallback, setAuthMethodCallback] = useState<
    (() => void) | null
  >(null);
  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (fetchingRates) return;

    if (!denomination || !quantity || !rate) return;

    if (+quantity < 1) return setMessage("warning", "Invalid quantity");

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Enter your pin");

    setContactVerificationCallback(
      () => () =>
        setAuthMethodCallback(() => () => {
          const submitBtn = submitBtnRef.current;

          submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Creating order`;
          submitBtn.setAttribute("disabled", "disabled");

          api_client({
            method: "POST",
            url: "/cards/purchase-card",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: {
              amount: denomination.fixedRecipientDenomination,
              quantity: quantity,
              product_id: denomination.productId,
              service_id: giftCard.service_id,
              country_id: cardCountry.country_id,
              pin: pin,
            },
          })
            .then((res) => {
              if (res.data.success) {
                setMessage("success", res.data.message);

                navigate(`/buy-gift-cards/otp/${res.data.order_hash}`);

                setTimeout(() => {
                  dispatch(clearGiftCardData());
                }, 5000);
              } else {
                setMessage("warning", res.data.message);
              }
            })
            .catch((err) => {
              setMessage("error", err.message);
            })
            .finally(() => {
              if (submitBtn) {
                submitBtn.removeAttribute("disabled");
                submitBtn.innerHTML = "Create Order";
              }
            });
        })
    );
  };

  useEffect(() => {
    if (frInterval.current) window.clearInterval(frInterval.current);

    if (
      !denomination?.productId ||
      !denomination?.fixedRecipientDenomination ||
      !quantity
    ) {
      setFetchingRates(false);
      setErrorFetchingRates(false);
      setRate(null);
      return;
    }

    setFetchingRates(true);
    setErrorFetchingRates(false);
    setRate(null);

    frInterval.current = window.setInterval(() => {
      api_client({
        url: `/cards/exchange-rate/${denomination?.productId}/${quantity}/${denomination?.fixedRecipientDenomination}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setRate(res.data.message);
        })
        .catch(() => {
          setErrorFetchingRates(true);
        })
        .finally(() => {
          setFetchingRates(false);
          if (frInterval.current) window.clearInterval(frInterval.current);
        });
    }, 3000);
  }, [
    denomination?.productId,
    denomination?.fixedRecipientDenomination,
    quantity,
    accessToken,
  ]);

  useEffect(() => {
    clearMessage();
  }, [quantity, denomination, pin, clearMessage]);

  useEffect(() => {
    if (!giftCard?.productName || !cardCountry?.currency) return;

    api_client({
      url: `/cards/list-offers/${
        giftCard.productName
      }/${cardCountry.currency.toLowerCase()}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success || !res.data.message.length) throw new Error("");

        setDenominations(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [accessToken, giftCard?.productName, cardCountry?.currency, navigate]);

  if (!denominations) return <Preloader />;

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />
      <SetAuth
        callback={authMethodCallback}
        closeHandler={() => setAuthMethodCallback(null)}
      />
      <ul className="breadcrumb">
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            Gift Cards
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            {giftCard.productName}
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/buy-gift-cards/country" className="breadcrumb__link">
            {cardCountry.countryName}
          </Link>
        </li>
        <li className="breadcrumb__item breadcrumb__item--active">
          <Link to="#" className="breadcrumb__link">
            Create Order
          </Link>
        </li>
      </ul>
      <div className="giftcards-pg__header">
        <div className="giftcards-pg__header-main">
          <h3 className="giftcards-pg__heading">Create Gift Card Order</h3>
          <div className="step-radio-btns">
            <input
              type="radio"
              className="radio"
              name="step"
              value="1"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="2"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="3"
              checked
              readOnly
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="4"
              disabled
            />
          </div>
        </div>
      </div>
      <form className="giftcard-block" onSubmit={handleSubmit}>
        <div className="giftcard-block__form">
          <div className="form-group">
            <label>Select denomination</label>
            <ul className="giftcard-block__denominations">
              {denominations.map((den) => {
                return (
                  <li
                    className={cls(
                      "giftcard-block__denomination",
                      denomination?.productId === den.productId &&
                        "giftcard-block__denomination--active"
                    )}
                    key={den.productId}
                  >
                    <input
                      type="checkbox"
                      className="radio"
                      name="minimum"
                      checked={den.productId === denomination?.productId}
                      onChange={() => setDenomination(den)}
                    />
                    <p
                      className="giftcard-block__denomination-text"
                      onClick={() => setDenomination(den)}
                    >
                      ${den.fixedRecipientDenomination}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="form-group">
            <label>How many gift card are you buying</label>
            <div className="input-group">
              <button
                type="button"
                onClick={() => {
                  setQuantity((quan) => {
                    if (!quan) return 1;

                    return quan === 1 ? 1 : quan - 1;
                  });
                }}
              >
                <IonIcon icon={removeOutline} className="c-icon" />
              </button>
              <input
                type="text"
                placeholder="Enter quantity"
                value={quantity}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setQuantity(+e.target.value)
                      : null
                    : setQuantity("")
                }
              />
              <button
                type="button"
                onClick={() => {
                  setQuantity((quan) => {
                    if (!quan) return 1;

                    return quan + 1;
                  });
                }}
              >
                <IonIcon icon={addOutline} className="c-icon" />
              </button>
            </div>
          </div>
          {fetchingRates ? <VerticalBarLoader sm /> : null}
          {errorFetchingRates ? (
            <div className="text-center text-danger">Error fetching rates</div>
          ) : null}
          {rate !== null ? (
            <div className="giftcard-block__rab">
              <p className="giftcard-block__rab-label">You will pay</p>
              <div className="giftcard-block__rab-div">
                &#8358;{roundDP(rate, 2)}
              </div>
            </div>
          ) : null}

          {userDetails.is_pin_set ? (
            <div
              className="crypto-final__pin-section"
              style={{ paddingTop: "3.6rem", paddingBottom: "3.6rem" }}
            >
              <h3 className="crypto-final__heading">Enter PIN</h3>
              <div className="register__pin-input-group-2">
                <PinGroup numInputs={4} pin={pin} handler={setPin} />
              </div>
              <span
                className="crypto-final__forgot-pin"
                onClick={() => navigate("/change-pin")}
              >
                Forgot PIN
              </span>
            </div>
          ) : null}

          {message}
        </div>
        <div className="giftcard-btns giftcard-btns--left">
          <button onClick={() => navigate(-1)}>
            <IonIcon icon={chevronBackOutline} /> Go Back
          </button>
          <button
            className={cls(
              quantity &&
                +quantity >= 1 &&
                denomination &&
                !fetchingRates &&
                rate &&
                (pin.length === 4 || !userDetails.is_pin_set) &&
                "active"
            )}
            ref={submitBtnRef}
          >
            Create Order
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(giftCardHoc(BuyGiftCardOrderCreate));
