import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBack, chevronDownOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import SelectCurrencyModal from "../../components/SelectCurrencyModal/SelectCurrencyModal";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";

import { isNumber, roundDP } from "../../utils/func";
import { updateBuyCrypto } from "../../store/cryptoSlice";
import { getPrecision } from "../../utils/app";

type tRateInfo = {
  amount_in_coin: number;
  amount_in_naira: number;
  rate: string;
};

const BuyCrypto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const walletSymbol = useSearchParams()[0].get("wallet")?.toLowerCase();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const wallets = useSelector((state: tRootState) => state.cache.wallets);

  const { fetchWallets } = useData();

  const [wallet, setWallet] = useState<undefined | tWallet>(
    wallets?.find((sWallet) => sWallet.symbol.toLowerCase() === walletSymbol)
  );
  const nairaWallet = wallets?.find(
    (sWallet) => sWallet.symbol.toLowerCase() === "ngn"
  );

  const [message, setMessage, clearMessage] = useAlert(true);

  const [showSelectFiatModal, setShowSelectFiatModal] = useState(false);
  const [showSelectWalletModal, setShowSelectWalletModal] = useState(false);

  const [amount, setAmount] = useState<number | string>("");

  const [fetchingRates, setFetchingRates] = useState(false);
  const [errorFetchingRates, setErrorFetchingRates] = useState(false);

  const frInterval = useRef<number | null>(null);

  const [rateInfo, setRateInfo] = useState<tRateInfo | null>(null);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!nairaWallet) return;

    if (!wallet) return setMessage("warning", "Select wallet");
    if (!amount) return setMessage("warning", "The amount field is required");

    if (fetchingRates) return;
    if (!rateInfo) return setMessage("warning", "Error fetching rate");

    if (nairaWallet.balance / +rateInfo.rate < +amount)
      return setMessage("warning", "Insfficient balance");

    dispatch(
      updateBuyCrypto({
        wallet,
        dollarAmount: +amount,
        cryptoAmount: rateInfo.amount_in_coin,
        nairaAmount: rateInfo.amount_in_naira,
      })
    );

    navigate("/buy-crypto-final");
  };

  useEffect(() => {
    clearMessage();
  }, [wallet, amount, clearMessage]);

  useEffect(() => {
    if (frInterval.current) window.clearInterval(frInterval.current);

    if (!wallet?.id || !amount) {
      setFetchingRates(false);
      setErrorFetchingRates(false);
      setRateInfo(null);
      return;
    }

    setFetchingRates(true);
    setErrorFetchingRates(false);
    setRateInfo(null);

    frInterval.current = window.setInterval(() => {
      api_client({
        url: `/wallet/rate/buy?wallet_id=${wallet.id}&amount=${amount}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const rtInfo: tRateInfo = res.data;

          if (
            typeof rtInfo !== "object" ||
            !rtInfo.amount_in_coin ||
            Number.isNaN(+rtInfo.amount_in_coin) ||
            !rtInfo.amount_in_naira ||
            Number.isNaN(+rtInfo.amount_in_naira) ||
            !rtInfo.rate ||
            Number.isNaN(+rtInfo.rate)
          ) {
            setErrorFetchingRates(true);
          } else {
            setRateInfo(res.data);
          }
        })
        .catch(() => {
          setErrorFetchingRates(true);
        })
        .finally(() => {
          setFetchingRates(false);
          if (frInterval.current) window.clearInterval(frInterval.current);
        });
    }, 3000);
  }, [amount, wallet?.id, accessToken]);

  useEffect(() => {
    if (wallets && walletSymbol && !wallet)
      setWallet(
        wallets?.find(
          (sWallet) => sWallet.symbol.toLowerCase() === walletSymbol
        )
      );
  }, [wallets, walletSymbol, wallet]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  if (!wallets) return <Preloader />;

  // Should never happen
  if (!nairaWallet) return null;

  return (
    <DashboardLayout>
      <SelectCurrencyModal
        show={showSelectWalletModal}
        type="crypto"
        selectHandler={(selWallet) => {
          setWallet(selWallet);
          setShowSelectWalletModal(false);
        }}
        closeHandler={() => setShowSelectWalletModal(false)}
        selectedWallet={wallet?.symbol}
      />
      <SelectCurrencyModal
        show={showSelectFiatModal}
        type="fiat"
        selectHandler={() => setShowSelectFiatModal(false)}
        closeHandler={() => setShowSelectFiatModal(false)}
        selectedWallet={nairaWallet.symbol}
      />
      <div className="crypto-block">
        <div className="crypto-block__header">
          <div className="crypto-block__back">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} /> Back
            </span>
          </div>
          <div className="tab-buttons tab-buttons--md">
            <button className="active">Buy</button>
            <button
              onClick={() =>
                navigate(
                  `/sell-crypto${
                    walletSymbol &&
                    walletSymbol.toLowerCase() === wallet?.symbol.toLowerCase()
                      ? `?wallet=${walletSymbol}`
                      : ""
                  }`
                )
              }
            >
              Sell
            </button>
          </div>
        </div>
        <div className="crypto-block__body">
          <div className="crypto-block__balance-block">
            <p className="crypto-block__balance-text">
              Available Naira balance
            </p>
            <p className="crypto-block__balance">
              {roundDP(nairaWallet.balance, 2)} NGN
            </p>
          </div>
          <div className="crypto-block__body-main">
            <div
              className="select-box select-box--md"
              onClick={() => setShowSelectFiatModal(true)}
            >
              <img src={nairaWallet.image} alt="" />
              <p>Naira</p>
              <IonIcon icon={chevronDownOutline} />
            </div>
            <div
              className="select-box select-box--md"
              onClick={() => setShowSelectWalletModal(true)}
            >
              {wallet ? <img src={wallet.image} alt="" /> : null}
              <p>{wallet ? wallet.coin : "Select wallet"}</p>
              <IonIcon icon={chevronDownOutline} />
            </div>
            <div className="form-group form-group--md">
              {rateInfo ? (
                <p>MAX: ${roundDP(nairaWallet.balance / +rateInfo.rate, 2)}</p>
              ) : null}
              <div className="input-group">
                <button type="button">$</button>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setAmount(e.target.value)
                        : null
                      : setAmount("")
                  }
                />
              </div>
              {rateInfo ? (
                <div className="input-group">
                  <button type="button">{wallet?.symbol.toUpperCase()}</button>
                  <input
                    type="text"
                    value={rateInfo.amount_in_coin}
                    disabled
                    className="form-disabled"
                  />
                </div>
              ) : null}
              {rateInfo ? (
                <AmountPercentage
                  totalAmount={nairaWallet.balance / +rateInfo.rate}
                  handler={setAmount}
                />
              ) : null}
            </div>
            {fetchingRates ? <VerticalBarLoader sm /> : null}
            {errorFetchingRates ? (
              <div className="text-center text-danger">
                Error fetching rates
              </div>
            ) : null}
            {wallet && rateInfo ? (
              <div className="crypto-block__rate-info">
                <p>
                  <IonIcon icon={alertCircle} /> RATE: $1 = &#8358;
                  {roundDP(rateInfo.rate, 2)}
                </p>
                <p>
                  YOU GET ={" "}
                  {roundDP(
                    rateInfo.amount_in_coin,
                    getPrecision(wallet.symbol)
                  )}{" "}
                  {wallet?.symbol.toUpperCase()}
                </p>
              </div>
            ) : null}
            <div className="auth__footer">
              {message}
              <button
                className={cls(
                  "form-button",
                  wallet && amount && rateInfo && "form-button--active"
                )}
                onClick={handleSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(BuyCrypto);
