import { useState, useRef, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  arrowBackOutline,
  checkmark,
  checkmarkCircle,
  chevronDownOutline,
  close,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updateBankAdded } from "../../store/cacheSlice";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import RingLoader from "../../loaders/RingLoader/RingLoader";

import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";
import { assertNotNull } from "../../utils/func";
import withAuth from "../../hoc/withAuth/withAuth";

const AddBank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const banks = useSelector((state: tRootState) => state.cache.depositAddBanks);

  assertNotNull(userDetails);

  const { fetchBanks } = useData();

  const [loadingBanks, setLoadingBanks] = useState(true);

  let banksSelectBoxData: SelectBoxDataType = [];

  banks?.forEach((bank) => {
    banksSelectBoxData.push({
      key: bank.bank_code,
      value: { text: bank.bank_name },
    });
  });

  const [bankCodeSelectBox, bankCode, openBankCodeSelectBox] =
    useSelectBox<string>("Select Bank", banksSelectBoxData, null);

  const [accountNumber, setAccountNumber] = useState("");

  const anverifyInterval = useRef<number | null>(null);

  const [verifyingAccount, setVerifyingAccount] = useState(false);
  const [errorVerifyingAccount, setErrorVerifyingAccount] = useState(false);

  const [accountName, setAccountName] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!bankCode) return setMessage("warning", "Select bank");

    if (!accountNumber) return setMessage("warning", "Enter account number");

    if (!accountName)
      return setMessage("warning", "Could not validate account details");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/add-bank",
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ bank: bankCode, account_number: accountNumber }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error();
        }

        dispatch(updateBankAdded(true));

        navigate("/bank-added");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Add Bank Account";
        }
      });
  };

  useEffect(() => {
    if (anverifyInterval.current)
      window.clearInterval(anverifyInterval.current);

    if (!bankCode || accountNumber.length !== 10) return;

    setVerifyingAccount(true);
    setErrorVerifyingAccount(false);
    setAccountName("");

    anverifyInterval.current = window.setInterval(() => {
      api_client({
        url: `/get-nuban-details/${bankCode}/${accountNumber}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          setAccountName(res.data.message.account_name);
        })
        .catch((err) => {
          setErrorVerifyingAccount(true);
        })
        .finally(() => {
          setVerifyingAccount(false);
          if (anverifyInterval.current)
            window.clearInterval(anverifyInterval.current);
        });
    }, 3000);
  }, [accessToken, accountNumber, bankCode]);

  useEffect(() => {
    clearMessage();
  }, [bankCode, accountNumber, clearMessage]);

  useEffect(() => {
    if (!loadingBanks) return;
    fetchBanks("deposit-add")
      .then(() => setLoadingBanks(false))
      .catch((err) => {
        if (!banks) navigate("/error");
      });
  }, [fetchBanks, navigate, loadingBanks, banks]);

  if (!banks) return <Preloader />;

  const bank = banks?.find((bank) => bank.bank_code === bankCode);

  return (
    <DashboardLayout>
      {bankCodeSelectBox}
      <div className="form-card">
        <div className="form-card__back mb-medium" onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
          Back
        </div>
        <div className="text-center mb-medium">
          <h3 className="heading-tertiary">Add New Bank Account</h3>
          <p>
            <strong>NOTE:</strong> Accounts added here are solely for deposits
            and not for withdrawals. Make sure it is your own account and the
            account name matches your verified name {userDetails.names}
          </p>
        </div>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Bank</label>
            <div className="select-box" onClick={openBankCodeSelectBox}>
              <p>{bank ? bank.bank_name : "Select Bank"}</p>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          <div className="form-group">
            <label>Account number</label>
            <div className="form-input-group">
              <input
                type="text"
                placeholder="Enter account number"
                name="account_number"
                autoComplete="off"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                maxLength={10}
              />
              <button>
                {accountNumber.length === 10 && verifyingAccount ? (
                  <RingLoader />
                ) : null}
                {accountNumber.length === 10 && accountName ? (
                  <IonIcon icon={checkmark} className="text-success" />
                ) : null}
                {accountNumber.length === 10 && errorVerifyingAccount ? (
                  <IonIcon icon={close} className="text-warning" />
                ) : null}
              </button>
            </div>
            {accountNumber.length === 10 && accountName ? (
              <span className="form-bottom-label text-success">
                <IonIcon icon={checkmarkCircle} className="active" />
                {accountName}
              </span>
            ) : null}
            {accountNumber.length === 10 && errorVerifyingAccount ? (
              <span className="form-bottom-label text-danger">
                <IonIcon icon={alertCircle} className="active" />
                Could not verify account
              </span>
            ) : null}
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                bank && accountNumber && accountName && "form-button--active"
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Add Bank Account
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(AddBank);
