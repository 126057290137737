import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tSKPHistory } from "../../store/types/app.types";

import api_client from "../../api/client";

import InfluencersTransactionsLoader from "../../loaders/InfluencersTransactionsLoader/InfluencersTransactionsLoader";

import { getDateTime } from "../../utils/func";

import { MONTHS_SHORT } from "../../data";
import { updateSKPHistory } from "../../store/cacheSlice";

const SKPHistory = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const transactions = useSelector(
    (state: tRootState) => state.cache.skphistory
  );

  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);

    api_client({
      url: "/user-earnings",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        const trans = (res.data.message as tSKPHistory).map((trns) => {
          const [date, time] = getDateTime(trns.created_at);

          const calcDate = new Date(date);

          const totalDate = `${
            MONTHS_SHORT[calcDate.getMonth()]
          }, ${calcDate.getDate()}`;

          trns.date = totalDate;
          trns.time = time;
          trns.year = calcDate.getFullYear();

          return trns;
        });
        dispatch(updateSKPHistory(trans));
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
      });
  }, [accessToken, dispatch]);

  if (!transactions) return <InfluencersTransactionsLoader />;

  if (error)
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={5} className="text-center">
              Error fetching recent transactions
            </td>
          </tr>
        </tbody>
      </table>
    );

  if (!transactions.length)
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={5} className="text-center">
              You have no recent transaction
            </td>
          </tr>
        </tbody>
      </table>
    );

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Transaction</th>
              <th>Points</th>
              <th>Total Points</th>
              <th className="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, i) => (
              <tr key={i}>
                <td>
                  <div className="transaction-label transaction-label--deposit">
                    <div className="transaction-label__icon-box">
                      <IonIcon icon={arrowDownOutline} />
                    </div>
                  </div>
                </td>
                <td>{transaction.content}</td>
                <td>{transaction.skp} SKP</td>
                <td className="bold">{transaction.total_skp} SKP</td>
                <td>
                  <div className="transaction-date">
                    <p>{transaction.date}</p>
                    <p>{transaction.time}</p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="transactions-flex">
        {transactions.map((transaction, i) => (
          <div className="transaction-mobile" key={i}>
            <div className="transaction-label transaction-label--deposit">
              <div className="transaction-label__icon-box">
                <IonIcon icon={arrowDownOutline} />
              </div>
            </div>
            <div className="transaction-mobile__main">
              <p className="transaction-mobile__hash">{transaction.content}</p>
            </div>
            <div className="transaction-mobile__right">
              <p className="transaction-mobile__amount text-success">
                {transaction.skp} SKP
              </p>
              <p className="transaction-mobile__date">
                {transaction.date}, {transaction.year}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SKPHistory;
