import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import Spinner from "../../loaders/Spinner/Spinner";
import MatiButton from "../../components/MatiButton/MatiButton";

import { assertNotNull } from "../../utils/func";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";
import verifyImg from "../../assets/img/verify-img.png";

const RegisterVerify = () => {
  const navigate = useNavigate();

  const { userDetails, hideVerifButton } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const { is_verified, verif_status } = userDetails;

  if (is_verified === 1) return <Navigate to="/dashboard" />;
  if (verif_status === "rejected") return <Navigate to="/dashboard" />;

  return (
    <AuthLayout variation>
      {verif_status === "pending" ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>Verification processing!</h3>
            <p className="text-center">
              Chief, your verification request has been sent. Check back in the
              next few minutes to get the status of your verification.
            </p>
            <div className="success-modal__btns">
              <button
                className="form-button form-button--active"
                onClick={() => navigate("/dashboard")}
              >
                Finish
              </button>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      ) : null}
      {verif_status !== "pending" && hideVerifButton ? <Spinner /> : null}
      <div className="auth auth--md">
        <div className="auth__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <div className="auth__img-block mt-lg">
          <img className="auth__img auth__img--1" src={verifyImg} alt="" />
        </div>
        <h2 className="auth__heading">Verify your account</h2>
        <p className="auth__heading-sub-text">
          Be a verified Sekiapp tribe. As a verified Sekiapp tribe you get to
          enjoy lots of Sekiapp goodies
        </p>
        <form className="auth__form-main">
          <ul className="check-list">
            <li>
              <IonIcon icon={checkmarkCircleOutline} />
              Higher withdrawal limit
            </li>
            <li>
              <IonIcon icon={checkmarkCircleOutline} />
              Higher bill payment Limit
            </li>
            <li>
              <IonIcon icon={checkmarkCircleOutline} />
              Higher Deposit limit
            </li>
          </ul>
          <div className="auth__footer auth__footer--1 mt-medium">
            {/* <button className="form-button form-button--active" type="button">
              Verify me
            </button> */}
            {verif_status !== "pending" ? <MatiButton /> : null}
            <p>
              <span
                className="cursor-pointer text-underline"
                onClick={() => navigate("/dashboard")}
              >
                Skip verification
              </span>
            </p>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default withAuth(RegisterVerify);
