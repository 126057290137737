import React, { useState, useEffect, useRef, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";

import { tRootState } from "../../store";
import { updateUserDetails } from "../../store/userSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import RegisterLayout from "../../layouts/RegisterLayout/RegisterLayout";

import authBio from "../../assets/img/auth-email.png";

const VerifyEmail = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const [emailCode, setEmailCode] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailCode)
      return setMessage("warning", "Email verification code is required");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/verify-email",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        email_code: emailCode,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        dispatch(updateUserDetails(res.data.message));
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Next";
        }
      });
  };

  const resendCode = (e: React.MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Resending code...`;

    api_client({
      method: "POST",
      //   mode: "no-cors",
      url: "/resend-email-verification-code",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setMessage("success", res.data.message);
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        setMessage("error", err.message);
      })
      .finally(() => {
        target.innerHTML = "Resend code";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [emailCode, clearMessage]);

  return (
    <RegisterLayout>
      <div className="register__img-box">
        <img src={authBio} alt="" />
      </div>
      <h3 className="register__heading">Confirm Email Address</h3>
      <p className="register__heading-sub-text">
        Chief, kindly check your inbox for a verification code
      </p>
      <form className="register__form" onSubmit={handleSubmit}>
        <div className="auth__form-main">
          <div className="form-group">
            <input
              className="form-input"
              type="text"
              placeholder="Enter verification code"
              autoComplete="off"
              value={emailCode}
              onChange={(e) => setEmailCode(e.target.value)}
            />
          </div>
        </div>
        <div className="auth__footer">
          {message}
          <button
            className={cls("form-button", emailCode && "form-button--active")}
            ref={submitBtnRef}
          >
            Verify Email
          </button>
          <p className="register__resend-code">
            <span onClick={resendCode}>Resend Code</span>
          </p>
        </div>
      </form>
    </RegisterLayout>
  );
};

export default withAuth(VerifyEmail);
