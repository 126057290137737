import { useState, useRef, useEffect, FormEvent, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack, clipboardOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { clearFundVirtualCard } from "../../store/vcardSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import { getPrecision } from "../../utils/app";
import { assertNotNull, isNumber, roundDP } from "../../utils/func";

import mastercard from "../../assets/img/mastercard-logo.png";
import visa from "../../assets/img/visa.png";

const TopipVirtualCardFinal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const fundCard = useSelector((state: tRootState) => state.vcard.fund);

  assertNotNull(userDetails);

  const { fetchVirtualCards, fetchVcardWallets, fetchProfile, fetchWallets } =
    useData();

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [OTP, setOTP] = useState("");
  const [gAuthOTP, setGAuthOTP] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const resendOTP = (e: MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Resending OTP`;

    const interval = setInterval(() => {
      setMessage("success", "Email OTP has been resent successfully");
      target.innerHTML = "Resend OTP";
      clearInterval(interval);
    }, 3000);

    // api_client({
    //   method: "POST",
    //   url: "/wallet/internal-crypto-transfer/resend-otp",
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   data: JSON.stringify({ hash: transaction.hash }),
    // })
    //   .then((res) => {
    //     if (res.data.success) {
    //       setMessage("success", res.data.message);
    //     } else {
    //       setMessage("warning", res.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     setMessage("error", err.message);
    //   })
    //   .finally(() => {
    //     target.innerHTML = "Resend OTP";
    //   });
  };

  const cardCreateHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Should never reach here
    if (!fundCard) return;

    if ([0, 2].includes(userDetails.withauth) && OTP.length !== 4)
      return setMessage("warning", "Enter OTP sent to your email");

    if ([1, 2].includes(userDetails.withauth) && gAuthOTP.length !== 6)
      return setMessage("warning", "Enter OTP in your google authentication");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/virtual/fund-card-otp",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        transaction_hash: fundCard.transaction.hash,
        code: OTP,
        twoFACode: gAuthOTP,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        setMessage(
          "success",
          "Virtual card funding successful. You would be redirected shortly..."
        );

        return fetchVirtualCards();
      })
      .then(() => {
        return wallet.symbol === "ngn" ? fetchProfile() : fetchWallets();
      })
      .then((res) => {
        return fetchVcardWallets();
      })
      .then((res) => {
        navigate(`/virtual-card?card=${fundCard.transaction.card_id}`);
        const interval: number = window.setInterval(() => {
          dispatch(clearFundVirtualCard());
          window.clearInterval(interval);
        }, 3000);
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled");
        submitBtnRef.current.innerHTML = "Proceed";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [OTP, gAuthOTP, clearMessage]);

  if (!fundCard) return <Navigate to="/not-found" />;

  const {
    wallet,
    virtualCard,
    cryptoAmount,
    fiatAmount,
    receiveAmount,
    otpMessage,
  } = fundCard;

  return (
    <DashboardLayout>
      <div className="crypto-final">
        <div className="crypto-final__header">
          <div className="crypto-final__back" onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </div>
          <p className="crypto-final__label">Virtual Card Funding From</p>
          <div className="crypto-final__currency">
            <img src={wallet.image} alt="" />
            {wallet.symbol !== "ngn" ? (
              <p>
                ${roundDP(fiatAmount, 2)} (
                {roundDP(cryptoAmount, getPrecision(wallet.symbol))}{" "}
                <span>{wallet.symbol.toUpperCase()}</span>)
              </p>
            ) : (
              <p>
                &#8358; {roundDP(cryptoAmount, getPrecision(wallet.symbol))}
              </p>
            )}
          </div>
          <p className="crypto-final__label2">To Receive</p>
          <div className="crypto-final__currency">
            <img
              src={virtualCard.brand === "MASTERCARD" ? mastercard : visa}
              alt=""
            />
            <p>{virtualCard.alias}</p>
            <p>${roundDP(receiveAmount, 2)}</p>
          </div>
        </div>
        <form className="crypto-final__body" onSubmit={cardCreateHandler}>
          {userDetails.withauth === 0 ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">Email OTP</h3>
              <div className="crypto-final__heading-subtext">{otpMessage}</div>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 4);
                    if (isNumber(text)) setOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={4}
                  pin={OTP}
                  handler={setOTP}
                />
              </div>
              <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                Resend Code
              </span>
            </div>
          ) : null}
          {userDetails.withauth === 1 ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">
                Google authentication code
              </h3>
              <div className="crypto-final__heading-subtext">
                Input your Google authentication code to complete the
                transaction
              </div>
              <div
                className="copy copy--bold"
                onClick={() => {
                  navigator.clipboard.readText().then((clipText) => {
                    const text = clipText.slice(0, 6);
                    if (isNumber(text)) setGAuthOTP(text);
                  });
                }}
              >
                <IonIcon icon={clipboardOutline} />
                Paste from clipboard
              </div>
              <div className="register__pin-input-group-2">
                <PinGroup
                  type="text"
                  numInputs={6}
                  pin={gAuthOTP}
                  handler={setGAuthOTP}
                />
              </div>
            </div>
          ) : null}
          {userDetails.withauth === 2 ? (
            <div className="crypto-final__pin-sections crypto-final__pin-sections--1">
              <div className="crypto-final__pin-section">
                <div className="crypto-final__heading-subtext">
                  Kindly input the OTP sent to your email and your Google
                  authentication code to complete the transaction
                </div>
              </div>
              <div className="crypto-final__pin-section">
                <h3 className="crypto-final__heading">Email OTP</h3>
                <div className="crypto-final__heading-subtext">
                  {otpMessage}
                </div>
                <div
                  className="copy copy--bold"
                  onClick={() => {
                    navigator.clipboard.readText().then((clipText) => {
                      const text = clipText.slice(0, 4);
                      if (isNumber(text)) setOTP(text);
                    });
                  }}
                >
                  <IonIcon icon={clipboardOutline} />
                  Paste from clipboard
                </div>
                <div className="register__pin-input-group-2">
                  <PinGroup
                    type="text"
                    numInputs={4}
                    pin={OTP}
                    handler={setOTP}
                  />
                </div>
                <span className="crypto-final__forgot-pin" onClick={resendOTP}>
                  Resend Code
                </span>
              </div>
              <div className="crypto-final__pin-section">
                <h3 className="crypto-final__heading">
                  Google authentication code
                </h3>
                <div
                  className="copy copy--bold"
                  onClick={() => {
                    navigator.clipboard.readText().then((clipText) => {
                      const text = clipText.slice(0, 6);
                      if (isNumber(text)) setGAuthOTP(text);
                    });
                  }}
                >
                  <IonIcon icon={clipboardOutline} />
                  Paste from clipboard
                </div>
                <div className="register__pin-input-group-2">
                  <PinGroup
                    type="text"
                    numInputs={6}
                    pin={gAuthOTP}
                    handler={setGAuthOTP}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                ((userDetails.withauth === 0 && OTP.length === 4) ||
                  (userDetails.withauth === 1 && gAuthOTP.length === 6) ||
                  (userDetails.withauth === 2 &&
                    OTP.length === 4 &&
                    gAuthOTP.length === 6)) &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Proceed
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(TopipVirtualCardFinal);
