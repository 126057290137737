import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

const TermsAndConditions = () => {
  return (
    <HomeLayout>
      <div className="section">
        <div className="section__header">
          <div className="section__heading">
            Website Terms and Conditions of Use
          </div>
        </div>
        <div className="section__main section__content">
          <h3>Terms</h3>
          <p>
            By accessing this Website, accessible from SekiApp, you are agreeing
            to be bound by these Website Terms and Conditions of Use and agree
            that you are responsible for the agreement with any applicable local
            laws. If you disagree with any of these terms, you are prohibited
            from accessing this site. The materials contained in this Website
            are protected by copyright and trade mark law.
          </p>
          <h3>Use License</h3>
          <div>
            <p>
              Permission is granted to temporarily download one copy of the
              materials on SekiApp's Website for personal, non-commercial
              transitory viewing only. This is the grant of a license, not a
              transfer of title, and under this license you may not:
            </p>
            <ul>
              <li>Modify or copy the materials;</li>
              <li>
                Use the materials for any commercial purpose or for any public
                display;
              </li>
              <li>
                Attempt to reverse engineer any software contained on SekiApp's
                Website;
              </li>
              <li>
                Remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                Transferring the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ul>
            <p>
              This will let SekiApp to terminate upon violations of any of these
              restrictions. Upon termination, your viewing right will also be
              terminated and you should destroy any downloaded materials in your
              possession whether it is printed or electronic format. These Terms
              of Service has been created with the help of the Terms Of Service
              Generator.
            </p>
          </div>
          <div>
            <h3>Disclaimer</h3>
            <p>
              All the materials on SekiApp’s Website are provided "as is".
              SekiApp makes no warranties, may it be expressed or implied,
              therefore negates all other warranties. Furthermore, SekiApp does
              not make any representations concerning the accuracy or
              reliability of the use of the materials on its Website or
              otherwise relating to such materials or any sites linked to this
              Website.
            </p>
          </div>
          <div>
            <h3>Limitations</h3>
            <p>
              SekiApp or its suppliers will not be hold accountable for any
              damages that will arise with the use or inability to use the
              materials on SekiApp’s Website, even if SekiApp or an authorize
              representative of this Website has been notified, orally or
              written, of the possibility of such damage. Some jurisdiction does
              not allow limitations on implied warranties or limitations of
              liability for incidental damages, these limitations may not apply
              to you.
            </p>
          </div>
          <div>
            <h3>Revisions and Errata</h3>
            <p>
              The materials appearing on SekiApp’s Website may include
              technical, typographical, or photographic errors. SekiApp will not
              promise that any of the materials in this Website are accurate,
              complete, or current. SekiApp may change the materials contained
              on its Website at any time without notice. SekiApp does not make
              any commitment to update the materials.
            </p>
          </div>
          <div>
            <h3>Links</h3>
            <p>
              SekiApp has not reviewed all of the sites linked to its Website
              and is not responsible for the contents of any such linked site.
              The presence of any link does not imply endorsement by SekiApp of
              the site. The use of any linked website is at the user’s own risk.
            </p>
          </div>
          <div>
            <h3>Site Terms of Use Modifications</h3>
            <p>
              SekiApp may revise these Terms of Use for its Website at any time
              without prior notice. By using this Website, you are agreeing to
              be bound by the current version of these Terms and Conditions of
              Use
            </p>
          </div>
          <div>
            <h3>Your Privacy</h3>
            <p>Please read our Privacy Policy.</p>
          </div>
          <div>
            <h3>Governing Law</h3>
            <p>
              Any claim related to SekiApp's Website shall be governed by the
              laws of ng without regards to its conflict of law provisions.
            </p>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default TermsAndConditions;
