import { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { addDepositTransaction } from "../../store/nairaSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import { assertNotNull, roundDP } from "../../utils/func";

const DepositDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );
  const { bank, amount, fee } = useSelector(
    (state: tRootState) => state.naira.deposit
  );

  assertNotNull(userDetails);

  const [agreed, setAgreed] = useState(false);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleDeposit = (e: MouseEvent<HTMLButtonElement>) => {
    if (!bank) return;

    if (!agreed) return setMessage("warning", "You have to agree to the terms");

    const target = e.target as HTMLButtonElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Finding Match`;
    target.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: `/get-account`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ amount, bank: bank.id }),
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(addDepositTransaction(res.data.message));
          navigate("/deposit/make-payment");
        } else {
          setMessage("warning", res.data.message);
        }
      })
      .catch((err) => {
        setMessage("error", err.message);
      })
      .finally(() => {
        target.innerHTML = `Next`;
        target.removeAttribute("disabled");
      });
  };

  useEffect(() => {
    clearMessage();
  }, [agreed, clearMessage]);

  if (!userDetails.is_verified) return <Navigate to="/404" />;
  if (!bank || !amount) return <Navigate to="/deposit/amount" />;

  return (
    <DashboardLayout>
      <div className="naira-block">
        <div className="naira-block__header">
          <h3 className="naira-block__heading">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Deposit Details
          </h3>
          <p className="naira-block__sub-heading text-center">
            Here is your deposit details
          </p>
        </div>
        <div className="naira-block__form">
          <div className="form-group">
            <label>Deposit Bank</label>
            <div className="select-box select-box--auto form-disabled">
              <div>
                <p>
                  <strong>Bank Name:</strong> {bank.bank_name}
                </p>
                <p>
                  <strong>Account Name:</strong> {bank.bank_account_name}
                </p>
                <p>
                  <strong>Account No:</strong> {bank.bank_account_no}
                </p>
              </div>
            </div>
          </div>
          {fee ? (
            <div className="form-group">
              <label>Fee</label>
              <div className="form-input-group form-disabled">NGN {fee}</div>
            </div>
          ) : null}
          <div className="form-group">
            <label>Amount</label>
            <input
              type="text"
              className="form-input form-disabled"
              placeholder="Enter amount"
              value={`NGN ${roundDP(amount, 2)}`}
              disabled
            />
            <label className="form-label--sm">
              Ensure you send the money from the selected bank account above.
              Failure to do so, your sekiapp account{" "}
              <span className="text-danger">WILL</span> not be credited
            </label>
          </div>
          {fee ? (
            <div className="form-group">
              <label>You will receive</label>
              <div className="form-input-group form-disabled">
                NGN {roundDP(amount - fee, 2)}
              </div>
            </div>
          ) : null}
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            />
            <p>I agree</p>
          </div>
          <div className="naira-block__info-card naira-block__info-card--2">
            When we find your match, we’ll provide their banking details for you
            to make your deposit. Remember to use the exact details.
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls("form-button", agreed && "form-button--active")}
              onClick={handleDeposit}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(DepositDetails);
