import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline, close, searchOutline } from "ionicons/icons";

import useData from "../../hooks/useData/useData";

import { tRootState } from "../../store";
import { tWallet, tWallets } from "../../store/types/app.types";
import { roundDP } from "../../utils/func";
// import { assertNotNull } from "../../utils/func";

type tSelectCurrencyModal = {
  list?: "all" | "vcard";
  show: boolean;
  onlyShow?: string[];
  dontShow?: string;
  type?: "crypto" | "fiat";
  showBalance?: boolean;
  selectedWallet?: string;
  selectHandler: (wallet: tWallet) => void;
  closeHandler: () => void;
};

const SelectCurrencyModal = ({
  list = "all",
  show,
  onlyShow,
  dontShow,
  type,
  showBalance,
  selectedWallet,
  selectHandler,
  closeHandler,
}: tSelectCurrencyModal) => {
  const walletsCached = useSelector(
    (state: tRootState) =>
      state.cache[list === "all" ? "wallets" : "vcardWallets"]
  );

  // assertNotNull(walletsCached);

  const { fetchWallets, fetchVcardWallets } = useData();

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!walletsCached && list === "all") fetchWallets();
    if (!walletsCached && list === "vcard") fetchVcardWallets();
  }, [fetchWallets, fetchVcardWallets, walletsCached, list]);

  let wallets: tWallets = walletsCached || [];

  // Filter
  if (onlyShow)
    wallets = wallets.filter((wallet) =>
      onlyShow
        .map((sh) => sh.toLowerCase())
        .includes(wallet.symbol.toLowerCase())
    );

  if (dontShow)
    wallets = wallets.filter(
      (wallet) => wallet.symbol.toLowerCase() !== dontShow.toLowerCase()
    );

  if (type) wallets = wallets.filter((wallet) => wallet.type === type);
  // End Filter

  if (!show) return null;

  return (
    <>
      <div className="currency-modal">
        <div className="currency-modal__header">
          <span className="currency-modal__close" onClick={closeHandler}>
            <IonIcon icon={close} />
          </span>
          <h3 className="currency-modal__heading">Select Wallet</h3>
        </div>
        <form className="search-form">
          <div className="search-form__icon-block">
            <IonIcon icon={searchOutline} />
          </div>
          <input
            type="text"
            placeholder="Search Coin"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        <div className="currency-modal__list">
          {wallets
            .filter((wallet) => {
              return (
                new RegExp(search, "i").test(wallet.coin) ||
                new RegExp(search, "i").test(wallet.symbol)
              );
            })
            .map((wallet) => (
              <div
                className={cls(
                  "currency1",
                  wallet.symbol.toLowerCase() ===
                    selectedWallet?.toLowerCase() && "currency1--active"
                )}
                key={wallet.id}
                onClick={() => selectHandler(wallet)}
              >
                <div className="currency1__img-block">
                  <img src={wallet.image} alt="" />
                </div>
                <div className="currency1__divider"></div>
                <div className="currency1__main">
                  <p className="currency1__abbr">{wallet.symbol}</p>
                  <p className="currency1__name">
                    {wallet.coin}{" "}
                    {showBalance ? (
                      wallet.symbol === "ngn" ? (
                        <>(&#8358;{roundDP(wallet.balance, 2)})</>
                      ) : (
                        <>(${roundDP(wallet.dollar_balance, 2)})</>
                      )
                    ) : null}
                  </p>
                </div>
                <span className="currency1__check">
                  <IonIcon icon={checkmarkOutline} />
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default SelectCurrencyModal;
