import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { cash, gift, pieChart } from "ionicons/icons";

const DashboardQuickActions = () => {
  return (
    <div className="quick-actions">
      <div className="quick-action quick-action--1">
        <div className="quick-action__icon-box">
          <IonIcon icon={gift} />
        </div>
        <h3 className="quick-action__heading">Top Gift Cards</h3>
        <h3 className="quick-action__text">Trade gift card for Fast cash</h3>
        <Link to="/gift-cards" className="quick-action__link">
          See Top Gift Cards
        </Link>
      </div>
      <div className="quick-action quick-action--2">
        <div className="quick-action__icon-box">
          <IonIcon icon={pieChart} />
        </div>
        <h3 className="quick-action__heading">Check Rates</h3>
        <h3 className="quick-action__text">
          Know your gift cards and crypto value at a glance
        </h3>
        <Link to="/rates" className="quick-action__link">
          Check Rates
        </Link>
      </div>
      <div className="quick-action quick-action--3">
        <div className="quick-action__icon-box">
          <IonIcon icon={cash} />
        </div>
        <h3 className="quick-action__heading">Pay Bills</h3>
        <h3 className="quick-action__text">Pay your bills at a glance</h3>
        <Link to="/pay-bills" className="quick-action__link">
          Pay Bills
        </Link>
      </div>
    </div>
  );
};

export default DashboardQuickActions;
