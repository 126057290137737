import { useEffect } from "react";
import { useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import Preloader from "../../components/Preloader/Preloader";

const SignOut = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  useEffect(() => {
    api_client({
      method: "POST",
      url: "/logout",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).catch((err) => {
      // do nothing
    });
  }, [accessToken]);

  return <Preloader />;
};

export default withAuth(SignOut);
