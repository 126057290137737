import { MouseEvent, useEffect, useRef, useState, FormEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, imagesOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tDepositTransaction } from "../DepositDetails/DepositDetails";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import { assertNotNull, sleep } from "../../utils/func";

import Preloader from "../../components/Preloader/Preloader";
import DashbordHeader from "../../components/DashboardHeader/DashboardHeader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import SendImageMessage from "../../components/SendImageMessage/SendImageMessage";
import ModeToggle from "../../components/ModeToggle/ModeToggle";

import { getProfilePicture } from "../../utils/user";

import avatarNeutral from "../../assets/img/avatar-neutral.png";
import ImgsPreview from "../../components/ImgsPreview/ImgsPreview";

type tThreadMessage = {
  id: number;
  message: string;
  has_file: 0 | 1;
  file: "";
  created_at: string;
  user_id: number;
};

type tDispute = {
  content: string;
  created_at: string;
  file: string;
  has_file: 0 | 1;
  status: null | "0" | "1" | "2";
  thread: tThreadMessage[];
};

const DepositDispute = () => {
  const navigate = useNavigate();

  const { hash } = useParams();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const { id: userId, gender } = userDetails;

  const [reloadDeposit, setReloadDeposit] = useState(true);
  const [deposit, setDeposit] = useState<tDepositTransaction | null>(null);

  const [loadingDispute, setLoadingDispute] = useState(true);

  const [reloadDispute, setReloadDispute] = useState(true);
  const [dispute, setDispute] = useState<tDispute | null>(null);

  const pauseAutoScroll = useRef(false);
  const chatBlockRef = useRef<HTMLDivElement>(null);

  const [chatMessage, setChatMessage] = useState("");
  const sendChatMessageBtnRef = useRef<HTMLButtonElement>(null);

  const [images, setImages] = useState<File[]>([]);

  const fileInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [prevImgs, setPrevImgs] = useState<{
    images: string[];
    selectedImage: number;
  } | null>(null);

  const handleFileInputChange = (e: FormEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;

    if (files?.length)
      setImages(
        [
          ...images,
          ...new Array(files.length).fill(null).map((_, i) => files[i]),
        ].slice(0, 1)
      );

    fileInputRef.current.value = "";
  };

  const sendChatMessage = (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!chatMessage || !deposit) return;

    const target = sendChatMessageBtnRef.current!;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/payment-deposit/disputes",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        payment_deposit_id: deposit.id,
        message: chatMessage,
      }),
    })
      .then((res) => {
        if (res.data.success) setChatMessage("");
      })
      .catch((err) => {
        // do nothing
      })
      .finally(() => {
        if (target) {
          target.removeAttribute("disabled");
          target.innerHTML = "Send";
        }
      });
  };

  // Reload Messages
  useEffect(() => {
    if (!deposit?.id) return;

    api_client({
      url: `/payment-deposit/dispute/${deposit.id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setDispute(res.data.message);

          if (chatBlockRef.current) {
            chatBlockRef.current.addEventListener("scroll", (e) => {
              const target = e.target! as HTMLDivElement;

              pauseAutoScroll.current = true;
              if (
                target.scrollTop + target.offsetHeight >=
                target.scrollHeight - 400
              ) {
                pauseAutoScroll.current = false;
              }
            });

            if (!pauseAutoScroll.current) {
              chatBlockRef.current?.scrollTo({
                top: chatBlockRef.current.scrollHeight,
                behavior: "smooth",
              });
            }
          }
        }
      })
      .catch((err) => {
        // there should be handler for this
        if (!dispute?.created_at) navigate("/404");
      })
      .finally(() => {
        setLoadingDispute(false);
        setReloadDispute((rm) => !rm);
        // Disputing wating
        // sleep(2000).then(() => setReloadDispute((rm) => !rm));
      });
  }, [deposit?.id, reloadDispute, accessToken, dispute?.created_at, navigate]);

  // Reload Order
  useEffect(() => {
    if (!hash) return;

    api_client({
      url: `payment-request/${hash}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success || res.data.message.is_completed !== 0)
          throw new Error("");

        setDeposit(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      })
      .finally(() => {
        // if (order?.can_have_dispue && order?.pending_dispute !== "2")
        sleep(10000).then(() => {
          setReloadDeposit((rd) => !rd);
        });
      });
  }, [hash, accessToken, reloadDeposit, navigate]);

  if (!deposit) return <Preloader />;

  return (
    <>
      <DashbordHeader />
      <ModeToggle />
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
        accept=".png,.jpg,jpeg"
      />
      <SendImageMessage
        images={images}
        setImages={setImages}
        maxImages={1}
        url="/payment-deposit/disputes"
        id_key="payment_deposit_id"
        id_value={deposit.id}
        images_key="file"
        finishHandler={() => {
          // Reload messages might be a bit much as it happens instantly
          setReloadDispute((rd) => !rd);
        }}
      />
      {prevImgs ? (
        <ImgsPreview
          images={prevImgs.images}
          selectedImg={prevImgs.selectedImage}
          closeHandler={() => setPrevImgs(null)}
        />
      ) : null}
      <main className="dispute">
        <div className="dispute-header">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBackOutline} />
          </span>
          <div className="dispute-header__main">
            <p>Deposit Dispute</p>
            <p>#{deposit.hash}</p>
          </div>
        </div>
        <div className="dispute__main">
          {loadingDispute ? <VerticalBarLoader /> : null}
          {!loadingDispute ? (
            <>
              <div className="order-details__chat-block" ref={chatBlockRef}>
                <div className="chat">
                  <img src={avatarNeutral} alt="" className="chat__img" />
                  <div className="chat__msg">
                    <div className="chat__main">
                      <p>Hello, How may we assist you on this deposit?&lrm;</p>
                    </div>
                  </div>
                </div>
                {dispute ? (
                  <>
                    {new Array(1).fill(null).map((_, i) => {
                      let msgFiles: string[] = [];

                      if (dispute.has_file) {
                        msgFiles = dispute.file.split(",");
                      }

                      return (
                        <div className="chat chat--right" key={i}>
                          <img
                            src={getProfilePicture(gender)}
                            alt=""
                            className="chat__img"
                          />
                          <div className="chat__msg">
                            <div className="chat__main">
                              {dispute.has_file ? (
                                <div
                                  className={cls(
                                    "chat__imgs",
                                    msgFiles.length === 1
                                      ? "chat__imgs--1"
                                      : msgFiles.length === 2
                                      ? "chat__imgs--2"
                                      : msgFiles.length === 3
                                      ? "chat__imgs--3"
                                      : null
                                  )}
                                >
                                  {msgFiles.slice(0, 4).map((file, i) => {
                                    return (
                                      <div
                                        key={i}
                                        onClick={() => {
                                          setPrevImgs({
                                            images: msgFiles,
                                            selectedImage: i,
                                          });
                                        }}
                                      >
                                        <img src={file} alt="" />
                                        {i === 3 && msgFiles.length > 4 ? (
                                          <div className="chat__img-overlay">
                                            +{msgFiles.length - 4}&lrm;
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {dispute.content ? (
                                <p>{dispute.content}&lrm;</p>
                              ) : null}
                            </div>
                            {dispute.created_at ? (
                              <p className="chat__time">{dispute.created_at}</p>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                    {dispute.thread.map((message) => {
                      let msgFiles: string[] = [];

                      if (message.has_file) {
                        msgFiles = message.file.split(",");
                      }

                      return (
                        <div
                          className={cls(
                            "chat",
                            message.user_id === userId && "chat--right"
                          )}
                          key={message.id}
                        >
                          {
                            <img
                              src={
                                message.user_id === userId
                                  ? getProfilePicture(gender)
                                  : avatarNeutral
                              }
                              alt=""
                              className="chat__img"
                            />
                          }
                          <div className="chat__msg">
                            <div className="chat__main">
                              {message.has_file ? (
                                <div
                                  className={cls(
                                    "chat__imgs",
                                    msgFiles.length === 1
                                      ? "chat__imgs--1"
                                      : msgFiles.length === 2
                                      ? "chat__imgs--2"
                                      : msgFiles.length === 3
                                      ? "chat__imgs--3"
                                      : null
                                  )}
                                >
                                  {msgFiles.slice(0, 4).map((file, i) => {
                                    return (
                                      <div
                                        key={i}
                                        onClick={() => {
                                          setPrevImgs({
                                            images: msgFiles,
                                            selectedImage: i,
                                          });
                                        }}
                                      >
                                        <img src={file} alt="" />
                                        {i === 3 && msgFiles.length > 4 ? (
                                          <div className="chat__img-overlay">
                                            +{msgFiles.length - 4}&lrm;
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {message.message ? (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: `${message.message}&lrm;`,
                                  }}
                                />
                              ) : null}
                            </div>
                            {message.created_at ? (
                              <p className="chat__time">{message.created_at}</p>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
              {!dispute || (dispute && dispute.status !== "2") ? (
                <div className="order-details__chat-footer">
                  <form className="chat-form" onSubmit={sendChatMessage}>
                    <input
                      type="text"
                      placeholder="Type message"
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                    />
                    <span
                      className="chat-form__send-img"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <IonIcon icon={imagesOutline} />
                    </span>
                    <button ref={sendChatMessageBtnRef}>Send</button>
                  </form>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </main>
    </>
  );
};

export default withAuth(DepositDispute);
