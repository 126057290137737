import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import { tVirtualCard } from "../../../store/types/app.types";

const VirtualCardOptions = ({
  virtualCard,
  closeHandler,
}: {
  virtualCard: tVirtualCard;
  closeHandler: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="vc-options">
        <div className="vc-options__header">
          <span className="vc-options__close" onClick={() => closeHandler()}>
            <IonIcon icon={closeOutline} />
          </span>
          <div className="vc-options__heading">More options</div>
        </div>
        <div className="vc-options__options">
          <div
            className="vc-options__option"
            onClick={() =>
              navigate(`/account-statement?statement=virtual-card`)
            }
          >
            <h3 className="vc-options__option-heading">Card Statement</h3>
            <p className="vc-options__option-description">
              Generate statement for your card
            </p>
          </div>
          {virtualCard.status === "ACTIVE" ? (
            <div
              className="vc-options__option"
              onClick={() => navigate(`/virtual-card/${virtualCard.id}/freeze`)}
            >
              <h3 className="vc-options__option-heading">Freeze card</h3>
              <p className="vc-options__option-description">
                All transactions will be declined.
              </p>
            </div>
          ) : virtualCard.status === "FROZEN" ? (
            <div
              className="vc-options__option"
              onClick={() =>
                navigate(`/virtual-card/${virtualCard.id}/unfreeze`)
              }
            >
              <h3 className="vc-options__option-heading">Unfreeze card</h3>
              <p className="vc-options__option-description">
                All transactions will be declined.
              </p>
            </div>
          ) : null}
          {/* <div
            className="vc-options__option"
            onClick={() => navigate(`/virtual-card/${virtualCard.id}/delete`)}
          >
            <h3 className="vc-options__option-heading">Delete card</h3>
            <p className="vc-options__option-description">
              All funds will be returned to your wallet.
            </p>
          </div> */}
        </div>
      </div>
      <div className="overlay" onClick={() => closeHandler()}></div>
    </>
  );
};

export default VirtualCardOptions;
