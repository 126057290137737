import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { alertCircle, checkmark } from "ionicons/icons";

import { tRootState } from "../../store";

import { getProfilePicture } from "../../utils/user";
import { assertNotNull } from "../../utils/func";

import withAuth from "../../hoc/withAuth/withAuth";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

const OldUserData = () => {
  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  return (
    <AuthLayout>
      <div className="auth__img-block">
        <Link to="/">
          <img
            src={sekiLogoLight}
            alt=""
            className="auth__img light-inline-block"
          />
          <img
            src={sekiLogoDark}
            alt=""
            className="auth__img dark-inline-block"
          />
        </Link>
      </div>
      <div className="auth">
        <div className="auth__img-block">
          <img
            src={getProfilePicture(userDetails.gender)}
            alt=""
            className="auth__img"
          />
        </div>
        <h2 className="auth__heading">
          Welcome Back, Chief <span>{userDetails.names}</span>
        </h2>
        <p className="auth__heading-sub-text">
          Hello Chief, Welcome to SekiApp version 2 you are required to provide
          a few information before you proceed to your dashboard. Kindly fill in
          the details below
        </p>
        <form className="auth__form-main">
          <div className="form-group">
            <label className="form-label">Username</label>
            <div className="form-input-group">
              <input
                type="text"
                placeholder="Enter User name"
                name="username"
                autoComplete="off"
              />
              <button>
                {/* <div className="loaderRing loaderRing--sm"></div>  */}
                <IonIcon icon={checkmark} className="text-success" />
              </button>
            </div>
            <span className="form-bottom-label text-warning">
              <IonIcon icon={alertCircle} className="active" />
              Username has been used
            </span>
          </div>
          <select className="form-select" name="gender">
            <option value="">-- Select Gender --</option>
            <option>Male</option>
            <option>Female</option>
            <option>Prefer not to say</option>
          </select>
          <div className="auth__footer">
            <button className="form-button" type="submit">
              Login
            </button>
          </div>
          <p className="auth__footer-link">
            <Link to="/login">Logout</Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  );
};

export default withAuth(OldUserData);
