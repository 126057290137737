import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
  chevronUpCircle,
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTiktok,
  logoTwitter,
  logoYoutube,
} from "ionicons/icons";

import footerBg from "../../assets/img/footer-bg.png";

const DashboardFooter = () => {
  return (
    <footer
      className="dashboard-footer"
      style={{ backgroundImage: `url(${footerBg})` }}
    >
      <div className="dashboard-footer__socials">
        <a
          href="https://web.facebook.com/sekiapp"
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoFacebook} />
        </a>
        <a
          href="https://twitter.com/seki_app/"
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoTwitter} />
        </a>
        <a
          href="https://instagram.com/seki_app"
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoInstagram} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCHxxbmOdcfyAmXUfutFfBXA"
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoYoutube} />
        </a>
        <a
          href="https://www.linkedin.com/company/sekiapp/"
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoLinkedin} />
        </a>
        <a
          href="https://www.tiktok.com/@seki_app"
          target="_blank"
          rel="noreferrer"
        >
          <IonIcon icon={logoTiktok} />
        </a>
      </div>
      <div className="dashboard-footer__main">
        <div className="dashboard-footer__quick-links">
          <Link to="/about-us"> About us </Link>
          <a href="https://blog.sekiapp.com/" target="_blank" rel="noreferrer">
            Blog
          </a>
          <Link to="/faq"> FAQ </Link>
          <Link to="/terms-and-conditions"> Terms of Use </Link>
        </div>
        <p>
          SekiApp is an evolution from MyBTC Nigeria (2016-2021) <br />
          With <span>♥</span> from all of us at SekiApp (c) 2021-2023
        </p>
      </div>
      <div className="dashboard-footer__arrow">
        <span
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <IonIcon icon={chevronUpCircle} />
        </span>
      </div>
    </footer>
  );
};

export default DashboardFooter;
