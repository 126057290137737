import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import { tCryptoTransferTransaction } from "../../../store/types/app.types";
import { copyData, getDate, roundDP } from "../../../utils/func";
import { getPrecision } from "../../../utils/app";

const CryptoTransferTransaction = ({
  transaction,
}: {
  transaction: tCryptoTransferTransaction;
}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  return (
    <div className="transaction-details">
      <h3 className="transaction-details__heading">
        <span onClick={() => navigate(-1)}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        Internal Crypto Transfer
      </h3>
      <p className="transaction-details__heading-subtext">
        Internal Crypto Transfer Transaction Details
      </p>
      <div className="transaction-details__infos">
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">COIN</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.coin_name.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Amount</p>
          <div className="transaction-details__info-right">
            &#36; {Number(transaction.dollar_value)} (
            {roundDP(
              transaction.coin_value,
              getPrecision(transaction.coin_name)
            )}{" "}
            {transaction.coin_name.toUpperCase()})
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Type</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.type.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">
            {transaction.type === "sent" ? "To" : "From"}
          </p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">
              {transaction.address}
            </p>
          </div>
        </div>
        {transaction.description ? (
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Description</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {transaction.description}
              </p>
            </div>
          </div>
        ) : null}
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Hash</p>
          <div className="transaction-details__info-right">
            <p className="transaction-details__info-text">{transaction.hash}</p>
            <div
              className="copy"
              onClick={() => copyData(transaction.hash, setCopied)}
            >
              <IonIcon icon={copy} />
              {copied ? "Copied!" : "Copy"}
            </div>
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Status</p>
          <div className="transaction-details__info-right">
            {transaction.status === 1 ? (
              <span className="badge badge--success">Confirmed</span>
            ) : (
              <span className="badge badge--warning">Unconfirmed</span>
            )}
          </div>
        </div>
        <div className="transaction-details__info">
          <p className="transaction-details__info-key">Date</p>
          <div className="transaction-details__info-right">
            {getDate(transaction.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoTransferTransaction;
