import { useEffect, useState, useRef, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  arrowBack,
  checkmark,
  checkmarkCircle,
  chevronDownOutline,
  close,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { addWithdrawInfo1 } from "../../store/nairaSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";
import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../components/Preloader/Preloader";
import RingLoader from "../../loaders/RingLoader/RingLoader";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import PinGroup from "../../components/PinGroup/PinGroup";
import BeneficiariesModal from "../../components/BeneficiariesModal/BeneficiariesModal";

import SetPin from "../../components/SetPin/SetPin";
import SetAuth from "../../components/SetAuth/SetAuth";
import TransactionLimit from "../../components/TransactionLimit/TransactionLimit";
import ContactVerification from "../../components/ContactVerification/ContactVerification";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";

const Withdraw = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const { withdrawalBeneficiaries, withdrawalBanks: banks } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(userDetails);

  const { fetchBanks, fetchBeneficiaries } = useData();

  const [loadingBanks, setLoadingBanks] = useState(true);

  let banksSelectBoxData: SelectBoxDataType = [];

  banks?.forEach((bank) => {
    banksSelectBoxData.push({
      key: bank.bank_code,
      value: { text: bank.bank_name },
    });
  });

  const [bankCodeSelectBox, bankCode, openBankCodeSelectBox, , setBankCode] =
    useSelectBox<string>("Select Bank", banksSelectBoxData, null);

  const [fee, setFee] = useState("");

  const [accountNumber, setAccountNumber] = useState("");

  const anverifyInterval = useRef<number | null>(null);

  const [verifyingAccount, setVerifyingAccount] = useState(false);
  const [errorVerifyingAccount, setErrorVerifyingAccount] = useState(false);

  const [accountName, setAccountName] = useState("");

  const [amount, setAmount] = useState<number | "">("");

  const [pin, setPin] = useState("");

  const [newBeneficiary, setNewBeneficiary] = useState(false);

  const [alias, setAlias] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [showBeneficiariesModal, setShowBeneficiariesModal] = useState(false);

  const [authMethodCallback, setAuthMethodCallback] = useState<
    (() => void) | null
  >(null);
  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!fee) return setMessage("warning", "An error occured. Try again");

    if (!bank) return setMessage("warning", "Select bank");

    if (!accountNumber) return setMessage("warning", "Enter account number");

    if (!amount) return setMessage("warning", "Enter amount");

    if (+amount === 0) return setMessage("warning", "Amount cannot be 0");

    if (+amount > userDetails.balance)
      return setMessage("warning", "Insifficient funds");

    if (newBeneficiary && !alias)
      return setMessage("warning", "Add nickname to save new beneficiary");

    if (newBeneficiary && alias.length > 10)
      return setMessage(
        "warning",
        "Alias should not be greater than 10 characters"
      );

    if (!accountName)
      return setMessage("warning", "Could not validate account details");

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Enter PIN");

    setContactVerificationCallback(
      () => () =>
        setAuthMethodCallback(() => () => {
          submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Processing`;
          submitBtnRef.current.setAttribute("disabled", "disabled");

          api_client({
            url: "/payment-request",
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify({
              amount,
              bank: bank.bank_code,
              account_number: accountNumber,
              beneficiary: newBeneficiary ? 1 : 0,
              alias,
              pin: pin,
            }),
          })
            .then((res) => {
              if (res.data.success) {
                dispatch(
                  addWithdrawInfo1({
                    emailMessage: res.data.message,
                    transaction: res.data.payment,
                    fee: +fee,
                  })
                );

                navigate("/withdraw/otp");
              } else {
                setMessage("warning", res.data.message);
              }
            })
            .catch((err) => {
              setMessage("error", err.message);
            })
            .finally(() => {
              if (submitBtnRef.current) {
                submitBtnRef.current.innerHTML = "Next";
                submitBtnRef.current.removeAttribute("disabled");
              }
            });
        })
    );
  };

  useEffect(() => {
    if (anverifyInterval.current)
      window.clearInterval(anverifyInterval.current);

    if (!bankCode || accountNumber.length !== 10) return;

    setVerifyingAccount(true);
    setErrorVerifyingAccount(false);
    setAccountName("");

    anverifyInterval.current = window.setInterval(() => {
      api_client({
        url: `/get-nuban-details/${bankCode}/${accountNumber}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          setAccountName(res.data.message.account_name);
        })
        .catch((err) => {
          setErrorVerifyingAccount(true);
        })
        .finally(() => {
          setVerifyingAccount(false);
          if (anverifyInterval.current)
            window.clearInterval(anverifyInterval.current);
        });
    }, 3000);
  }, [accessToken, accountNumber, bankCode]);

  useEffect(() => {
    clearMessage();
  }, [
    bankCode,
    accountNumber,
    amount,
    newBeneficiary,
    alias,
    pin,
    clearMessage,
  ]);

  useEffect(() => {
    api_client({
      url: "/get-withdrawal-charges",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      if (res.data.success) {
        setFee(res.data.message);
      }
    });
  }, [accessToken]);

  useEffect(() => {
    if (!loadingBanks) return;
    fetchBanks("withdraw")
      .then(() => setLoadingBanks(false))
      .catch((err) => {
        if (!banks) navigate("/error");
      });
  }, [fetchBanks, navigate, loadingBanks, banks]);

  useEffect(() => {
    fetchBeneficiaries("withdrawal");
  }, [fetchBeneficiaries]);

  // Accessible in handleSubmit method 💀💀
  const bank = banks?.find((bank) => bank.bank_code === bankCode);

  if (!banks) return <Preloader />;

  return (
    <DashboardLayout>
      {bankCodeSelectBox}
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />
      <SetAuth
        callback={authMethodCallback}
        closeHandler={() => setAuthMethodCallback(null)}
      />
      <BeneficiariesModal
        show={showBeneficiariesModal}
        beneficiaries={withdrawalBeneficiaries}
        withdrawal
        isActive={(ben) =>
          bankCode === ben.main_id && accountNumber === ben.recipient
        }
        handler={(ben) => {
          setBankCode(ben.main_id);
          setAccountNumber(ben.recipient);
          setShowBeneficiariesModal(false);
        }}
        closeHandler={() => setShowBeneficiariesModal(false)}
      />
      <form className="crypto-block" onSubmit={handleSubmit}>
        <div className="crypto-block__header">
          <div className="crypto-block__back">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} /> Back
            </span>
          </div>
          <h3 className="crypto-block__heading">Withdraw Naira</h3>
        </div>
        <div className="crypto-block__body">
          <div className="crypto-block__balance-block">
            <p className="crypto-block__balance-text">
              Available Naira balance
            </p>
            <p className="crypto-block__balance">
              {roundDP(userDetails.balance, 2)} NGN
            </p>
          </div>
          <div className="crypto-block__body-main">
            {withdrawalBeneficiaries && withdrawalBeneficiaries.length ? (
              <div className="beneficiaries">
                <div className="beneficiaries__header">
                  <h3 className="beneficiaries__heading">Beneficiaries</h3>
                  <span
                    className="transactions-section__link"
                    onClick={() => setShowBeneficiariesModal(true)}
                  >
                    View All
                  </span>
                </div>
                <div className="beneficiaries__beneficiaries">
                  {withdrawalBeneficiaries.map((beneficiary) => (
                    <div
                      className={cls(
                        "beneficiary",
                        bankCode === beneficiary.main_id &&
                          accountNumber === beneficiary.recipient &&
                          "beneficiary--active"
                      )}
                      onClick={() => {
                        setBankCode(beneficiary.main_id);
                        setAccountNumber(beneficiary.recipient);
                      }}
                      key={beneficiary.id}
                    >
                      <div
                        className="beneficiary__name-logo"
                        style={{ backgroundColor: beneficiary.background }}
                      >
                        {beneficiary.recipient_alias[0].toUpperCase()}
                      </div>
                      <p className="beneficiary__name" title={`${alias}`}>
                        {beneficiary.recipient_alias.slice(0, 10)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label>Bank</label>
              <div className="select-box" onClick={openBankCodeSelectBox}>
                <p>{bank ? bank.bank_name : "Select Bank"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            <div className="form-group">
              <label>Account number</label>
              <div className="form-input-group">
                <input
                  type="text"
                  placeholder="Enter account number"
                  name="account_number"
                  autoComplete="off"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  maxLength={10}
                />
                <button>
                  {accountNumber.length === 10 && verifyingAccount ? (
                    <RingLoader />
                  ) : null}
                  {accountNumber.length === 10 && accountName ? (
                    <IonIcon icon={checkmark} className="text-success" />
                  ) : null}
                  {accountNumber.length === 10 && errorVerifyingAccount ? (
                    <IonIcon icon={close} className="text-warning" />
                  ) : null}
                </button>
              </div>
              {accountNumber.length === 10 && accountName ? (
                <span className="form-bottom-label text-success">
                  <IonIcon icon={checkmarkCircle} className="active" />
                  {accountName}
                </span>
              ) : null}
              {accountNumber.length === 10 && errorVerifyingAccount ? (
                <span className="form-bottom-label text-danger">
                  <IonIcon icon={alertCircle} className="active" />
                  Could not verify account
                </span>
              ) : null}
            </div>
            <div className="form-group form-group--md">
              <div className="form-group">
                <div className="flex-between">
                  <p>Amount</p>
                  <p>MAX: {roundDP(userDetails.balance, 2)}</p>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <button type="button">&#8358;</button>
                    <input
                      type="text"
                      placeholder="Enter amount"
                      value={amount}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setAmount(+e.target.value)
                            : null
                          : setAmount("")
                      }
                    />
                  </div>
                </div>
              </div>
              <AmountPercentage
                totalAmount={userDetails.balance}
                handler={setAmount}
              />
              {fee ? (
                <div className="flex-between">
                  <p></p>
                  <p className="flex-center">
                    <IonIcon icon={alertCircle} />
                    Bank charges: &#8358;{fee}
                  </p>
                </div>
              ) : null}
            </div>
            {!withdrawalBeneficiaries?.some(
              (beneficiary) =>
                bankCode === beneficiary.main_id &&
                accountNumber === beneficiary.recipient
            ) ? (
              <div className="accept">
                <input
                  type="checkbox"
                  className="accept__checkbox"
                  checked={newBeneficiary}
                  onChange={(e) => setNewBeneficiary(e.target.checked)}
                />
                <p>Save as beneficiary</p>
              </div>
            ) : null}
            {newBeneficiary ? (
              <div className="form-group">
                <label>Add Nickname</label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="e.g Johnny"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  maxLength={10}
                />
              </div>
            ) : null}
            <TransactionLimit type="naira_withdrawal" />
          </div>
        </div>
        {userDetails.is_pin_set ? (
          <div className="crypto-block__pin crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span
              className="crypto-final__forgot-pin"
              onClick={() => navigate("/change-pin")}
            >
              Forgot PIN
            </span>
          </div>
        ) : null}
        <div className="crypto-block__footer">
          {message}
          <button
            className={cls(
              "form-button",
              bank &&
                accountNumber &&
                accountName &&
                amount &&
                (!newBeneficiary || (newBeneficiary && alias)) &&
                (pin.length === 4 || !userDetails.is_pin_set) &&
                "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Continue
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(Withdraw);
