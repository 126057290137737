import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { tRootState } from "../../store";

import { assertNotNull } from "../../utils/func";

import withAuth from "../../hoc/withAuth/withAuth";

import ModeToggle from "../../components/ModeToggle/ModeToggle";

import authBackground from "../../assets/img/auth-background.png";
import blockedImg from "../../assets/img/blocked-img.png";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

const UserBlocked = () => {
  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  const {
    status,
    is_blocked,
    blocked_duration,
    blocked_until,
    blocked_reason,
  } = userDetails;

  return (
    <>
      <ModeToggle />
      <div
        className="user-blocked"
        style={{ backgroundImage: `url(${authBackground})` }}
      >
        <div className="user-blocked__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <div className="user-blocked__main">
          <img alt="" src={blockedImg} className="user-blocked__img" />

          <h3 className="user-blocked__heading">Account restricted!</h3>

          {status === 0 ? (
            <p className="user-blocked__text">
              Hello, you have been blocked permanently Contact customer service
              for further details.
            </p>
          ) : is_blocked === 1 ? (
            <p className="user-blocked__text">
              Hello, you have been blocked for <span>{blocked_duration}</span>{" "}
              until <span>{blocked_until}</span>. <br />
              <span>Reason: </span>
              {blocked_reason}. <br />
              Contact customer service for further details.
            </p>
          ) : null}

          <button className="user-blocked__button">Logout</button>
        </div>
      </div>
    </>
  );
};

export default withAuth(UserBlocked);
