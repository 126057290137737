import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import thumb from "../../assets/img/thumb.png";
import withAuth from "../../hoc/withAuth/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { tRootState } from "../../store";
import { assertNotNull } from "../../utils/func";
import { clearDepositDetails } from "../../store/nairaSlice";
import DepositWarningModal from "./Modals/DepositWarningModal";
import { useState } from "react";

const DepositProcessing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const { transaction, bank, amount } = useSelector(
    (state: tRootState) => state.naira.deposit
  );

  assertNotNull(userDetails);

  const [showDepositModal, setShowDepositModal] = useState(true);

  const finish = (link: string) => {
    navigate(link);
    const interval = setInterval(() => {
      dispatch(clearDepositDetails());
      clearInterval(interval);
    }, 5000);
  };

  if (!userDetails.is_verified) return <Navigate to="/404" />;
  if (!bank || !amount) return <Navigate to="/deposit/amount" />;
  if (!transaction) return <Navigate to="/deposit/details" />;

  return (
    <DashboardLayout>
      <DepositWarningModal
        bank={bank}
        show={showDepositModal}
        closeHandler={() => setShowDepositModal(false)}
      />
      <div className="naira-block naira-block--final">
        <div className="text-center">
          <img src={thumb} alt="" className="naira-block__img" />
        </div>
        <div className="naira-block__header mb-0">
          <h3 className="naira-block__heading">Deposit in progress</h3>
          <p className="naira-block__sub-heading text-center">
            Give us some seconds as your transaction is being processed
          </p>
        </div>
        <div className="auth__footer mb-medium">
          <button
            className="form-button form-button--active"
            onClick={() => finish("/dashboard")}
          >
            Go back to dashboard
          </button>
        </div>
        <div className="text-center">
          <span
            className="transactions-section__link"
            onClick={() => finish(`/deposit/${transaction.hash}`)}
          >
            View transaction details
          </span>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(DepositProcessing);
