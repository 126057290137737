import { IonIcon } from "@ionic/react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import { checkmarkOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { tRootState } from "../../store";
import { Navigate, useNavigate } from "react-router-dom";
import { clearWithdrawInfo } from "../../store/nairaSlice";
import withAuth from "../../hoc/withAuth/withAuth";

const WithdrawProcessing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { transaction, fee, emailMessage, OTPMessage, longOTPMessage } =
    useSelector((state: tRootState) => state.naira.withdraw);

  const finish = (link: string) => {
    navigate(link);
    const interval = setInterval(() => {
      dispatch(clearWithdrawInfo());
      clearInterval(interval);
    }, 5000);
  };

  if (!transaction || !fee || !emailMessage) return <Navigate to="/withdraw" />;
  if (!OTPMessage || !longOTPMessage) return <Navigate to="/withdraw/otp" />;

  return (
    <DashboardLayout>
      <div className="naira-block naira-block--final">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <div className="naira-block__header mb-medium">
          <h3 className="naira-block__heading">Payment successful</h3>
          <p className="naira-block__sub-heading text-center">{OTPMessage}</p>
        </div>
        <div className="naira-block__info-card naira-block__info-card--2 mb-medium">
          {longOTPMessage}
        </div>
        <div className="auth__footer mb-medium">
          <button
            className="form-button form-button--active"
            onClick={() => finish("/dashboard")}
          >
            Go back to dashboard
          </button>
        </div>
        <div className="text-center">
          <span
            className="transactions-section__link"
            onClick={() => finish(`/withdrawal/${transaction.payment_hash}`)}
          >
            View transaction details
          </span>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(WithdrawProcessing);
