import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { copy, eyeOffOutline, eyeOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { updateSetting } from "../../store/userSlice";

import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import InfluencerTransactions from "../../components/InfluencerTransactions/InfluencerTransactions";

import UserWelcome from "../../components/UserWelcome/UserWelcome";

import { assertNotNull, copyData, roundDP } from "../../utils/func";
import { getProfilePicture } from "../../utils/user";

import walletBgDark from "../../assets/img/wallet-bg-2-dark.svg";
import walletBgLight from "../../assets/img/wallet-bg-2-light.svg";
import withAuth from "../../hoc/withAuth/withAuth";

const InfluencerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    userDetails,
    settings: { hideBonusBalance },
  } = useSelector((state: tRootState) => state.user);
  const influencers = useSelector(
    (state: tRootState) => state.cache.influencers
  );

  assertNotNull(userDetails);

  const { fetchInfluencers } = useData();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    fetchInfluencers();
  }, [fetchInfluencers]);

  if (!userDetails.is_influencer) return <Navigate to="/dashboard" />;

  return (
    <DashboardLayout>
      <section>
        <UserWelcome />
        <section className="wallet-box">
          <img
            src={walletBgDark}
            alt=""
            className="wallet-box__img dark-block"
          />
          <img
            src={walletBgLight}
            alt=""
            className="wallet-box__img light-block"
          />
          <div className="wallet-box__main wallet-box__main--primary">
            <p className="wallet-box__header">
              SekiApp Point
              <span
                className="wallet-box__show-hide"
                onClick={() =>
                  dispatch(
                    updateSetting({
                      key: "hideBonusBalance",
                      value: !hideBonusBalance,
                    })
                  )
                }
              >
                <IonIcon icon={hideBonusBalance ? eyeOffOutline : eyeOutline} />
              </span>
            </p>
            <p className="wallet-box__balance">
              <span className={cls(hideBonusBalance && "font-hidden")}>
                {!hideBonusBalance ? roundDP(userDetails.iskp, 2) : "*****"}
              </span>{" "}
              SKP
            </p>
          </div>
          <button
            className="wallet-box__points-2"
            onClick={() => navigate("/convert-skp")}
          >
            Convert Points
          </button>
        </section>
      </section>
      {influencers && influencers.length ? (
        <>
          <h3 className="influencer-heading text-center">
            Top SekiApp Influencers
          </h3>
          <section className="services-section">
            <div className="services-section__header">
              <h3 className="services-section__heading">Leader Board</h3>
            </div>
            <div className="services-section__services">
              {influencers.map((influencer, i) => (
                <div className="service" key={i}>
                  <img
                    src={getProfilePicture(influencer.gender)}
                    alt=""
                    className="service__img"
                  />
                  <p>{influencer.username}</p>
                </div>
              ))}
            </div>
          </section>
        </>
      ) : null}
      <section className="refer-section">
        <p className="font-large">
          You earn for life on transactions of people you refer to SekiApp
        </p>
        <div className="refer-section__block">
          <div>
            use link
            <a
              href={userDetails.referral_link}
              target="_blank"
              rel="noreferrer"
            >
              {userDetails.referral_link}
            </a>{" "}
            or give your audience your code to use during registration “
            {userDetails.username}”
          </div>
          <div
            className="copy copy--var"
            onClick={() => copyData(userDetails.referral_link, setCopied)}
          >
            <IonIcon icon={copy} /> {!copied ? "Copy Link" : "Copied!"}
          </div>
        </div>
      </section>
      <section className="transactions-section">
        <div className="transactions-section__header">
          <h3 className="heading-secondary">Recent</h3>
        </div>
        <div className="transactions-section__main">
          <InfluencerTransactions />
        </div>
      </section>
    </DashboardLayout>
  );
};

export default withAuth(InfluencerDashboard);
