import { Fragment } from "react";
import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

import { tRootState } from "../../../store";
import { tVirtualCard } from "../../../store/types/app.types";

import VirtualCard from "../../../components/VirtualCard/VirtualCard";

import { assertNotNull } from "../../../utils/func";

const VirtualCardSlider = ({
  virtualCard,
  setVirtualCard,
}: {
  virtualCard: tVirtualCard;
  setVirtualCard: (vcardId: string) => void;
}) => {
  const virtualCards = useSelector(
    (state: tRootState) => state.cache.virtualCards
  );

  assertNotNull(virtualCards);

  let currentCard = virtualCards.findIndex(
    (vcard) => vcard.id === virtualCard.id
  );
  if (currentCard === -1) currentCard = 0;

  const totalCards = virtualCards.length;

  return (
    <div className="vcard-details__cards-block">
      {currentCard > 0 ? (
        <button
          className="vcard-details__cards-control previous"
          onClick={() =>
            setVirtualCard(virtualCards[currentCard - 1].id.toString())
          }
        >
          <IonIcon icon={chevronBackOutline} />
        </button>
      ) : null}
      {currentCard < totalCards - 1 ? (
        <button
          className="vcard-details__cards-control next"
          onClick={() =>
            setVirtualCard(virtualCards[currentCard + 1].id.toString())
          }
        >
          <IonIcon icon={chevronForwardOutline} />
        </button>
      ) : null}
      <div className="vcard-details__cards">
        {virtualCards.map((vCard, i) => (
          <Fragment key={vCard.id}>
            <VirtualCard
              card={vCard}
              style={{ transform: `translateX(${100 * (i - currentCard)}%)` }}
              showLoader
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default VirtualCardSlider;
