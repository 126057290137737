import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";

import withAuth from "../../hoc/withAuth/withAuth";
import giftCardHoc from "../../hoc/giftCard/giftCard";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tGiftCardTypes } from "../../store/types/app.types";
import { updateGiftCardType } from "../../store/giftcardSlice";

import { assertNotNull } from "../../utils/func";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../components/Preloader/Preloader";

const GiftCardReceipt = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { giftCard, cardCountry, cardType } = useSelector(
    (state: tRootState) => state.giftcard
  );

  assertNotNull(giftCard);
  assertNotNull(cardCountry);

  const [cardTypes, setCardTypes] = useState<tGiftCardTypes | null>(null);

  useEffect(() => {
    if (!giftCard?.id || !cardCountry?.id) return;

    api_client({
      url: `/order/services/types/${giftCard.id}/${cardCountry.id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setCardTypes(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [accessToken, giftCard?.id, cardCountry?.id, navigate]);

  if (!cardTypes) return <Preloader />;

  return (
    <DashboardLayout>
      <ul className="breadcrumb">
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            Gift Cards
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards" className="breadcrumb__link">
            {giftCard.name}
          </Link>
        </li>
        <li className="breadcrumb__item">
          <Link to="/gift-cards/country" className="breadcrumb__link">
            {cardCountry.country_name}
          </Link>
        </li>
        <li className="breadcrumb__item breadcrumb__item--active">
          <Link to="#" className="breadcrumb__link">
            Select Receipt
          </Link>
        </li>
      </ul>
      <div className="giftcards-pg__header">
        <div className="giftcards-pg__header-main">
          <h3 className="giftcards-pg__heading">Select Gift Card Receipt</h3>
          <div className="step-radio-btns">
            <input
              type="radio"
              className="radio"
              name="step"
              value="1"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="2"
              disabled
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="3"
              checked
              readOnly
            />
            <input
              type="radio"
              className="radio"
              name="step"
              value="4"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="giftcard-block">
        <ul className="giftcard-block__receipts">
          {cardTypes.map((crdType) => (
            <li
              className={cls(
                "giftcard-block__receipt",
                crdType.id === cardType?.id && "giftcard-block__receipt--active"
              )}
              key={crdType.id}
              onClick={() => {
                dispatch(updateGiftCardType(crdType));
                navigate("/gift-cards/order-create");
              }}
            >
              <input
                type="radio"
                className="radio"
                name="receipt"
                checked={crdType.id === cardType?.id}
                onChange={() => {
                  dispatch(updateGiftCardType(crdType));
                  navigate("/gift-cards/order-create");
                }}
              />
              <p className="giftcard-block__receipt-text">
                {crdType.type_name}
              </p>
            </li>
          ))}
        </ul>
        <div className="giftcard-btns">
          <button onClick={() => navigate(-1)}>
            <IonIcon icon={chevronBackOutline} /> Go Back
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(giftCardHoc(GiftCardReceipt));
