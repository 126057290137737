import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { tRootState } from "../../store";
import { updateLastLoginCheck } from "../../store/userSlice";

import useData from "../useData/useData";

const useProfileCheck = () => {
  const dispatch = useDispatch();

  const lastLoginCheck = useSelector(
    (state: tRootState) => state.user.lastLoginCheck
  );

  const { fetchProfile } = useData();

  const [loading, setLoading] = useState(
    !lastLoginCheck || Date.now() >= lastLoginCheck + 900000
  );

  useEffect(() => {
    fetchProfile().then((res) => {
      if (res === "Profile fetched successfully") {
        setLoading(false);
        dispatch(updateLastLoginCheck());
      }
    });
  }, [fetchProfile, dispatch]);

  return loading;
};

export default useProfileCheck;
