// import { IonIcon } from "@ionic/react";
// import { alertCircle } from "ionicons/icons";

const ManualUnverified = ({
  show,
  closeHandler,
}: {
  show: boolean;
  closeHandler: () => void;
}) => {
  if (!show) return null;

  return (
    <>
      <div className="deposit-naira-modal">
        <div className="deposit-naira-modal__heading text-center">
          NAIRA DEPOSIT and INTERNAL TRANSFER BAN.
        </div>
        <p>
          While you are a fully verified user, we found transactions from your
          account not safe and trusted, hence is potentially unsafe for the
          business community we are building here. This type of transactions
          include!
        </p>
        <ol style={{ paddingLeft: "1.2rem" }}>
          <li>Depositing and withdrawing same value almost immediately.</li>
          <li>Deposit Naira, buy sell buy sell crypto and withdraw naira.</li>
        </ol>
        <p>Among others.</p>
        <p>
          You can still perform other transactions like, sell Giftcards, receive
          all crypto assets, sell crypto assets, make naira withdrawals and
          perform bill payments.
        </p>
        <p>
          Thank you.
          <br />
          SekiApp Fraud Desk
        </p>
        <div className="text-right">
          <button className="button" onClick={closeHandler}>
            I agree
          </button>
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default ManualUnverified;
