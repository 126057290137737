import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { tRootState } from "../../store";

import useData from "../../hooks/useData/useData";

// import bulkCryptoBg from "../../assets/img/bulk-crypto-bg.png";

const WalletBulk = ({
  sm,
  sidebar,
  wallet,
}: {
  sm?: boolean;
  sidebar?: boolean;
  wallet?: boolean;
}) => {
  const navigate = useNavigate();

  const { bulkCryptoBanner, webBulkCryptoBanner } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchBulkCryptoBanner, fetchWebBulkCryptoBanner } = useData();

  useEffect(() => {
    fetchBulkCryptoBanner();
    fetchWebBulkCryptoBanner();
  }, [fetchBulkCryptoBanner, fetchWebBulkCryptoBanner]);

  if (!bulkCryptoBanner || !webBulkCryptoBanner) return null;

  return (
    <div
      className={cls(
        "wallet-bulk",
        sm && "wallet-bulk--sm",
        sidebar && "wallet-bulk--sidebar",
        wallet && "wallet-bulk--wallet"
      )}
      onClick={() => navigate("/bulk-otc")}
      style={sm || wallet ? { borderRadius: "8px" } : {}}
    >
      <img
        src={webBulkCryptoBanner.image}
        alt=""
        className="wallet-bulk__bg wallet-bulk__bg--big"
      />
      <img
        src={bulkCryptoBanner.image}
        alt=""
        className="wallet-bulk__bg wallet-bulk__bg--small"
      />
      <div className="wallet-bulk__main" style={{ visibility: "hidden" }}>
        <h3 className="wallet-bulk__heading">{bulkCryptoBanner.title}</h3>
        <div className="wallet-bulk__info">
          <p>{bulkCryptoBanner.short_description}</p>
          <Link to="/bulk-otc" className="wallet-bulk__link">
            Click here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WalletBulk;
