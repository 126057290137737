import { IonIcon } from "@ionic/react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import { checkmarkOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { tRootState } from "../../store";
import { Navigate, useNavigate } from "react-router-dom";
import { clearTransferInfo } from "../../store/nairaSlice";
import withAuth from "../../hoc/withAuth/withAuth";

const TransferSuccessful = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { transaction, emailMessage, OTPMessage, longOTPMessage } = useSelector(
    (state: tRootState) => state.naira.transfer
  );

  const finish = (link: string) => {
    navigate(link);
    const interval = setInterval(() => {
      dispatch(clearTransferInfo());
      clearInterval(interval);
    }, 5000);
  };

  if (!transaction || !emailMessage) return <Navigate to="/transfer" />;
  if (!OTPMessage || !longOTPMessage) return <Navigate to="/transfer-final" />;

  return (
    <DashboardLayout>
      <div className="naira-block naira-block--final">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <div className="naira-block__header mb-medium">
          <h3 className="naira-block__heading">Transfer successful</h3>
          <p className="naira-block__sub-heading text-center">{OTPMessage}</p>
        </div>
        <div className="naira-block__info-card naira-block__info-card--2 mb-medium">
          {longOTPMessage}
        </div>
        <div className="auth__footer mb-medium">
          <button
            className="form-button form-button--active"
            onClick={() => finish("/dashboard")}
          >
            Go back to dashboard
          </button>
        </div>
        <div className="text-center">
          <span
            className="transactions-section__link"
            onClick={() => finish(`/transactions`)}
          >
            View transactions
          </span>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(TransferSuccessful);
