import { FormEvent, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import { assertNotNull } from "../../utils/func";
import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";

const genderData: { [key: string]: string } = {
  Male: "Male",
  Female: "Female",
  "Prefer not to say": "Prefer not to say",
};

const Profile = () => {
  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const {
    email,
    firstname,
    lastname,
    gender: cachedGender,
    phone,
    is_verified,
    date_of_birth,
  } = userDetails;

  const [personalDetails, setPersonalDetails] = useState({
    firstname,
    lastname,
    phone,
    email,
    date_of_birth,
  });

  const [genderSelectBox, gender, openGenderSelectBox] = useSelectBox<string>(
    "Select Gender",
    getSelectBoxData(genderData),
    cachedGender
  );

  const [message, setMessage, clearMessage] = useAlert();

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const { fetchProfile } = useData();

  const handleChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setPersonalDetails({ ...personalDetails, [target.name]: target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (is_verified) return;

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Updating Profile`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/update-profile",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        firstname: personalDetails.firstname,
        lastname: personalDetails.lastname,
        date_of_birth: personalDetails.date_of_birth,
        gender: gender,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        setMessage("success", res.data.message);

        return fetchProfile();
      })
      .then(() => {
        // do nothing
        clearMessage();
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Update Profile";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [personalDetails, clearMessage]);

  return (
    <>
      {genderSelectBox}
      <h3 className="heading-tertiary">Personal Information</h3>
      <form className="account-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            className={cls("form-input", is_verified && "form-disabled")}
            placeholder="Enter first name"
            name="firstname"
            value={personalDetails.firstname}
            onChange={is_verified ? () => null : handleChangeHandler}
            disabled={!!is_verified}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            className={cls("form-input", is_verified && "form-disabled")}
            placeholder="Enter first name"
            name="lastname"
            value={personalDetails.lastname}
            onChange={is_verified ? () => null : handleChangeHandler}
            disabled={!!is_verified}
          />
        </div>
        <div className="form-group">
          <label>Gender</label>
          <div
            className={cls("select-box", is_verified && "form-disabled")}
            onClick={is_verified ? () => null : openGenderSelectBox}
          >
            <p>{gender ? gender : is_verified ? "Unset" : "Select Gender"}</p>
            {!is_verified ? <IonIcon icon={chevronDownOutline} /> : null}
          </div>
        </div>
        <div className="form-group">
          <label>Date of Birth</label>
          <input
            type="date"
            className={cls("form-input", is_verified && "form-disabled")}
            name="date_of_birth"
            value={personalDetails.date_of_birth || ""}
            onChange={is_verified ? () => null : handleChangeHandler}
            disabled={!!is_verified}
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            className="form-input form-disabled"
            placeholder="Enter phone number"
            value={phone || ""}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="text"
            className="form-input form-disabled"
            placeholder="Enter email address"
            value={email}
            disabled
          />
        </div>
        <div className="account-form__item-full">{message}</div>
        {!is_verified ? (
          <button
            className="form-button form-button--active"
            ref={submitBtnRef}
          >
            Save
          </button>
        ) : null}
      </form>
    </>
  );
};

export default withAuth(Profile);
