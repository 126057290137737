const TopGiftCardsLoader = () => {
  return (
    <>
      {new Array(6).fill(null).map((_, i) => (
        <div className="mt-giftcards__card" key={i}>
          <div className="mt-giftcards__card-img placeholder"></div>
          <div className="mt-giftcards__card-info">
            <h3 className="mt-giftcards__card-name">
              <span className="color-transparent placeholder">Steam - USA</span>
            </h3>
          </div>
        </div>
      ))}
    </>
  );
};

export default TopGiftCardsLoader;
