import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, copy } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import { copyData, getDate, roundDP } from "../../utils/func";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../components/Preloader/Preloader";

export type tDepositTransaction = {
  id: number;
  hash: string;
  account_name: string;
  is_completed: number;
  created_at: string;
  amount: string;
};

const DepositDetails = () => {
  const navigate = useNavigate();
  const { hash } = useParams();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const msg = useSelector(
    (state: tRootState) => state.cache.warnings.fraud_deposit
  );

  const [deposit, setDeposit] = useState<tDepositTransaction | null>(null);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    api_client({
      url: `payment-request/${hash}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setDeposit(res.data.message);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [hash, accessToken, navigate]);

  if (!deposit) return <Preloader />;

  return (
    <DashboardLayout>
      <div className="transaction-details">
        <h3 className="transaction-details__heading">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBackOutline} />
          </span>
          Deposit Details
        </h3>
        <p className="transaction-details__heading-subtext">
          Your Deposit Payment Information
        </p>
        <div className="transaction-details__infos">
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Hash</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">{deposit.hash}</p>
              <div
                className="copy"
                onClick={() => copyData(deposit.hash, setCopied)}
              >
                <IonIcon icon={copy} />
                {copied ? "Copied!" : "Copy"}
              </div>
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Account Name</p>
            <div className="transaction-details__info-right">
              <p className="transaction-details__info-text">
                {deposit.account_name}
              </p>
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Amount</p>
            <div className="transaction-details__info-right">
              &#8358;{roundDP(deposit.amount, 2)}
            </div>
          </div>
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Status</p>
            <div className="transaction-details__info-right">
              {deposit.is_completed === 1 ? (
                <span className="badge badge--success">Approved</span>
              ) : deposit.is_completed === 0 ? (
                <span className="badge badge--warning">Pending</span>
              ) : deposit.is_completed === -1 ? (
                <span className="badge badge--danger">Failed</span>
              ) : deposit.is_completed === -2 ? (
                <span className="badge badge--danger">Expired</span>
              ) : deposit.is_completed === -3 ? (
                <span className="badge badge--danger">Cancelled</span>
              ) : deposit.is_completed === -4 ? (
                <span className="badge badge--success">Refunded</span>
              ) : deposit.is_completed === -5 ? (
                <span className="badge badge--danger">Fraud Deposit</span>
              ) : null}
            </div>
          </div>
          {deposit.is_completed === -5 && msg ? (
            <div className="transaction-details__info text-danger text-justify">
              <p>
                <strong>Note:</strong> {msg}
              </p>
            </div>
          ) : null}
          {deposit.is_completed === 0 ? (
            <div className="transaction-details__info">
              <p className="transaction-details__info-key">Create Dispute</p>
              <div className="transaction-details__info-right">
                <button
                  className="button button--danger"
                  onClick={() => navigate(`/deposit-dispute/${hash}`)}
                >
                  Create Dispute
                </button>
              </div>
            </div>
          ) : null}
          <div className="transaction-details__info">
            <p className="transaction-details__info-key">Date</p>
            <div className="transaction-details__info-right">
              {getDate(deposit.created_at)}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(DepositDetails);
