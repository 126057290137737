import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

import { tRootState } from "../../../store";
import { removeBillData } from "../../../store/paybillsSlice";

import withAuth from "../../../hoc/withAuth/withAuth";

import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";

import { assertNotNull, roundDP } from "../../../utils/func";

const BillSuccessful = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const billData = useSelector((state: tRootState) => state.paybills.billData);

  assertNotNull(userDetails);

  const clearData = useCallback(() => dispatch(removeBillData()), [dispatch]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      clearData();
      window.clearInterval(interval);
    }, 15000);
  }, [clearData]);

  if (!billData) return <Navigate to="/pay-bills" />;

  const {
    service,
    serviceProvider,
    servicePackage,
    recipient,
    recipientValidation,
    numberOfPins,
    pinValue,
    amount,
    newBeneficiary,
    alias,
    success,
  } = billData;

  const formComplete =
    serviceProvider &&
    (!service.has_package || servicePackage) &&
    (!service.has_recipient || recipient) &&
    (!service.has_recipient_validation || recipientValidation) &&
    (service.service_type !== "epin" || (numberOfPins && pinValue)) &&
    amount &&
    +amount <= userDetails.balance &&
    (!newBeneficiary || (alias && alias.length < 10));

  if (!formComplete || !success)
    return (
      <Navigate to={`/pay-bills${service ? `/${service.service_type}` : ""}`} />
    );

  return (
    <DashboardLayout>
      <div className="pay-bills-block">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <h3 className="pay-bills-block__success-heading">
          Bill Purchase Successful
        </h3>
        <p className="pay-bills-block__success-text">
          Your {serviceProvider.provider_name} {service.service_name} Purchase
          {servicePackage ? <>({servicePackage.package_name})</> : null} of{" "}
          {amount ? (
            <>
              &#8358;
              {roundDP(amount, 2)}
            </>
          ) : null}{" "}
          was successful
          {recipient ? (
            <>
              ly sent to <span>{recipient}</span>
            </>
          ) : null}
          ! Thank you for choosing SekiApp
        </p>
        <div className="auth__footer">
          <button
            className="form-button form-button--active"
            onClick={clearData}
          >
            Finish
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(BillSuccessful);
