import { IonIcon } from "@ionic/react";
import { addOutline, businessOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { tRootState } from "../../store";
import { useEffect, useState } from "react";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import useData from "../../hooks/useData/useData";
import { useNavigate } from "react-router-dom";

const Banks = () => {
  const navigate = useNavigate();

  const banks = useSelector((state: tRootState) => state.cache.depositBanks);

  const [error, setError] = useState(false);

  const { fetchBanks } = useData();

  useEffect(() => {
    fetchBanks("deposit").catch(() => {
      setError(true);
    });
  }, [fetchBanks]);

  return (
    <>
      <div className="account-header">
        <h3 className="heading-tertiary">Deposit Bank Account</h3>
        <p>
          <strong>Note:</strong> You are only allowed to add a total of 5 banks
        </p>
      </div>
      {!banks && error ? <div>Error fetching banks</div> : null}
      {!banks && !error ? <VerticalBarLoader align="left" /> : null}
      {banks ? (
        <>
          <div className="account-banks">
            {banks.map((bank) => (
              <div className="account-bank" key={bank.id}>
                <IonIcon
                  icon={businessOutline}
                  className="account-bank__icon"
                />
                <div className="account-bank__info">
                  <p className="account-bank__name">{bank.bank_name}</p>
                  <p className="account-bank__account-name-number">
                    <span>{bank.bank_account_name}</span>
                    <span>{bank.bank_account_no}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          {banks.length < 5 ? (
            <div>
              <div
                className="account-add-bank"
                onClick={() => navigate("/add-bank")}
              >
                <IonIcon icon={addOutline} />
                <p>Add New Bank account</p>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Banks;
