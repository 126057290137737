import { useState } from "react";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
} from "ionicons/icons";

const ImgsPreview = ({
  images,
  selectedImg,
  closeHandler,
}: {
  images: string[];
  selectedImg: number;
  closeHandler: () => void;
}) => {
  const [selectedImage, setSelectedImage] = useState(selectedImg);

  const previousImg = () => {
    if (selectedImage > 0) setSelectedImage(selectedImage - 1);
  };

  const nextImg = () => {
    if (selectedImage < images.length - 1) setSelectedImage(selectedImage + 1);
  };

  return (
    <div className="imgs-preview">
      <div className="imgs-preview__main">
        <button
          className="imgs-preview__control imgs-preview__close"
          onClick={closeHandler}
        >
          <IonIcon icon={closeOutline} />
        </button>
        <button
          className="imgs-preview__control imgs-preview__control--prev"
          onClick={previousImg}
        >
          <IonIcon icon={chevronBackOutline} />
        </button>
        <button
          className="imgs-preview__control imgs-preview__control--next"
          onClick={nextImg}
        >
          <IonIcon icon={chevronForwardOutline} />
        </button>
        <img src={images[selectedImage]} className="imgs-preview__img" alt="" />
      </div>
      <div className="imgs-preview__previews">
        {images.map((img, i) => {
          return (
            <div
              className={cls(
                "imgs-preview__preview ",
                i === selectedImage && "imgs-preview__preview--active"
              )}
              onClick={() => setSelectedImage(i)}
              key={i}
            >
              <img src={img} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImgsPreview;
