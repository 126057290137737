import { IonIcon } from "@ionic/react";
import { caretDownOutline } from "ionicons/icons";

const MarketLoader = () => {
  return (
    <>
      {new Array(10).fill(null).map((_, i) => (
        <tr key={i}>
          <td>
            <span className="color-transparent placeholder">100</span>
          </td>
          <td>
            <div className="market__currency">
              <div className="market__currency-plh-image placeholder"></div>
              <span className="market__currency-name color-transparent placeholder">
                Bitcoin |<span>BTC</span>
              </span>
            </div>
          </td>
          <td>
            <span className="color-transparent placeholder">$ 26,000</span>
          </td>
          <td>
            <span className="color-transparent placeholder">
              <IonIcon icon={caretDownOutline} />
              1.3
            </span>
          </td>
          <td>
            <button className="market__trade-btn market__trade-btn--plh placeholder">
              Trade
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default MarketLoader;
