import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tTransactionDetails } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import Preloader from "../../components/Preloader/Preloader";
import CryptoDepositWithdrawTransaction from "./CryptoDepositWithdrawTransaction/CryptoDepositWithdrawTransaction";
import CryptoSwapTransaction from "./CryptoSwapTransaction/CryptoSwapTransaction";
import BillTransaction from "./BillTransaction/BillTransaction";
import CryptoTransferTransaction from "./CryptoTransferTransaction/CryptoTransferTransaction";
import NairaTransferTransaction from "./NairaTransferTransaction/NairaTransferTransaction";
import VirtualCardTransaction from "./VirtualCardTransaction/VirtualCardTransaction";

const TransactionDetails = () => {
  const navigate = useNavigate();

  const { type, hash } = useParams();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [transaction, setTransaction] = useState<tTransactionDetails | null>(
    null
  );

  useEffect(() => {
    api_client({
      url: `/transactions/details/${type}/${hash}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (typeof res.data.success !== "undefined" && !res.data.success) {
          throw new Error("");
        }

        setTransaction({ ...res.data, __type: type });
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [hash, type, accessToken, navigate]);

  if (!transaction) return <Preloader />;

  return (
    <DashboardLayout>
      {transaction.__type === "crypto_sent" ||
      transaction.__type === "crypto_received" ? (
        <CryptoDepositWithdrawTransaction transaction={transaction} />
      ) : null}
      {transaction.__type === "crypto_swap" ? (
        <CryptoSwapTransaction transaction={transaction} />
      ) : null}
      {transaction.__type === "bills" ? (
        <BillTransaction transaction={transaction} />
      ) : null}
      {transaction.__type === "internal_wallets" ? (
        <CryptoTransferTransaction transaction={transaction} />
      ) : null}
      {transaction.__type === "internal_naira" ? (
        <NairaTransferTransaction transaction={transaction} />
      ) : null}
      {transaction.__type === "virtual_cards" ? (
        <VirtualCardTransaction transaction={transaction} />
      ) : null}
    </DashboardLayout>
  );
};

export default withAuth(TransactionDetails);
