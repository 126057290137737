import { useState } from "react";

import { copyData } from "../../utils/func";

const useCopy = (data: string): [boolean, (callback?: () => void) => void] => {
  const [copied, setCopied] = useState(false);

  return [
    copied,
    (callback?: () => void) => {
      copyData(data, setCopied, callback);
    },
  ];
};

export default useCopy;
