import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowBack, copy } from "ionicons/icons";

import { tRootState } from "../../store";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import DepositWarningModal from "./Modals/DepositWarningModal";
import DepositWarningModal2 from "./Modals/DepositWarningModal2";

import withAuth from "../../hoc/withAuth/withAuth";

import { assertNotNull, copyData, roundDP } from "../../utils/func";

const DepositBankDetails = () => {
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const { bank, amount, transaction } = useSelector(
    (state: tRootState) => state.naira.deposit
  );

  assertNotNull(userDetails);

  const [copied, setCopied] = useState(false);

  const [showDepositModal, setShowDepositModal] = useState<
    "" | "first-modal" | "second-modal"
  >("first-modal");

  if (!userDetails.is_verified) return <Navigate to="/404" />;
  if (!bank || !amount) return <Navigate to="/deposit/amount" />;
  if (!transaction) return <Navigate to="/deposit/details" />;

  return (
    <DashboardLayout>
      <DepositWarningModal
        bank={bank}
        show={showDepositModal === "first-modal"}
        closeHandler={() => setShowDepositModal("second-modal")}
      />
      <DepositWarningModal2
        show={showDepositModal === "second-modal"}
        closeHandler={() => setShowDepositModal("")}
      />
      <div className="naira-block">
        <div className="naira-block__header">
          <h3 className="naira-block__heading">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Make Payment
          </h3>
          <p className="naira-block__sub-heading text-center">
            Make payment to the account provided below to credit yout SekiApp
            naira wallet
          </p>
        </div>
        <div className="naira-block__form">
          <div className="form-group">
            <label>Bank Name</label>
            <input
              type="text"
              className="form-input form-disabled"
              value={transaction.bank_name}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Account Name</label>
            <input
              type="text"
              className="form-input form-disabled"
              value={transaction.account_name}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Account Number</label>
            <div className="form-input form-disabled naira-block__copiable">
              {transaction.account_number}
              <div
                className="copy"
                onClick={() => copyData(transaction.account_number, setCopied)}
              >
                <IonIcon icon={copy} />
                {copied ? "Copied!" : "Copy"}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="text"
              className="form-input form-disabled"
              value={`NGN ${roundDP(transaction.amount, 2)}`}
              disabled
            />
          </div>
          <div
            className="auth__footer"
            onClick={() => navigate("/deposit/processing")}
          >
            <button className="form-button form-button--active">
              I have made payment
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(DepositBankDetails);
