import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

const CVCSuccessModal = ({ cardId }: { cardId: string }) => {
  const navigate = useNavigate();

  if (!cardId) return null;

  return (
    <>
      <div className="virtual-card-modal">
        <div className="text-center mb-medium">
          <div className="success-icon-block success-icon-block--1">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <div className="naira-block__header mb-medium">
          <h3 className="naira-block__heading">Payment Success</h3>
          <p className="naira-block__sub-heading text-center">
            Your payment for USD card was successful
          </p>
        </div>
        <div className="auth__footer mb-small">
          <button
            className="form-button form-button--active"
            onClick={() => navigate(`/virtual-card?card=${cardId}`)}
            style={{ width: "30rem", alignSelf: "center" }}
          >
            Done
          </button>
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default CVCSuccessModal;
