const InfluencersTransactionsLoader = () => {
  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Username</th>
              <th>Points</th>
              <th>Total Points</th>
              <th className="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {new Array(8).fill(null).map((_, i) => (
              <tr key={i}>
                <td>
                  <div className="transaction-label">
                    <div className="transaction-label__icon-box placeholder"></div>
                  </div>
                </td>
                <td>
                  <span className="color-transparent placeholder">aiyeboy</span>
                </td>
                <td className="bold">
                  <span className="color-transparent placeholder">
                    2000 SKP
                  </span>
                </td>
                <td className="bold">
                  <span className="color-transparent placeholder">
                    2100 SKP
                  </span>
                </td>
                <td>
                  <div className="transaction-date">
                    <p>
                      <span className="color-transparent placeholder">
                        Jun, 22
                      </span>
                    </p>
                    <p>
                      <span className="color-transparent placeholder">
                        12:00:39
                      </span>
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="transactions-flex">
        {new Array(8).fill(null).map((_, i) => (
          <div className="transaction-mobile" key={i}>
            <div className="transaction-label">
              <div className="transaction-label__icon-box placeholder"></div>
            </div>
            <div className="transaction-mobile__main">
              <p className="transaction-mobile__type">
                <span className="color-transparent placeholder"> aiyeboy </span>
              </p>
              <p className="transaction-mobile__text">
                <span className="color-transparent placeholder"> aiyeboy </span>
              </p>
            </div>
            <div className="transaction-mobile__right">
              <p className="transaction-mobile__amount text-success">
                <span className="color-transparent placeholder">2000 SKP</span>
              </p>
              <p className="transaction-mobile__date">
                <span className="color-transparent placeholder">
                  Jun 22, 2023
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default InfluencersTransactionsLoader;
