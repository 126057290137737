import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updateUserDetails } from "../../store/userSlice";

import useAlert from "../../hooks/useAlert/useAlert";

import withAuth from "../../hoc/withAuth/withAuth";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

const NewReferralCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [referralCode, setReferralCode] = useState(
    localStorage.getItem("referral") || ""
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!referralCode)
      return setMessage("warning", "Enter your referral code or skip");

    submitBtnRef.current.setAttribute("disabled", "disabled");
    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    api_client({
      url: "/add-referral",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ referral_code: referralCode }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        dispatch(updateUserDetails(res.data.message));

        localStorage.removeItem("referral");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Proceed";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [clearMessage, referralCode]);

  return (
    <AuthLayout>
      <div className="auth auth--md">
        <div className="auth__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <h2 className="auth__heading">Enter your referral code</h2>
        <p className="auth__heading-sub-text">
          If you don’t have one, kindly click on “don’t have a referral code” to
          proceed
        </p>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="referral_code"
              placeholder="Your referral code"
              className="form-input"
              autoComplete="off"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            <p className="auth__footer-link">
              <Link to="#" onClick={() => setReferralCode("sekiapp")}>
                Don’t have a referral code
              </Link>
            </p>
          </div>
          {message}
          <div className="auth__footer auth__footer--1 mt-medium">
            <button
              className={cls(
                "form-button",
                referralCode && "form-button--active"
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Proceed
            </button>
            <p>
              <span
                className="cursor-pointer"
                onClick={(e) => navigate("/sign-out")}
              >
                Logout
              </span>
            </p>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default withAuth(NewReferralCode);
