import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  arrowBack,
  arrowForwardOutline,
  chevronDownOutline,
  closeOutline,
  swapHorizontalOutline,
  swapVerticalOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import PinGroup from "../../components/PinGroup/PinGroup";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import SelectCurrencyModal from "../../components/SelectCurrencyModal/SelectCurrencyModal";
import Preloader from "../../components/Preloader/Preloader";
import Spinner from "../../loaders/Spinner/Spinner";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import SwapTimer from "./SwapTimer/SwapTimer";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";
import SetPin from "../../components/SetPin/SetPin";
import { getPrecision } from "../../utils/app";

type tRateInfo = {
  amoun_in_coin: number;
  amount: string;
  amount_to_get: number;
  amount_to_get_in_coin: number;
  charge: number;
  charge_in_coin: number;
};

const SwapCryptoNew = () => {
  const navigate = useNavigate();

  const walletSymbol = useSearchParams()[0].get("wallet")?.toLowerCase();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const wallets = useSelector((state: tRootState) => state.cache.wallets);

  assertNotNull(userDetails);

  const { fetchWallets, fetchProfile } = useData();

  const [walletFrom, setWalletFrom] = useState<undefined | tWallet>(
    wallets?.find((sWallet) => sWallet.symbol.toLowerCase() === walletSymbol)
  );
  const [walletTo, setWalletTo] = useState<undefined | tWallet>(undefined);

  const [showSelectWalletFromModal, setShowSelectWalletFromModal] =
    useState(false);
  const [showSelectWalletToModal, setShowSelectWalletToModal] = useState(false);

  const [amount, setAmount] = useState<number | string>("");

  const [pin, setPin] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);
  const [messageSwap, setMessageSwap, clearMessageSwap] = useAlert(true);

  const [showSpinnerLoader, setShowSpinnerLoader] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);

  const [fetchingRates, setFetchingRates] = useState(false);
  const [errorFetchingRates, setErrorFetchingRates] = useState(false);

  const [swapTimeout, setSwapTimeout] = useState(false);

  const [isSwapping, setIsSwapping] = useState(false);

  const [rateInfo, setRateInfo] = useState<tRateInfo | null>(null);

  const [showPinModal, setShowPinModal] = useState(false);

  const getSwapRate = useCallback(() => {
    return new Promise((resolve, reject) => {
      const reqObject =
        walletTo!.symbol.toLowerCase() === "ngn"
          ? {
              url: `/wallet/rate/new-sell?wallet_id=${
                walletFrom!.id
              }&amount=${amount}`,
            }
          : {
              url: `/wallet/rate/new-swap?wallet_from=${
                walletFrom!.symbol
              }&wallet_to=${walletTo!.symbol}&amount=${amount}`,
            };

      api_client({
        ...reqObject,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if ("success" in res.data && !res.data.success) {
            reject(res.data.message);
          } else {
            const newObj =
              walletTo!.symbol.toLowerCase() === "ngn"
                ? {
                    amoun_in_coin: res.data.amount_in_coin,
                    amount_to_get_in_coin: res.data.amount_in_naira,
                  }
                : {};

            resolve({ ...res.data, ...newObj });
          }
        })
        .catch((err) => {
          reject("Error fetching rate info");
        });
    });
  }, [walletFrom, walletTo, accessToken, amount]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!walletFrom || !walletTo)
      return setMessage("warning", "Select both wallets");

    if (!amount) return setMessage("warning", "The amount field is required");

    if (walletFrom.balance < +amount)
      return setMessage("warning", "Insfficient balance");

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Enter PIN");

    setShowSpinnerLoader(true);

    getSwapRate()
      .then((rate) => {
        setRateInfo(rate as any); // Bad
        setSwapTimeout(true);
        setShowSwapModal(true);
      })
      .catch((err) => {
        // Do an error handler
        setMessage("warning", err);
      })
      .finally(() => {
        setShowSpinnerLoader(false);
      });
  };

  const refreshRate = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;

    target.setAttribute("disabled", "disabled");

    setErrorFetchingRates(false);
    setFetchingRates(true);

    getSwapRate()
      .then((rate) => {
        const rtInfo: tRateInfo = rate as any;

        if (
          typeof rtInfo !== "object" ||
          !rtInfo.amoun_in_coin ||
          Number.isNaN(+rtInfo.amoun_in_coin) ||
          !rtInfo.amount_to_get_in_coin ||
          Number.isNaN(+rtInfo.amount_to_get_in_coin)
        )
          throw new Error("");

        setRateInfo(rtInfo); // Bad
        setSwapTimeout(true);
      })
      .catch((err) => {
        // Do an error handler
        setErrorFetchingRates(true);
      })
      .finally(() => {
        setFetchingRates(false);
        target.removeAttribute("disabled");
      });
  };

  const swapHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Should never get here
    if (!walletFrom || !walletTo || pin.length !== 4) return;

    const triggerBtn = e.target as HTMLButtonElement;

    triggerBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    triggerBtn.setAttribute("disabled", "disabled");

    setIsSwapping(true);
    clearMessageSwap();

    api_client({
      method: "POST",
      url: "/wallet/new-swap-coin",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        swap_from: walletFrom.symbol,
        swap_to: walletTo.symbol,
        amount: amount,
        flow: 1,
        pin: pin,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessageSwap("warning", res.data.message);
          throw new Error("");
        }

        setMessageSwap(
          "success",
          "Crypto swap successful. You would be redirected shortly..."
        );

        return fetchProfile();
      })
      .then((res) => {
        navigate("/wallet");
      })
      .catch((err) => {
        if (err.message) setMessageSwap("error", err.message);
      })
      .finally(() => {
        setIsSwapping(false);
        if (triggerBtn) {
          triggerBtn.removeAttribute("disabled");
          triggerBtn.innerHTML = `Swap Now`;
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [walletFrom, walletTo, amount, pin, clearMessage]);

  useEffect(() => {
    if (wallets && walletSymbol && !walletFrom)
      setWalletFrom(
        wallets?.find(
          (sWallet) => sWallet.symbol.toLowerCase() === walletSymbol
        )
      );
  }, [wallets, walletSymbol, walletFrom]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  if (!wallets) return <Preloader />;

  const dynamicProps2: Partial<{ onlyShow: string[]; dontShow: string }> = {};

  if (walletFrom) {
    if (walletFrom.symbol !== "usdt") {
      dynamicProps2.onlyShow = ["usdt", "ngn"];

      if (
        walletTo &&
        !["usdt", "ngn"].includes(walletTo.symbol.toLowerCase())
      ) {
        setWalletTo(undefined);
      }
    } else {
      dynamicProps2.dontShow = "usdt";
    }

    if (walletTo && walletTo?.symbol === walletFrom.symbol) {
      setWalletTo(undefined);
    }
  }

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      {showSpinnerLoader ? <Spinner /> : null}
      <SelectCurrencyModal
        show={showSelectWalletFromModal}
        type="crypto"
        selectHandler={(selWallet) => {
          setWalletFrom(selWallet);
          setShowSelectWalletFromModal(false);
        }}
        closeHandler={() => setShowSelectWalletFromModal(false)}
        selectedWallet={walletFrom?.symbol}
      />
      <SelectCurrencyModal
        show={showSelectWalletToModal}
        selectHandler={(selWallet) => {
          setWalletTo(selWallet);
          setShowSelectWalletToModal(false);
        }}
        closeHandler={() => setShowSelectWalletToModal(false)}
        selectedWallet={walletTo?.symbol}
        {...dynamicProps2}
      />
      {showSwapModal && walletFrom && walletTo && rateInfo ? (
        <>
          <div className="swap-modal">
            <div className="swap-modal__header">
              <h3 className="swap-modal__heading">Confirm Swap</h3>
              <span
                className="swap-modal__close"
                onClick={() => setShowSwapModal(false)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
            <div className="swap-modal__main">
              <div className="swap-modal__swap-block">
                <div className="swap-modal__crypto">
                  <img
                    src={walletFrom.image}
                    alt=""
                    className="swap-modal__crypto-img"
                  />
                  <p>{walletFrom.symbol.toUpperCase()}</p>
                </div>
                <IonIcon
                  icon={arrowForwardOutline}
                  className="swap-modal__swap-icon"
                />
                <div className="swap-modal__crypto">
                  <img
                    src={walletTo.image}
                    alt=""
                    className="swap-modal__crypto-img"
                  />
                  <p>{walletTo.symbol.toUpperCase()}</p>
                </div>
              </div>
              <div className="swap-modal__infos">
                <div className="swap-modal__info">
                  <div>Rate</div>
                  <div>
                    <span>
                      <IonIcon icon={swapHorizontalOutline} />
                    </span>
                    {roundDP(
                      rateInfo.amount_to_get_in_coin / rateInfo.amoun_in_coin,
                      getPrecision(walletTo.symbol)
                    )}{" "}
                    <small>
                      {walletTo.symbol.toUpperCase()} per{" "}
                      {walletFrom.symbol.toUpperCase()}
                    </small>
                  </div>
                </div>
                <div className="swap-modal__info">
                  <div>From</div>
                  <div>
                    {roundDP(
                      rateInfo.amoun_in_coin,
                      getPrecision(walletFrom.symbol)
                    )}{" "}
                    <small>{walletFrom.symbol.toUpperCase()}</small>
                  </div>
                </div>
                <div className="swap-modal__info">
                  <div>To</div>
                  <div>
                    {roundDP(
                      rateInfo.amount_to_get_in_coin,
                      getPrecision(walletTo.symbol)
                    )}{" "}
                    <small>{walletTo.symbol.toUpperCase()}</small>
                  </div>
                </div>
              </div>
              {fetchingRates ? (
                <div style={{ marginBottom: "-4.2rem" }}>
                  <VerticalBarLoader sm />
                </div>
              ) : null}
              {errorFetchingRates ? (
                <div className="text-center text-danger">
                  Error fetching rates. Try again
                </div>
              ) : null}
              {swapTimeout ? (
                <SwapTimer setElapsed={() => setSwapTimeout(false)} />
              ) : null}
              {!swapTimeout &&
              !fetchingRates &&
              !errorFetchingRates &&
              !isSwapping ? (
                <div className="text-center text-danger">
                  Quote expired. Please refresh
                </div>
              ) : null}
            </div>
            {messageSwap}
            {!swapTimeout && !isSwapping ? (
              <button
                className="form-button form-button--active"
                onClick={refreshRate}
              >
                Refresh Quote
              </button>
            ) : (
              <button
                className="form-button form-button--active"
                onClick={swapHandler}
              >
                Swap Now
              </button>
            )}
          </div>
          <div
            className="overlay"
            onClick={() => setShowSwapModal(false)}
          ></div>
        </>
      ) : null}
      <div className="crypto-block2">
        <div className="crypto-block2__header">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </span>
          <h3 className="crypto-block2__heading">Swap your crypto</h3>
        </div>
        <div className="crypto-block2__body crypto-block2__body--p0">
          <div className="crypto-block2__flex">
            <div className="crypto-block2__swap-block">
              <div className="form-group">
                {walletFrom ? (
                  <div className="crypto-block2__swap-heading">
                    <p>
                      You are swapping from {walletFrom.symbol.toUpperCase()}
                    </p>
                    <p>
                      AVAIL. ={" "}
                      {roundDP(
                        walletFrom.balance,
                        getPrecision(walletFrom.symbol)
                      )}{" "}
                      {walletFrom.symbol.toUpperCase()} ($
                      {roundDP(walletFrom.dollar_balance, 2)})
                    </p>
                  </div>
                ) : null}
                <div
                  className="select-box select-box--md"
                  onClick={() => setShowSelectWalletFromModal(true)}
                >
                  {walletFrom ? <img src={walletFrom.image} alt="" /> : null}
                  <p>{walletFrom ? walletFrom.coin : "Select Wallet"}</p>
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
              <button
                className={cls(
                  "crypto-block2__swap-btn"
                  // walletTo && "crypto-block2__swap-draw-up"
                )}
                onClick={() => {
                  if (walletTo?.symbol.toLowerCase() === "ngn") return;
                  setWalletTo(walletFrom);
                  setWalletFrom(walletTo);
                }}
              >
                <IonIcon icon={swapVerticalOutline} />
              </button>
              <div className="form-group">
                {walletTo ? (
                  <div className="crypto-block2__swap-heading">
                    <p>to receive {walletTo.symbol.toUpperCase()}</p>
                    <p>
                      AVAIL. ={" "}
                      {roundDP(walletTo.balance, getPrecision(walletTo.symbol))}{" "}
                      {walletTo.symbol.toUpperCase()} ($
                      {roundDP(walletTo.dollar_balance, 2)})
                    </p>
                  </div>
                ) : null}
                <div
                  className="select-box select-box--md"
                  onClick={() => setShowSelectWalletToModal(true)}
                >
                  {walletTo ? <img src={walletTo.image} alt="" /> : null}
                  <p>{walletTo ? walletTo.coin : "Select Wallet"}</p>
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
            {walletFrom ? (
              <div className="form-group form-group--md">
                <p>
                  MAX: {walletFrom.symbol.toUpperCase()}{" "}
                  {roundDP(walletFrom.balance, getPrecision(walletFrom.symbol))}
                </p>
                <div className="input-group">
                  <button type="button">
                    {walletFrom.symbol.toUpperCase()}
                  </button>
                  <input
                    type="text"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) =>
                      e.target.value
                        ? isNumber(e.target.value) &&
                          +e.target.value <= walletFrom.balance
                          ? setAmount(e.target.value)
                          : null
                        : setAmount("")
                    }
                  />
                </div>
                <AmountPercentage
                  totalAmount={walletFrom.balance}
                  handler={setAmount}
                  precision={getPrecision(walletFrom.symbol)}
                />
              </div>
            ) : null}
          </div>
          {userDetails.is_pin_set ? (
            <div className="crypto-block__pin crypto-final__pin-section">
              <h3 className="crypto-final__heading">Enter PIN</h3>
              <div className="register__pin-input-group-2">
                <PinGroup numInputs={4} pin={pin} handler={setPin} />
              </div>
              <span
                className="crypto-final__forgot-pin"
                onClick={() => navigate("/change-pin")}
              >
                Forgot PIN
              </span>
            </div>
          ) : null}
          <div className="crypto-block2__flex">
            {message}
            <button
              className={cls(
                "form-button",
                walletFrom &&
                  walletTo &&
                  amount &&
                  (!userDetails.is_pin_set || pin.length === 4) &&
                  "form-button--active"
              )}
              onClick={handleSubmit}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(SwapCryptoNew);
