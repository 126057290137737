import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { cloneDeep } from "lodash-es";

import { IonIcon } from "@ionic/react";
import { addOutline, removeOutline, searchOutline } from "ionicons/icons";

import { updateFaqs } from "../../store/appSlice";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

const FAQ = () => {
  const dispatch = useDispatch();

  const cachedFAQs = useSelector((state: tRootState) => state.app.faqs);

  const [active, setActive] = useState<number | null>(null);

  const [search, setSearch] = useState("");

  const FAQs =
    search && cachedFAQs
      ? cloneDeep(cachedFAQs)
          .filter(
            (faq) =>
              new RegExp(search, "i").test(faq.topic_title) ||
              faq.questions.some(
                (question) =>
                  new RegExp(search, "i").test(question.question) ||
                  new RegExp(search, "i").test(question.answer)
              )
          )
          .map((faq) => {
            if (new RegExp(search, "i").test(faq.topic_title)) return faq;
            faq.questions = faq.questions.filter(
              (question) =>
                new RegExp(search, "i").test(question.question) ||
                new RegExp(search, "i").test(question.answer)
            );
            return faq;
          })
      : cachedFAQs;

  useEffect(() => {
    api_client({
      url: "/get-faq-list",
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(updateFaqs(res.data.message));
        } else {
          // handle error
        }
      })
      .catch((err) => {
        // handle error
      });
  }, [dispatch]);

  return (
    <HomeLayout>
      <div className="section">
        <div className="section__header">
          <div className="section__heading">FAQ</div>
          <p className="section__desc">Need answers? find them here...</p>
          <div className="section__search-form">
            <IonIcon icon={searchOutline} />
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="section__main rates">
          {!FAQs ? <VerticalBarLoader /> : null}
          {FAQs?.map((faq) => (
            <div className="rates__block" key={faq.topic_title}>
              <h3 className="rates__heading">{faq.topic_title}</h3>
              <div className="rates__rates">
                {faq.questions.map((question) => (
                  <div
                    className={`accordion ${
                      active === question.id ? "accordion--open" : ""
                    }`}
                    key={question.id}
                    onClick={() =>
                      setActive((a) => (a === question.id ? null : question.id))
                    }
                  >
                    <div className="accordion__header">
                      <p>{question.question}</p>
                      <span className="accordion__toggle">
                        <IonIcon icon={addOutline} />
                        <IonIcon icon={removeOutline} />
                      </span>
                    </div>
                    <div
                      className="accordion__body"
                      dangerouslySetInnerHTML={{ __html: question.answer }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </HomeLayout>
  );
};

export default FAQ;
