import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tInfluencerTransactions } from "../../store/types/app.types";

import api_client from "../../api/client";

import InfluencersTransactionsLoader from "../../loaders/InfluencersTransactionsLoader/InfluencersTransactionsLoader";

import { getDateTime } from "../../utils/func";

import { MONTHS_SHORT } from "../../data";

const InfluencerTransactions = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [url, setUrl] = useState(`/influencer-user-activities`);

  const [transactions, setTransactions] = useState<tInfluencerTransactions>([]);

  const [links, setLinks] = useState<
    {
      url: string | null;
      label: string;
      active: boolean;
    }[]
  >([]);
  const [edgeLinks, setEdgeLinks] = useState<{
    first_page_url: string;
    last_page_url: string;
  }>({ first_page_url: "", last_page_url: "" });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(false);

    api_client({
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setLinks(res.data.message.links);
        const trans = (res.data.message.data as tInfluencerTransactions).map(
          (trns) => {
            const [date, time] = getDateTime(trns.date);

            const calcDate = new Date(date);

            const totalDate = `${
              MONTHS_SHORT[calcDate.getMonth()]
            }, ${calcDate.getDate()}`;

            trns.date = totalDate;
            trns.time = time;
            trns.year = calcDate.getFullYear();

            return trns;
          }
        );
        setTransactions(trans);
        setEdgeLinks({
          first_page_url: res.data.message.first_page_url,
          last_page_url: res.data.message.last_page_url,
        });
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, url]);

  if (loading) return <InfluencersTransactionsLoader />;

  if (error)
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={5} className="text-center">
              Error fetching recent transactions
            </td>
          </tr>
        </tbody>
      </table>
    );

  if (!transactions.length)
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={5} className="text-center">
              You have no recent transaction
            </td>
          </tr>
        </tbody>
      </table>
    );

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Username</th>
              <th>Points</th>
              <th>Total Points</th>
              <th className="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, i) => (
              <tr key={i}>
                <td>
                  <div className="transaction-label transaction-label--deposit">
                    <div className="transaction-label__icon-box">
                      <IonIcon icon={arrowDownOutline} />
                    </div>
                  </div>
                </td>
                <td>{transaction.username}</td>
                <td>{transaction.skp} SKP</td>
                <td className="bold">{transaction.total_skp} SKP</td>
                <td>
                  <div className="transaction-date">
                    <p>{transaction.date}</p>
                    <p>{transaction.time}</p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="transactions-flex">
        {transactions.map((transaction, i) => (
          <div className="transaction-mobile" key={i}>
            <div className="transaction-label transaction-label--deposit">
              <div className="transaction-label__icon-box">
                <IonIcon icon={arrowDownOutline} />
              </div>
            </div>
            <div className="transaction-mobile__main">
              <p className="transaction-mobile__hash">{transaction.username}</p>
            </div>
            <div className="transaction-mobile__right">
              <p className="transaction-mobile__amount text-success">
                {transaction.skp} SKP
              </p>
              <p className="transaction-mobile__date">
                {transaction.date}, {transaction.year}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination mt-medium">
        {edgeLinks.first_page_url ? (
          <button onClick={() => setUrl(edgeLinks.first_page_url)}>
            &#171;
          </button>
        ) : null}
        {links.map((link, i) => {
          return isNaN(+link.label) || link.active ? (
            <button
              className={link.active ? "active" : ""}
              onClick={() => setUrl(link.url || "")}
              key={link.label}
            >
              {i === 0 ? (
                <>&#8249;</>
              ) : i === links.length - 1 ? (
                <>&#8250;</>
              ) : (
                link.label
              )}
            </button>
          ) : null;
        })}
        {edgeLinks.last_page_url ? (
          <button onClick={() => setUrl(edgeLinks.last_page_url)}>
            &#187;
          </button>
        ) : null}
      </div>
    </>
  );
};

export default InfluencerTransactions;
