import { IonIcon } from "@ionic/react";
import { chevronDown, searchOutline } from "ionicons/icons";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tRootState } from "../../store";
import { useEffect, useRef, useState } from "react";
import { Entries } from "../../utils/utils.types";
import cls from "classnames";
import OrdersLoader from "../../loaders/OrdersLoader/OrdersLoader";
import {
  tGiftCardTransaction,
  tGiftCardTransactions,
} from "../../store/types/app.types";
import { getDateTime } from "../../utils/func";

import api_client from "../../api/client";
import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";

const filterData = {
  all: "All",
  new: "New",
  pending: "Pending",
  completed: "Paid",
  cancelled: "Canceled",
  disputes: "Disputes",
};

const getBadgeData = (status: number): [string, string] => {
  if (status === 0) return ["danger", "Canceled"];
  if (status === 1) return ["success", "New"];
  if (status === 3) return ["success", "Paid"];
  if (status === 4) return ["warning", "Pending"];

  return ["", ""];
};

const Orders = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [url, setUrl] = useState(`/orders/all`);

  const [filterSelectBox, filter, openFilterSelectBox, , setFilter] =
    useSelectBox<keyof typeof filterData>(
      "Filter Transaction",
      getSelectBoxData(filterData),
      "all"
    );
  const [orders, setOrders] = useState<tGiftCardTransactions>([]);

  const [links, setLinks] = useState<
    {
      url: string | null;
      label: string;
      active: boolean;
    }[]
  >([]);
  const [edgeLinks, setEdgeLinks] = useState<{
    first_page_url: string;
    last_page_url: string;
  }>({ first_page_url: "", last_page_url: "" });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<tGiftCardTransaction | null>(null);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [errorSearch, setErrorSearch] = useState(false);

  const searchInterval = useRef<number | null>(null);

  useEffect(() => {
    if (!search) return;

    if (searchInterval.current) window.clearInterval(searchInterval.current);

    setLoadingSearch(true);
    setErrorSearch(false);
    setOrder(null);

    searchInterval.current = window.setInterval(() => {
      api_client({
        url: `/order/find/${search}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (!res.data.success) throw new Error("");

          setOrder(res.data.message);
        })
        .catch((err) => {
          setErrorSearch(true);
        })
        .finally(() => {
          setLoadingSearch(false);
          if (searchInterval.current)
            window.clearInterval(searchInterval.current);
        });
    }, 3000);
  }, [search, accessToken]);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(false);

    api_client({
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setOrders(res.data.message.data);
        setLinks(res.data.message.links);
        setEdgeLinks({
          first_page_url: res.data.message.first_page_url,
          last_page_url: res.data.message.last_page_url,
        });

        setError(false);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, url]);

  useEffect(() => {
    setUrl(`/orders/${filter}`);
  }, [filter]);

  return (
    <DashboardLayout>
      {filterSelectBox}
      <div className="orders-transactions__header mb-0">
        <h3 className="orders-transactions__heading">My Orders</h3>
        <div className="orders-transactions__header-main">
          <div className="tab-buttons tab-buttons--sm tab-buttons--auto tab-buttons--orders">
            {(Object.entries(filterData) as Entries<typeof filterData>).map(
              ([key, value]) => (
                <button
                  className={cls(filter === key && "active")}
                  onClick={() => setFilter(key)}
                  key={key}
                >
                  {value}
                </button>
              )
            )}
          </div>
          <div className="transactions-pg__filter orders-transactions__filter">
            <span> Filter: </span>
            <div className="select-box" onClick={() => openFilterSelectBox()}>
              {filterData[filter!]} <IonIcon icon={chevronDown} />
            </div>
          </div>
          <div className="orders-transactions__header-right">
            <form className="search-form">
              <div className="search-form__icon-block">
                <IonIcon icon={searchOutline} />
              </div>
              <input
                type="text"
                placeholder="Search by OrderHash"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </div>
        </div>
      </div>
      {loadingSearch && search ? <OrdersLoader len={1} /> : null}
      {!loadingSearch && search ? (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Card Type</th>
                <th>Order Hash</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Status</th>
                <th>Dispute</th>
                <th className="text-center">Date</th>
              </tr>
            </thead>
            <tbody>
              {errorSearch ? (
                <tr>
                  <td colSpan={7} className="text-center">
                    Could not find order with provided order hash
                  </td>
                </tr>
              ) : null}
              {order
                ? [order].map((order) => {
                    const [badge, text] = getBadgeData(order.status);

                    const [date, time] = getDateTime(order.created_at, true);

                    return (
                      <tr
                        key={order.id}
                        onClick={() => navigate(`/order/${order.order_hash}`)}
                      >
                        <td>
                          <div className="transaction-label transaction-label--deposit">
                            <img
                              src={order.service_img}
                              alt=""
                              className="transaction-label__icon-box br-0 bg-transparent"
                            />
                            <p className="bold">
                              {order.service.name}
                              {order.card_country.country_name
                                ? `- ${order.card_country.country_name}`
                                : ""}
                            </p>
                          </div>
                        </td>
                        <td>#{order.order_hash}</td>
                        <td>
                          {order.type === "buy" ? (
                            <span>
                              &#8358;{order.order_amt} (${order.amt_paid})
                            </span>
                          ) : (
                            <span>
                              ${order.order_amt} (&#8358;{order.amt_paid})
                            </span>
                          )}
                        </td>
                        <td className="text-uppercase">
                          {order.type === "buy" ? "Buy" : "Sell"}
                        </td>
                        <td>
                          <span className={`badge badge--${badge}`}>
                            {" "}
                            {text}{" "}
                          </span>
                        </td>
                        <td
                          className={`text-${
                            order.pending_dispute !== null
                              ? "danger"
                              : "success"
                          } text-uppercase`}
                        >
                          {order.pending_dispute !== null
                            ? "ONGOING DISPUTE"
                            : "NO DISPUTE"}
                        </td>
                        <td>
                          <div className="transaction-date">
                            <p>{date}</p>
                            <p>{time}</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      ) : null}
      {!loadingSearch && search ? (
        <div className="transactions-flex">
          {errorSearch ? (
            <table className="table">
              <tbody>
                <tr>
                  <td className="text-center">
                    Could not find order with provided order hash
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
          {order
            ? [order].map((order) => (
                <div
                  className="transaction-mobile"
                  key={order.id}
                  onClick={() => navigate(`/order/${order.order_hash}`)}
                >
                  <div className="transaction-label transaction-label--deposit">
                    <img
                      src={order.service_img}
                      className="transaction-label__icon-box bg-transparent"
                      alt=""
                    />
                  </div>
                  <div className="transaction-mobile__main">
                    <p className="transaction-mobile__type">
                      {order.service.name}
                      {order.card_country.country_name
                        ? `- ${order.card_country.country_name}`
                        : ""}{" "}
                      ({`${order.type[0].toUpperCase()}${order.type.slice(1)}`})
                    </p>
                    <p className="transaction-mobile__text">
                      {order.service.name}
                      {order.card_country.country_name
                        ? `- ${order.card_country.country_name}`
                        : ""}{" "}
                      ({`${order.type[0].toUpperCase()}${order.type.slice(1)}`})
                    </p>
                    <p className="transaction-mobile__hash">
                      {order.order_hash}
                    </p>
                  </div>
                  <div className="transaction-mobile__right">
                    <p className="transaction-mobile__amount text-success">
                      {order.type === "buy" ? (
                        <span>
                          &#8358; {order.order_amt} ($ {order.amt_paid})
                        </span>
                      ) : (
                        <span>
                          $ {order.order_amt} (&#8358; {order.amt_paid})
                        </span>
                      )}
                    </p>
                    <p className="transaction-mobile__date">
                      {getDateTime(order.created_at)[0]}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      ) : null}

      {loading && !search ? <OrdersLoader /> : null}
      {!loading && !search ? (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Card Type</th>
                <th>Order Hash</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Status</th>
                <th>Dispute</th>
                <th className="text-center">Date</th>
              </tr>
            </thead>
            <tbody>
              {error ? (
                <tr>
                  <td colSpan={7} className="text-center">
                    Error fetching orders
                  </td>
                </tr>
              ) : null}
              {!error && !orders.length ? (
                <tr>
                  <td colSpan={7} className="text-center">
                    You have no orders
                  </td>
                </tr>
              ) : null}
              {orders.map((order) => {
                const [badge, text] = getBadgeData(order.status);

                const [date, time] = getDateTime(order.created_at, true);

                return (
                  <tr
                    key={order.id}
                    onClick={() => navigate(`/order/${order.order_hash}`)}
                  >
                    <td>
                      <div className="transaction-label transaction-label--deposit">
                        <img
                          src={order.service_img}
                          alt=""
                          className="transaction-label__icon-box br-0 bg-transparent"
                        />
                        <p className="bold">
                          {order.service.name}
                          {order.card_country.country_name
                            ? `- ${order.card_country.country_name}`
                            : ""}
                        </p>
                      </div>
                    </td>
                    <td>#{order.order_hash}</td>
                    <td>
                      {order.type === "buy" ? (
                        <span>
                          &#8358;{order.order_amt} (${order.amt_paid})
                        </span>
                      ) : (
                        <span>
                          ${order.order_amt} (&#8358;{order.amt_paid})
                        </span>
                      )}
                    </td>
                    <td className="text-uppercase">
                      {order.type === "buy" ? "Buy" : "Sell"}
                    </td>
                    <td>
                      <span className={`badge badge--${badge}`}> {text} </span>
                    </td>
                    <td
                      className={`text-${
                        order.pending_dispute !== null ? "danger" : "success"
                      } text-uppercase`}
                    >
                      {order.pending_dispute !== null
                        ? "ONGOING DISPUTE"
                        : "NO DISPUTE"}
                    </td>
                    <td>
                      <div className="transaction-date">
                        <p>{date}</p>
                        <p>{time}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
      {!loading && !search ? (
        <div className="transactions-flex">
          {error ? (
            <table className="table">
              <tbody>
                <tr>
                  <td className="text-center">Error fetching orders</td>
                </tr>
              </tbody>
            </table>
          ) : null}
          {!error && !orders.length ? (
            <table className="table">
              <tbody>
                <tr>
                  <td className="text-center">You have no orders</td>
                </tr>
              </tbody>
            </table>
          ) : null}
          {orders.map((order) => (
            <div
              className="transaction-mobile"
              key={order.id}
              onClick={() => navigate(`/order/${order.order_hash}`)}
            >
              <div className="transaction-label transaction-label--deposit">
                <img
                  src={order.service_img}
                  className="transaction-label__icon-box bg-transparent"
                  alt=""
                />
              </div>
              <div className="transaction-mobile__main">
                <p className="transaction-mobile__type">
                  {order.service.name}
                  {order.card_country.country_name
                    ? `- ${order.card_country.country_name}`
                    : ""}{" "}
                  ({`${order.type[0].toUpperCase()}${order.type.slice(1)}`})
                </p>
                <p className="transaction-mobile__text">
                  {order.service.name}
                  {order.card_country.country_name
                    ? `- ${order.card_country.country_name}`
                    : ""}{" "}
                  ({`${order.type[0].toUpperCase()}${order.type.slice(1)}`})
                </p>
                <p className="transaction-mobile__hash">{order.order_hash}</p>
              </div>
              <div className="transaction-mobile__right">
                <p className="transaction-mobile__amount text-success">
                  {order.type === "buy" ? (
                    <span>
                      &#8358; {order.order_amt} ($ {order.amt_paid})
                    </span>
                  ) : (
                    <span>
                      $ {order.order_amt} (&#8358; {order.amt_paid})
                    </span>
                  )}
                </p>
                <p className="transaction-mobile__date">
                  {getDateTime(order.created_at)[0]}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {!loading && !error && !search && orders.length ? (
        <div className="pagination">
          {edgeLinks.first_page_url ? (
            <button onClick={() => setUrl(edgeLinks.first_page_url)}>
              &#171;
            </button>
          ) : null}
          {links.map((link, i) => {
            return isNaN(+link.label) || link.active ? (
              <button
                className={link.active ? "active" : ""}
                onClick={() => setUrl(link.url || "")}
                key={link.label}
              >
                {i === 0 ? (
                  <>&#8249;</>
                ) : i === links.length - 1 ? (
                  <>&#8250;</>
                ) : (
                  link.label
                )}
              </button>
            ) : null;
          })}
          {edgeLinks.last_page_url ? (
            <button onClick={() => setUrl(edgeLinks.last_page_url)}>
              &#187;
            </button>
          ) : null}
        </div>
      ) : null}
    </DashboardLayout>
  );
};

export default Orders;
