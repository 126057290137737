import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBackOutline, checkbox, close } from "ionicons/icons";

import { tRootState } from "../../store";
import {
  tVirtualCardTheme,
  tVirtualCardThemeTypes,
} from "../../store/types/app.types";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import useAlert from "../../hooks/useAlert/useAlert";

import Preloader from "../../components/Preloader/Preloader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

import SetPin from "../../components/SetPin/SetPin";
import PinGroup from "../../components/PinGroup/PinGroup";
import ContactVerification from "../../components/ContactVerification/ContactVerification";

import CVCSuccessModal from "./CVCSuccessModal/CVCSuccessModal";
import MatiButton from "../../components/MatiButton/MatiButton";

import { getProfilePicture } from "../../utils/user";
import { assertNotNull, roundDP } from "../../utils/func";

import naijaFlag from "../../assets/img/naija-flag.png";
import verificationIcon from "../../assets/img/verification-icon.png";

const CreateVirtualCard = () => {
  const navigate = useNavigate();

  const { userDetails, accessToken } = useSelector(
    (state: tRootState) => state.user
  );
  const { virtualCards, virtualCardThemes, virtualCardSummary } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(userDetails);

  const {
    names,
    username,
    gender,
    verif_status,
    is_verified,
    balance,
    is_pin_set,
  } = userDetails;

  const {
    fetchVirtualCards,
    fetchVcardWallets,
    fetchVirtualCardThemes,
    fetchVirtualCardSummary,
    fetchProfile,
  } = useData();

  const verifButtonRef = useRef<HTMLDivElement>(null);

  const [virtualCardThemeType, setVirtualCardThemeType] =
    useState<tVirtualCardThemeTypes>("mastercard");

  const [virtualCardTheme, setVirtualCardTheme] =
    useState<tVirtualCardTheme | null>(null);

  const [alias, setAlias] = useState("");

  const [showEnterPinModal, setShowEnterPinModal] = useState(false);

  const [pin, setPin] = useState("");

  const [message1, setMessage1, clearMessage1] = useAlert(true);
  const [message, setMessage, clearMessage] = useAlert(true);

  const [showSuccessModal, setShowSuccessModal] = useState("");

  const [showPinModal, setShowPinModal] = useState(false);

  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const proceedVirtualCard = (e: MouseEvent<HTMLButtonElement>) => {
    if (!virtualCardTheme)
      return setMessage1("warning", "Select virtual card theme");

    if (!alias) return setMessage1("warning", "Card alias is required");

    if (aliasExists) return setMessage1("warning", "Inputed alias exists");

    if (!is_pin_set) return setShowPinModal(true);

    setContactVerificationCallback(() => () => {
      setShowEnterPinModal(true);
    });
  };

  const createVirtualCard = (e: MouseEvent<HTMLButtonElement>) => {
    if (pin.length !== 4) return setMessage("warning", "Enter PIN");

    const submitBtn = e.target! as HTMLButtonElement;

    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtn.setAttribute("disabled", "disabled");

    let cardId: string;

    api_client({
      method: "POST",
      url: "/virtual/create-card",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        card_theme: virtualCardTheme!.id,
        pin,
        alias,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        cardId = res.data.card.id;

        return fetchVirtualCards();
      })
      .then(() => {
        return fetchVcardWallets();
      })
      .then(() => {
        return fetchProfile();
      })
      .then(() => {
        setShowEnterPinModal(false);
        setShowSuccessModal(cardId);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtn) {
          submitBtn.removeAttribute("disabled");
          submitBtn.innerHTML = "Proceed";
        }
      });
  };

  const handleCardTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVirtualCardThemeType(e.target.value as any);
  };

  useEffect(() => {
    setPin("");
  }, [showEnterPinModal]);

  useEffect(() => {
    clearMessage();
  }, [pin, clearMessage]);

  useEffect(() => {
    clearMessage1();
  }, [virtualCardTheme, alias, clearMessage1]);

  useEffect(() => {
    fetchVirtualCardSummary(virtualCardThemeType);
    fetchVirtualCardThemes(virtualCardThemeType);

    setVirtualCardTheme(null);
  }, [fetchVirtualCardSummary, fetchVirtualCardThemes, virtualCardThemeType]);

  useEffect(() => {
    fetchVirtualCards();
  }, [fetchVirtualCards]);

  if (!virtualCards) return <Preloader />;

  const cardSummary = virtualCardSummary[virtualCardThemeType];

  if (!cardSummary) return <Preloader />;

  const activeThemes = virtualCardThemes[virtualCardThemeType];

  const enoughToBuyCard = balance > +cardSummary.fiat_amount;

  const aliasExists =
    virtualCards.findIndex(
      (vCard) => vCard.alias.toLowerCase() === alias.toLowerCase()
    ) !== -1;

  return (
    <DashboardLayout>
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />

      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}

      {showEnterPinModal ? (
        <>
          <div className="withdraw-type-modal">
            <span
              className="withdraw-type-modal__close"
              onClick={() => setShowEnterPinModal(false)}
            >
              <IonIcon icon={close} />
            </span>
            <div className="withdraw-type-modal__header">
              <h3 className="withdraw-type-modal__heading">Enter pin</h3>
              <p className="text-center">
                Kindly enter your pin to complete the virtual card creation
                process
              </p>
            </div>
            <div
              className="crypto-block__pin crypto-final__pin-section"
              style={{ backgroundColor: "transparent", padding: 0 }}
            >
              <h3 className="crypto-final__heading">Enter PIN</h3>
              <div className="register__pin-input-group-2">
                <PinGroup numInputs={4} pin={pin} handler={setPin} />
              </div>
              <span
                className="crypto-final__forgot-pin"
                onClick={() => navigate("/change-pin")}
              >
                Forgot PIN
              </span>
            </div>
            <div className="virtual-card__footer">
              <div style={{ alignSelf: "stretch" }}>{message}</div>
              <button
                className={cls(
                  "form-button withdraw-type-modal__button",
                  pin.length === 4 && "form-button--active"
                )}
                onClick={createVirtualCard}
              >
                Proceed
              </button>
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setShowEnterPinModal(false)}
          ></div>
        </>
      ) : null}

      <CVCSuccessModal cardId={showSuccessModal} />

      <div className="virtual-card">
        <div className="virtual-card__header">
          <span
            className="virtual-card__back"
            onClick={() => navigate("/virtual-cards")}
          >
            <IonIcon icon={arrowBackOutline} />
          </span>
          <h3 className="virtual-card__heading">Create new card</h3>
        </div>
        <div className="virtual-card__body">
          <p>
            There is a {cardSummary.currency}
            {roundDP(cardSummary.amount, 2)} ({cardSummary.wallet}{" "}
            {roundDP(cardSummary.fiat_amount, 2)}) fee to create a card and KYC
            verified to be eligible to create a virtual card.
          </p>
          <div className="form-group">
            <div className="form-label">Select account</div>
            <div className="virtual-card__account">
              <img
                src={naijaFlag}
                alt=""
                className="virtual-card__account-img"
              />
              <p className="virtual-card__account-name">Naira wallet</p>
              <span className="virtual-card__account-balance">
                {roundDP(balance, 2)} NGN
              </span>
            </div>
          </div>
          {!is_verified ? (
            <div className="virtual-card__user">
              <div className="vau">
                <img
                  src={getProfilePicture(gender)}
                  alt=""
                  className="vau__img"
                />
                <div className="vau__main">
                  <p className="vau__user">
                    {names}{" "}
                    {is_verified ? <img src={verificationIcon} alt="" /> : null}
                  </p>
                  <p className="vau__username">{username}</p>
                </div>
              </div>
              <div className="vau-verif">
                <p className="vau-verif__label">User verification</p>
                <div className="vau-verif__main">
                  <span></span>
                  <button
                    className={[
                      "badge badge--btn",
                      is_verified
                        ? "badge--success"
                        : verif_status === "pending"
                        ? "badge--warning"
                        : "badge--danger",
                    ].join(" ")}
                  >
                    {is_verified
                      ? "Verified"
                      : verif_status === "pending"
                      ? "Pending Verification"
                      : "Unverified"}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label className="form-label">Card type</label>
            <div className="checkbox-group">
              <div className="check-control">
                <input
                  type="radio"
                  className="radio-1"
                  name="cardType"
                  value="mastercard"
                  onChange={handleCardTypeChange}
                  checked={virtualCardThemeType === "mastercard"}
                />
                <span>Master card</span>
              </div>
              <div className="check-control">
                <input
                  type="radio"
                  className="radio-1"
                  name="cardType"
                  value="visa"
                  onChange={handleCardTypeChange}
                  checked={virtualCardThemeType === "visa"}
                />
                <span>Visa card</span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">Card alias</label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter card alias"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
            {aliasExists ? (
              <div className="form-bottom-label text-warning">
                <IonIcon icon={alertCircle} />
                You have used <strong>'{alias}'</strong> for another card
                already
              </div>
            ) : null}
          </div>
          <div className="virtual-card__theme-block">
            <div className="virtual-card__theme-heading">Choose card theme</div>
            {activeThemes ? (
              <div className="virtual-card__themes">
                {activeThemes.map((vcardTheme) => (
                  <div
                    className={cls(
                      "virtual-card__theme",
                      virtualCardTheme?.id === vcardTheme.id && "active"
                    )}
                    key={vcardTheme.id}
                    onClick={() => setVirtualCardTheme(vcardTheme)}
                  >
                    <img src={vcardTheme.img_url} alt="" />
                    <IonIcon icon={checkbox} />
                  </div>
                ))}
              </div>
            ) : (
              <VerticalBarLoader sm />
            )}
          </div>
        </div>
        <div className="virtual-card__footer">
          <div style={{ alignSelf: "stretch" }}>{message1}</div>
          {is_verified && !enoughToBuyCard ? (
            <div className="virtual-card__message">
              <IonIcon icon={alertCircle} />
              Kindly fund your naira wallet to proceed.
            </div>
          ) : null}
          {!is_verified && verif_status === "pending" ? (
            <div className="virtual-card__message virtual-card__message--warning">
              <IonIcon icon={alertCircle} />
              Your account verification is in progress. you cannot proceeed
              while your verification is pending
            </div>
          ) : null}
          {is_verified ? (
            enoughToBuyCard ? (
              <button
                className={cls(
                  "form-button form-button--md",
                  virtualCardTheme &&
                    alias &&
                    !aliasExists &&
                    "form-button--active"
                )}
                style={{ color: "#fff", width: "30rem" }}
                onClick={proceedVirtualCard}
              >
                Proceed
              </button>
            ) : (
              <button
                className="form-button form-button--md form-button--active"
                style={{ color: "#fff", width: "30rem" }}
                onClick={() => navigate("/deposit")}
              >
                Fund naira wallet
              </button>
            )
          ) : verif_status === "pending" ? (
            <button
              className="form-button form-button--md"
              style={{ color: "#fff", width: "30rem" }}
              disabled
            >
              Proceed
            </button>
          ) : (
            <>
              <div style={{ display: "none" }} ref={verifButtonRef}>
                <MatiButton />
              </div>
              <button
                className="form-button form-button--md form-button--active"
                style={{ color: "#fff", width: "30rem" }}
                onClick={(e) => {
                  const matiButton = verifButtonRef.current?.querySelector(
                    "mati-button"
                  )! as HTMLButtonElement;

                  if (matiButton) matiButton.click();

                  const triggerBtn = e.target! as HTMLButtonElement;

                  triggerBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
                  triggerBtn.setAttribute("disabled", "disabled");

                  matiButton.addEventListener("mati:loaded", () => {
                    triggerBtn.removeAttribute("disabled");
                    triggerBtn.innerHTML = "Proceed to verification";
                  });
                }}
              >
                Proceed to verification
              </button>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(CreateVirtualCard);
