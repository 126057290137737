import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cartOutline,
  close,
  eyeOffOutline,
  eyeOutline,
  swapHorizontalOutline,
} from "ionicons/icons";

import { assertNotNull, roundDP } from "../../utils/func";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";

import { updateSetting } from "../../store/userSlice";

import NairaTransactions from "./NairaTransactions/NairaTransactions";
import SelectCurrencyModal from "../SelectCurrencyModal/SelectCurrencyModal";
import VerifyAccountModal from "../VerifyAccountModal/VerifyAccountModal";

import deposit from "../../assets/img/deposit.png";
import withdraw from "../../assets/img/withdraw.png";
import ManualUnverified from "../VerifyAccountModal/ManualUnverifiedModal";

const NairaWallet = ({ wallet }: { wallet: tWallet }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    userDetails,
    settings: { hideTotalBalance },
  } = useSelector((state: tRootState) => state.user);

  assertNotNull(userDetails);

  const {
    balance,
    total_naira_balance_in_dollar,
    is_verified,
    manual_unverified,
  } = userDetails;

  const [showSelectCurrencyModal, setShowSelectCurrencyModal] = useState(false);

  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);
  const [showManualUnverifiedModal, setShowManualUnverifiedModal] =
    useState(false);

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawType, setWithdrawType] = useState<
    "internal" | "external" | ""
  >("");

  return (
    <>
      {showWithdrawModal ? (
        <>
          <div className="withdraw-type-modal">
            <span
              className="withdraw-type-modal__close"
              onClick={() => setShowWithdrawModal(false)}
            >
              <IonIcon icon={close} />
            </span>
            <div className="withdraw-type-modal__header">
              <h3 className="withdraw-type-modal__heading">
                Withdraw {wallet.coin}
              </h3>
              <p>Kindly select a process for withdrawal</p>
            </div>
            <div className="withdraw-type-modal__options">
              <label>
                <input
                  type="radio"
                  name="type"
                  value="internal"
                  className="radio-1"
                  checked={withdrawType === "internal"}
                  onChange={(e) =>
                    setWithdrawType(e.target.checked ? "internal" : "")
                  }
                />
                <p>
                  Send to other Sekiapp users{" "}
                  <span className="badge badge--danger">FREE</span>
                </p>
              </label>
              <label>
                <input
                  type="radio"
                  name="type"
                  value="external"
                  className="radio-1"
                  checked={withdrawType === "external"}
                  onChange={(e) =>
                    setWithdrawType(e.target.checked ? "external" : "")
                  }
                />
                <p>Send to Bank account</p>
              </label>
            </div>
            <button
              className={cls(
                "form-button withdraw-type-modal__button",
                withdrawType && "form-button--active"
              )}
              onClick={() => {
                if (!withdrawType) return;

                if (withdrawType === "internal") navigate(`/transfer`);

                if (withdrawType === "external") navigate(`/withdraw`);
              }}
            >
              Proceed
            </button>
          </div>
          <div
            className="overlay"
            onClick={() => setShowWithdrawModal(false)}
          ></div>
        </>
      ) : null}
      <VerifyAccountModal
        closeHandler={() => setShowVerifyAccountModal(false)}
        show={showVerifyAccountModal}
      />
      <ManualUnverified
        closeHandler={() => setShowManualUnverifiedModal(false)}
        show={showManualUnverifiedModal}
      />
      <SelectCurrencyModal
        show={showSelectCurrencyModal}
        type="crypto"
        selectHandler={(wallet) => {
          navigate(`/buy-crypto?wallet=${wallet.symbol}`);
        }}
        closeHandler={() => setShowSelectCurrencyModal(false)}
      />
      <div className="wallet-card">
        <div className="wallet-card__main">
          <div className="wallet-card__header">
            <p>Available balances</p>
            <span
              onClick={() =>
                dispatch(
                  updateSetting({
                    key: "hideTotalBalance",
                    value: !hideTotalBalance,
                  })
                )
              }
            >
              <IonIcon icon={hideTotalBalance ? eyeOffOutline : eyeOutline} />
            </span>
          </div>
          <div className="wallet-card__amount">
            <span className="wallet-card__amount-currency">
              &#8358;{" "}
              <span className={cls(hideTotalBalance && "font-hidden")}>
                {hideTotalBalance ? "*****" : roundDP(balance, 2)}
              </span>
            </span>
            <span className="wallet-card__amount-usd">
              <span className={cls(hideTotalBalance && "font-hidden")}>
                {hideTotalBalance
                  ? "*****"
                  : roundDP(total_naira_balance_in_dollar, 2)}
              </span>{" "}
              USD
            </span>
          </div>
        </div>
        <div className="wallet-card__actions">
          <div
            className="wallet-card__action wallet-card__action--active"
            onClick={
              is_verified
                ? manual_unverified === 0
                  ? () => navigate("/deposit")
                  : () => setShowManualUnverifiedModal(true)
                : () => setShowVerifyAccountModal(true)
            }
          >
            <img src={deposit} alt="" />
            <p>Deposit</p>
          </div>
          <div
            className="wallet-card__action"
            onClick={() => setShowWithdrawModal(true)}
          >
            <img src={withdraw} alt="" />
            <p>Withdraw</p>
          </div>
        </div>
      </div>
      <div className="wallet-operations">
        <div className="wallet-operations__left">
          <ul className="wallet-operations__quick-links">
            <li>
              <Link
                to="#"
                className="wallet-operations__quick-link"
                onClick={() => setShowSelectCurrencyModal(true)}
              >
                <span className="wallet-operations__quick-link-icon-box">
                  <IonIcon icon={cartOutline} />
                </span>
                Buy Crypto
              </Link>
            </li>
            <li>
              <Link to="/pay-bills" className="wallet-operations__quick-link">
                <span className="wallet-operations__quick-link-icon-box">
                  <IonIcon icon={swapHorizontalOutline} />
                </span>
                Pay Bills
              </Link>
            </li>
          </ul>
        </div>
        <div className="wallet-operations__right">
          <button
            className="wallet-operations__btn wallet-operations__btn--deposit"
            onClick={
              is_verified
                ? manual_unverified === 0
                  ? () => navigate("/deposit")
                  : () => setShowManualUnverifiedModal(true)
                : () => setShowVerifyAccountModal(true)
            }
          >
            Deposit
          </button>
          <Link
            to="#"
            className="wallet-operations__btn wallet-operations__btn--withdraw-1"
            onClick={() => setShowWithdrawModal(true)}
          >
            Withdraw
          </Link>
          {/* <Link
            to="/transfer"
            className="wallet-operations__btn wallet-operations__btn--transfer"
          >
            Transfer
          </Link> */}
        </div>
      </div>
      <NairaTransactions />
    </>
  );
};

export default NairaWallet;
