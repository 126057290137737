import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import useData from "../../hooks/useData/useData";

import { withUnAuth } from "../../hoc/withAuth/withAuth";

import { login } from "../../store/userSlice";

import useAlert from "../../hooks/useAlert/useAlert";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

import { tUser } from "../../store/types/app.types";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fetchWarnings, fetchDashboardPopup, fetchBillServices } = useData();

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !password)
      return setMessage("warning", "Fill in username and password");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Logging in`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    let token: string;
    let user: tUser;

    api_client({
      url: `/login`,
      method: "POST",
      data: {
        email: email,
        password: password,
        type: "web",
        vsn: "2.1.0",
      },
    })
      .then((res) => {
        if (!res.data.status) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        localStorage.removeItem("login_timeout");

        token = res.data.token;

        // do in the background
        fetchBillServices(token);

        return api_client({
          url: `/get-profile`,
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        user = res.data.message;

        return fetchDashboardPopup(token);
      })
      .then(() => {
        dispatch(login({ accessToken: token, userDetails: user }));

        // Do in the background

        fetchWarnings(token);

        // Do in the background

        navigate("/dashboard");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.innerHTML = "Login";
          submitBtnRef.current.removeAttribute("disabled");
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [email, password, clearMessage]);

  return (
    <AuthLayout>
      <div className="auth">
        <div className="auth__img-block">
          <Link to="/">
            <img
              src={sekiLogoLight}
              alt=""
              className="auth__img light-inline-block"
            />
            <img
              src={sekiLogoDark}
              alt=""
              className="auth__img dark-inline-block"
            />
          </Link>
        </div>
        <h2 className="auth__heading">Login to your account</h2>
        <p className="auth__heading-sub-text">
          New on SekiApp?
          <Link to="/register"> Sign up </Link>
          here
        </p>
        <form className="auth__form-main" onSubmit={loginHandler}>
          <div className="form-group">
            <label className="form-label">Username / Email</label>
            <input
              type="text"
              name="email"
              placeholder="Enter Email or Username"
              className="form-input"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sh) => !sh)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                email && password ? "form-button--active" : ""
              )}
              type="submit"
              ref={submitBtnRef}
            >
              Login
            </button>
          </div>
          <p className="auth__footer-link">
            <Link to="/forgot-password">Forgot Password</Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  );
};

export default withUnAuth(Login);
