import { useEffect, useState, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { tRootState } from "../../store";
import { updateLastActivity } from "../../store/userSlice";

import api_client from "../../api/client";

import useData from "../../hooks/useData/useData";
import useProfileCheck from "../../hooks/useProfileCheck/useProfileCheck";

import Preloader from "../../components/Preloader/Preloader";

import { sleep } from "../../utils/func";
// import { getRegistrationStep } from "../../utils/user";

const CheckInactivity = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLastActivity());
  }, [dispatch]);

  return <>{children}</>;
};

// const oldWithAuth =
//   <P extends object>(Component: React.ComponentType) =>
//   (props: P) => {
//     const location = useLocation().pathname;

//     const { loggedIn, accessToken, userDetails, lastActivity } = useSelector(
//       (state: tRootState) => state.user
//     );

//     const [reloadProfile, setReloadProfile] = useState(true);

//     const { fetchProfile } = useData();

//     // Reload Profile
//     useEffect(() => {
//       if (
//         !accessToken ||
//         userDetails?.status === 0 ||
//         localStorage.getItem("login_timeout")
//       )
//         return;

//       fetchProfile().finally(() => {
//         new Promise((resolve, reject) => {
//           if (!lastActivity || Date.now() >= lastActivity + 900000) {
//             api_client({
//               method: "POST",
//               url: "/logout",
//               headers: {
//                 Authorization: `Bearer ${accessToken}`,
//               },
//             })
//               .catch((err) => {
//                 // do nothing
//               })
//               .finally(() => {
//                 resolve("Checked Inactivity");
//               });
//           } else {
//             resolve("Checked Inactivity");
//           }
//         }).then(() => {
//           sleep(5000).then(() => {
//             setReloadProfile((rp) => !rp);
//           });
//         });
//       });
//     }, [
//       reloadProfile,
//       accessToken,
//       userDetails?.status,
//       lastActivity,
//       fetchProfile,
//     ]);

//     // Not Logged In
//     if (!loggedIn || !accessToken || !userDetails)
//       return <Navigate to="/login" />;

//     let blocked = false;
//     let loginTimeout = false;

//     // Blocked

//     // => Blocked but not in blocked page
//     if (
//       (userDetails.status === 0 || userDetails.is_blocked) &&
//       !["/blocked", "/blocked/"].includes(location)
//     )
//       return <Navigate to="/blocked" />;

//     // => Not blocked but in blocked page
//     if (
//       userDetails.status !== 0 &&
//       !userDetails.is_blocked &&
//       ["/blocked", "/blocked/"].includes(location)
//     )
//       return <Navigate to="/dashboard" />;

//     // => Flag: Blocked and in blocked page
//     if (
//       (userDetails.status === 0 || userDetails.is_blocked) &&
//       ["/blocked", "/blocked/"].includes(location)
//     )
//       blocked = true;

//     // Login Timeout

//     // => Login Timeout but not in sign in page
//     if (
//       localStorage.getItem("login_timeout") &&
//       !["/sign-in", "/sign-in/"].includes(location)
//     )
//       return <Navigate to="/sign-in" />;

//     // => Not Login Timeout but in sign in page
//     if (
//       !localStorage.getItem("login_timeout") &&
//       ["/sign-in", "/sign-in/"].includes(location)
//     )
//       return <Navigate to="/dashboard" />;

//     // => Flag : Login Timeout and in sign in page
//     if (
//       localStorage.getItem("login_timeout") &&
//       ["/sign-in", "/sign-in/"].includes(location)
//     )
//       loginTimeout = true;

//     // Old User
//     if (
//       userDetails.status === 2 &&
//       !userDetails.username &&
//       !["/old-user-data", "/old-user-data/"].includes(location)
//     )
//       return <Navigate to="/old-user-data" />;

//     // Unfinished Registration
//     const [step, path] = getRegistrationStep(userDetails);
//     if (
//       !blocked &&
//       !loginTimeout &&
//       userDetails.status === 1 &&
//       ![path, path + "/"].includes(location)
//     ) {
//       if (
//         step === 4 &&
//         ["/register/telephone", "/register/telephone/"].includes(location) &&
//         userDetails.centri > 0
//       ) {
//         // do nothing
//       } else {
//         return <Navigate to={path} />;
//       }
//     }

//     // For transaction pin
//     if (
//       !blocked &&
//       !loginTimeout &&
//       userDetails.status === 2 &&
//       ["/register/transaction-pin", "/register/transaction-pin/"].includes(
//         location
//       )
//     )
//       return <Navigate to="/register/referral-code" />;

//     return (
//       <CheckInactivity>
//         <Component {...props} />
//       </CheckInactivity>
//     );
//   };

const withAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const location = useLocation().pathname;

    const { loggedIn, accessToken, userDetails, lastActivity } = useSelector(
      (state: tRootState) => state.user
    );

    const profileChecking = useProfileCheck();

    const [reloadProfile, setReloadProfile] = useState(true);

    const { fetchProfile } = useData();

    // Reload Profile
    useEffect(() => {
      if (
        !accessToken ||
        userDetails?.status === 0 ||
        localStorage.getItem("login_timeout")
      )
        return;

      fetchProfile().finally(() => {
        new Promise((resolve, reject) => {
          if (!lastActivity || Date.now() >= lastActivity + 900000) {
            api_client({
              method: "POST",
              url: "/logout",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
              .then(() => {
                localStorage.removeItem("appState");
                window.location.assign("/login");
              })
              .catch((err) => {
                // do nothing
              })
              .finally(() => {
                resolve("Checked Inactivity");
              });
          } else {
            resolve("Checked Inactivity");
          }
        }).then(() => {
          sleep(5000).then(() => {
            setReloadProfile((rp) => !rp);
          });
        });
      });
    }, [
      reloadProfile,
      accessToken,
      userDetails?.status,
      lastActivity,
      fetchProfile,
    ]);

    // Not Logged In
    if (!loggedIn || !accessToken || !userDetails)
      return <Navigate to="/login" />;

    if (profileChecking) return <Preloader />;

    let blocked = false;
    let loginTimeout = false;

    // Blocked

    // => Blocked but not in blocked page
    if (
      (userDetails.status === 0 || userDetails.is_blocked) &&
      !["/blocked", "/blocked/"].includes(location)
    )
      return <Navigate to="/blocked" />;

    // => Not blocked but in blocked page
    if (
      userDetails.status !== 0 &&
      !userDetails.is_blocked &&
      ["/blocked", "/blocked/"].includes(location)
    )
      return <Navigate to="/dashboard" />;

    // => Flag: Blocked and in blocked page
    if (
      (userDetails.status === 0 || userDetails.is_blocked) &&
      ["/blocked", "/blocked/"].includes(location)
    )
      blocked = true;

    // Login Timeout

    // => Login Timeout but not in sign in page
    if (
      localStorage.getItem("login_timeout") &&
      !["/sign-in", "/sign-in/"].includes(location)
    )
      return <Navigate to="/sign-in" />;

    // => Not Login Timeout but in sign in page
    if (
      !localStorage.getItem("login_timeout") &&
      ["/sign-in", "/sign-in/"].includes(location)
    )
      return <Navigate to="/dashboard" />;

    // => Flag : Login Timeout and in sign in page
    if (
      localStorage.getItem("login_timeout") &&
      ["/sign-in", "/sign-in/"].includes(location)
    )
      loginTimeout = true;

    // Old User
    if (
      userDetails.status === 2 &&
      !userDetails.username &&
      !["/old-user-data", "/old-user-data/"].includes(location)
    )
      return <Navigate to="/old-user-data" />;

    // Unfinished Registration
    if (
      !blocked &&
      !loginTimeout &&
      userDetails.status === 1 &&
      !["/register/referral-code", "/register/referral-code/"].includes(
        location
      )
    )
      return <Navigate to="/register/referral-code" />;

    if (
      !blocked &&
      !loginTimeout &&
      userDetails.status === 2 &&
      ["/register/referral-code", "/register/referral-code/"].includes(location)
    )
      return <Navigate to="/register/verify" />;

    return (
      <CheckInactivity>
        <Component {...props} />
      </CheckInactivity>
    );
  };

export default withAuth;

export const withUnAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const { loggedIn, accessToken, userDetails } = useSelector(
      (state: tRootState) => state.user
    );

    if (loggedIn && accessToken && userDetails)
      return <Navigate to="/dashboard" />;

    return <Component {...props} />;
  };
