import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { tRootState } from "../../../store";

import { assertNotNull } from "../../../utils/func";

const toRadians = (val: number) => val * (Math.PI / 180);

const SwapTimer = ({ setElapsed }: { setElapsed: () => void }) => {
  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  const canvasBlock = useRef<HTMLCanvasElement>({} as HTMLCanvasElement);

  const swapTime = useRef(userDetails.swap_refresh * 1000);

  const time = useRef(Date.now() + swapTime.current);

  useEffect(() => {
    const ctx = canvasBlock.current!.getContext("2d")!;

    const canvasWidth = canvasBlock.current.offsetWidth;
    const canvasHeight = canvasBlock.current.offsetHeight;

    const canvasHalfWidth = canvasWidth / 2;
    const canvasHalfHeight = canvasHeight / 2;

    const timerRadius = 40;

    const update = () => {
      const timeElapsed = Date.now();

      // CLEAR RECTANGLE
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);

      // TEXT STYLES
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = "32px Karla";

      // TIMER

      // PATH
      ctx.beginPath();

      ctx.fillStyle = getComputedStyle(ctx.canvas).getPropertyValue(
        "--account-bg-green"
      );

      ctx.arc(
        canvasHalfWidth,
        canvasHalfHeight,
        timerRadius,
        toRadians(-90),
        Math.PI * 2
      );

      ctx.fill();

      // PROGRESS
      ctx.beginPath();

      ctx.fillStyle = getComputedStyle(ctx.canvas).getPropertyValue(
        "--header-bg"
      );

      ctx.arc(
        canvasHalfWidth,
        canvasHalfHeight,
        timerRadius + 5,
        toRadians(-90),
        ((swapTime.current - (time.current - timeElapsed)) / swapTime.current) *
          toRadians(360) -
          toRadians(90)
      );
      ctx.lineTo(canvasHalfWidth, canvasHalfHeight);
      ctx.closePath();

      ctx.fill();

      // TEXT
      ctx.fillStyle = getComputedStyle(ctx.canvas).getPropertyValue(
        "--text-color"
      );
      ctx.fillText(
        Math.ceil((time.current - timeElapsed) / 1000).toString(),
        canvasHalfWidth,
        canvasHalfHeight
      );

      // RELOAD ANIMATION
      if (timeElapsed < time.current) {
        requestAnimationFrame(update);
      } else {
        setElapsed();
      }
    };

    requestAnimationFrame(update);
  }, [setElapsed]);

  return (
    <div className="text-center">
      <canvas id="canvas" width="80" height="80" ref={canvasBlock}></canvas>
    </div>
  );
};

export default SwapTimer;
