import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";
import { QRCodeSVG } from "qrcode.react";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  caretDown,
  caretUp,
  cartOutline,
  chevronDownOutline,
  close,
  eyeOffOutline,
  eyeOutline,
  swapHorizontalOutline,
} from "ionicons/icons";

import useData from "../../hooks/useData/useData";
import useCopy from "../../hooks/useCopy/useCopy";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import { tWallet } from "../../store/types/app.types";
import { updateSetting } from "../../store/userSlice";
import { selectNetwork } from "../../store/cacheSlice";

// import CryptoChart from "./CryptoChart/CryptoChart";
import WalletBulk from "../WalletBulk/WalletBulk";

import CryptoTransaction from "./CryptoTransactions/CryptoTransactions";
import SelectNetworkModal from "./SelectNetworkModal/SelectNetworkModal";
import DepositCryptoModal, {
  DepositCryptoNoticeModal,
} from "./DepositCryptoModal/DepositCryptoModal";
import Spinner from "../../loaders/Spinner/Spinner";

import { roundDP } from "../../utils/func";
import { getPrecision } from "../../utils/app";

import deposit from "../../assets/img/deposit.png";
import withdraw from "../../assets/img/withdraw.png";

const CryptoWallet = ({ wallet }: { wallet: tWallet }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const msg = useSelector((state: tRootState) => state.cache.warnings.memo);
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const hideWalletBalance = useSelector(
    (state: tRootState) => state.user.settings.hideWalletBalance
  );
  const networkId = useSelector(
    (state: tRootState) => state.cache.wallet.networkId
  );

  const { fetchWallets } = useData();

  const network = wallet?.networks.find((network) => network.id === networkId);

  const [showNetworksModal, setShowNetworksModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);

  const [triggerDepositWallet, setTriggerDepositWallet] = useState(false);

  const [creatingAddress, setCreatingAddress] = useState(false);

  const [copiedAddress, copyAddress] = useCopy(network?.address || "");
  const [copiedMemo, copyMemo] = useCopy(network?.memo || "");

  const [showNotice, setShowNotice] = useState(false);

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawType, setWithdrawType] = useState<
    "internal" | "external" | ""
  >("");

  const triggerDeposit = () => {
    if (network)
      return network.address
        ? setShowDepositModal(true)
        : depositWallet(wallet.id, wallet.symbol, network.id);

    setTriggerDepositWallet(true);
    setShowNetworksModal(true);
  };

  const depositWallet = (
    walletId: number | string, // would always be number cause it's crypto wallet
    walletSymbol: string,
    networkId: number // should not be null - UPDATE: all crypto has network*
  ) => {
    setCreatingAddress(true);

    api_client({
      url: `/receive/${walletId}/coin/${walletSymbol}${
        networkId ? `/${networkId}` : ""
      }`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error(res.data.message);

        return fetchWallets();
      })
      .then((res) => {
        setShowDepositModal(true);
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setCreatingAddress(false);
      });
  };

  return (
    <>
      {showWithdrawModal ? (
        <div className="">
          <div className="withdraw-type-modal">
            <span
              className="withdraw-type-modal__close"
              onClick={() => setShowWithdrawModal(false)}
            >
              <IonIcon icon={close} />
            </span>
            <div className="withdraw-type-modal__header">
              <h3 className="withdraw-type-modal__heading">
                Withdraw {wallet.coin}
              </h3>
              <p>Kindly select a process for withdrawal</p>
            </div>
            <div className="withdraw-type-modal__options">
              <label>
                <input
                  type="radio"
                  name="type"
                  value="internal"
                  className="radio-1"
                  checked={withdrawType === "internal"}
                  onChange={(e) =>
                    setWithdrawType(e.target.checked ? "internal" : "")
                  }
                />
                <p>
                  Send to other Sekiapp users{" "}
                  <span className="badge badge--danger">FREE</span>
                </p>
              </label>
              {(!network && [2, 1].includes(wallet.capability)) ||
              (network && [2, 1].includes(network.capability)) ? (
                <label>
                  <input
                    type="radio"
                    name="type"
                    value="external"
                    className="radio-1"
                    checked={withdrawType === "external"}
                    onChange={(e) =>
                      setWithdrawType(e.target.checked ? "external" : "")
                    }
                  />
                  <p>Send to external wallets</p>
                </label>
              ) : null}
            </div>
            <button
              className={cls(
                "form-button withdraw-type-modal__button",
                withdrawType && "form-button--active"
              )}
              onClick={() => {
                if (!withdrawType) return;

                if (withdrawType === "internal")
                  navigate(`/transfer-crypto?wallet=${wallet.symbol}`);

                if (withdrawType === "external")
                  navigate(
                    `/withdraw-crypto?wallet=${wallet.symbol}${
                      network ? `&network=${network.id}` : ""
                    }`
                  );
              }}
            >
              Proceed
            </button>
          </div>
          <div
            className="overlay"
            onClick={() => setShowWithdrawModal(false)}
          ></div>
        </div>
      ) : null}
      {creatingAddress ? <Spinner /> : null}
      <DepositCryptoModal
        wallet={wallet}
        network={network || null}
        closeHandler={() => setShowDepositModal(false)}
        show={showDepositModal}
      />
      <SelectNetworkModal
        wallet={wallet}
        type="deposit"
        selectedNetwork={network?.id || null}
        show={showNetworksModal}
        closeHandler={() => {
          setShowNetworksModal(false);
          setTriggerDepositWallet(false);
        }}
        handler={(network) => {
          dispatch(selectNetwork(network.id));
          setShowNetworksModal(false);

          if (triggerDepositWallet) {
            network.address
              ? setShowDepositModal(true)
              : depositWallet(wallet.id, wallet.symbol, network.id);
            setTriggerDepositWallet(false);
          }
        }}
      />

      {showNotice && network ? (
        <DepositCryptoNoticeModal
          wallet={wallet}
          network={network}
          closeHandler={() => setShowNotice(false)}
        />
      ) : null}

      <div className="wallet-card">
        <div className="wallet-card__main">
          <div className="wallet-card__header">
            <p>Available balances</p>
            <span
              onClick={() =>
                dispatch(
                  updateSetting({
                    key: "hideWalletBalance",
                    value: !hideWalletBalance,
                  })
                )
              }
            >
              <IonIcon icon={hideWalletBalance ? eyeOffOutline : eyeOutline} />
            </span>
          </div>
          <div className="wallet-card__amount">
            <span className="wallet-card__amount-currency">
              {wallet.symbol.toUpperCase() || "USD"}{" "}
              <span className={cls(hideWalletBalance && "font-hidden")}>
                {!hideWalletBalance
                  ? roundDP(wallet.balance, getPrecision(wallet.symbol))
                  : "*****"}
              </span>
            </span>
            <span className="wallet-card__amount-usd">
              <span className={cls(hideWalletBalance && "font-hidden")}>
                {!hideWalletBalance
                  ? roundDP(wallet.dollar_balance, 2)
                  : "****"}{" "}
              </span>
              USD{" "}
            </span>
          </div>
          <div className="wallet-card__rate-block">
            <p className="wallet-card__rate">
              1 {wallet.symbol.toUpperCase()} = $
              {roundDP(
                wallet.current_price,
                +wallet.current_price >= 1 ? 2 : 6
              )}
            </p>
            <div
              className={`market__change market__change--${
                +wallet.price_change > 0 ? "success" : "danger"
              }`}
            >
              <IonIcon icon={+wallet.price_change > 0 ? caretUp : caretDown} />
              {Number(wallet.price_change).toFixed(2)}%
            </div>
          </div>
        </div>
        <div className="wallet-card__actions">
          {(!network && [2, 0].includes(wallet.capability)) ||
          (network && [2, 0].includes(network.capability)) ? (
            <div
              onClick={triggerDeposit}
              className="wallet-card__action wallet-card__action--active"
            >
              <img src={deposit} alt="" />
              <p>Deposit</p>
            </div>
          ) : null}
          <div
            className="wallet-card__action"
            onClick={() => setShowWithdrawModal(true)}
          >
            <img src={withdraw} alt="" />
            <p>Withdraw</p>
          </div>
        </div>
      </div>
      {network ? (
        <div className="wallet__select-network">
          <div className="wallet__select-network-main">
            <p className="wallet__select-network-heading">Select Network:</p>
            <div
              className="select-box select-box--md"
              onClick={() => setShowNetworksModal(true)}
            >
              <img src={wallet.image} alt="" />
              <p>
                {wallet.coin} - {network.name}
              </p>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
        </div>
      ) : null}
      <div className="wallet-operations">
        <div className="wallet-operations__left">
          <ul className="wallet-operations__quick-links">
            <li>
              <Link
                to={`/sell-crypto?wallet=${wallet.symbol}`}
                className="wallet-operations__quick-link"
              >
                <span className="wallet-operations__quick-link-icon-box">
                  <IonIcon icon={cartOutline} />
                </span>
                Sell
              </Link>
            </li>
            <li>
              <Link
                to={`/buy-crypto?wallet=${wallet.symbol}`}
                className="wallet-operations__quick-link"
              >
                <span className="wallet-operations__quick-link-icon-box">
                  <IonIcon icon={cartOutline} />
                </span>
                Buy
              </Link>
            </li>
            <li>
              <Link
                to={`/swap-crypto?wallet=${wallet.symbol}`}
                className="wallet-operations__quick-link"
              >
                <span className="wallet-operations__quick-link-icon-box">
                  <IonIcon icon={swapHorizontalOutline} />
                </span>
                Swap
              </Link>
            </li>
          </ul>
        </div>
        <div className="wallet-operations__right">
          {(!network && [2, 0].includes(wallet.capability)) ||
          (network && [2, 0].includes(network.capability)) ? (
            <button
              onClick={triggerDeposit}
              className="wallet-operations__btn wallet-operations__btn--deposit"
            >
              Deposit
            </button>
          ) : null}
          <Link
            to="#"
            className="wallet-operations__btn wallet-operations__btn--withdraw-1"
            onClick={() => setShowWithdrawModal(true)}
          >
            Withdraw
          </Link>
          {/* <Link
            to={`/transfer-crypto?wallet=${wallet.symbol}`}
            className="wallet-operations__btn wallet-operations__btn--transfer"
          >
            Transfer
          </Link> */}
        </div>
      </div>
      <WalletBulk wallet />
      {/* <CryptoChart wallet={wallet} /> */}
      {network && network.address ? (
        <div className="wallet-deposit">
          <h3 className="wallet-deposit__heading">
            Deposit {wallet.coin} - {network.name} wallet from other wallet
            services or exchanges:
          </h3>
          <p className="wallet-deposit__desc">
            To fund your {wallet.coin} - {network.name} wallet from other wallet
            services or exchanges:
          </p>
          <div className="wallet-deposit__recieve-steps">
            <div>
              Copy and paste your {wallet.coin} - {network.name} wallet from
              other wallet services or exchanges: address shown below or scan
              the QR code.
            </div>
            {network.memo && msg ? (
              <div className="bold text-danger">{msg}</div>
            ) : null}
            <div className="wallet-deposit__qr-container">
              <div className="minimum-deposit">
                <IonIcon icon={alertCircle} />
                Minimum Deposit -{" "}
                <strong>
                  {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()}
                </strong>
              </div>
            </div>
            <div className="wallet-deposit__qr-container">
              <QRCodeSVG
                value={network.address}
                className="wallet-deposit__qr-img"
              />
            </div>
            <div className="wallet-deposit__qr-link">
              <div>{network.address}</div>
              <button onClick={() => copyAddress(() => setShowNotice(true))}>
                {copiedAddress ? "Copied!" : "Copy Address"}
              </button>
            </div>
            {network.memo ? (
              <div className="wallet-deposit__qr-link">
                <div>{network.memo}</div>
                <button onClick={() => copyMemo(() => setShowNotice(true))}>
                  {copiedMemo
                    ? "Copied!"
                    : wallet.symbol.toLowerCase() === "xrp"
                    ? "Copy Destination Tag"
                    : "Copy Memo"}
                </button>
              </div>
            ) : null}
            <div className="wallet-deposit__qr-container">
              <div className="minimum-deposit">
                <div className="minimum-deposit">
                  If you deposit any amount below{" "}
                  {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()}, you risk losing your asset
                  forever.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CryptoTransaction wallet={wallet} />
    </>
  );
};

export default CryptoWallet;
