import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBack, chevronDownOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tService } from "../../store/types/app.types";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";
import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";
import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import ContactVerification from "../../components/ContactVerification/ContactVerification";

const sortData = {
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  last_month: "Last Month",
  custom: "Custom",
};

const AccountStatement = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const statement = searchParams.get("statement");

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const uiMode = useSelector((state: tRootState) => state.app.uiMode);
  const { services, servicesSelectData } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchServices } = useData();

  const [serviceSelectBox, serviceId, openServiceSelectVox, , setServiceId] =
    useSelectBox<string>("Select Service", servicesSelectData, null);
  const [service, setService] = useState<tService | null>(null);

  const [sortSelectBox, sort, openSortSelectBox] = useSelectBox<
    keyof typeof sortData
  >("Select Duration", getSelectBoxData(sortData), null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!service || !sort || (sort === "custom" && (!startDate || !endDate)))
      return setMessage("warning", "Fill in all fields");

    const operation = () => {
      submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
      submitBtnRef.current.setAttribute("disabled", "disabled");

      api_client({
        url: "/account-statement/send-statement",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          service: service.id,
          duration: sort,
          start: startDate,
          end: endDate,
        },
      })
        .then((res) => {
          if (!res.data.success) {
            setMessage("warning", res.data.message);
            throw new Error("");
          }

          setMessage("success", res.data.message);
        })
        .catch((err) => {
          if (err.code === "ERR_BAD_REQUEST") {
            setMessage("warning", err.response.data.message);
          } else if (err.message) {
            setMessage("error", err.message);
          }
        })
        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Submit";
          }
        });
    };

    if (!userDetails?.email) {
      setContactVerificationCallback(() => () => {
        operation();
      });
    } else {
      operation();
    }
  };

  useEffect(() => {
    if (!serviceId) return;

    setService(services.find((serv) => serviceId === serv.id.toString())!);
  }, [serviceId, services]);

  useEffect(() => {
    clearMessage();
  }, [service, sort, startDate, endDate, clearMessage]);

  useEffect(() => {
    if (!statement) return;

    setServiceId(statement);

    setSearchParams(
      (sp) => {
        sp.delete("statement");

        return sp;
      },
      { replace: true }
    );
  }, [statement, setSearchParams, setServiceId]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <DashboardLayout>
      {serviceSelectBox}
      {sortSelectBox}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
        description="You need to add your email address because your account statement would be sent to your email"
      />
      <div className="bulk-otp">
        <div className="auth auth--md" style={{ margin: "auto" }}>
          <h3 className="naira-block__heading">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Account Statement
          </h3>
          <p className="auth__heading-sub-text">
            Kindly select the service and duration of interest
          </p>
          <form className="auth__form-main" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label">Service</label>
              <div
                className="select-box select-box--md"
                onClick={() => openServiceSelectVox()}
              >
                <p>{service ? service.name : "Select Service"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Duration</label>
              <div
                className="select-box select-box--md"
                onClick={() => openSortSelectBox()}
              >
                <p>{sort ? sortData[sort!] : "Select Duration"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
              {sort === "custom" ? (
                <span className="form-label text-blue">
                  <IonIcon icon={alertCircle} className="active" />
                  You cannot generate more than a year statement
                </span>
              ) : null}
            </div>
            {sort === "custom" ? (
              <div className="form-grid">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    type="date"
                    className="form-input"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    style={uiMode === "dark" ? { colorScheme: "dark" } : {}}
                  />
                </div>
                <div className="form-group">
                  <label>End Date</label>
                  <input
                    type="date"
                    className="form-input"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    style={uiMode === "dark" ? { colorScheme: "dark" } : {}}
                  />
                </div>
              </div>
            ) : null}
            {userDetails?.email ? (
              <div
                className="giftcard-block__indemnity text-center"
                style={{
                  backgroundColor: "var(--pay-bills-right-info-bg)",
                  color: "var(--text-color)",
                }}
              >
                The account statement will be sent to your Email
                <br />
                <span className="text-success">“{userDetails?.email}”</span>
              </div>
            ) : null}
            <div className="auth__footer">
              {message}
              <button
                className={cls(
                  "form-button",
                  service &&
                    sort &&
                    (sort !== "custom" ||
                      (sort === "custom" && startDate && endDate)) &&
                    "form-button--active"
                )}
                ref={submitBtnRef}
                style={{ width: "80%", alignSelf: "center" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(AccountStatement);
