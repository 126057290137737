import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import {
  tServiceProvider,
  tServicePackage,
  tBillServices,
  tBeneficiaries,
  tServiceProviders,
  tServicePackages,
  tBillService,
} from "./types/app.types";

type numType = number | "";

type tPayBillsMini = {
  serviceProvider: tServiceProvider | null;
  newBeneficiary: boolean;
  alias: string;
};

export type tBillData = {
  service: tBillService;
  serviceProvider: tServiceProvider;
  servicePackage?: tServicePackage;
  recipient?: string;
  recipientValidation?: string;
  amount?: number;
  numberOfPins?: number;
  pinValue?: number;
  newBeneficiary: boolean;
  alias?: string;
  success: boolean;
};

type PayBillsType = {
  services: tBillServices | null;
  availableServices: { [key: string]: boolean };
  beneficiaries: {
    [key: string]: tBeneficiaries;
  };
  serviceProviders: {
    [key: string]: tServiceProviders;
  };
  servicePackages: {
    [key: string]: tServicePackages;
  };
  billData: tBillData | null;

  airtime: tPayBillsMini & {
    phoneNumber: numType;
    amount: numType;
  };
  data: tPayBillsMini & {
    servicePackage: tServicePackage | null;
    phoneNumber: numType;
  };
  cable: tPayBillsMini & {
    servicePackage: tServicePackage | null;
    recipient: numType;
    verification: string;
  };
  electricity: tPayBillsMini & {
    recipient: numType;
    amount: numType;
    verification: string;
  };
};

const PayBillsInitialState: PayBillsType = {
  services: null,
  availableServices: {},
  beneficiaries: {},
  serviceProviders: {},
  servicePackages: {},
  billData: null,

  airtime: {
    serviceProvider: null,
    phoneNumber: "",
    amount: "",
    newBeneficiary: false,
    alias: "",
  },
  data: {
    serviceProvider: null,
    servicePackage: null,
    phoneNumber: "",
    newBeneficiary: false,
    alias: "",
  },
  cable: {
    serviceProvider: null,
    servicePackage: null,
    recipient: "",
    verification: "",
    newBeneficiary: false,
    alias: "",
  },
  electricity: {
    serviceProvider: null,
    recipient: "",
    amount: "",
    verification: "",
    newBeneficiary: false,
    alias: "",
  },
};

const paybillsSlice = createSlice({
  name: "paybills",
  initialState: getState<PayBillsType>("paybills", PayBillsInitialState),
  reducers: {
    updateBillServices(state, { payload }: PayloadAction<tBillServices>) {
      const availableServices: { [key: string]: boolean } = {};

      payload.forEach((service) => {
        availableServices[service.service_type] = true;
      });

      state.services = payload;
      state.availableServices = availableServices;

      saveState("paybills", current(state));
    },
    updateBillsBeneficiaries(
      state,
      { payload }: PayloadAction<{ key: string; beneficiaries: tBeneficiaries }>
    ) {
      state.beneficiaries[payload.key] = payload.beneficiaries;

      saveState("paybills", current(state));
    },
    updateServiceProviders(
      state,
      { payload }: PayloadAction<{ key: string; providers: tServiceProviders }>
    ) {
      state.serviceProviders[payload.key] = payload.providers;

      saveState("paybills", current(state));
    },
    updateServicePackages(
      state,
      { payload }: PayloadAction<{ key: string; packages: tServicePackages }>
    ) {
      state.servicePackages[payload.key] = payload.packages;

      saveState("paybills", current(state));
    },
    updateBillData(state, { payload }: PayloadAction<tBillData>) {
      state.billData = payload;

      saveState("paybills", current(state));
    },
    updateBillSuccessful(state) {
      if (!state.billData) return;

      state.billData.success = true;

      saveState("paybills", current(state));
    },
    removeBillData(state) {
      state.billData = null;

      saveState("paybills", current(state));
    },

    updateAirtime(state, { payload }: PayloadAction<PayBillsType["airtime"]>) {
      state.airtime = payload;

      saveState("paybills", current(state));
    },
    updateData(state, { payload }: PayloadAction<PayBillsType["data"]>) {
      state.data = payload;

      saveState("paybills", current(state));
    },
    updateCable(state, { payload }: PayloadAction<PayBillsType["cable"]>) {
      state.cable = payload;

      saveState("paybills", current(state));
    },
    updateElectricity(
      state,
      { payload }: PayloadAction<PayBillsType["electricity"]>
    ) {
      state.electricity = payload;

      saveState("paybills", current(state));
    },
    clearBillData(state, { payload }: PayloadAction<keyof PayBillsType>) {
      state[payload] = PayBillsInitialState[payload] as any; // Never use any

      saveState("paybills", current(state));
    },
  },
});

export const {
  updateBillServices,
  updateBillsBeneficiaries,
  updateServiceProviders,
  updateServicePackages,
  updateBillData,
  updateBillSuccessful,
  removeBillData,

  updateAirtime,
  updateData,
  updateCable,
  updateElectricity,
  clearBillData,
} = paybillsSlice.actions;

export default paybillsSlice.reducer;
