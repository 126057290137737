import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  arrowUp,
  caretDownOutline,
  eyeOffOutline,
  eyeOutline,
  removeOutline,
  searchOutline,
  swapHorizontalOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";

import { assertNotNull, roundDP } from "../../utils/func";

import {
  tAssetAction,
  tAssetSubAction,
  tAssetTab,
  updateAction,
  updateSubAction,
  updateTab,
  updateTriggerPage,
} from "../../store/assetSlice";
import { selectWallet } from "../../store/cacheSlice";
import { updateSetting } from "../../store/userSlice";
import { updateGiftCard } from "../../store/giftcardSlice";
import { updateGiftCard as updateBuyGiftCard } from "../../store/buyGiftcardSlice";

import useData from "../../hooks/useData/useData";

import DashboardWalletCurrenciesLoader from "../../loaders/DashboardWalletCurrenciesLoader/DashboardWalletCurrenciesLoader";
import GiftCardsLoader from "../../loaders/GiftCardsLoader/GiftCardsLoader";
import WalletBulk from "../WalletBulk/WalletBulk";

import VerifyAccountModal from "../VerifyAccountModal/VerifyAccountModal";
import SelectWithdrawalType, {
  tSelectWithdrawalType,
} from "../SelectWithdrawalType/SelectWithdrawalType";
import ManualUnverified from "../VerifyAccountModal/ManualUnverifiedModal";

import walletBgDark from "../../assets/img/wallet-bg-dark.svg";
import walletBgLight from "../../assets/img/wallet-bg-light.svg";
// import walletBgMobileDark from "../../assets/img/wallet-mobile-bg-dark.png";
// import walletBgMobileLight from "../../assets/img/wallet-mobile-bg-light.png";

import sellImg from "../../assets/img/sell.png";
import buyImg from "../../assets/img/buy.png";
import swapImg from "../../assets/img/swap.png";

import depositIcon from "../../assets/img/deposit-icon.png";
import withdrawIcon from "../../assets/img/withdraw-icon.png";
import tradeIcon from "../../assets/img/trade-icon.png";
import { getPrecision } from "../../utils/app";

const DashboardWallet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().pathname;

  const onPageCheck = ["/dashboard-wallet", "/dashboard-wallet/"].includes(
    location
  );

  const { giftCards, buyGiftCards, wallets } = useSelector(
    (state: tRootState) => state.cache
  );
  const {
    settings: { hideTotalBalance, balanceWallet },
    userDetails,
  } = useSelector((state: tRootState) => state.user);
  const {
    action: cachedAction,
    subAction: cachedSubAction,
    tab: cachedTab,
    triggerPage,
  } = useSelector((state: tRootState) => state.asset);

  const action = onPageCheck ? cachedAction : null;
  const subAction = onPageCheck ? cachedSubAction : null;
  const tab = onPageCheck ? cachedTab : "all";

  const { fetchWallets, fetchGiftcards, fetchBuyGiftcards } = useData();

  assertNotNull(userDetails);

  const {
    total_balance_in_dollar,
    total_balance_in_naira,
    total_skp,
    is_verified,
    manual_unverified,
  } = userDetails;

  const [searchCurrencies, setSearchCurrencies] = useState("");
  const [hideZeroBalances, setHideZeroBalances] = useState(false);
  const [searchGiftCards, setSearchGiftCards] = useState("");

  const [withdrawalAction, setWithdrawalAction] =
    useState<tSelectWithdrawalType | null>(null);

  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);
  const [showManualUnverifiedModal, setShowManualUnverifiedModal] =
    useState(false);

  const [showWalletBalanceOptions, setShowWalletBalanceOptions] =
    useState(false);

  const setBallanceCur = (cur: "USD" | "NGN") => {
    if (balanceWallet !== cur) {
      dispatch(
        updateSetting({
          key: "balanceWallet",
          value: cur,
        })
      );
    }

    setShowWalletBalanceOptions(false);
  };

  const setAction = (action: tAssetAction) => {
    dispatch(updateAction(action));
    if (!onPageCheck) {
      dispatch(updateTriggerPage(location));
      navigate("/dashboard-wallet");
    }
  };

  const setSubAction = (subAction: tAssetSubAction) => {
    dispatch(updateSubAction(subAction));
  };

  const setTab = (tab: tAssetTab) => {
    dispatch(updateTab(tab));
  };

  const performAction = (wallet: tWallet) => {
    if (action === "deposit") {
      if (wallet.type === "fiat") {
        if (is_verified !== 1) {
          setShowVerifyAccountModal(true);
        } else if (is_verified === 1) {
          if (manual_unverified === 1) {
            setShowManualUnverifiedModal(true);
          } else {
            navigate("/deposit");
          }
        }
      } else if (wallet.type === "crypto") {
        dispatch(selectWallet(wallet.id));
        navigate(`/deposit-crypto?wallet=${wallet.symbol}`);
      }
    } else if (action === "withdraw") {
      setWithdrawalAction({
        type: wallet.type,
        internalAction: () =>
          navigate(
            wallet.type === "fiat"
              ? "/transfer"
              : `/transfer-crypto?wallet=${wallet.symbol}`
          ),
        externalAction: () =>
          navigate(
            wallet.type === "fiat"
              ? "/withdraw"
              : `/withdraw-crypto?wallet=${wallet.symbol}`
          ),
        closeHandler: () => setWithdrawalAction(null),
      });
    } else if (action === "trade") {
      if (subAction === "sell") {
        if (tab === "crypto") {
          dispatch(selectWallet(wallet.id));
          navigate(`/sell-crypto?wallet=${wallet.symbol}`);
        }
      } else if (subAction === "buy") {
        if (tab === "crypto") {
          dispatch(selectWallet(wallet.id));
          navigate(`/buy-crypto?wallet=${wallet.symbol}`);
        }
      }
    } else if (action === "transfer") {
      if (wallet.type === "fiat") {
        navigate(`/transfer`);
      } else if (wallet.type === "crypto") {
        navigate(`/transfer-crypto?wallet=${wallet.id}`);
      }
    }
  };

  useEffect(() => {
    if (wallets || !action || !tab || !["all", "fiat", "crypto"].includes(tab))
      return;

    fetchWallets();
  }, [wallets, action, tab, fetchWallets]);

  useEffect(() => {
    if (
      giftCards ||
      !action ||
      !subAction ||
      subAction !== "sell" ||
      !tab ||
      tab !== "giftcards"
    )
      return;

    fetchGiftcards();
  }, [giftCards, action, subAction, tab, fetchGiftcards]);

  useEffect(() => {
    if (
      buyGiftCards ||
      !action ||
      !subAction ||
      subAction !== "buy" ||
      !tab ||
      tab !== "giftcards"
    )
      return;

    fetchBuyGiftcards();
  }, [buyGiftCards, action, subAction, tab, fetchBuyGiftcards]);

  useEffect(() => {
    if (onPageCheck && !action && triggerPage) navigate(triggerPage);
  }, [action, triggerPage, navigate, onPageCheck]);

  return (
    <>
      {withdrawalAction ? <SelectWithdrawalType {...withdrawalAction} /> : null}
      <VerifyAccountModal
        closeHandler={() => setShowVerifyAccountModal(false)}
        show={showVerifyAccountModal}
      />
      <ManualUnverified
        closeHandler={() => setShowManualUnverifiedModal(false)}
        show={showManualUnverifiedModal}
      />
      <div>
        <section className="wallet-box">
          <img
            src={walletBgDark}
            alt=""
            className="wallet-box__img dark-block"
          />
          <img
            src={walletBgLight}
            alt=""
            className="wallet-box__img light-block"
          />
          {/* <img
            src={walletBgMobileDark}
            alt=""
            className="wallet-box__img wallet-box__img--mobile dark-block"
          />
          <img
            src={walletBgMobileLight}
            alt=""
            className="wallet-box__img wallet-box__img--mobile light-block"
          /> */}
          <div className="wallet-box__main">
            <p className="wallet-box__header">
              Overview
              <span
                className="wallet-box__show-hide"
                onClick={() =>
                  dispatch(
                    updateSetting({
                      key: "hideTotalBalance",
                      value: !hideTotalBalance,
                    })
                  )
                }
              >
                <IonIcon icon={hideTotalBalance ? eyeOffOutline : eyeOutline} />
              </span>
              <span
                className="wallet-box__pts"
                onClick={() => navigate("/convert-skp")}
              >
                <span className={cls(hideTotalBalance && "font-hidden")}>
                  {!hideTotalBalance ? roundDP(total_skp, 2) : "*****"}
                </span>{" "}
                SKP
              </span>
            </p>
            <div className="wallet-box__balance-block">
              <div className="wallet-box__balance-toggle-wrapper">
                <span
                  className="wallet-box__balance-toggle"
                  onClick={() => setShowWalletBalanceOptions((sh) => !sh)}
                >
                  {balanceWallet} <IonIcon icon={caretDownOutline} />
                </span>
                <div
                  className={cls(
                    "wallet-box__balance-options",
                    showWalletBalanceOptions &&
                      "wallet-box__balance-options--open"
                  )}
                >
                  <div
                    className={cls(
                      "wallet-box__balance-option",
                      balanceWallet === "USD" && "active"
                    )}
                    onClick={() => setBallanceCur("USD")}
                  >
                    USD
                  </div>
                  <div
                    className={cls(
                      "wallet-box__balance-option",
                      balanceWallet === "NGN" && "active"
                    )}
                    onClick={() => setBallanceCur("NGN")}
                  >
                    NGN
                  </div>
                </div>
              </div>
              <p className="wallet-box__balance">
                {balanceWallet === "NGN" ? <>&#8358;</> : "$"}
                <span className={cls(hideTotalBalance && "font-hidden")}>
                  {!hideTotalBalance
                    ? roundDP(
                        balanceWallet === "NGN"
                          ? total_balance_in_naira
                          : total_balance_in_dollar,
                        2
                      )
                    : "*****"}
                </span>
              </p>
            </div>
            <span
              className="wallet-box__points"
              onClick={() => navigate("/convert-skp")}
            >
              <span className={cls(hideTotalBalance && "font-hidden")}>
                {!hideTotalBalance ? roundDP(total_skp, 2) : "*****"}
              </span>{" "}
              SKP
            </span>
          </div>
          <div className="wallet-box__actions">
            <div
              className={cls(
                "wallet-box-action",
                action === "deposit" && "wallet-box-action--active"
              )}
              onClick={() => {
                setAction(action === "deposit" ? null : "deposit");
                setTab("all");
              }}
            >
              <div className="wallet-box-action__icon-box">
                <IonIcon
                  icon={addOutline}
                  className="wallet-box-action__icon"
                />
              </div>
              <p>Deposit</p>
            </div>
            <div
              className={cls(
                "wallet-box-action",
                action === "trade" && "wallet-box-action--active"
              )}
              onClick={() => {
                setAction(action === "trade" ? null : "trade");
                setSubAction(null);
                setTab(null);
              }}
            >
              <div className="wallet-box-action__icon-box">
                <IonIcon
                  icon={swapHorizontalOutline}
                  className="wallet-box-action__icon"
                />
              </div>
              <p>Trade</p>
            </div>
            <div
              className={cls(
                "wallet-box-action",
                ["withdraw", "transfer"].includes(action!) &&
                  "wallet-box-action--active"
              )}
              onClick={() => {
                setAction(action === "withdraw" ? null : "withdraw");
                setTab("all");
              }}
            >
              <div className="wallet-box-action__icon-box">
                <IonIcon
                  icon={removeOutline}
                  className="wallet-box-action__icon"
                />
              </div>
              <p>Withdraw</p>
            </div>
          </div>
        </section>
        <div className="wallet-actions-mobile">
          <div className="wallet-actions-mobile__main">
            <div
              className={cls(
                "wallet-action-mobile",
                action === "deposit" && "wallet-action-mobile--active"
              )}
              onClick={() => {
                setAction(action === "deposit" ? null : "deposit");
                setTab("all");
              }}
            >
              <div className="wallet-action-mobile__icon-box">
                <img
                  src={depositIcon}
                  alt=""
                  className="wallet-action-mobile__icon"
                />
              </div>
              <p>Deposit</p>
            </div>
            <div className="wallet-actions-mobile__divider"></div>
            <div
              className={cls(
                "wallet-action-mobile",
                action === "trade" && "wallet-action-mobile--active"
              )}
              onClick={() => {
                setAction(action === "trade" ? null : "trade");
                setSubAction(null);
                setTab(null);
              }}
            >
              <div className="wallet-action-mobile__icon-box">
                <img
                  src={tradeIcon}
                  alt=""
                  className="wallet-action-mobile__icon"
                />
              </div>
              <p>Trade</p>
            </div>
            <div className="wallet-actions-mobile__divider"></div>
            <div
              className={cls(
                "wallet-action-mobile",
                ["withdraw", "transfer"].includes(action!) &&
                  "wallet-action-mobile--active"
              )}
              onClick={() => {
                setAction(action === "withdraw" ? null : "withdraw");
                setTab("all");
              }}
            >
              <div className="wallet-action-mobile__icon-box">
                <img
                  src={withdrawIcon}
                  alt=""
                  className="wallet-action-mobile__icon"
                />
              </div>
              <p>Withdraw</p>
            </div>
          </div>
        </div>
      </div>
      {onPageCheck && action ? (
        <>
          <WalletBulk />

          {action === "trade" ? (
            <div className="tab-actions">
              <div
                className={cls(
                  "tab-actions__action",
                  subAction === "sell" && "tab-actions__action--active"
                )}
                onClick={() => {
                  setSubAction("sell");
                  if (!tab) setTab("crypto");
                }}
              >
                <img alt="" className="tab-actions__action-img" src={sellImg} />
                <p>Sell</p>
              </div>
              <div
                className={cls(
                  "tab-actions__action",
                  subAction === "buy" && "tab-actions__action--active"
                )}
                onClick={() => {
                  setSubAction("buy");
                  if (!tab) setTab("crypto");
                }}
              >
                <img alt="" className="tab-actions__action-img" src={buyImg} />
                <p>Buy</p>
              </div>
              <div
                className="tab-actions__action"
                onClick={() => navigate("/swap-crypto")}
              >
                <img alt="" className="tab-actions__action-img" src={swapImg} />
                <p>Swap</p>
              </div>
            </div>
          ) : null}

          {action === "transfer" ? (
            <div className="wallet-header">
              <h3 className="wallet-header__heading">Transfer</h3>
              <p className="wallet-header__text">
                Transfer fiat or crypto internally to another SekiApp user for{" "}
                <span className="text-success">FREE</span>
              </p>
            </div>
          ) : null}

          {["deposit", "withdraw", "transfer"].includes(action) ? (
            <div className="tab-buttons">
              <button
                className={cls(tab === "all" && "active")}
                onClick={() => setTab("all")}
              >
                All
              </button>
              <button
                className={cls(tab === "fiat" && "active")}
                onClick={() => setTab("fiat")}
              >
                Fiat
              </button>
              <button
                className={cls(tab === "crypto" && "active")}
                onClick={() => setTab("crypto")}
              >
                Crypto
              </button>
            </div>
          ) : null}

          {action === "trade" && subAction ? (
            <div className="tab-buttons">
              <button
                className={cls(tab === "crypto" && "active")}
                onClick={() => setTab("crypto")}
              >
                Crypto
              </button>
              <button
                className={cls(tab === "giftcards" && "active")}
                onClick={() => setTab("giftcards")}
              >
                GiftCards
              </button>
            </div>
          ) : null}

          {tab && ["all", "fiat", "crypto"].includes(tab) ? (
            <div className="assets-block">
              <div className="assets-block__header">
                <form className="search-form assets-block__search-form">
                  <div className="search-form__icon-block">
                    <IonIcon icon={searchOutline} />
                  </div>
                  <input
                    type="text"
                    placeholder="Search Coin"
                    value={searchCurrencies}
                    onChange={(e) => setSearchCurrencies(e.target.value)}
                  />
                </form>
                <div className="assets-block__hide">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={hideZeroBalances}
                    onChange={(e) => setHideZeroBalances(e.target.checked)}
                  />{" "}
                  Hide 0 Balances
                </div>
              </div>
              <div className="assets-block__assets">
                {wallets ? (
                  wallets
                    .filter((wallet) => {
                      if (
                        !(
                          new RegExp(searchCurrencies, "i").test(wallet.coin) ||
                          new RegExp(searchCurrencies, "i").test(wallet.symbol)
                        )
                      )
                        return false;

                      if (
                        tab &&
                        ["fiat", "crypto"].includes(tab) &&
                        wallet.type !== tab
                      )
                        return false;

                      if (hideZeroBalances && !Number(wallet.balance))
                        return false;

                      if (
                        action === "deposit" &&
                        ![2, 0].includes(wallet.capability)
                      )
                        return false;

                      if (
                        action === "withdraw" &&
                        ![2, 1].includes(wallet.capability)
                      )
                        return false;

                      return true;
                    })
                    .map((wallet) => {
                      return (
                        <Fragment key={wallet.id}>
                          <div
                            className="asset"
                            onClick={() => performAction(wallet)}
                          >
                            <div className="asset__main">
                              <div className="asset__currency">
                                <img
                                  src={wallet.image}
                                  alt=""
                                  className="asset__currency-img"
                                />
                                <span className="asset__currency-name">
                                  {wallet.coin}{" "}
                                </span>
                                <span>|</span>
                                <span>{wallet.symbol.toUpperCase()}</span>
                              </div>
                              <p className="asset__value">
                                {wallet.symbol.toUpperCase()}{" "}
                                {roundDP(
                                  wallet.balance,
                                  getPrecision(wallet.symbol)
                                )}
                              </p>
                              <p className="asset__value1">
                                ~ USD {roundDP(wallet.dollar_balance, 2)}
                              </p>
                              <p className="asset__value-change text-success">
                                <IonIcon icon={arrowUp} /> +2.888
                              </p>
                            </div>
                          </div>
                          <div
                            className="asset-mobile"
                            onClick={() => performAction(wallet)}
                          >
                            <img
                              src={wallet.image}
                              alt=""
                              className="asset-mobile__currency-img"
                            />
                            <div className="asset-mobile__wallet">
                              <p className="asset-mobile__wallet-symbol">
                                {wallet.symbol.toUpperCase()}
                              </p>
                              <p className="asset-mobile__wallet-name">
                                {wallet.coin}
                              </p>
                            </div>
                            <div className="asset-mobile__wallet-right">
                              <p className="asset-mobile__balance">
                                {wallet.symbol.toUpperCase()}{" "}
                                {roundDP(
                                  wallet.balance,
                                  getPrecision(wallet.symbol)
                                )}
                              </p>
                              <p className="asset-mobile__wallet-name">
                                ~ USD {roundDP(wallet.dollar_balance, 2)}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                ) : (
                  <DashboardWalletCurrenciesLoader />
                )}
              </div>
            </div>
          ) : null}

          {tab === "giftcards" ? (
            <div className="wallet-giftcards">
              <div className="giftcards-pg__header mb-medium">
                <div className="giftcards-pg__header-main">
                  <h3 className="giftcards-pg__heading">
                    Select Gift Card Type
                  </h3>
                  <div className="step-radio-btns">
                    <input
                      type="radio"
                      className="radio"
                      name="step"
                      value="1"
                      checked
                      readOnly
                    />
                    <input
                      type="radio"
                      className="radio"
                      name="step"
                      value="2"
                      disabled
                    />
                    <input
                      type="radio"
                      className="radio"
                      name="step"
                      value="3"
                      disabled
                    />
                    <input
                      type="radio"
                      className="radio"
                      name="step"
                      value="4"
                      disabled
                    />
                  </div>
                </div>
                <div className="giftcards-pg__header-right">
                  <form className="search-form">
                    <div className="search-form__icon-block">
                      <IonIcon icon={searchOutline} />
                    </div>
                    <input
                      type="text"
                      placeholder="Search Giftcard"
                      value={searchGiftCards}
                      onChange={(e) => setSearchGiftCards(e.target.value)}
                    />
                  </form>
                </div>
              </div>
              {subAction === "sell" ? (
                <div className="wallet-giftcards__grid mb-large">
                  {giftCards ? (
                    giftCards
                      .filter((giftCard) =>
                        new RegExp(searchGiftCards, "i").test(giftCard.name)
                      )
                      .map((giftCard) => (
                        <div
                          className="giftcard"
                          key={giftCard.id}
                          onClick={() => {
                            dispatch(updateGiftCard(giftCard));
                            navigate("/gift-cards/country");
                          }}
                        >
                          <img src={giftCard.service_img} alt="" />
                          <p className="giftcard__name">{giftCard.name}</p>
                        </div>
                      ))
                  ) : (
                    <GiftCardsLoader />
                  )}
                </div>
              ) : (
                <div className="wallet-giftcards__grid mb-large">
                  {buyGiftCards ? (
                    buyGiftCards
                      .filter((giftCard) =>
                        new RegExp(searchGiftCards, "i").test(
                          giftCard.productName
                        )
                      )
                      .map((giftCard) => (
                        <div
                          className="giftcard"
                          key={giftCard.productName}
                          onClick={() => {
                            dispatch(updateBuyGiftCard(giftCard));
                            navigate("/buy-gift-cards/country");
                          }}
                        >
                          <img src={giftCard.imageUrl} alt="" />
                          <p className="giftcard__name">
                            {giftCard.productName}
                          </p>
                        </div>
                      ))
                  ) : (
                    <GiftCardsLoader />
                  )}
                </div>
              )}
            </div>
          ) : null}

          {/* {subAction === "buy" && tab === "giftcards" ? (
            <div className="wallet-header">
              <h3 className="wallet-header__heading">Coming Soon...</h3>
              <p className="wallet-header__text">
                Soon Enough you can buy your favorite Gift cards from SekiApp
                Hang in there King
              </p>
            </div>
          ) : null} */}
        </>
      ) : null}
    </>
  );
};

export default DashboardWallet;
