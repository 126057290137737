import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBack } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { clearCryptoData } from "../../store/cryptoSlice";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";

import { assertNotNull, roundDP } from "../../utils/func";

import nairaIcon from "../../assets/img/naira.png";
import SetPin from "../../components/SetPin/SetPin";
import withAuth from "../../hoc/withAuth/withAuth";
import { getPrecision } from "../../utils/app";

const BuyCryptoFinal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const { wallet, dollarAmount, nairaAmount, cryptoAmount } = useSelector(
    (state: tRootState) => state.crypto.buy
  );

  assertNotNull(userDetails);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [pin, setPin] = useState("");

  const [message, setMessage, clearMessage] = useAlert(true);

  const { fetchProfile } = useData();

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (!wallet)
      // Should never get here
      return;

    if (pin.length !== 4)
      return setMessage("warning", "Enter your transaction PIN");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/wallet/convert-naira-to-coin",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        coin: wallet.symbol,
        amount: dollarAmount,
        pin: pin,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        setMessage(
          "success",
          "Crypto purchase successful. You would be redirected shortly..."
        );

        return fetchProfile();
      })
      .then((res) => {
        navigate("/wallet");
        const interval: number = window.setInterval(() => {
          dispatch(clearCryptoData("buy"));
          window.clearInterval(interval);
        }, 3000);
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = `Buy Now`;
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [pin, clearMessage]);

  if (!wallet || !dollarAmount || !nairaAmount || !cryptoAmount)
    return <Navigate to="/buy-crypto" />;

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <div className="crypto-final">
        <div className="crypto-final__header">
          <div className="crypto-final__back" onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </div>
          <p className="crypto-final__label">I want to buy</p>
          <div className="crypto-final__currency">
            <img src={wallet.image} alt="" />
            <p>
              ${dollarAmount} (
              {roundDP(cryptoAmount, getPrecision(wallet.symbol))}{" "}
              <span>{wallet.symbol.toUpperCase()}</span>)
            </p>
          </div>
          <p className="crypto-final__label2">For</p>
          <div className="crypto-final__currency">
            <img src={nairaIcon} alt="" />
            <p>
              {roundDP(nairaAmount, 2)} <span>NGN</span>
            </p>
          </div>
        </div>
        <form className="crypto-final__body" onSubmit={handleSubmit}>
          {userDetails.is_pin_set ? (
            <div className="crypto-final__pin-section">
              <h3 className="crypto-final__heading">Enter PIN</h3>
              <div className="register__pin-input-group-2">
                <PinGroup numInputs={4} pin={pin} handler={setPin} />
              </div>
              <span
                className="crypto-final__forgot-pin"
                onClick={() => navigate("/change-pin")}
              >
                Forgot PIN
              </span>
            </div>
          ) : null}
          <div
            className={cls("auth__footer", !userDetails.is_pin_set && "mt-0")}
          >
            {message}
            <button
              className={cls(
                "form-button",
                (pin.length === 4 || !userDetails.is_pin_set) &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Buy Now
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(BuyCryptoFinal);
