import { useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  checkmarkOutline,
  close as closeIcon,
  searchOutline,
} from "ionicons/icons";

import { tRootState } from "../../../store";
import { tNetwork, tWallet } from "../../../store/types/app.types";

type tSelectNetworkModal = {
  wallet: tWallet;
  type: "deposit" | "withdraw";
  selectedNetwork: number | null;
  message?: string;
  handler: (network: tNetwork) => void;
  closeHandler: () => void;
  show: boolean;
};

const SelectNetworkModal = ({
  wallet,
  type,
  selectedNetwork,
  message,
  handler,
  closeHandler,
  show,
}: tSelectNetworkModal) => {
  const msg = useSelector((state: tRootState) => state.cache.warnings.popup);

  const networks = wallet.networks.filter((ntw) =>
    [2, type === "deposit" ? 0 : 1].includes(ntw.capability)
  );

  const [search, setSearch] = useState("");

  const close = () => {
    setSearch("");
    closeHandler();
  };

  if (!show) return null;

  return (
    <>
      <div className="currency-modal">
        <div className="currency-modal__header">
          <span className="currency-modal__close" onClick={close}>
            <IonIcon icon={closeIcon} />
          </span>
          <h3 className="currency-modal__heading">Select Network</h3>
        </div>
        <form className="search-form">
          <div className="search-form__icon-block">
            <IonIcon icon={searchOutline} />
          </div>
          <input
            type="text"
            placeholder="Search Network"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        {(msg || message) && networks.length ? (
          <p className="currency-modal__info">{message || msg}</p>
        ) : null}
        <div className="currency-modal__list">
          {networks
            .filter((network) => new RegExp(search, "i").test(network.name))
            .map((network) => (
              <div
                className={cls(
                  "currency1",
                  network.id === selectedNetwork && "currency1--active"
                )}
                key={network.id}
                onClick={() => handler(network)}
              >
                <div className="currency1__img-block">
                  <img src={wallet.image} alt="" />
                </div>
                <div className="currency1__divider"></div>
                <div className="currency1__main">
                  <p className="currency1__abbr">{wallet.symbol}</p>
                  <p className="currency1__name">{network.name}</p>
                </div>
                <span className="currency1__check">
                  <IonIcon icon={checkmarkOutline} />
                </span>
              </div>
            ))}
          {!networks.length ? (
            <p className="text-center">
              {wallet.coin} is not available for{" "}
              {type === "deposit" ? "deposit" : "withdrawal"} at the moment.
              Please check back soon.
            </p>
          ) : null}
        </div>
      </div>
      <div className="overlay" onClick={close}></div>
    </>
  );
};

export default SelectNetworkModal;
