import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  arrowBack,
  checkmark,
  chevronDownOutline,
  close,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";
import { updateTransferCrypto } from "../../store/cryptoSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import SetPin from "../../components/SetPin/SetPin";
import SetAuth from "../../components/SetAuth/SetAuth";
import PinGroup from "../../components/PinGroup/PinGroup";
import RingLoader from "../../loaders/RingLoader/RingLoader";
import Preloader from "../../components/Preloader/Preloader";
import TransactionLimit from "../../components/TransactionLimit/TransactionLimit";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import BeneficiariesModal from "../../components/BeneficiariesModal/BeneficiariesModal";
import SelectCurrencyModal from "../../components/SelectCurrencyModal/SelectCurrencyModal";
import ContactVerification from "../../components/ContactVerification/ContactVerification";

import { roundDP, isNumber, assertNotNull } from "../../utils/func";
import { getPrecision } from "../../utils/app";

type tRateInfo = {
  amount_in_coin: number;
  amount_in_dollar: "string";
  charge_in_coin: number;
  charge_in_dollar: number;
  total_in_coin: number;
  total_in_dollar: number;
};

const TransferCrypto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const walletSymbol = useSearchParams()[0].get("wallet")?.toLowerCase();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const { wallets, cryptoTransferBeneficiaries } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(userDetails);

  const { fetchWallets, fetchBeneficiaries } = useData();

  const [wallet, setWallet] = useState<undefined | tWallet>(
    wallets?.find((sWallet) => sWallet.symbol.toLowerCase() === walletSymbol)
  );

  const [message, setMessage, clearMessage] = useAlert(true);

  const [showSelectWalletModal, setShowSelectWalletModal] = useState(false);

  const [username, setUsername] = useState("");

  const [usernameExists, setUsernameExists] = useState<boolean | null>(null);

  const [verifyingUsername, setVerifyingUsername] = useState(false);
  const [errorVerifyingUsername, setErrorVerifyingUsername] = useState(false);

  const uverifyInterval = useRef<number | null>(null);

  const [amount, setAmount] = useState<number | string>("");

  const [fetchingRates, setFetchingRates] = useState(false);
  const [errorFetchingRates, setErrorFetchingRates] = useState(false);

  const frInterval = useRef<number | null>(null);

  const [rateInfo, setRateInfo] = useState<tRateInfo | null>(null);

  const [description, setDescription] = useState("");

  const [newBeneficiary, setNewBeneficiary] = useState(false);

  const [alias, setAlias] = useState("");

  const [pin, setPin] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [showBeneficiariesModal, setShowBeneficiariesModal] = useState(false);

  const [authMethodCallback, setAuthMethodCallback] = useState<
    (() => void) | null
  >(null);
  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!wallet) return setMessage("warning", "Select wallet");

    if (!username) return setMessage("warning", "Enter receipient username");
    if (verifyingUsername) return;
    if (!usernameExists)
      return setMessage("warning", "Invalid username entered");

    if (!amount) return setMessage("warning", "Enter amount");

    if (newBeneficiary && !alias)
      return setMessage(
        "warning",
        "Alias is required to add a new beneficiary"
      );
    if (newBeneficiary && alias.length > 10)
      return setMessage(
        "warning",
        "Alias should not be more than 10 characters long"
      );

    if (fetchingRates) return;
    if (!rateInfo) return setMessage("warning", "Error fetching rate");

    if (+amount > +wallet.dollar_balance)
      return setMessage(
        "warning",
        `You don't have sufficient balance to transfer $${amount}`
      );

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Enter transaction PIN");

    setContactVerificationCallback(
      () => () =>
        setAuthMethodCallback(() => () => {
          submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
          submitBtnRef.current.setAttribute("disabled", "disabled");

          api_client({
            method: "POST",
            url: "/internal-crypto-transfer",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            data: {
              username: username,
              amount: amount,
              coin: wallet.symbol,
              pin: pin,
              beneficiary: newBeneficiary,
              alias,
              description,
            },
          })
            .then((res) => {
              if (!res.data.success) {
                setMessage("warning", res.data.message);
                throw new Error("");
              }

              dispatch(
                updateTransferCrypto({
                  transaction: res.data.transfer,
                  message: res.data.message,
                  wallet,
                  dollarAmount: +amount,
                  username,
                  description,
                })
              );

              navigate("/transfer-crypto-final");
            })
            .catch((err) => {
              if (err.message) setMessage("error", err.message);
            })
            .finally(() => {
              if (submitBtnRef.current) {
                submitBtnRef.current.removeAttribute("disabled");
                submitBtnRef.current.innerHTML = "Continue";
              }
            });
        })
    );
  };

  useEffect(() => {
    if (uverifyInterval.current) window.clearInterval(uverifyInterval.current);

    if (!username) {
      setUsernameExists(null);
      setVerifyingUsername(false);
      setErrorVerifyingUsername(false);
      return;
    }

    setVerifyingUsername(true);
    setErrorVerifyingUsername(false);
    setUsernameExists(null);

    uverifyInterval.current = window.setInterval(() => {
      api_client({
        url: `/check-username/${username}`,
      })
        .then((res) => {
          setUsernameExists(res.data.success);
        })
        .catch((err) => {
          setErrorVerifyingUsername(true);
        })
        .finally(() => {
          setVerifyingUsername(false);
          if (uverifyInterval.current)
            window.clearInterval(uverifyInterval.current);
        });
    }, 3000);
  }, [username]);

  useEffect(() => {
    if (frInterval.current) window.clearInterval(frInterval.current);

    if (!wallet?.id || !amount) {
      setFetchingRates(false);
      setErrorFetchingRates(false);
      setRateInfo(null);
      return;
    }

    setFetchingRates(true);
    setErrorFetchingRates(false);
    setRateInfo(null);

    frInterval.current = window.setInterval(() => {
      api_client({
        url: `/wallet/rate/send?wallet_id=${wallet.id}&amount=${amount}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const rtInfo: tRateInfo = res.data;

          if (
            typeof rtInfo !== "object" ||
            !rtInfo.amount_in_coin ||
            Number.isNaN(+rtInfo.amount_in_coin)
          ) {
            setErrorFetchingRates(true);
          } else {
            setRateInfo(res.data);
          }
        })
        .catch(() => {
          setErrorFetchingRates(true);
        })
        .finally(() => {
          setFetchingRates(false);
          if (frInterval.current) window.clearInterval(frInterval.current);
        });
    }, 3000);
  }, [amount, wallet?.id, accessToken]);

  useEffect(() => {
    clearMessage();
  }, [
    wallet,
    username,
    amount,
    description,
    newBeneficiary,
    alias,
    pin,
    clearMessage,
  ]);

  useEffect(() => {
    if (wallets && walletSymbol && !wallet)
      setWallet(
        wallets?.find(
          (sWallet) => sWallet.symbol.toLowerCase() === walletSymbol
        )
      );
  }, [wallets, walletSymbol, wallet]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  useEffect(() => {
    if (wallets) fetchBeneficiaries("crypto-transfer");
  }, [wallets, fetchBeneficiaries]);

  if (!wallets) return <Preloader />;

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />
      <SetAuth
        callback={authMethodCallback}
        closeHandler={() => setAuthMethodCallback(null)}
      />
      <SelectCurrencyModal
        show={showSelectWalletModal}
        type="crypto"
        selectHandler={(selWallet) => {
          setWallet(selWallet);
          setShowSelectWalletModal(false);
        }}
        closeHandler={() => setShowSelectWalletModal(false)}
        selectedWallet={wallet?.symbol}
      />
      <BeneficiariesModal
        show={showBeneficiariesModal}
        crypto_transfer
        beneficiaries={cryptoTransferBeneficiaries}
        isActive={(ben) =>
          ben.main.toLowerCase() === wallet?.symbol.toLowerCase() &&
          username === ben.recipient
        }
        handler={(ben) => {
          setWallet(ben.walletRef);
          setUsername(ben.recipient);

          setShowBeneficiariesModal(false);
        }}
        closeHandler={() => setShowBeneficiariesModal(false)}
      />
      <form className="crypto-block" onSubmit={handleSubmit}>
        <div className="crypto-block__header crypto-block__header--1">
          <div className="crypto-block__back">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} /> Back
            </span>
          </div>
          <h3 className="crypto-block__heading">Transfer Crypto</h3>
          <p className="crypto-block__sub-heading text-center">
            Transfer to any SekiApp user for{" "}
            <span className="text-success">FREE</span> with their unique
            username
          </p>
        </div>
        <div className="crypto-block__body crypto-block__body--1">
          <div className="crypto-block__body-main mt-0">
            {cryptoTransferBeneficiaries &&
            cryptoTransferBeneficiaries.length ? (
              <div className="beneficiaries">
                <div className="beneficiaries__header">
                  <h3 className="beneficiaries__heading">Beneficiaries</h3>
                  <span
                    className="transactions-section__link"
                    onClick={() => setShowBeneficiariesModal(true)}
                  >
                    View All
                  </span>
                </div>
                <div className="beneficiaries__beneficiaries">
                  {cryptoTransferBeneficiaries.map((beneficiary) => {
                    if (!beneficiary.walletRef) return null;

                    return (
                      <div
                        className={cls(
                          "beneficiary",
                          beneficiary.main.toLowerCase() ===
                            wallet?.symbol.toLowerCase() &&
                            username === beneficiary.recipient &&
                            "beneficiary--active"
                        )}
                        onClick={() => {
                          setWallet(beneficiary.walletRef);
                          setUsername(beneficiary.recipient);
                        }}
                        key={beneficiary.id}
                      >
                        <img
                          src={beneficiary.walletRef.image}
                          alt=""
                          className="beneficiary__name-logo"
                        />
                        <p className="beneficiary__name" title={`${alias}`}>
                          {beneficiary.recipient_alias.slice(0, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="form-group">
              {wallet ? (
                <label>
                  BALANCE:{" "}
                  {roundDP(wallet.balance, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()} ($
                  {Math.floor(wallet.dollar_balance)})
                </label>
              ) : null}
              <div
                className="select-box select-box--md"
                onClick={() => setShowSelectWalletModal(true)}
              >
                {wallet ? <img src={wallet.image} alt="" /> : null}
                <p>{wallet ? wallet.coin : "Select wallet"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            <div className="form-group">
              <div className="form-input-group">
                <input
                  type="text"
                  placeholder="Enter recipient username"
                  name="username"
                  autoComplete="off"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <button type="button">
                  {verifyingUsername ? <RingLoader /> : null}
                  {typeof usernameExists === "boolean" && usernameExists ? (
                    <IonIcon icon={checkmark} className="text-success" />
                  ) : null}
                  {typeof usernameExists === "boolean" && !usernameExists ? (
                    <IonIcon icon={close} className="text-warning" />
                  ) : null}
                </button>
              </div>
              {typeof usernameExists === "boolean" && !usernameExists ? (
                <span className="form-bottom-label text-warning">
                  <IonIcon icon={alertCircle} className="active" />
                  Username does not exist
                </span>
              ) : null}
              {errorVerifyingUsername ? (
                <span className="form-bottom-label text-danger">
                  <IonIcon icon={alertCircle} className="active" />
                  Error verifying username
                </span>
              ) : null}
            </div>
            <div className="form-group form-group--md">
              {wallet ? <p>MAX: ${roundDP(wallet.dollar_balance, 2)}</p> : null}
              <div className="input-group">
                <button type="button">$</button>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setAmount(e.target.value)
                        : null
                      : setAmount("")
                  }
                />
              </div>
              {rateInfo ? (
                <div className="input-group">
                  <button type="button">{wallet?.symbol.toUpperCase()}</button>
                  <input
                    type="text"
                    value={rateInfo.amount_in_coin}
                    disabled
                    className="form-disabled"
                  />
                </div>
              ) : null}
              {wallet ? (
                <AmountPercentage
                  totalAmount={wallet.dollar_balance}
                  handler={setAmount}
                />
              ) : null}
            </div>
            {fetchingRates ? <VerticalBarLoader sm /> : null}
            {errorFetchingRates ? (
              <div className="text-center text-danger">
                Error fetching rates
              </div>
            ) : null}
            {rateInfo && wallet ? (
              <div className="crypto-block__rate-info">
                <p>
                  YOU ARE TRANSFERRING{" "}
                  {roundDP(
                    rateInfo.amount_in_coin,
                    getPrecision(wallet.symbol)
                  )}{" "}
                  {wallet.symbol.toUpperCase()}
                </p>
              </div>
            ) : null}
            <textarea
              rows={3}
              className="form-input"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            {!cryptoTransferBeneficiaries?.some(
              (beneficiary) =>
                beneficiary.main.toLowerCase() ===
                  wallet?.symbol.toLowerCase() &&
                username === beneficiary.recipient
            ) ? (
              <div className="accept">
                <input
                  type="checkbox"
                  className="accept__checkbox"
                  checked={newBeneficiary}
                  onChange={(e) => setNewBeneficiary(e.target.checked)}
                />
                <p>Save as beneficiary</p>
              </div>
            ) : null}
            {newBeneficiary ? (
              <div className="form-group">
                <label>Add Nickname</label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="e.g Johnny"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  maxLength={10}
                />
              </div>
            ) : null}
            <TransactionLimit type="internal_wallets" />
          </div>
        </div>
        {userDetails.is_pin_set ? (
          <div className="crypto-block__pin crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span
              className="crypto-final__forgot-pin"
              onClick={() => navigate("/change-pin")}
            >
              Forgot PIN
            </span>
          </div>
        ) : null}
        <div className="crypto-block__footer">
          {message}
          <button
            className={cls(
              "form-button",
              wallet &&
                username &&
                usernameExists &&
                amount &&
                rateInfo &&
                (!newBeneficiary ||
                  (newBeneficiary && alias && alias.length <= 10)) &&
                (!userDetails.is_pin_set || pin.length === 4) &&
                "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Continue
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(TransferCrypto);
