import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  arrowBack,
  chevronDownOutline,
  swapVerticalOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tWallet } from "../../store/types/app.types";
import { updateSwapCrypto } from "../../store/cryptoSlice";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import SelectCurrencyModal from "../../components/SelectCurrencyModal/SelectCurrencyModal";

import { roundDP, isNumber } from "../../utils/func";
import { getPrecision } from "../../utils/app";

const SwapCrypto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const walletSymbol = useSearchParams()[0].get("wallet")?.toLowerCase();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const wallets = useSelector((state: tRootState) => state.cache.wallets);

  const { fetchWallets } = useData();

  const [walletFrom, setWalletFrom] = useState<undefined | tWallet>(
    wallets?.find((sWallet) => sWallet.symbol.toLowerCase() === walletSymbol)
  );
  const [walletTo, setWalletTo] = useState<undefined | tWallet>(undefined);

  const [message, setMessage, clearMessage] = useAlert(true);

  const [showSelectWalletFromModal, setShowSelectWalletFromModal] =
    useState(false);
  const [showSelectWalletToModal, setShowSelectWalletToModal] = useState(false);

  const [amount, setAmount] = useState<number | string>("");

  const [fetchingRates, setFetchingRates] = useState(false);
  const [, setErrorFetchingRates] = useState(false);

  const frInterval = useRef<number | null>(null);

  const [rateInfo, setRateInfo] = useState<{
    amoun_in_coin: number;
    amount: string;
    amount_to_get: number;
    amount_to_get_in_coin: number;
    charge: number;
    charge_in_coin: number;
  } | null>(null);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!walletFrom || !walletTo)
      return setMessage("warning", "Select both wallets");

    if (!amount) return setMessage("warning", "The amount field is required");

    if (fetchingRates) return;

    if (!rateInfo) return setMessage("warning", "Error fetching rate");

    if (walletFrom.dollar_balance < +amount)
      return setMessage("warning", "Insfficient balance");

    dispatch(
      updateSwapCrypto({
        from: walletFrom,
        to: walletTo,
        flow: 1,
        dollarAmount: +amount,
        rateInfo,
      })
    );

    navigate("/swap-crypto-final");
  };

  useEffect(() => {
    clearMessage();
  }, [walletFrom, walletTo, amount, clearMessage]);

  useEffect(() => {
    if (frInterval.current) window.clearInterval(frInterval.current);

    if (!walletFrom?.symbol || !walletTo?.symbol || !amount) {
      setFetchingRates(false);
      setErrorFetchingRates(false);
      setRateInfo(null);
      return;
    }

    setFetchingRates(true);
    setErrorFetchingRates(false);
    setRateInfo(null);

    frInterval.current = window.setInterval(() => {
      api_client({
        url: `/wallet/rate/get?wallet_from=${walletFrom.symbol}&wallet_to=${walletTo.symbol}&amount=${amount}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setRateInfo(res.data);
        })
        .catch(() => {
          setErrorFetchingRates(true);
        })
        .finally(() => {
          setFetchingRates(false);
          if (frInterval.current) window.clearInterval(frInterval.current);
        });
    }, 3000);
  }, [amount, walletFrom?.symbol, walletTo?.symbol, accessToken]);

  useEffect(() => {
    if (wallets && walletSymbol && !walletFrom)
      setWalletFrom(
        wallets?.find(
          (sWallet) => sWallet.symbol.toLowerCase() === walletSymbol
        )
      );
  }, [wallets, walletSymbol, walletFrom]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  if (!wallets) return <Preloader />;

  const dynamicProps2: Partial<{ onlyShow: string[]; dontShow: string }> = {};

  if (walletFrom) {
    if (walletFrom.symbol !== "usdt") {
      dynamicProps2.onlyShow = ["usdt"];

      if (walletTo && walletTo.symbol !== "usdt") {
        setWalletTo(undefined);
      }
    } else {
      dynamicProps2.dontShow = "usdt";
    }

    if (walletTo && walletTo?.symbol === walletFrom.symbol) {
      setWalletTo(undefined);
    }
  }

  return (
    <DashboardLayout>
      <SelectCurrencyModal
        show={showSelectWalletFromModal}
        type="crypto"
        selectHandler={(selWallet) => {
          setWalletFrom(selWallet);
          setShowSelectWalletFromModal(false);
        }}
        closeHandler={() => setShowSelectWalletFromModal(false)}
        selectedWallet={walletFrom?.symbol}
      />
      <SelectCurrencyModal
        show={showSelectWalletToModal}
        type="crypto"
        selectHandler={(selWallet) => {
          setWalletTo(selWallet);
          setShowSelectWalletToModal(false);
        }}
        closeHandler={() => setShowSelectWalletToModal(false)}
        selectedWallet={walletTo?.symbol}
        {...dynamicProps2}
      />
      <div className="crypto-block2">
        <div className="crypto-block2__header">
          <span onClick={() => navigate(-1)}>
            <IonIcon icon={arrowBack} />
          </span>
          <h3 className="crypto-block2__heading">Swap your crypto</h3>
        </div>
        <div className="crypto-block2__body">
          <div className="crypto-block2__swap-block">
            <div className="form-group">
              {walletFrom ? (
                <div className="crypto-block2__swap-heading">
                  <p>You are swapping from {walletFrom.symbol.toUpperCase()}</p>
                  <p>
                    AVAIL. ={" "}
                    {roundDP(
                      walletFrom.balance,
                      getPrecision(walletFrom.symbol)
                    )}{" "}
                    {walletFrom.symbol.toUpperCase()} ($
                    {roundDP(walletFrom.dollar_balance, 2)})
                  </p>
                </div>
              ) : null}
              <div
                className="select-box select-box--md"
                onClick={() => setShowSelectWalletFromModal(true)}
              >
                {walletFrom ? <img src={walletFrom.image} alt="" /> : null}
                <p>{walletFrom ? walletFrom.coin : "Select Wallet"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            <button
              className={cls(
                "crypto-block2__swap-btn"
                // walletTo && "crypto-block2__swap-draw-up"
              )}
              onClick={() => {
                setWalletTo(walletFrom);
                setWalletFrom(walletTo);
              }}
            >
              <IonIcon icon={swapVerticalOutline} />
            </button>
            <div className="form-group">
              {walletTo ? (
                <div className="crypto-block2__swap-heading">
                  <p>to receive {walletTo.symbol.toUpperCase()}</p>
                  <p>
                    AVAIL. ={" "}
                    {roundDP(walletTo.balance, getPrecision(walletTo.symbol))}{" "}
                    {walletTo.symbol.toUpperCase()} ($
                    {roundDP(walletTo.dollar_balance, 2)})
                  </p>
                </div>
              ) : null}
              <div
                className="select-box select-box--md"
                onClick={() => setShowSelectWalletToModal(true)}
              >
                {walletTo ? <img src={walletTo.image} alt="" /> : null}
                <p>{walletTo ? walletTo.coin : "Select Wallet"}</p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
          </div>
          <div className="form-group form-group--md">
            {walletFrom ? (
              <p>MAX: ${roundDP(walletFrom.dollar_balance, 2)}</p>
            ) : null}
            <div className="input-group">
              <button type="button">$</button>
              <input
                type="text"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setAmount(e.target.value)
                      : null
                    : setAmount("")
                }
              />
            </div>
            {rateInfo ? (
              <div className="input-group">
                <button type="button">
                  {walletFrom?.symbol.toUpperCase()}
                </button>
                <input
                  type="text"
                  value={rateInfo.amoun_in_coin}
                  disabled
                  className="form-disabled"
                />
              </div>
            ) : null}
            {walletFrom ? (
              <AmountPercentage
                totalAmount={walletFrom.dollar_balance}
                handler={setAmount}
              />
            ) : null}
          </div>
          {fetchingRates ? <VerticalBarLoader sm /> : null}
          {walletFrom && rateInfo ? (
            <div className="crypto-block__rate-info">
              <p>
                <IonIcon icon={alertCircle} /> TRANSACTION FEE: $
                {roundDP(rateInfo.charge, 2)}
              </p>
              <p>
                YOU ARE SWAPPING{" "}
                {roundDP(
                  rateInfo.amoun_in_coin,
                  getPrecision(walletFrom.symbol)
                )}{" "}
                {walletFrom?.symbol.toUpperCase()}
              </p>
            </div>
          ) : null}
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                walletFrom &&
                  walletTo &&
                  amount &&
                  rateInfo &&
                  "form-button--active"
              )}
              onClick={handleSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(SwapCrypto);
