import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code === "ERR_BAD_REQUEST" && error.response.status === 401) {
      // localStorage.setItem("login_timeout", "timeout");
      localStorage.removeItem("appState");
      window.location.assign("/login");

      return new Promise((resolve, reject) => {
        const interval = window.setInterval(() => {
          reject(error);
          window.clearInterval(interval);
        }, 5000);
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
