import { IonIcon } from "@ionic/react";
import { arrowBack, chevronDownOutline } from "ionicons/icons";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { tRootState } from "../../store";
import { FormEvent, useRef, useEffect, useState } from "react";
import useData from "../../hooks/useData/useData";
import Preloader from "../../components/Preloader/Preloader";
import { Navigate, useNavigate } from "react-router-dom";
import { assertNotNull, isNumber, roundDP } from "../../utils/func";
import { tDepositBank } from "../../store/types/app.types";
import cls from "classnames";
import withAuth from "../../hoc/withAuth/withAuth";
import useAlert from "../../hooks/useAlert/useAlert";
import { addDepositInfo } from "../../store/nairaSlice";
import SelectDepositBank from "./Modals/SelectDepositBank";

const DepositAmount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );
  const {
    warnings: { deposit_disclaimer },
    depositBanks: banks,
    deposit: { min, max },
  } = useSelector((state: tRootState) => state.cache);

  assertNotNull(userDetails);

  const { fetchWarnings, fetchBanks, fetchDepositFee } = useData();

  const [amount, setAmount] = useState<number | "">("");
  const [bank, setBank] = useState<tDepositBank | null>(null);

  const [showDepositBanks, setShowDepositBanks] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!min || !max) return;

    if (!bank) return setMessage("warning", "Select your bank");
    if (!amount) return setMessage("warning", "Amount is required");

    if (+amount < min)
      return setMessage("warning", `Minimum amount you can deposit is ${min}`);

    if (+amount > max)
      return setMessage("warning", `Maximum amount you can deposit is ${max}`);

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    const actionContinue = async () => {
      let fee = 0,
        text = "";

      try {
        const data = await fetchDepositFee(amount);
        fee = data.fee;
        text = data.text;
      } catch (error) {
        // do nothing
      }

      dispatch(addDepositInfo({ amount, bank, fee, feeText: text }));

      navigate("/deposit/details");
    };

    actionContinue();
  };

  useEffect(() => {
    fetchBanks("deposit").catch((err) => {
      navigate("/error");
    });
  }, [fetchBanks, navigate]);

  useEffect(() => {
    fetchWarnings();
  }, [fetchWarnings]);

  useEffect(() => {
    clearMessage();
  }, [bank, amount, clearMessage]);

  if (!userDetails.is_verified) return <Navigate to="/404" />;
  if (!banks || !min || !max) return <Preloader />;

  return (
    <DashboardLayout>
      <SelectDepositBank
        banks={banks}
        show={showDepositBanks}
        selectedBank={bank?.id || null}
        handler={(selBank) => {
          setBank(selBank);
          setShowDepositBanks(false);
        }}
        closeHandler={() => setShowDepositBanks(false)}
      />
      <div className="naira-block">
        <div className="naira-block__header">
          <h3 className="naira-block__heading">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} />
            </span>
            Deposit Amount
          </h3>
          <p className="naira-block__sub-heading text-center">
            Fill in how much you would like to deposit
          </p>
        </div>
        <form className="naira-block__form" onSubmit={handleSubmit}>
          <div className="naira-block__info-card">{deposit_disclaimer}</div>
          <div className="form-group">
            <label>Deposit Bank</label>
            <div
              className={cls("select-box", bank && "select-box--auto")}
              onClick={() => setShowDepositBanks(true)}
            >
              {bank ? (
                <div>
                  <p>
                    <strong>Bank Name:</strong> {bank.bank_name}
                  </p>
                  <p>
                    <strong>Account Name:</strong> {bank.bank_account_name}
                  </p>
                  <p>
                    <strong>Account No:</strong> {bank.bank_account_no}
                  </p>
                </div>
              ) : (
                <p>Select Bank</p>
              )}
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          <div className="form-group">
            <label>Amount</label>
            <div className="input-group">
              <button type="button">NGN</button>
              <input
                type="text"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setAmount(+e.target.value)
                      : null
                    : setAmount("")
                }
              />
            </div>
            <div className="naira-block__min-max">
              <p>MIN: ₦{roundDP(min, 2)}</p>
              <p>MAX: ₦{roundDP(max, 2)}</p>
            </div>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls(
                "form-button",
                amount &&
                  bank &&
                  min &&
                  max &&
                  amount >= min &&
                  amount <= max &&
                  "form-button--active"
              )}
              ref={submitBtnRef}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(DepositAmount);
