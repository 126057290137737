import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { clearPinData } from "../../store/cacheSlice";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import withAuth from "../../hoc/withAuth/withAuth";

const PinChanged = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { message, changedMessage } = useSelector(
    (state: tRootState) => state.cache.pin
  );

  const finish = () => {
    navigate("/account/security");
    const interval = setInterval(() => {
      dispatch(clearPinData());
      clearInterval(interval);
    }, 5000);
  };

  if (!message) return <Navigate to="/change-pin" />;
  if (!changedMessage) return <Navigate to="/reset-pin" />;

  return (
    <DashboardLayout>
      <div className="naira-block naira-block--final">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <div className="naira-block__header mb-medium">
          <h3 className="naira-block__heading">PIN Changed Successfuly</h3>
          <p className="naira-block__sub-heading text-center">
            {changedMessage}
          </p>
        </div>
        <div className="auth__footer mb-medium">
          <button className="form-button form-button--active" onClick={finish}>
            Done
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(PinChanged);
