import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import { IonIcon } from "@ionic/react";
import { alertCircle, close } from "ionicons/icons";

import useData from "../../../hooks/useData/useData";
import useCopy from "../../../hooks/useCopy/useCopy";

import { tRootState } from "../../../store";
import { tNetwork, tWallet } from "../../../store/types/app.types";

import { roundDP } from "../../../utils/func";
import { getPrecision } from "../../../utils/app";

export const DepositCryptoNoticeModal = ({
  wallet,
  network,
  closeHandler,
}: {
  wallet: tWallet;
  network: tNetwork;
  closeHandler: () => void;
}) => {
  return (
    <>
      <div className="cnotice-modal">
        <span className="cnotice-modal__close" onClick={() => closeHandler()}>
          <IonIcon icon={close} />
        </span>
        <div className="cnotice-modal__main">
          <div className="cnotice-modal__heading">Please note!</div>
          <div className="cnotice-modal__body">
            <div className="cnotice-modal__info">
              <p className="cnotice-modal__info-heading">
                <IonIcon icon={alertCircle} />
                Please read
              </p>
              <p className="cnotice-modal__info-text">
                If you deposit any amount below{" "}
                {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                {wallet.symbol.toUpperCase()}, you risk <br />
                losing your asset forever.
              </p>
            </div>
            <div className="cnotice-modal__address-block">
              <p className="cnotice-modal__address-label">Address</p>
              <p className="cnotice-modal__address">{network.address}</p>
            </div>
            {network.memo ? (
              <div className="cnotice-modal__address-block">
                <p className="cnotice-modal__address-label">Address</p>
                <p className="cnotice-modal__address">{network.memo}</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="cnotice-modal__footer">
          <button
            className="form-button form-button--active"
            onClick={() => closeHandler()}
          >
            I agree
          </button>
        </div>
      </div>
      <div className="overlay" onClick={() => closeHandler()}></div>
    </>
  );
};

const DepositCryptoModal = ({
  wallet,
  network,
  closeHandler,
  show,
}: {
  wallet: tWallet;
  network: tNetwork | null;
  closeHandler: () => void;
  show: boolean;
}) => {
  const msg = useSelector((state: tRootState) => state.cache.warnings.memo);

  const { fetchWarnings } = useData();

  const [copiedAddress, copyAddress] = useCopy(network?.address || "");
  const [copiedMemo, copyMemo] = useCopy(network?.memo || "");

  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    fetchWarnings();
  }, [fetchWarnings]);

  if (!show || !network) return null;

  if (showNotice)
    return (
      <DepositCryptoNoticeModal
        wallet={wallet}
        network={network}
        closeHandler={() => setShowNotice(false)}
      />
    );

  return (
    <>
      <div className="deposit-modal">
        <div className="deposit-modal__header">
          <span className="deposit-modal__close" onClick={closeHandler}>
            <IonIcon icon={close} />
          </span>
          <h3 className="deposit-modal__heading">
            Deposit {wallet.coin} - {network.name}
          </h3>
        </div>
        <div className="deposit-modal__body">
          <div className="wallet-deposit__recieve-steps">
            <div>
              Copy and paste your {wallet.coin} - {network.name} wallet from
              other wallet services or exchanges: address shown below or scan
              the QR code.
            </div>
            {network.memo && msg ? (
              <div className="bold text-danger">{msg}</div>
            ) : null}
            <div className="wallet-deposit__qr-container">
              <div className="minimum-deposit">
                <IonIcon icon={alertCircle} />
                Minimum Deposit -{" "}
                <strong>
                  {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()}
                </strong>
              </div>
            </div>
            <div className="wallet-deposit__qr-container w-100">
              <QRCodeSVG
                value={network.address}
                className="wallet-deposit__qr-img wallet-deposit__qr-img--small"
              />
            </div>
            <div className="wallet-deposit__qr-link wallet-deposit__qr-link--small w-100">
              <div>{network.address}</div>
              <button
                onClick={() =>
                  copyAddress(() => {
                    setShowNotice(true);
                  })
                }
              >
                {copiedAddress ? "Copied!" : "Copy Address"}
              </button>
            </div>
            {network.memo ? (
              <div className="wallet-deposit__qr-link wallet-deposit__qr-link--small w-100">
                <div>{network.memo}</div>
                <button
                  onClick={() =>
                    copyMemo(() => {
                      setShowNotice(true);
                    })
                  }
                >
                  {copiedMemo
                    ? "Copied!"
                    : wallet.symbol.toLowerCase() === "xrp"
                    ? "Copy Destination Tag"
                    : "Copy Memo"}
                </button>
              </div>
            ) : null}
            <div className="wallet-deposit__qr-container">
              <div className="minimum-deposit">
                <div className="minimum-deposit">
                  If you deposit any amount below{" "}
                  {roundDP(wallet.minimum_value, getPrecision(wallet.symbol))}{" "}
                  {wallet.symbol.toUpperCase()}, you risk losing your asset
                  forever.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default DepositCryptoModal;
