import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import { login } from "../../store/userSlice";

import { assertNotNull } from "../../utils/func";
import { getProfilePicture } from "../../utils/user";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [message, setMessage, clearMessage] = useAlert(true);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password) return setMessage("warning", "Password field is required");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Logging In`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    let accessToken: string;

    api_client({
      method: "POST",
      url: "/login",
      data: JSON.stringify({
        email: userDetails.username,
        password,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        accessToken = res.data.token;

        return api_client({
          url: `/get-profile`,
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        localStorage.removeItem("login_timeout");

        dispatch(login({ accessToken, userDetails: res.data.message }));

        navigate("/dashboard");
      })
      .catch((err) => {
        if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Login";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [password, clearMessage]);

  return (
    <AuthLayout>
      <div className="auth__img-block">
        <Link to="/">
          <img
            src={sekiLogoLight}
            alt=""
            className="auth__img light-inline-block"
          />
          <img
            src={sekiLogoDark}
            alt=""
            className="auth__img dark-inline-block"
          />
        </Link>
      </div>
      <div className="auth">
        <div className="auth__img-block">
          <img
            src={getProfilePicture(userDetails.gender)}
            alt=""
            className="auth__img"
          />
        </div>
        <h2 className="auth__heading">
          Hello, Chief <span>{userDetails.names}</span>
        </h2>
        <p className="auth__heading-sub-text">
          For extra security you have been logged out of your account. kindly
          login again into your account
        </p>
        <form className="auth__form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
          </div>
          <div className="auth__footer">
            {message}
            <button
              className={cls("form-button", password && "form-button--active")}
              type="submit"
              ref={submitBtnRef}
            >
              Login
            </button>
          </div>
          <p className="auth__footer-link">
            <Link to="/sign-out">Logout</Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  );
};

export default withAuth(SignIn);
