import { useEffect, useRef, useState } from "react";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  arrowBackOutline,
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  pauseOutline,
  playOutline,
} from "ionicons/icons";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

const StatusContent = ({ content, loaded, setLoaded }) => {
  useEffect(() => {
    setLoaded(content.type === "text");
  }, [content.type, setLoaded]);

  if (content.type === "text")
    return (
      <div
        className="status__text"
        dangerouslySetInnerHTML={{ __html: content.content }}
      ></div>
    );

  return (
    <>
      <div className={cls("status__loader", loaded && "hidden")}>
        <VerticalBarLoader />
      </div>
      <img
        src={content.content}
        alt=""
        className={cls("status__img", !loaded && "hidden")}
        onLoad={(e) => setLoaded(true)}
      />
    </>
  );
};

const Status = ({ data, selectStatus = 0, closeHandler }) => {
  const [currentStatus, setCurrentStatus] = useState(selectStatus);
  const [currentStatusContent, setCurrentStatusContent] = useState(0);

  const currentStatusContentLength = data[currentStatus].contents.length;
  const statusLength = data.length - 1;

  const [loaded, setLoaded] = useState(false);

  const [pause, setPause] = useState(false);

  const moveInterval = useRef(null);
  const moveMethod = useRef(null);
  const moveTime = useRef(null);
  const endTime = useRef(null);

  const resetMove = () => {
    moveTime.current = null;
    moveMethod.current = null;
    setPause(false);
  };

  useEffect(() => {
    if (moveInterval.current) window.clearInterval(moveInterval.current);

    if (!loaded) return;

    if (pause) {
      moveTime.current = endTime.current - Date.now();

      return;
    }

    moveMethod.current =
      moveMethod.current ||
      (() => {
        if (currentStatusContent < currentStatusContentLength - 1) {
          setCurrentStatusContent((curStatContent) => curStatContent + 1);
        } else if (currentStatus < statusLength) {
          setCurrentStatus((curStat) => curStat + 1);
          setCurrentStatusContent(0);
        }
      });
    moveTime.current = moveTime.current || 5000;
    endTime.current = Date.now() + moveTime.current;

    moveInterval.current = window.setInterval(() => {
      const method = moveMethod.current;

      moveTime.current = null;
      moveMethod.current = null;

      if (method) method();
    }, moveTime.current);
  }, [
    currentStatusContent,
    statusLength,
    currentStatusContentLength,
    currentStatus,
    loaded,
    pause,
  ]);

  const previousStatus = () => {
    if (currentStatusContent > 0) {
      setCurrentStatusContent(currentStatusContent - 1);
      resetMove();
    } else if (currentStatus > 0) {
      setCurrentStatus(currentStatus - 1);
      setCurrentStatusContent(0);
      resetMove();
    }
  };

  const nextStatus = () => {
    if (currentStatusContent < currentStatusContentLength - 1) {
      setCurrentStatusContent(currentStatusContent + 1);
      resetMove();
    } else if (currentStatus < statusLength) {
      setCurrentStatus(currentStatus + 1);
      setCurrentStatusContent(0);
      resetMove();
    }
  };

  const content = data[currentStatus].contents[currentStatusContent];

  return (
    <div className="status">
      {currentStatus > 0 || currentStatusContent > 0 ? (
        <button
          className="status__control status__control--prev"
          onClick={previousStatus}
        >
          <IonIcon icon={chevronBackOutline} />
        </button>
      ) : null}
      {currentStatus < statusLength ||
      currentStatusContent < currentStatusContentLength - 1 ? (
        <button
          className="status__control status__control--next"
          onClick={nextStatus}
        >
          <IonIcon icon={chevronForwardOutline} />
        </button>
      ) : null}
      <div className="status__header">
        <span className="status__icon-box" onClick={closeHandler}>
          <IonIcon icon={arrowBackOutline} />
        </span>
        <span className="status__icon-box" onClick={closeHandler}>
          <IonIcon icon={closeOutline} />
        </span>
      </div>
      {data.map((status, index) => (
        <div
          className={cls(
            "status__box",
            currentStatus === index && "status__box--active"
          )}
          key={index}
        >
          <div className="status__indicators">
            {status.contents.map((_, i) => (
              <div className="status__indicator" key={i}>
                <div
                  className={cls(
                    "status__indicator-progress",
                    i < currentStatusContent
                      ? "done"
                      : i === currentStatusContent && loaded
                      ? "current"
                      : ""
                  )}
                  style={pause ? { animationPlayState: "paused" } : {}}
                ></div>
              </div>
            ))}
          </div>
          <div className="status__heading">
            <h3 className="status__title">{status.title}</h3>
            <span
              className="status__playback"
              onClick={() => setPause((p) => !p)}
            >
              <IonIcon icon={pause ? playOutline : pauseOutline} />
            </span>
          </div>
          <div className="status__content">
            <StatusContent
              content={content}
              loaded={loaded}
              setLoaded={setLoaded}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Status;
