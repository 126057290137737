import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import { assertNotNull, copyData, getDate } from "../../utils/func";

const Refer = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const userDetails = useSelector(
    (state: tRootState) => state.user.userDetails
  );

  assertNotNull(userDetails);

  const [active, setActive] = useState<"CL" | "Earnings">("CL");

  const [url, setUrl] = useState(`/list-referrals`);

  const [referrals, setReferrals] = useState<
    {
      id: number;
      paid: 0 | 1;
      user: { username: string };
      created_at: string;
    }[]
  >([]);
  const [links, setLinks] = useState<
    {
      url: string | null;
      label: string;
      active: boolean;
    }[]
  >([]);
  const [edgeLinks, setEdgeLinks] = useState<{
    first_page_url: string;
    last_page_url: string;
  }>({ first_page_url: "", last_page_url: "" });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [codeCopied, setCodeCopied] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(false);

    api_client({
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        setReferrals(res.data.message.data);
        setLinks(res.data.message.links);
        setEdgeLinks({
          first_page_url: res.data.message.first_page_url,
          last_page_url: res.data.message.last_page_url,
        });

        setError(false);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, url]);

  return (
    <>
      <div className="account-section">
        <div className="account-section__header">
          <h3 className="heading-tertiary">Refer and Earn</h3>
        </div>
        <div className="account-section__main">
          <ul className="account-section__menu">
            <li
              className={cls(
                "account-section__menu-item",
                active === "CL" && "account-section__menu-item--active"
              )}
              onClick={() => setActive("CL")}
            >
              Code & Link
            </li>
            <li
              className={cls(
                "account-section__menu-item",
                active === "Earnings" && "account-section__menu-item--active"
              )}
              onClick={() => setActive("Earnings")}
            >
              Earnings
            </li>
          </ul>
          <p>{userDetails.referral_text}</p>
          {active === "CL" ? (
            <div className="account-section__form">
              <div className="account-section__block">
                <p>Referral Code</p>
                <p>{userDetails.referral_code}</p>
              </div>
              <button
                className="form-button form-button--active"
                onClick={() =>
                  copyData(userDetails.referral_code, setCodeCopied)
                }
              >
                {codeCopied ? "Copied!" : "Copy Code"}
              </button>
              <div className="account-section__block">
                <p>Referral Link</p>
                <p>{userDetails.referral_link}</p>
              </div>
              <button
                className="form-button form-button--active"
                onClick={() =>
                  copyData(userDetails.referral_link, setLinkCopied)
                }
              >
                {linkCopied ? "Copied!" : "Copy Link"}
              </button>
            </div>
          ) : (
            <>
              <div className="account-earning">
                <p>Total Referrals</p>
                <p>{userDetails.total_refs}</p>
              </div>
              <div className="pay-bills__transactions">
                <h3 className="heading-tertiary">All Referrals</h3>
                <div className="table-responsive table-responsive--no-hide">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Date Referred</th>
                        <th>Paid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading
                        ? new Array(5).fill(null).map((_, i) => (
                            <tr key={i}>
                              <td>
                                <span className="color-transparent placeholder">
                                  Akashi Sejuiro
                                </span>
                              </td>
                              <td>
                                <span className="color-transparent placeholder">
                                  7 April 2023 03:17:04 PM
                                </span>
                              </td>
                              <td>
                                <span className="badge placeholder color-transparent">
                                  Paid
                                </span>
                              </td>
                            </tr>
                          ))
                        : null}
                      {error ? (
                        <tr>
                          <td colSpan={3} className="text-center">
                            Error fetching Referrals
                          </td>
                        </tr>
                      ) : null}
                      {!loading && !error && !referrals.length ? (
                        <tr>
                          <td colSpan={3} className="text-center">
                            You have no referrals
                          </td>
                        </tr>
                      ) : null}
                      {!loading && !error && referrals.length
                        ? referrals.map((referral) => (
                            <tr key={referral.id}>
                              <td>{referral.user.username}</td>
                              <td>{getDate(referral.created_at)}</td>
                              <td>
                                {referral.paid ? (
                                  <span className="badge badge--success">
                                    Paid
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
              {!loading && !error && referrals.length ? (
                <div className="pagination">
                  {edgeLinks.first_page_url ? (
                    <button onClick={() => setUrl(edgeLinks.first_page_url)}>
                      &#171;
                    </button>
                  ) : null}
                  {links.map((link, i) => {
                    return isNaN(+link.label) || link.active ? (
                      <button
                        className={link.active ? "active" : ""}
                        onClick={() => setUrl(link.url || "")}
                        key={link.label}
                      >
                        {i === 0 ? (
                          <>&#8249;</>
                        ) : i === links.length - 1 ? (
                          <>&#8250;</>
                        ) : (
                          link.label
                        )}
                      </button>
                    ) : null;
                  })}
                  {edgeLinks.last_page_url ? (
                    <button onClick={() => setUrl(edgeLinks.last_page_url)}>
                      &#187;
                    </button>
                  ) : null}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withAuth(Refer);
