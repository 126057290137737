import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tBuyGiftCard, tBuyGiftCardCountry } from "./types/app.types";

type BuyGiftCardType = {
  giftCard: tBuyGiftCard | null;
  cardCountry: tBuyGiftCardCountry | null;
};

const BuyGiftCardInitialState: BuyGiftCardType = {
  giftCard: null,
  cardCountry: null,
};

const buyGiftCardSlice = createSlice({
  name: "buygiftcard",
  initialState: getState<BuyGiftCardType>(
    "buygiftcard",
    BuyGiftCardInitialState
  ),
  reducers: {
    updateGiftCard(state, { payload }: PayloadAction<tBuyGiftCard>) {
      state.giftCard = payload;

      saveState("buygiftcard", current(state));
    },
    updateGiftCardCountry(
      state,
      { payload }: PayloadAction<tBuyGiftCardCountry>
    ) {
      state.cardCountry = payload;

      saveState("buygiftcard", current(state));
    },
    clearGiftCardData(state) {
      state.giftCard = null;
      state.cardCountry = null;

      saveState("buygiftcard", current(state));
    },
  },
});

export const { updateGiftCard, updateGiftCardCountry, clearGiftCardData } =
  buyGiftCardSlice.actions;

export default buyGiftCardSlice.reducer;
