import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { updateBankAdded } from "../../store/cacheSlice";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

const BankAdded = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bankAdded = useSelector((state: tRootState) => state.cache.bankAdded);

  const finish = () => {
    navigate("/account/banks");
    const interval = setInterval(() => {
      dispatch(updateBankAdded(false));
      clearInterval(interval);
    }, 5000);
  };

  if (!bankAdded) return <Navigate to="/add-bank" />;

  return (
    <DashboardLayout>
      <div className="naira-block naira-block--final">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <div className="naira-block__header mb-medium">
          <h3 className="naira-block__heading">Well done!</h3>
          <p className="naira-block__sub-heading text-center">
            Deposit bank account added successfully. You can add up to 5 bank
            accounts.
          </p>
        </div>
        <div className="auth__footer mb-medium">
          <button className="form-button form-button--active" onClick={finish}>
            Done
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BankAdded;
