import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  arrowBackOutline,
  close,
  copy,
  ellipsisHorizontal,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tVirtualCardTransactions } from "../../store/types/app.types";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useCopy from "../../hooks/useCopy/useCopy";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import Preloader from "../../components/Preloader/Preloader";
import Pagination from "../../components/Pagination/Pagination";
import VirtualCardOptions from "./VCMoreOptions/VirtualCardOptions";
import VirtualCardSlider from "./VirtualCardSlider/VirtualCardSlider";

import { getDateTime, roundDP, sleep } from "../../utils/func";

import emptyTransactionsImg from "../../assets/img/empty-transactions.png";

const VirtualCardTransactions = ({ cardId }: { cardId: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [reload, setReload] = useState(false);

  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);

  const [transactions, setTransactions] = useState<tVirtualCardTransactions>(
    []
  );

  useEffect(() => {
    setLoading(true);
    setError(false);

    api_client({
      url: `/virtual/transactions-list/${cardId}?page=${page}`,
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        if (!res.data.status) throw new Error("");

        const trns = res.data.message;
        const totalRecords = res.data.pagination.total;
        const pgn = res.data.pagination.last_page;

        setPagination(totalRecords ? pgn : totalRecords);

        if (page > 1 && !trns.length) {
          setPage(page - 1);
        } else {
          setTransactions(trns);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [cardId, accessToken, dispatch, page, reload]);

  return (
    <div className="vcard-details__transactions">
      <div className="vcard-details__transactions-header">
        <h3 className="vcard-details__transactions-heading">
          Transaction history
        </h3>
        {/* <div className="vcard-details__transactions-filters">
      <div className="vcard-details__transactions-filter">
        Filter:
        <div className="select-box select-box--sm">
          All <IonIcon icon={chevronDown} />
        </div>
      </div>
    </div> */}
      </div>
      <div className="vcard-details__transactions-main">
        {loading
          ? new Array(10).fill(null).map((_, i) => (
              <div className="vcard-transaction" key={i}>
                <div className="vcard-transaction__desc">
                  <p>
                    <span className="placeholder color-transparent">
                      Successful charge from Facebook
                    </span>
                  </p>
                  <p
                    className="vcard-transaction__date-time"
                    style={{ marginTop: "4px" }}
                  >
                    <span className="placeholder color-transparent">
                      8 June 2024 03:42:46 PM
                    </span>
                  </p>
                </div>

                <div className="vcard-transaction__info">
                  <p className="vcard-transaction__amount text-success">
                    <span className="placeholder color-transparent">
                      + $11.87
                    </span>
                  </p>
                  <p>
                    <span className="badge badge--xs placeholder color-transparent">
                      Success
                    </span>
                  </p>
                </div>
              </div>
            ))
          : null}
        {error ? (
          <div>
            Error fetching virtual card transactions.{" "}
            <span
              className="link-primary"
              onClick={() => setReload((rl) => !rl)}
            >
              Try again
            </span>
          </div>
        ) : null}
        {!loading && !error ? (
          transactions.length ? (
            transactions.map((transaction) => {
              const [date, time] = getDateTime(transaction.created_at);

              return (
                <div
                  className="vcard-transaction"
                  key={transaction.id}
                  onClick={() =>
                    navigate(
                      `/transaction-details/virtual_cards/${transaction.id}`
                    )
                  }
                >
                  <div className="vcard-transaction__desc">
                    <p>{transaction.content}</p>
                    <p className="vcard-transaction__date-time">
                      <span>{date}</span>
                      <span>{time}</span>
                    </p>
                  </div>
                  <div className="vcard-transaction__info">
                    <p
                      className={cls(
                        "vcard-transaction__amount",
                        transaction.flow === "credit" &&
                          +transaction.amount > 0 &&
                          "text-success",
                        transaction.flow === "debit" &&
                          +transaction.amount > 0 &&
                          "text-danger"
                      )}
                    >
                      {+transaction.amount > 0
                        ? transaction.flow === "credit"
                          ? "+"
                          : "-"
                        : ""}{" "}
                      {transaction.currency}
                      {roundDP(transaction.amount, 2)}
                    </p>
                    <p>
                      {transaction.status === "COMPLETED" ? (
                        <span className="badge badge--xs badge--success">
                          {transaction.status}
                        </span>
                      ) : null}
                      {transaction.status === "PENDING" ? (
                        <span className="badge badge--xs badge--warning">
                          {transaction.status}
                        </span>
                      ) : null}
                      {transaction.status === "FAILED" ? (
                        <span className="badge badge--xs badge--danger">
                          {transaction.status}
                        </span>
                      ) : null}
                      {transaction.status === "REFUNDED" ? (
                        <span className="badge badge--xs badge--info">
                          {transaction.status}
                        </span>
                      ) : null}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="giftcard-transactions__empty">
              <img
                src={emptyTransactionsImg}
                className="giftcard-transactions__empty-img"
                alt=""
              />
              <p className="giftcard-transactions__empty-text">
                No Transactions Yet
              </p>
              <p className="giftcard-transactions__empty-sub-text">
                This place is empty because you have not done any transaction
                with this virtual card...
              </p>
            </div>
          )
        ) : null}
        <div className="mt-small">
          <Pagination pagination={pagination} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

const VirtualCardDetails = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const card = searchParams.get("card");

  const virtualCards = useSelector(
    (state: tRootState) => state.cache.virtualCards
  );

  const { fetchVirtualCards, fetchVirtualCard } = useData();

  const [reloadVirtualCard, setReloadVirtualCard] = useState(true);
  const virtualCard = virtualCards?.find(
    (vCard) => vCard.id.toString() === card
  );

  const [showDetails, setShowDetails] = useState(false);

  const [nameCopied, copyName] = useCopy(virtualCard?.name || "");
  const [numberCopied, copyNumber] = useCopy(virtualCard?.number || "");
  const [expirationCopied, copyExpiration] = useCopy(virtualCard?.expiry || "");
  const [cvvCopied, copyCVV] = useCopy(virtualCard?.cvv?.toString() || "");
  const [addressCopied, copyAddress] = useCopy(virtualCard?.address || "");
  const [postalCodeCopied, copyPostalCode] = useCopy(
    virtualCard?.postal_code || ""
  );

  const [showOptions, setShowOptions] = useState(false);

  const [showCardFrozedModal, setShowCardFrozenModal] = useState(false);

  useEffect(() => {
    if (!card) return;

    setShowDetails(false);
  }, [card]);

  useEffect(() => {
    if (!card) return;

    fetchVirtualCard(card)
      .catch((err) => {
        if (!virtualCard?.id) navigate("/404");
      })
      .finally(() => {
        if (virtualCard?.status === "PENDING")
          sleep(5000).then(() => {
            setReloadVirtualCard((rv) => !rv);
          });
      });
  }, [
    card,
    reloadVirtualCard,
    fetchVirtualCard,
    navigate,
    virtualCard?.id,
    virtualCard?.status,
  ]);

  useEffect(() => {
    fetchVirtualCards();
  }, [fetchVirtualCards]);

  if (!card) return <Navigate to="/not-found" />;

  if (!virtualCard) return <Preloader />;

  return (
    <DashboardLayout>
      {showDetails ? (
        <div
          className="overlay vcard-details__card-info-overlay"
          onClick={() => setShowDetails(false)}
        ></div>
      ) : null}

      {showOptions ? (
        <VirtualCardOptions
          virtualCard={virtualCard}
          closeHandler={() => setShowOptions(false)}
        />
      ) : null}

      {showCardFrozedModal ? (
        <>
          <div className="success-modal" style={{ borderRadius: "1.2rem" }}>
            <span
              className="success-modal__close"
              onClick={() => setShowCardFrozenModal(false)}
            >
              <IonIcon icon={close} />
            </span>
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <h3 className="mb-small">Card Frozen!</h3>
            <p className="text-center">
              You cannot top-up card while it is frozen. In order to top-up card
              you have to unfreeze card, you can do that from card more options
            </p>
          </div>
          <div
            className="overlay"
            onClick={() => setShowCardFrozenModal(false)}
          ></div>
        </>
      ) : null}

      <div className="virtual-card virtual-card--md">
        <div className="virtual-card__header">
          <span
            className="virtual-card__back"
            onClick={() => navigate("/virtual-cards")}
          >
            <IonIcon icon={arrowBackOutline} />
          </span>
        </div>
        <div className="vcard-details">
          <div className="vcard-details__main">
            <VirtualCardSlider
              virtualCard={virtualCard}
              setVirtualCard={(virtualCardId) => {
                setSearchParams((sp) => {
                  sp.set("card", virtualCardId);

                  return sp;
                });
              }}
            />

            {virtualCard.status !== "PENDING" ? (
              <>
                <div className="vcard-details__actions">
                  <div
                    className={cls(
                      "vcard-details__action",
                      virtualCard.status === "BLOCKED" && "disabled"
                    )}
                    onClick={() =>
                      virtualCard.status !== "BLOCKED"
                        ? setShowDetails((sd) => !sd)
                        : null
                    }
                  >
                    <IonIcon icon={showDetails ? eyeOffOutline : eyeOutline} />
                    <p>{showDetails ? "Hide" : "Show"} details</p>
                  </div>
                  <div className="vcard-details__action-divider"></div>
                  <div
                    className={cls(
                      "vcard-details__action",
                      virtualCard.status === "BLOCKED" && "disabled"
                    )}
                    onClick={() =>
                      virtualCard.status === "FROZEN"
                        ? setShowCardFrozenModal(true)
                        : virtualCard.status === "BLOCKED"
                        ? null
                        : navigate(`/virtual-card/${virtualCard.id}/topup`)
                    }
                  >
                    <IonIcon icon={addOutline} />
                    <p>Top-up</p>
                  </div>
                  <div className="vcard-details__action-divider"></div>
                  <div
                    className="vcard-details__action"
                    onClick={() => setShowOptions(true)}
                  >
                    <IonIcon icon={ellipsisHorizontal} />
                    <p>More</p>
                  </div>
                </div>
                <div
                  className={cls(
                    "vcard-details__card-info",
                    showDetails && "open"
                  )}
                >
                  <div className="vcard-details__card-data">
                    <h3>Card name</h3>
                    {showDetails ? (
                      <div>
                        {virtualCard.name}{" "}
                        <div className="copy" onClick={() => copyName()}>
                          <IonIcon icon={copy} />
                          {nameCopied ? "Copied!" : "Copy"}
                        </div>
                      </div>
                    ) : (
                      <p>*********</p>
                    )}
                  </div>
                  <div className="vcard-details__card-data">
                    <h3>Card number</h3>
                    {showDetails ? (
                      <div>
                        {virtualCard.number}{" "}
                        <div className="copy" onClick={() => copyNumber()}>
                          <IonIcon icon={copy} />
                          {numberCopied ? "Copied!" : "Copy"}
                        </div>
                      </div>
                    ) : (
                      <p>**** **** **** ****</p>
                    )}
                  </div>
                  <div className="vcard-details__card-data-grid">
                    <div className="vcard-details__card-data">
                      <h3>Expiration</h3>
                      {showDetails ? (
                        <div>
                          {virtualCard.expiry}{" "}
                          <div
                            className="copy"
                            onClick={() => copyExpiration()}
                          >
                            <IonIcon icon={copy} />
                            {expirationCopied ? "Copied!" : "Copy"}
                          </div>
                        </div>
                      ) : (
                        <p>**/**</p>
                      )}
                    </div>
                    <div className="vcard-details__card-data">
                      <h3>CVV</h3>
                      {showDetails ? (
                        <div>
                          {virtualCard.cvv}{" "}
                          <div className="copy" onClick={() => copyCVV()}>
                            <IonIcon icon={copy} />
                            {cvvCopied ? "Copied!" : "Copy"}
                          </div>
                        </div>
                      ) : (
                        <p>***</p>
                      )}
                    </div>
                  </div>
                  <div className="vcard-details__card-data">
                    <h3>Billing Address</h3>
                    {showDetails ? (
                      <div>
                        {virtualCard.address}{" "}
                        <div className="copy" onClick={() => copyAddress()}>
                          <IonIcon icon={copy} />
                          {addressCopied ? "Copied!" : "Copy"}
                        </div>
                      </div>
                    ) : (
                      <p>********************</p>
                    )}
                  </div>
                  <div className="vcard-details__card-data">
                    <h3>Zip code</h3>
                    {showDetails ? (
                      <div>
                        {virtualCard.postal_code}{" "}
                        <div className="copy" onClick={() => copyPostalCode()}>
                          <IonIcon icon={copy} />
                          {postalCodeCopied ? "Copied!" : "Copy"}
                        </div>
                      </div>
                    ) : (
                      <p>****</p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "1.2rem",
                  paddingBottom: "2rem",
                }}
              >
                <h2>Processing</h2>
                <p>
                  We are currently preparing your card it may take a few minutes
                  before your card is active and you can start making payments
                </p>
              </div>
            )}
          </div>
          <VirtualCardTransactions
            cardId={virtualCard.id.toString()}
            key={virtualCard.id}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(VirtualCardDetails);
