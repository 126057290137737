import { Entries } from "./utils.types";

import { MONTHS, MONTHS_SHORT } from "../data";

export const getPagination = (numRecords: number, division: number) => {
  if (!numRecords) return 0;

  const pages = Math.floor(numRecords / division);

  return numRecords % division > 0 ? pages + 1 : pages;
};

export const getSkip = (page: number, division: number) =>
  (page - 1) * division;

export const sleep = (milliseconds: number) => {
  return new Promise((resolve, reject) => {
    const interval = window.setInterval(() => {
      resolve("Woken Up");
      window.clearInterval(interval);
    }, milliseconds);
  });
};

export const roundDP = (
  value: number | string,
  len: number,
  noCommas?: boolean
) => {
  value = Number(value).toFixed(len);

  const [bfDec, afDec] = value.toString().split(".");

  let s = "";

  if (afDec) {
    s = afDec.slice(0, len);
  }

  for (let i = s.length; i < len; i++) {
    s += "0";
  }

  return noCommas ? `${bfDec}.${s}` : `${Number(bfDec).toLocaleString()}.${s}`;
};

export const getRandomBG = () => {
  const colors = ["#4687d5", "#D5466C", "#46D58E", "#D5CA46", "#B846D5"];

  return colors[Math.floor(Math.random() * colors.length)];
};

export const getDateTime = (date_string: string, short: boolean = false) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${
    short ? MONTHS_SHORT[month] : MONTHS[month]
  } ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")}:${String(new_date.getSeconds()).padStart(
    2,
    "0"
  )} ${meridian}`;

  return [comp_date, comp_time];
};

export const getDate = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${MONTHS[month]} ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")}:${String(new_date.getSeconds()).padStart(
    2,
    "0"
  )} ${meridian}`;

  return `${comp_date} ${comp_time}`;
};

export const copyData = (
  text: string,
  stateUpdate: (val: any) => void,
  callback?: () => void
) => {
  navigator.clipboard.writeText(text).then(
    () => {
      stateUpdate(true);
      if (callback) callback();
      const interval = window.setInterval(() => {
        stateUpdate(false);
        window.clearInterval(interval);
      }, 1500);
    },
    () => {
      // Failed to copy do nothing
    }
  );
};

export const isNumber = (value: string) => {
  if (!value) return true;
  if ((value.match(/\./g)?.length || 0) > 1) return false;
  if (value.endsWith(".")) return true;

  const re = /^[0-9.\b]+$/;

  // if value is not blank, then test the regex
  return re.test(value);
};

export const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

type Assert = <T>(data: T | null) => asserts data is T;
export const assertNotNull: Assert = <T>(data: T | null): asserts data is T => {
  if (data === null) {
    throw new Error(`null was found unexpectedly`);
  }
};

export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
