import { useState, useEffect, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronDown,
  close,
  notifications as notificationsIcon,
} from "ionicons/icons";

import { tRootState } from "../../store";

import api_client from "../../api/client";

import useSelectBox, {
  getSelectBoxData,
} from "../../hooks/useSelectBox/useSelectBox";

import { getDateTime } from "../../utils/func";

// import NotificationsLoader from "../../loaders/NotificationsLoader/NotificationsLoader";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";

const filterData = {
  all: "All",
  read: "Read",
  unread: "Unread",
};

const Notifications = ({ closeHandler }: { closeHandler: () => void }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [filterSelectBox, filter, openFilterSelectBox] = useSelectBox<
    keyof typeof filterData
  >("Filter Transaction", getSelectBoxData(filterData), "all");

  const [url, setUrl] = useState(`/notifications/${filter}`);

  const [notifications, setNotifications] = useState<
    {
      id: number;
      title: string | null;
      msg: string;
      created_at: string;
      hash: string;
      type: string;
    }[]
  >([]);
  const [links, setLinks] = useState<
    {
      url: string | null;
      label: string;
      active: boolean;
    }[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [show, setShow] = useState(false);

  const clearNotifications = (e: MouseEvent<HTMLDivElement>) => {
    const targetEl = e.target as HTMLDivElement;

    targetEl.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Clearing notifications`;

    api_client({
      method: "GET",
      url: "/clear-notifications",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        setNotifications([]);
        setLinks([]);
      })
      .finally(() => {
        targetEl.innerHTML = "Clear Notificiations";
      });
  };

  useEffect(() => {
    setUrl(`/notifications/${filter}`);
  }, [filter]);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(false);

    api_client({
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (
          !res.data.success &&
          res.data.message !== "You have no new notifications."
        )
          throw new Error("");

        const noNewNotifications =
          res.data.message === "You have no new notifications.";

        let nots;

        if (noNewNotifications) {
          nots = [];
        } else {
          nots = res.data.message.data;

          setNotifications(nots);
          setLinks(res.data.message.links);
        }

        // Set notifications read in background
        return !nots.length
          ? new Promise((resolve, reject) => {
              resolve("No notification to set as read");
            })
          : api_client({
              method: "POST",
              url: "/read-notifications",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              data: JSON.stringify({
                last_id: nots[0].id,
              }),
            });
      })
      .then(() => {
        // do nothing
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, url, dispatch]);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      {filterSelectBox}
      <div
        className={cls(
          "notifications-block",
          show && "notifications-block--show"
        )}
      >
        <div className="notifications-block__header">
          <IonIcon icon={notificationsIcon} />
          Notifications
          <span className="notifications-block__close" onClick={closeHandler}>
            <IonIcon icon={close} />
          </span>
        </div>
        {!loading && !error && notifications.length ? (
          <div className="notifications-block__header2">
            <div className="transactions-pg__filter wallet-transactions__filter d-flex">
              Filter:
              <div className="select-box" onClick={openFilterSelectBox}>
                {filterData[filter!]} <IonIcon icon={chevronDown} />
              </div>
            </div>
            <div className="pagination">
              {links.map((link, i) => {
                return isNaN(+link.label) || link.active ? (
                  <button
                    className={link.active ? "active" : ""}
                    onClick={() => setUrl(link.url || "")}
                    key={link.label}
                  >
                    {i === 0 ? (
                      <>&#8249;</>
                    ) : i === links.length - 1 ? (
                      <>&#8250;</>
                    ) : (
                      link.label
                    )}
                  </button>
                ) : null;
              })}
            </div>
          </div>
        ) : null}
        <div className="notifications-block__notifications">
          {error ? (
            <div className="notifications-block__info">
              Error fetching notifications
            </div>
          ) : null}
          {loading ? <VerticalBarLoader /> : null}
          {!loading && !error && !notifications.length ? (
            <div className="notifications-block__info">
              You have no new notifications
            </div>
          ) : null}
          {!loading && !error && notifications.length
            ? notifications.map((notification) => {
                const [date, time] = getDateTime(notification.created_at);

                let handler;

                if (notification.type === "order") {
                  handler = () => navigate(`/order/${notification.hash}`);
                } else if (notification.type === "payment") {
                  handler = () => navigate(`/withdrawal/${notification.hash}`);
                } else if (notification.type === "bills") {
                  handler = () =>
                    navigate(`/transaction-details/bills/${notification.hash}`);
                } else if (notification.type === "deposit") {
                  handler = () => navigate(`/deposit/${notification.hash}`);
                } else if (notification.type === "received_wallet") {
                  handler = () =>
                    navigate(
                      `/transaction-details/crypto_received/${notification.hash}`
                    );
                } else if (notification.type === "sent_crypto") {
                  handler = () =>
                    navigate(
                      `/transaction-details/crypto_sent/${notification.hash}`
                    );
                } else if (notification.type === "internal_crypto") {
                  handler = () =>
                    navigate(
                      `/transaction-details/internal_wallets/${notification.hash}`
                    );
                } else if (notification.type === "internal_naira") {
                  handler = () =>
                    navigate(
                      `/transaction-details/internal_naira/${notification.hash}`
                    );
                } else if (notification.type === "virtual_cards") {
                  handler = () =>
                    navigate(
                      `/transaction-details/virtual_cards/${notification.hash}`
                    );
                }

                return (
                  <div
                    className="notification"
                    key={notification.id}
                    onClick={handler}
                  >
                    <button className="notification__btn"></button>
                    <div className="notification__main">
                      {notification.title ? (
                        <div className="notification__dates">
                          <p>{notification.title}</p>
                        </div>
                      ) : null}
                      <p className="notification__text">{notification.msg}</p>
                      <div className="notification__dates">
                        <p>{date}</p>
                        <p>{time}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {!loading && !error && notifications.length ? (
          <div
            className="notifications-block__clear"
            onClick={clearNotifications}
          >
            Clear Notifications
          </div>
        ) : null}
      </div>
      <div className="overlay" onClick={closeHandler}></div>
    </>
  );
};

export default Notifications;
