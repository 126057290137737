import { useState, useEffect, useRef, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBack, chevronDownOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updateWithdrawCrypto } from "../../store/cryptoSlice";
import { tBeneficiaries, tNetwork, tWallet } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import SetAuth from "../../components/SetAuth/SetAuth";
import Preloader from "../../components/Preloader/Preloader";
import PinGroup from "../../components/PinGroup/PinGroup";
import VerticalBarLoader from "../../loaders/VerticalBarLoader/VerticalBarLoader";
import AmountPercentage from "../../components/AmountPercentage/AmountPercentage";
import TransactionLimit from "../../components/TransactionLimit/TransactionLimit";
import BeneficiariesModal from "../../components/BeneficiariesModal/BeneficiariesModal";
import ContactVerification from "../../components/ContactVerification/ContactVerification";
import SelectNetworkModal from "../../components/CryptoWallet/SelectNetworkModal/SelectNetworkModal";

import { assertNotNull, isNumber, roundDP } from "../../utils/func";
import SetPin from "../../components/SetPin/SetPin";
import { getPrecision } from "../../utils/app";

type tRateInfo = {
  amount_in_coin: number;
  amount_in_dollar: "string";
  charge_in_coin: number;
  charge_in_dollar: number;
  total_in_coin: number;
  total_in_dollar: number;
};

const WithdrawCrypto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = useSearchParams()[0];

  const walletSymbol = searchParams.get("wallet");
  const networkId = searchParams.get("network");

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );
  const wallets = useSelector((state: tRootState) => state.cache.wallets);

  assertNotNull(userDetails);

  const { fetchWallets } = useData();

  const [wallet, setWallet] = useState<tWallet | null>(null);
  const [beneficiaries, setBeneficiaries] = useState<tBeneficiaries | null>(
    null
  );

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert(true);

  const [showNetworksModal, setShowNetworksModal] = useState(false);
  const [network, setNetwork] = useState<tNetwork | null>(null);

  const [address, setAddress] = useState("");

  const [amount, setAmount] = useState<number | string>("");

  const [fetchingRates, setFetchingRates] = useState(false);
  const [errorFetchingRates, setErrorFetchingRates] = useState(false);

  const frInterval = useRef<number | null>(null);

  const [rateInfo, setRateInfo] = useState<tRateInfo | null>(null);

  const [newBeneficiary, setNewBeneficiary] = useState(false);
  const [alias, setAlias] = useState("");

  const [pin, setPin] = useState("");

  const [showBeneficiariesModal, setShowBeneficiariesModal] = useState(false);

  const [authMethodCallback, setAuthMethodCallback] = useState<
    (() => void) | null
  >(null);
  const [contactVerificationCallback, setContactVerificationCallback] =
    useState<(() => void) | null>(null);

  const [showPinModal, setShowPinModal] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Should never be teuw
    if (!wallet) return;

    if (!network) return setMessage("warning", "Select network");

    if (!address)
      return setMessage("warning", "Enter recipient wallet address");

    if (!amount) return setMessage("warning", "Enter amount");

    if (newBeneficiary && !alias)
      return setMessage("warning", "Alias is required to add new beneficiary");

    if (newBeneficiary && alias.length > 10)
      return setMessage(
        "warning",
        "Alias should not be longer than 10 characters"
      );

    if (fetchingRates) return;
    if (!rateInfo) return setMessage("warning", "Error fetching rate");

    if (+amount > +wallet.dollar_balance)
      return setMessage("warning", "Insufficient balance");

    if (!userDetails.is_pin_set) return setShowPinModal(true);

    if (pin.length !== 4) return setMessage("warning", "Pin is required");

    setContactVerificationCallback(
      () => () =>
        setAuthMethodCallback(() => () => {
          submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
          submitBtnRef.current.setAttribute("disabled", "disabled");

          api_client({
            url: "/wallet/transfer/send",
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify({
              coin: wallet.symbol,
              address,
              amount,
              network_id: network?.id,
              beneficiary: newBeneficiary ? 1 : 0,
              alias,
              pin: pin,
            }),
          })
            .then((res) => {
              if (res.data.success) {
                dispatch(
                  updateWithdrawCrypto({
                    wallet,
                    transaction: res.data.sending,
                    message: res.data.message,
                  })
                );

                navigate("/withdraw-crypto-final");
              } else {
                return setMessage("warning", res.data.message);
              }
            })
            .catch((err) => {
              return setMessage("error", err.message);
            })
            .finally(() => {
              if (submitBtnRef.current) {
                submitBtnRef.current.removeAttribute("disabled");
                submitBtnRef.current.innerHTML = `Continue`;
              }
            });
        })
    );
  };

  useEffect(() => {
    clearMessage();
  }, [network, address, amount, newBeneficiary, alias, pin, clearMessage]);

  useEffect(() => {
    if (frInterval.current) window.clearInterval(frInterval.current);

    if (!wallet?.id || !network?.network_id || !amount) {
      setFetchingRates(false);
      setErrorFetchingRates(false);
      setRateInfo(null);
      return;
    }

    setFetchingRates(true);
    setErrorFetchingRates(false);
    setRateInfo(null);

    frInterval.current = window.setInterval(() => {
      api_client({
        url: `/wallet/rate/send?wallet_id=${wallet.id}&amount=${amount}&network_id=${network.network_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const rtInfo: tRateInfo = res.data;

          if (
            typeof rtInfo !== "object" ||
            !rtInfo.amount_in_coin ||
            Number.isNaN(+rtInfo.amount_in_coin) ||
            !rtInfo.charge_in_dollar ||
            Number.isNaN(+rtInfo.charge_in_dollar)
          ) {
            setErrorFetchingRates(true);
          } else {
            setRateInfo(res.data);
          }
        })
        .catch(() => {
          setErrorFetchingRates(true);
        })
        .finally(() => {
          setFetchingRates(false);
          if (frInterval.current) window.clearInterval(frInterval.current);
        });
    }, 3000);
  }, [amount, wallet?.id, accessToken, network?.network_id]);

  useEffect(() => {
    if (!wallet?.symbol) return;

    api_client({
      url: `/transfers/beneficiaries/${wallet.symbol}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (!res.data.success) throw new Error("");

        const allowedNetworks = wallet.networks
          .filter((ntw) => [2, 1].includes(ntw.capability))
          .map((ntw) => +ntw.network_id);

        const benRes = (res.data.message as tBeneficiaries).filter((ben) =>
          allowedNetworks.includes(+ben.sub_main)
        );

        setBeneficiaries(benRes);
      })
      .catch((err) => {
        // do nothing
      });
  }, [wallet?.symbol, accessToken, wallet?.networks]);

  useEffect(() => {
    if (wallets && walletSymbol && !wallet) {
      const walletSel = wallets?.find(
        (sWallet) =>
          sWallet.symbol.toLowerCase() === walletSymbol.toLowerCase() &&
          [2, 1].includes(sWallet.capability)
      );
      if (!walletSel) return navigate("/404");
      setWallet(walletSel);
      if (networkId)
        setNetwork(
          walletSel.networks.find(
            (ntw) =>
              ntw.id.toString() === networkId && [2, 1].includes(ntw.capability)
          ) || null
        );
    }
  }, [wallets, walletSymbol, wallet, networkId, navigate]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  if (!walletSymbol) return <Navigate to="/404" />;

  if (!wallet) return <Preloader />;

  return (
    <DashboardLayout>
      {showPinModal ? (
        <SetPin closeHandler={() => setShowPinModal(false)} />
      ) : null}
      <ContactVerification
        callback={contactVerificationCallback}
        closeHandler={() => setContactVerificationCallback(null)}
      />
      <SetAuth
        callback={authMethodCallback}
        closeHandler={() => setAuthMethodCallback(null)}
      />
      <SelectNetworkModal
        type="withdraw"
        wallet={wallet}
        selectedNetwork={network?.id || null}
        message="SEKIAPP only supports the following networks, be sure to verify wallet network. Sending to a wrong network can cause you to loose your coin forever."
        show={showNetworksModal}
        closeHandler={() => {
          setShowNetworksModal(false);
        }}
        handler={(network) => {
          setNetwork(network);
          setShowNetworksModal(false);
        }}
      />
      <BeneficiariesModal
        show={showBeneficiariesModal}
        image={wallet.image}
        isActive={(ben) =>
          +ben.sub_main === network?.network_id && address === ben.recipient
        }
        beneficiaries={beneficiaries}
        handler={(ben) => {
          setNetwork(
            wallet.networks.find((ntw) => ntw.network_id === +ben.sub_main)!
          );
          setAddress(ben.recipient);
          setShowBeneficiariesModal(false);
        }}
        closeHandler={() => setShowBeneficiariesModal(false)}
      />
      <form className="crypto-block" onSubmit={handleSubmit}>
        <div className="crypto-block__header">
          <div className="crypto-block__back">
            <span onClick={() => navigate(-1)}>
              <IonIcon icon={arrowBack} /> Back
            </span>
          </div>
          <h3 className="crypto-block__heading">Withdraw {wallet.coin}</h3>
        </div>
        <div className="crypto-block__body">
          <div className="crypto-block__balance-block">
            <p className="crypto-block__balance-text">
              Available {wallet.coin} balance
            </p>
            <p className="crypto-block__balance">
              {roundDP(wallet.balance, getPrecision(wallet.symbol))}{" "}
              {wallet.symbol.toUpperCase()}
            </p>
          </div>
          <div className="crypto-block__body-main">
            {beneficiaries && beneficiaries.length ? (
              <div className="beneficiaries">
                <div className="beneficiaries__header">
                  <h3 className="beneficiaries__heading">Beneficiaries</h3>
                  <span
                    className="transactions-section__link"
                    onClick={() => setShowBeneficiariesModal(true)}
                  >
                    View All
                  </span>
                </div>
                <div className="beneficiaries__beneficiaries">
                  {beneficiaries.map((beneficiary) => {
                    return (
                      <div
                        className={cls(
                          "beneficiary",
                          +beneficiary.sub_main === network?.network_id &&
                            address === beneficiary.recipient &&
                            "beneficiary--active"
                        )}
                        onClick={() => {
                          setNetwork(
                            wallet.networks.find(
                              (ntw) => ntw.network_id === +beneficiary.sub_main
                            )!
                          );
                          setAddress(beneficiary.recipient);
                        }}
                        key={beneficiary.id}
                      >
                        <img
                          src={wallet.image}
                          alt=""
                          className="beneficiary__name-logo"
                        />
                        <p className="beneficiary__name" title={`${alias}`}>
                          {beneficiary.recipient_alias.slice(0, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label>Network</label>
              <div
                className="select-box select-box--md"
                onClick={() => setShowNetworksModal(true)}
              >
                {network ? <img src={wallet.image} alt="" /> : null}
                <p>
                  {network
                    ? `${wallet.coin} - ${network.name}`
                    : "Select Network"}
                </p>
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
            {network ? (
              <div className="form-group">
                <label>
                  Recipient {wallet.coin} - {network.name} Address
                </label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Enter Recipient Bitcoin - Bitcoin Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            ) : null}
            <div className="form-group form-group--md">
              <p>MAX: ${roundDP(wallet.dollar_balance, 2)}</p>
              <div className="input-group">
                <button type="button">$</button>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) =>
                    e.target.value
                      ? isNumber(e.target.value)
                        ? setAmount(e.target.value)
                        : null
                      : setAmount("")
                  }
                />
              </div>
              {rateInfo ? (
                <div className="input-group">
                  <button type="button">{wallet?.symbol.toUpperCase()}</button>
                  <input
                    type="text"
                    value={rateInfo.amount_in_coin}
                    disabled
                    className="form-disabled"
                  />
                </div>
              ) : null}
              <AmountPercentage
                totalAmount={wallet.dollar_balance}
                handler={setAmount}
              />
            </div>
            {fetchingRates ? <VerticalBarLoader sm /> : null}
            {errorFetchingRates ? (
              <div className="text-center text-danger">
                Error fetching rates
              </div>
            ) : null}
            {rateInfo ? (
              <div className="crypto-block__rate-info">
                <p>
                  <IonIcon icon={alertCircle} /> TRANSACTION FEE: $
                  {roundDP(rateInfo.charge_in_dollar, 2)}
                </p>
                <p>
                  YOU ARE WITHDRAWING{" "}
                  {roundDP(
                    rateInfo.amount_in_coin,
                    getPrecision(wallet.symbol)
                  )}{" "}
                  {wallet.symbol.toUpperCase()}
                </p>
              </div>
            ) : null}
            {!beneficiaries?.find(
              (ben) =>
                +ben.sub_main === network?.network_id &&
                address === ben.recipient
            ) ? (
              <div className="accept">
                <input
                  type="checkbox"
                  className="accept__checkbox"
                  checked={newBeneficiary}
                  onChange={(e) => setNewBeneficiary(e.target.checked)}
                />
                <p>Save as beneficiary</p>
              </div>
            ) : null}
            {newBeneficiary ? (
              <div className="form-group">
                <label>Add Nickname</label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="e.g Johnny"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  maxLength={10}
                />
              </div>
            ) : null}
            <TransactionLimit type="external_wallets" />
          </div>
        </div>
        {userDetails.is_pin_set ? (
          <div className="crypto-block__pin crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span
              className="crypto-final__forgot-pin"
              onClick={() => navigate("/change-pin")}
            >
              Forgot PIN
            </span>
          </div>
        ) : null}
        <div className="crypto-block__footer">
          {message}
          <button
            className={cls(
              "form-button",
              network &&
                address &&
                amount &&
                rateInfo &&
                (!newBeneficiary ||
                  (newBeneficiary && alias && alias.length <= 10)) &&
                (!userDetails.is_pin_set || pin.length === 4) &&
                "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Continue
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(WithdrawCrypto);
