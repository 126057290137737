import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash-es";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tStatus } from "../../store/types/app.types";

import { updateStatus } from "../../store/cacheSlice";

import Status from "./Status";

const StatusSection = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const status = useSelector((state: tRootState) => state.cache.status);

  const [selectStatus, setSelectStatus] = useState<number | null>(null);

  useEffect(() => {
    api_client({
      url: "/status/topics",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const responseStatus = res.data.message;

          const loadStatusContents = async (status: tStatus) => {
            const workStatus = cloneDeep(status);

            for (const singleStatus of workStatus) {
              try {
                const contents = await api_client({
                  url: `/status/content/${singleStatus.id}`,
                  headers: { Authorization: `Bearer ${accessToken}` },
                });
                singleStatus.contents = contents.data.message;
              } catch (error) {
                // Do nothing
              }
            }

            const statuses = workStatus.filter((status) => status.contents);
            statuses.sort((a, b) => +b.id - +a.id);

            dispatch(updateStatus(statuses));
          };

          if (responseStatus) loadStatusContents(responseStatus);
        }
      })
      .catch((err) => {
        // do nothing
      });
  }, [accessToken, dispatch]);

  if (!status || !status.length) return null;

  return (
    <>
      {typeof selectStatus === "number" ? (
        <Status
          closeHandler={() => setSelectStatus(null)}
          data={status}
          selectStatus={selectStatus}
        />
      ) : null}
      <section className="status-section">
        {status.map((singleStatus, i) => (
          <div
            className="status-section__status"
            title={singleStatus.title}
            key={singleStatus.id}
            onClick={() => setSelectStatus(i)}
          >
            <div className="status-section__img-box">
              <img src={singleStatus.image} alt="" />
            </div>
            <p className="status-section__text">
              {singleStatus.title.slice(0, 15)}
              {singleStatus.title.length > 15 ? "..." : ""}
            </p>
          </div>
        ))}
      </section>
    </>
  );
};

export default StatusSection;
