import { Fragment } from "react";

const DashboardWalletCurrenciesLoader = () => {
  return (
    <>
      {new Array(10).fill(null).map((_, i) => (
        <Fragment key={i}>
          <div className="asset">
            <div className="asset__main">
              <div className="asset__currency">
                <div className="asset__currency-img placeholder round"></div>
                <span className="asset__currency-name color-transparent placeholder">
                  Bitcoin
                </span>
                <span className="color-transparent">|</span>
                <span className="color-transparent placeholder">BTC</span>
              </div>
              <p className="asset__value color-transparent placeholder">
                BTC 0.57272
              </p>
              <p className="asset__value1 color-transparent placeholder">
                ~ USD 15.40
              </p>
            </div>
          </div>
          <div className="asset-mobile">
            <div className="asset-mobile__currency-img placeholder round"></div>
            <div className="asset-mobile__wallet">
              <p className="asset-mobile__wallet-symbol color-transparent placeholder">
                BTC
              </p>
              <p className="asset-mobile__wallet-name color-transparent placeholder">
                Bitcoin
              </p>
            </div>
            <div className="asset-mobile__wallet-right">
              <p className="asset-mobile__balance color-transparent placeholder">
                BTC 0.57272
              </p>
              <p className="asset-mobile__wallet-name color-transparent placeholder">
                ~ USD 15.40
              </p>
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default DashboardWalletCurrenciesLoader;
