import { useSelector } from "react-redux";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { alertCircle, arrowBackOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

import PinGroup from "../../components/PinGroup/PinGroup";
import Preloader from "../../components/Preloader/Preloader";

const FreezeVirtualCard = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const virtualCards = useSelector(
    (state: tRootState) => state.cache.virtualCards
  );

  const { fetchVirtualCards } = useData();

  const virtualCard = virtualCards?.find((vCard) => vCard.id.toString() === id);

  const [pin, setPin] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pin.length !== 4) return setMessage("warning", "Enter pin");

    const submitBtn = submitBtnRef.current;

    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtn.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/virtual/freeze-card",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        card: virtualCard!.id,
        pin: pin,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return fetchVirtualCards();
      })
      .then(() => {
        navigate(`/virtual-card?card=${virtualCard!.id}`);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else if (err.message) {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtn) {
          submitBtn.removeAttribute("disabled");
          submitBtn.innerHTML = "Freeze Card";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [pin, clearMessage]);

  useEffect(() => {
    fetchVirtualCards().then((vCards) => {
      const fCard = vCards.find((vCard) => vCard.id.toString() === id);

      if (!fCard) navigate("/error");
    });
  }, [fetchVirtualCards, navigate, id]);

  if (!virtualCard) return <Preloader />;

  if (virtualCard.status !== "ACTIVE")
    return <Navigate to={`/virtual-card?card=${virtualCard.id}`} />;

  return (
    <DashboardLayout>
      <div className="crypto-block2 crypto-block2--md">
        <form
          className="crypto-block2__body crypto-block2__body--p0"
          onSubmit={handleSubmit}
        >
          <div className="crypto-block2__flex">
            <div className="virtual-card__header mb-small">
              <span className="virtual-card__back" onClick={() => navigate(-1)}>
                <IonIcon icon={arrowBackOutline} />
              </span>
              <h3 className="virtual-card__heading">Freeze card</h3>
            </div>
            <div className="virtual-card__message virtual-card__message--warning  mb-small">
              <IonIcon icon={alertCircle} />
              You can instantly freeze your card without having to delete the
              card.
            </div>
            <p>
              All transactions on the card will be declined till you unfreeze
              the card. Unfreezing your card costs a certain fee.
            </p>
          </div>
          <div className="crypto-block__pin crypto-final__pin-section">
            <h3 className="crypto-final__heading">Enter PIN</h3>
            <div className="register__pin-input-group-2">
              <PinGroup numInputs={4} pin={pin} handler={setPin} />
            </div>
            <span
              className="crypto-final__forgot-pin"
              onClick={() => navigate("/change-pin")}
            >
              Forgot PIN
            </span>
          </div>
          <div className="crypto-block2__flex">
            {message}
            <button
              className={cls(
                "form-button",
                pin.length === 4 && "form-button--active"
              )}
              style={{ width: "80%", alignSelf: "center" }}
              ref={submitBtnRef}
            >
              Freeze Card
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(FreezeVirtualCard);
