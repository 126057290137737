import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

import withAuth from "../../hoc/withAuth/withAuth";

import { tRootState } from "../../store";
import { clearCryptoData } from "../../store/cryptoSlice";

import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

const BulkOTCSuccessful = () => {
  const dispatch = useDispatch();

  const { wallet } = useSelector((state: tRootState) => state.crypto.bulk);

  const clearData = useCallback(
    () => dispatch(clearCryptoData("bulk")),
    [dispatch]
  );

  // useEffect(() => {
  //   const interval = window.setInterval(() => {
  //     clearData();
  //     window.clearInterval(interval);
  //   }, 4000);
  // }, [clearData]);

  if (!wallet) return <Navigate to="/dashboard" />;

  return (
    <DashboardLayout>
      <div className="pay-bills-block">
        <div className="text-center mb-medium">
          <div className="success-icon-block">
            <div>
              <IonIcon icon={checkmarkOutline} />
            </div>
          </div>
        </div>
        <h3 className="pay-bills-block__success-heading">
          OTC Request Received Successfully
        </h3>
        <p className="pay-bills-block__success-text">
          Our OTC Team will initiate a conversation with you immediately.
        </p>
        <div className="auth__footer">
          <button
            className="form-button form-button--active"
            onClick={clearData}
          >
            Go to dashboard
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(BulkOTCSuccessful);
