import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons";

import { tDashboardBanners } from "../../../store/types/app.types";
import { selectNetwork, selectWallet } from "../../../store/cacheSlice";

const DashboardBanner = ({ banners }: { banners: tDashboardBanners }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const imagesRef = useRef<HTMLImageElement[]>([]);
  const slideInterval = useRef<number | null>(null);

  const previousButton = useRef<HTMLButtonElement | null>(null);
  const nextButton = useRef<HTMLButtonElement | null>(null);

  const goToSlide = (slide: number) => {
    const slides = imagesRef.current;

    slides.forEach((s, i) => {
      if (!s) return;

      s.style.transform = `translateX(${100 * (i - slide)}%)`;
    });
  };

  useEffect(() => {
    const slides = imagesRef.current;

    let curSlide = 0;
    const maxSlide = slides.length;

    const nextSlide = () => {
      if (curSlide === maxSlide - 1) {
        curSlide = 0;
      } else {
        curSlide++;
      }
      goToSlide(curSlide);
    };

    const previousSlide = () => {
      if (curSlide === 0) {
        curSlide = maxSlide - 1;
      } else {
        curSlide--;
      }
      goToSlide(curSlide);
    };

    previousButton.current?.addEventListener("click", previousSlide);
    nextButton.current?.addEventListener("click", nextSlide);

    // Go to next slide every 10 seconds
    slideInterval.current = window.setInterval(nextSlide, 3000);
  }, []);

  return (
    <div className="dashboard-banner">
      <div
        className={cls(
          "dashboard-banner__controls",
          banners.length <= 1 && "hidden"
        )}
      >
        <button ref={previousButton}>
          <IonIcon icon={arrowBackOutline} />
        </button>
        <button ref={nextButton}>
          <IonIcon icon={arrowForwardOutline} />
        </button>
      </div>
      <div className="dashboard-banner__main">
        {banners.map((banner, i) => {
          const bannerImg = (
            <img
              src={banner.image}
              alt=""
              className="dashboard-banner__img"
              key={i}
              ref={(el) => {
                imagesRef.current[i] = el!;
              }}
            />
          );

          return (
            <Fragment key={i}>
              {banner.route === "link" ? (
                <a href={banner.url} rel="noreferrer" target="_blank">
                  {bannerImg}
                </a>
              ) : banner.page === "wallet_detail" ? (
                <div
                  onClick={() => {
                    dispatch(selectWallet(+banner.coin_id));
                    dispatch(selectNetwork(null));

                    navigate("/wallet");
                  }}
                >
                  {bannerImg}
                </div>
              ) : (
                <Link to={banner.page}>{bannerImg}</Link>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardBanner;
