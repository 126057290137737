import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { menuOutline, closeOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { changeMode } from "../../store/appSlice";

import { getProfilePicture } from "../../utils/user";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

const HomeHeader = () => {
  const dispatch = useDispatch();

  const uiMode = useSelector((state: tRootState) => state.app.uiMode);
  const { loggedIn, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <header className={`home-header ${navbarOpen ? "navbar--open" : ""}`}>
      <div className="home-header__container">
        <Link to="/">
          <div className="app-brand">
            <img src={sekiLogoLight} className="light-block" alt="" />
            <img src={sekiLogoDark} className="dark-block" alt="" />
          </div>
        </Link>
        <div className="navbar">
          <ul className="navbar__nav">
            <li className="navbar__nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  ["navbar__link ", isActive && "navbar__link--active"].join(
                    " "
                  )
                }
              >
                Home
              </NavLink>
            </li>
            <li className="navbar__nav-item">
              <NavLink
                to="/rates"
                className={({ isActive }) =>
                  ["navbar__link ", isActive && "navbar__link--active"].join(
                    " "
                  )
                }
              >
                Rates
              </NavLink>
            </li>
            <li className="navbar__nav-item">
              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  ["navbar__link ", isActive && "navbar__link--active"].join(
                    " "
                  )
                }
              >
                FAQ
              </NavLink>
            </li>
            <li className="navbar__nav-item">
              <a
                href="https://blog.sekiapp.com/"
                target="_blank"
                className="navbar__link"
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
            {!loggedIn ? (
              <li className="navbar__nav-item navbar__nav-item-mobile">
                <Link to="/register" className="navbar__link">
                  Register
                </Link>
              </li>
            ) : null}
          </ul>
          <div className="navbar__right">
            <input
              type="checkbox"
              className="toggle"
              checked={uiMode === "dark"}
              onChange={(e) =>
                dispatch(changeMode(e.target.checked ? "dark" : "light"))
              }
              style={{ flexShrink: 0 }}
            />

            {loggedIn && userDetails ? (
              <Link to="/dashboard" className="header-user-welcome">
                <img
                  src={getProfilePicture(userDetails.gender)}
                  className="header-user-welcome__img"
                  alt=""
                />
                <span
                  className="header-user-welcome__text"
                  style={{ textTransform: "capitalize" }}
                >
                  {userDetails.username}
                </span>
              </Link>
            ) : (
              <>
                <Link to="/register" className="navbar__link">
                  Register
                </Link>
                <Link to="/login" className="navbar__btn-link">
                  Login
                </Link>
              </>
            )}

            <button
              className="navbar__btn"
              onClick={() => setNavbarOpen((cur) => !cur)}
            >
              <IonIcon icon={menuOutline} />
              <IonIcon icon={closeOutline} />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
