import withAuth from "../../hoc/withAuth/withAuth";

import RegisterLayout from "../../layouts/RegisterLayout/RegisterLayout";

import { IonIcon } from "@ionic/react";
import { alertCircle, chevronDownOutline } from "ionicons/icons";

import authPhone from "../../assets/img/auth-phone.png";
import whatsapp from "../../assets/img/whatsapp.png";
import sms from "../../assets/img/sms.png";
import { FormEvent, useEffect, useRef, useState } from "react";
import useData from "../../hooks/useData/useData";
import { tCountries } from "../../store/types/app.types";
import useSelectBox, {
  getSelectBoxData,
  tSelectBoxGeneric,
} from "../../hooks/useSelectBox/useSelectBox";
import Preloader from "../../components/Preloader/Preloader";
import { useNavigate } from "react-router-dom";
import { assertNotNull, isNumber } from "../../utils/func";
import useAlert from "../../hooks/useAlert/useAlert";
import cls from "classnames";
import { updateUserDetails } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import api_client from "../../api/client";
import { tRootState } from "../../store";

const getCountrySelectData = (
  countries: tCountries | null
): tSelectBoxGeneric | null => {
  if (!countries) return countries;

  const data: tSelectBoxGeneric = {};

  for (const country of countries) {
    data[
      country.contry_code
    ] = `${country.country_name}(${country.contry_code})`;
  }

  return data;
};

const AddTelephone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, userDetails } = useSelector(
    (state: tRootState) => state.user
  );

  assertNotNull(userDetails);

  const [countries, setCountries] = useState<tCountries | null>(null);

  const { fetchCountries } = useData();

  const countriesSelectData = getCountrySelectData(countries);

  const [countrySelectBox, countryCode, openCountrySelectBox] =
    useSelectBox<string>(
      "Select Country",
      getSelectBoxData(countriesSelectData),
      userDetails.phone
        ? userDetails.phone.slice(0, userDetails.phone.length - 10)
        : null
    );

  const [phoneNumber, setPhoneNumber] = useState<number | "">(
    userDetails.phone
      ? +userDetails.phone.slice(userDetails.phone.length - 10)
      : ""
  );

  const [smsType, setSMSType] = useState<"whatsapp" | "sms">("whatsapp");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const country = countries?.find(
      (currentCountry) => currentCountry.contry_code === countryCode
    );

    if (!phoneNumber || !country || !smsType)
      return setMessage("warning", "Fill in all fields");

    if (userDetails.phone === `${countryCode}${phoneNumber}`)
      return navigate("/register/verify-telephone");

    submitBtnRef.current.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      method: "POST",
      url: "/add-phone-no",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        phone: `${country.contry_code}${phoneNumber}`,
        country_id: +country.id,
        option: smsType,
      }),
    })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", res.data.message);
          throw new Error("");
        }

        return api_client({
          url: "/get-profile",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then((res) => {
        if (!res.data.success) {
          setMessage("warning", "An error occured. Login with inputed details");
          throw new Error("");
        }

        dispatch(updateUserDetails(res.data.message));
        navigate("/register/verify-telephone");
      })
      .catch((err) => {
        if (err.message) setMessage("error", err.message);
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Next";
        }
      });
  };

  useEffect(() => {
    fetchCountries()
      .then((counts) => setCountries(counts))
      .catch((err) => {
        navigate("/error");
      });
  }, [fetchCountries, navigate]);

  useEffect(() => {
    clearMessage();
  }, [countryCode, phoneNumber, smsType, clearMessage]);

  if (!countries) return <Preloader />;

  return (
    <RegisterLayout>
      {countrySelectBox}
      <div className="register__img-box">
        <img src={authPhone} alt="" />
      </div>
      <h3 className="register__heading">Mobile number verification</h3>
      <p className="register__heading-sub-text">
        Input your mobile number to get verified
      </p>
      <form className="register__form" onSubmit={handleSubmit}>
        <div className="auth__form-main">
          <div className="form-group">
            <div className="input-group">
              <button type="button" onClick={openCountrySelectBox}>
                {countryCode ? countryCode : "Select country"}
                <IonIcon icon={chevronDownOutline} />
              </button>
              <input
                type="tel"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setPhoneNumber(+e.target.value)
                      : null
                    : setPhoneNumber("")
                }
                maxLength={10}
              />
            </div>
            <label className="form-label text-danger text-center">
              <IonIcon icon={alertCircle} />
              Enter phone number without country code
            </label>
          </div>
          <div className="otp-medium">
            <h3 className="otp-medium__heading">Receive OTP Via</h3>
            <div className="otp-medium__blocks">
              <div className="otp-medium__block">
                <img src={whatsapp} alt="" className="otp-medium__block-img" />
                <p className="otp-medium__block-name">Whatsapp (Instant)</p>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={smsType === "whatsapp" ? true : false}
                  onChange={(e) =>
                    setSMSType(e.target.checked ? "whatsapp" : "sms")
                  }
                />
              </div>
              <div className="otp-medium__block">
                <img src={sms} alt="" className="otp-medium__block-img" />
                <p className="otp-medium__block-name">SMS (0 - 5 MIN)</p>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={smsType === "sms" ? true : false}
                  onChange={(e) =>
                    setSMSType(e.target.checked ? "sms" : "whatsapp")
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="auth__footer">
          {message}
          <button
            className={cls(
              "form-button",
              countryCode && phoneNumber && smsType && "form-button--active"
            )}
            ref={submitBtnRef}
          >
            Next
          </button>
        </div>
      </form>
    </RegisterLayout>
  );
};

export default withAuth(AddTelephone);
