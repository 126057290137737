import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
  mailOpenOutline,
  callOutline,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
  logoLinkedin,
  logoTiktok,
} from "ionicons/icons";

import sekiLogoLight from "../../assets/img/sekilogo-light.png";
import sekiLogoDark from "../../assets/img/sekilogo-dark.png";

const HomeFooter = () => {
  return (
    <footer className="home-footer">
      <div className="container home-footer__container">
        <div>
          <img
            src={sekiLogoLight}
            alt=""
            className="home-footer__logo light-block"
          />
          <img
            src={sekiLogoDark}
            alt=""
            className="home-footer__logo dark-block"
          />
          <p className="home-footer__text">
            Easily Store, Sell, Buy And Swap Digital Assests From Anywhere in
            The World
          </p>
        </div>
        <div>
          <h3 className="home-footer__heading">Navigation</h3>
          <ul className="home-footer__nav">
            <li className="home-footer__nav-item">
              <Link to="/login" className="home-footer__nav-link">
                Login
              </Link>
            </li>
            <li className="home-footer__nav-item">
              <Link to="/about-us" className="home-footer__nav-link">
                About Us
              </Link>
            </li>
            <li className="home-footer__nav-item">
              <Link to="/faq" className="home-footer__nav-link">
                FAQ
              </Link>
            </li>
            <li className="home-footer__nav-item">
              <Link
                to="/terms-and-conditions"
                className="home-footer__nav-link"
              >
                Terms & Conditions
              </Link>
            </li>
            <li className="home-footer__nav-item">
              <Link to="/aml-policy" className="home-footer__nav-link">
                AML Policy
              </Link>
            </li>
            <li className="home-footer__nav-item">
              <Link to="/privacy-policy" className="home-footer__nav-link">
                Privacy Policy
              </Link>
            </li>
            <li className="home-footer__nav-item">
              <a
                href="https://blog.sekiapp.com/"
                target="_blank"
                className="home-footer__nav-link"
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="home-footer__heading">Contact</h3>
          <p className="home-footer__text">
            <IonIcon icon={mailOpenOutline} />
            info@sekiapp.com
          </p>
          <p className="home-footer__text">
            <IonIcon icon={callOutline} />
            <span>
              +2349072777999
              <br />
              +2348169661206
            </span>
          </p>
          <p className="home-footer__text"></p>
        </div>
        <div>
          <h3 className="home-footer__heading">Follow us on</h3>
          <div className="home-footer__socials">
            <a
              className="home-footer__social-link"
              href="https://web.facebook.com/sekiapp"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon icon={logoFacebook} />
            </a>
            <a
              className="home-footer__social-link"
              href="https://instagram.com/seki_app"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon icon={logoInstagram} />
            </a>
            <a
              className="home-footer__social-link"
              href="https://twitter.com/Seki_APP"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon icon={logoTwitter} />
            </a>
            <a
              className="home-footer__social-link"
              href="https://www.youtube.com/channel/UCHxxbmOdcfyAmXUfutFfBXA"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon icon={logoYoutube} />
            </a>
            <a
              className="home-footer__social-link"
              href="https://www.linkedin.com/company/sekiapp/"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon icon={logoLinkedin} />
            </a>
            <a
              className="home-footer__social-link"
              href="https://www.tiktok.com/@seki_app"
              target="_blank"
              rel="noreferrer"
            >
              <IonIcon icon={logoTiktok} />
            </a>
          </div>
        </div>
        <p className="home-footer__footer">
          SekiApp is an evolution from MyBTC Nigeria (2016-2021) <br />
          With <span>♥</span> from all of us at SekiApp (c) 2021-
          {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default HomeFooter;
