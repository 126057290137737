import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  barChart,
  caretDownOutline,
  closeOutline,
  logOut,
  menuOutline,
  notifications,
  person,
  receipt,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { changeMode } from "../../store/appSlice";

import { assertNotNull, roundDP } from "../../utils/func";

import VerifyAccountModal from "../VerifyAccountModal/VerifyAccountModal";
import ManualUnverified from "../VerifyAccountModal/ManualUnverifiedModal";

import sekiLogoDark from "../../assets/img/sekilogo-dark.png";
import Notifications from "../Notifications/Notifications";

const DashbordHeader = () => {
  const dispatch = useDispatch();

  const uiMode = useSelector((state: tRootState) => state.app.uiMode);

  const navigate = useNavigate();

  const {
    userDetails,
    settings: { hideTotalBalance },
  } = useSelector((state: tRootState) => state.user);

  assertNotNull(userDetails);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);
  const [showManualUnverifiedModal, setShowManualUnverifiedModal] =
    useState(false);

  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  const noLinkPath = `${window.location.href}#`;

  return (
    <>
      {showNotificationsModal ? (
        <Notifications closeHandler={() => setShowNotificationsModal(false)} />
      ) : null}
      <ManualUnverified
        closeHandler={() => setShowManualUnverifiedModal(false)}
        show={showManualUnverifiedModal}
      />
      <VerifyAccountModal
        closeHandler={() => setShowVerifyAccountModal(false)}
        show={showVerifyAccountModal}
      />
      <header
        className={cls("dashboard-header", navbarOpen && "navbar2--open")}
      >
        <div className="dashboard-header__container">
          <div className="app-brand">
            <Link to="/">
              <img src={sekiLogoDark} alt="" />
            </Link>
          </div>
          <ul className="navbar2">
            <li className="navbar2__item">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  cls("navbar2__link", isActive && "navbar2__link--active")
                }
              >
                Dashboard
              </NavLink>
            </li>
            <li className="navbar2__item">
              <NavLink
                to="/wallet"
                className={({ isActive }) =>
                  cls("navbar2__link", isActive && "navbar2__link--active")
                }
              >
                Wallets
              </NavLink>
            </li>
            <li className="navbar2__item">
              <NavLink
                to="/virtual-cards"
                className={({ isActive }) =>
                  cls("navbar2__link", isActive && "navbar2__link--active")
                }
              >
                Virtual Cards
              </NavLink>
            </li>
            <li className="navbar2__item navbar2__item-has-submenu">
              <Link to={noLinkPath} className="navbar2__link">
                Giftcard <IonIcon icon={caretDownOutline} />
              </Link>
              <div className="navbar2__sub-menu">
                <ul className="navbar2__sub-menu-menu">
                  <li className="navbar2__sub-menu-item">
                    <NavLink
                      to="/gift-cards"
                      className={({ isActive }) =>
                        cls(
                          "navbar2__sub-menu-link",
                          isActive && "navbar2__sub-menu-link--active"
                        )
                      }
                    >
                      Start Giftcard Trade
                    </NavLink>
                  </li>
                  <li className="navbar2__sub-menu-item">
                    <NavLink
                      to="/orders"
                      className={({ isActive }) =>
                        cls(
                          "navbar2__sub-menu-link",
                          isActive && "navbar2__sub-menu-link--active"
                        )
                      }
                    >
                      My Orders
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="navbar2__item">
              <NavLink
                to="/pay-bills"
                className={({ isActive }) =>
                  cls("navbar2__link", isActive && "navbar2__link--active")
                }
              >
                Paybills
              </NavLink>
            </li>
            <li className="navbar2__item navbar2__item-has-submenu">
              <Link to={noLinkPath} className="navbar2__link">
                Account <IonIcon icon={caretDownOutline} />
              </Link>
              <div className="navbar2__sub-menu">
                <ul className="navbar2__sub-menu-menu">
                  <li className="navbar2__sub-menu-item">
                    <NavLink
                      to="/account"
                      className={({ isActive }) =>
                        cls(
                          "navbar2__sub-menu-link",
                          isActive && "navbar2__sub-menu-link--active"
                        )
                      }
                    >
                      <IonIcon icon={person} />
                      My Account
                    </NavLink>
                  </li>
                  <li className="navbar2__sub-menu-item">
                    <NavLink
                      to="/transactions"
                      className={({ isActive }) =>
                        cls(
                          "navbar2__sub-menu-link",
                          isActive && "navbar2__sub-menu-link--active"
                        )
                      }
                    >
                      <IonIcon icon={barChart} />
                      Transaction History
                    </NavLink>
                  </li>
                  <li className="navbar2__sub-menu-item">
                    <NavLink
                      to="/account-statement"
                      className={({ isActive }) =>
                        cls(
                          "navbar2__sub-menu-link",
                          isActive && "navbar2__sub-menu-link--active"
                        )
                      }
                    >
                      <IonIcon icon={receipt} />
                      Account Statement
                    </NavLink>
                  </li>
                  <li className="navbar2__sub-menu-item">
                    <Link to="/sign-out" className="navbar2__sub-menu-link">
                      <IonIcon icon={logOut} />
                      Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="navbar2__item navbar2__wallet-btns">
              <Link to={noLinkPath} className="navbar2__wallet-btn">
                NGN{" "}
                <span className={hideTotalBalance ? "font-hidden" : ""}>
                  {hideTotalBalance ? "*****" : roundDP(userDetails.balance, 2)}
                </span>
              </Link>
              <button
                className="navbar2__wallet-btn"
                onClick={
                  userDetails.is_verified
                    ? userDetails.manual_unverified === 0
                      ? () => navigate("/deposit")
                      : () => setShowManualUnverifiedModal(true)
                    : () => setShowVerifyAccountModal(true)
                }
              >
                Deposit
              </button>
              <Link to="/withdraw" className="navbar2__wallet-btn">
                Withdraw
              </Link>
            </li>
          </ul>
          <div className="navbar2__right">
            <input
              type="checkbox"
              className="toggle toggle--1"
              checked={uiMode === "dark"}
              onChange={(e) =>
                dispatch(changeMode(e.target.checked ? "dark" : "light"))
              }
            />
            <button
              className="navbar2__btn"
              onClick={() => setNavbarOpen((open) => !open)}
            >
              <IonIcon icon={menuOutline} />
              <IonIcon icon={closeOutline} />
            </button>
            <p
              className="header-notification"
              onClick={() => setShowNotificationsModal(true)}
            >
              <IonIcon icon={notifications} />
              {+userDetails.notifications > 1 ? (
                <span className="header-notification__number">
                  {+userDetails.notifications >= 100
                    ? "99+"
                    : userDetails.notifications}
                </span>
              ) : null}
            </p>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashbordHeader;
