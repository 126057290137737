import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import {
  tStatus,
  tWallets,
  tGiftCards,
  tBeneficiaries,
  tBanks,
  tdepositBanks,
  tSKPHistory,
  tTransactionLimit,
  tBulkCryptoBanner,
  tVolumes,
  tBuyGiftCards,
  tServices,
  tDashboardBanner,
  tDashboardBanners,
  tVirtualCards,
  tVirtualCardThemes,
  tVirtualCardThemeTypes,
  tVirtualCardSummary,
  tVirtualCard,
  tFundVirtualCardRate,
} from "./types/app.types";
import { SelectBoxDataType } from "../components/SelectBox/SelectBox";

export type tTransactionLimits =
  | "external_wallets"
  | "naira_withdrawal"
  | "bills"
  | "internal_naira"
  | "internal_wallets";

type CacheType = {
  status: tStatus | null;
  wallets: tWallets | null;
  vcardWallets: tWallets | null;
  giftCards: tGiftCards | null;
  buyGiftCards: tBuyGiftCards | null;
  wallet: {
    walletId: number | null;
    networkId: number | null;
  };
  warnings: {
    [key: string]: string;
  };
  withdrawalBeneficiaries: tBeneficiaries | null;
  cryptoTransferBeneficiaries: tBeneficiaries | null;
  nairaTransferBeneficiaries: tBeneficiaries | null;
  billsBeneficiaries: {
    [key in
      | "airtime"
      | "cable"
      | "data"
      | "electricity"]: tBeneficiaries | null;
  };
  withdrawalBanks: tBanks | null;
  depositAddBanks: tBanks | null;
  depositBanks: tdepositBanks | null;
  deposit: {
    min: number | null;
    max: number | null;
  };
  bankAdded: boolean;
  pin: {
    message: string | null;
    changedMessage: string | null;
  };
  influencers:
    | {
        username: string;
        gender: string;
      }[]
    | null;
  skphistory: tSKPHistory | null;
  skpConverted: {
    type: "skp" | "iskp";
    points: number;
    nairaValue: number;
  } | null;
  transactionLimits: {
    [key in tTransactionLimits]: tTransactionLimit | null;
  };
  depositFee: number | null;
  depositFeeText: string | null;
  bulkCryptoBanner: tBulkCryptoBanner | null;
  webBulkCryptoBanner: tBulkCryptoBanner | null;
  dashboardBanners: tDashboardBanners | null;
  volumes: tVolumes;
  volumesSelectData: SelectBoxDataType;
  services: tServices;
  servicesSelectData: SelectBoxDataType;
  virtualCardSummary: {
    [key in tVirtualCardThemeTypes]: tVirtualCardSummary | null;
  };
  virtualCardThemes: {
    [key in tVirtualCardThemeTypes]: tVirtualCardThemes | null;
  };
  virtualCards: tVirtualCards | null;
  fundVirtualCardRates: {
    [key: string]: tFundVirtualCardRate | undefined;
  };
  dashboardPopup: tDashboardBanner | null;
};

const CacheInitialState: CacheType = {
  status: null,
  wallets: null,
  vcardWallets: null,
  giftCards: null,
  buyGiftCards: null,
  wallet: {
    walletId: null,
    networkId: null,
  },
  warnings: {},
  withdrawalBeneficiaries: null,
  cryptoTransferBeneficiaries: null,
  nairaTransferBeneficiaries: null,
  billsBeneficiaries: {
    airtime: null,
    cable: null,
    data: null,
    electricity: null,
  },
  withdrawalBanks: null,
  depositAddBanks: null,
  depositBanks: null,
  deposit: {
    min: null,
    max: null,
  },
  bankAdded: false,
  pin: {
    message: null,
    changedMessage: null,
  },
  influencers: null,
  skphistory: null,
  skpConverted: null,
  transactionLimits: {
    external_wallets: null,
    naira_withdrawal: null,
    bills: null,
    internal_naira: null,
    internal_wallets: null,
  },
  depositFee: null,
  depositFeeText: null,
  bulkCryptoBanner: null,
  webBulkCryptoBanner: null,
  dashboardBanners: null,
  volumes: [],
  volumesSelectData: [],
  services: [],
  servicesSelectData: [],
  virtualCardSummary: { mastercard: null, visa: null },
  virtualCardThemes: { mastercard: null, visa: null },
  virtualCards: null,
  fundVirtualCardRates: {},
  dashboardPopup: null,
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateStatus(state, { payload }: PayloadAction<tStatus>) {
      state.status = payload;

      saveState("cache", current(state));
    },
    updateWallets(state, { payload }: PayloadAction<tWallets>) {
      state.wallets = payload;

      saveState("cache", current(state));
    },
    updateVcardWallets(state, { payload }: PayloadAction<tWallets>) {
      state.vcardWallets = payload;

      saveState("cache", current(state));
    },
    updateGiftCards(state, { payload }: PayloadAction<tGiftCards>) {
      state.giftCards = payload;

      saveState("cache", current(state));
    },
    updateBuyGiftCards(state, { payload }: PayloadAction<tBuyGiftCards>) {
      state.buyGiftCards = payload;

      saveState("cache", current(state));
    },
    updateWarnings(state, { payload }: PayloadAction<CacheType["warnings"]>) {
      state.warnings = payload;

      saveState("cache", current(state));
    },
    selectWallet(state, { payload }) {
      state.wallet.walletId = payload;

      saveState("cache", current(state));
    },
    selectNetwork(state, { payload }) {
      state.wallet.networkId = payload;

      saveState("cache", current(state));
    },
    updateWithdrawalBeneficiaries(
      state,
      { payload }: PayloadAction<tBeneficiaries>
    ) {
      state.withdrawalBeneficiaries = payload;

      saveState("cache", current(state));
    },
    updateCryptoTransferBeneficiaries(
      state,
      { payload }: PayloadAction<tBeneficiaries>
    ) {
      state.cryptoTransferBeneficiaries = payload;

      saveState("cache", current(state));
    },
    updateNairaTransferBeneficiaries(
      state,
      { payload }: PayloadAction<tBeneficiaries>
    ) {
      state.nairaTransferBeneficiaries = payload;

      saveState("cache", current(state));
    },
    updateBillsBeneficiaries(
      state,
      { payload }: PayloadAction<CacheType["billsBeneficiaries"]>
    ) {
      state.billsBeneficiaries = payload;

      saveState("cache", current(state));
    },
    updateWithdrawalBanks(state, { payload }: PayloadAction<tBanks>) {
      state.withdrawalBanks = payload;

      saveState("cache", current(state));
    },
    updateDepositAddBanks(state, { payload }: PayloadAction<tBanks>) {
      state.depositAddBanks = payload;

      saveState("cache", current(state));
    },
    updateDepositBanks(
      state,
      {
        payload,
      }: PayloadAction<{ banks: tdepositBanks; min: number; max: number }>
    ) {
      state.depositBanks = payload.banks;
      state.deposit.min = payload.min;
      state.deposit.max = payload.max;

      saveState("cache", current(state));
    },
    updateBankAdded(state, { payload }: PayloadAction<boolean>) {
      state.bankAdded = payload;

      saveState("cache", current(state));
    },
    updatePinMessage(state, { payload }: PayloadAction<string>) {
      state.pin.message = payload;

      saveState("cache", current(state));
    },
    updatePinChangedMessage(state, { payload }: PayloadAction<string>) {
      state.pin.changedMessage = payload;

      saveState("cache", current(state));
    },
    clearPinData(state) {
      state.pin.message = null;
      state.pin.changedMessage = null;

      saveState("cache", current(state));
    },
    updateInfluencers(
      state,
      { payload }: PayloadAction<CacheType["influencers"]>
    ) {
      state.influencers = payload;

      saveState("cache", current(state));
    },
    updateSKPHistory(state, { payload }: PayloadAction<tSKPHistory>) {
      state.skphistory = payload;

      saveState("cache", current(state));
    },
    updateSKPConverted(
      state,
      { payload }: PayloadAction<CacheType["skpConverted"]>
    ) {
      state.skpConverted = payload;

      saveState("cache", current(state));
    },
    updateTransactionLimit(
      state,
      {
        payload,
      }: PayloadAction<{
        key: keyof CacheType["transactionLimits"];
        value: tTransactionLimit;
      }>
    ) {
      state.transactionLimits[payload.key] = payload.value;

      saveState("cache", current(state));
    },
    updateDepositFee(
      state,
      { payload }: PayloadAction<{ fee: number; text: string }>
    ) {
      state.depositFee = payload.fee;
      state.depositFeeText = payload.text;

      saveState("cache", current(state));
    },
    updateBulkCryptoBanner(
      state,
      { payload }: PayloadAction<tBulkCryptoBanner>
    ) {
      state.bulkCryptoBanner = payload;

      saveState("cache", current(state));
    },
    updateWebBulkCryptoBanner(
      state,
      { payload }: PayloadAction<tBulkCryptoBanner>
    ) {
      state.webBulkCryptoBanner = payload;

      saveState("cache", current(state));
    },
    updateDashboardBanners(
      state,
      { payload }: PayloadAction<tDashboardBanners>
    ) {
      state.dashboardBanners = payload;

      saveState("cache", current(state));
    },
    updateVolumes(
      state,
      {
        payload,
      }: PayloadAction<{
        volumes: tVolumes;
        volumesSelectData: SelectBoxDataType;
      }>
    ) {
      state.volumes = payload.volumes;
      state.volumesSelectData = payload.volumesSelectData;

      saveState("cache", current(state));
    },
    updateServices(
      state,
      {
        payload,
      }: PayloadAction<{
        services: tServices;
        servicesSelectData: SelectBoxDataType;
      }>
    ) {
      state.services = payload.services;
      state.servicesSelectData = payload.servicesSelectData;

      saveState("cache", current(state));
    },
    updateVirtualCardSummary(
      state,
      {
        payload,
      }: PayloadAction<{
        key: "mastercard" | "visa";
        cardSummary: tVirtualCardSummary;
      }>
    ) {
      state.virtualCardSummary[payload.key] = payload.cardSummary;

      saveState("cache", current(state));
    },
    updateVirtualCardThemes(
      state,
      {
        payload,
      }: PayloadAction<{
        key: "mastercard" | "visa";
        cardThemes: tVirtualCardThemes;
      }>
    ) {
      state.virtualCardThemes[payload.key] = payload.cardThemes;

      saveState("cache", current(state));
    },
    updateVirtualCards(state, { payload }: PayloadAction<tVirtualCards>) {
      state.virtualCards = payload;

      saveState("cache", current(state));
    },
    updateVirtualCard(
      state,
      {
        payload: { virtualCardId, virtualCardDetails },
      }: PayloadAction<{
        virtualCardId: string;
        virtualCardDetails: tVirtualCard;
      }>
    ) {
      if (!state.virtualCards) state.virtualCards = [];

      const virtualCardIndex = state.virtualCards!.findIndex(
        (vCard) => vCard.id.toString() === virtualCardId
      );

      if (virtualCardIndex === -1) {
        state.virtualCards.push(virtualCardDetails);
      } else {
        state.virtualCards[virtualCardIndex] = virtualCardDetails;
      }

      saveState("cache", current(state));
    },
    updateFundVirtualCardRate(
      state,
      {
        payload,
      }: PayloadAction<{
        key: string;
        rate: tFundVirtualCardRate;
      }>
    ) {
      state.fundVirtualCardRates[payload.key] = payload.rate;

      saveState("cache", current(state));
    },
    updateDashboardPopup(
      state,
      { payload }: PayloadAction<tDashboardBanner | null>
    ) {
      state.dashboardPopup = payload;

      saveState("cache", current(state));
    },
  },
});

export const {
  updateStatus,
  updateWallets,
  updateVcardWallets,
  updateGiftCards,
  updateBuyGiftCards,
  updateWarnings,
  selectWallet,
  selectNetwork,
  updateBillsBeneficiaries,
  updateWithdrawalBeneficiaries,
  updateCryptoTransferBeneficiaries,
  updateNairaTransferBeneficiaries,
  updateWithdrawalBanks,
  updateDepositAddBanks,
  updateDepositBanks,
  updateBankAdded,
  updatePinMessage,
  updatePinChangedMessage,
  clearPinData,
  updateInfluencers,
  updateSKPHistory,
  updateSKPConverted,
  updateTransactionLimit,
  updateDepositFee,
  updateBulkCryptoBanner,
  updateWebBulkCryptoBanner,
  updateDashboardBanners,
  updateVolumes,
  updateServices,
  updateVirtualCardSummary,
  updateVirtualCardThemes,
  updateVirtualCards,
  updateVirtualCard,
  updateFundVirtualCardRate,
  updateDashboardPopup,
} = cacheSlice.actions;

export default cacheSlice.reducer;
